import React from 'react'

import { Icon, DecimalsFixed } from 'components'
import { CryptoElement, CryptoName, TextWarning, TextSuccess } from './Style'

const CurrencyElement = (value: string) => {
  return (
    value && (
      <CryptoElement>
        <Icon
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          name={value.toLowerCase()}
        />
        <CryptoName>{value.toUpperCase()}</CryptoName>
      </CryptoElement>
    )
  )
}

const PendingFundsComponent = (value: string, currency: string) => (
  <TextWarning>
    {DecimalsFixed(value, currency === 'USD' || currency === 'EUR' ? 'fiat' : 'crypto')} {currency}
  </TextWarning>
)

const AvailableBalanceComponent = (value: string, currency: string) => (
  <TextSuccess>
    {DecimalsFixed(value, currency === 'USD' || currency === 'EUR' ? 'fiat' : 'crypto')} {currency}
  </TextSuccess>
)

const Text = (value: string, currency: string) => {
  return (
    <span>
      {DecimalsFixed(value, currency === 'USD' || currency === 'EUR' ? 'fiat' : 'crypto')} {currency}
    </span>
  )
}

export const COLUMNS = [
  {
    title: 'Asset',
    key: 'currency',
    render: (props: string) => CurrencyElement(props),
  },
  {
    title: 'Balance',
    key: 'total',
    render: (props: string, render: { currency: string }) => Text(props, render.currency),
  },
  {
    title: 'Sent',
    key: 'send',
    render: (props: string, render: { currency: string }) => Text(props, render.currency),
  },
  {
    title: 'Pending',
    key: 'pending',
    render: (props: string, render: { currency: string }) => PendingFundsComponent(props, render.currency),
  },
  {
    title: 'Available',
    key: 'available',
    render: (props: string, render: { currency: string }) => AvailableBalanceComponent(props, render.currency),
  },
]
