import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { invoices, getInvoices } from './Invoices'
import { invoice, checkInvoiceRequest, createInvoiceRequest } from './Invoice'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    invoice,
    invoices,
  }),
  [DOMAIN_STATE]: combineReducers({
    getInvoices,
    checkInvoiceRequest,
    createInvoiceRequest,
  }),
})
