import React from 'react'

const ZUsd = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <circle cx='10' cy='10' r='10' fill='#000' />
    <path
      fill='#ffa451'
      fillRule='evenodd'
      d='M10.605 3.477a.605.605 0 00-1.21 0v5.999l-4.472 4.47a.605.605 0 00.429 1.034h4.043v1.543a.605.605 0 101.21 0V9.977l3.925-3.924a.605.605 0 00-.428-1.033h-3.497V3.477zm0 2.753v2.035L12.64 6.23h-2.035zm-1.21 4.958L6.813 13.77h2.582v-2.582z'
      clipRule='evenodd'
    />
    <path
      fill='#ffa451'
      d='M8.184 5.02H5.352a.605.605 0 000 1.21h2.832V5.02zm6.484 9.96h-2.852v-1.21h2.852a.605.605 0 010 1.21z'
    />
  </svg>
)

export { ZUsd }
