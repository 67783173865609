import { createSelector } from 'reselect'

import { ToastState } from './@types'
import { DOMAIN, UI_STATE } from './constants'

const selectUi = (state: ToastState) => state[DOMAIN][UI_STATE]

export const selectToasts = () => createSelector(selectUi, subState => subState.toasts)

export const selectToast = (name: string) =>
  createSelector(selectToasts(), subState => subState.find(m => m.name === name))
