import React, { Fragment, Component } from 'react'
import { ChangeDetails } from '../../@types'

import { Button, Icon, KeysListener } from 'components'
import {
  Wrap,
  TextSend,
  TitleSend,
  IconWrapSend,
  CurrentAddress,
  NewAddressInput,
  CurrentAddressLabel,
  CurrentAddressValue,
} from './Style'

type State = {
  value: string
  requestSend: boolean
}

type Props = {
  currentValue: string
  closeModal: () => void
  type: ChangeDetails['detail_type']
  changeDetails: (data: ChangeDetails) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TITLE: any = {
  approach_currency: 'Approach company',
  callback_url: 'Call Back URL',
  btc_address: 'Bitcoin withdrawal address',
  eth_address: 'Ethereum withdrawal address',
  bank_detail_eur: 'Fiat withdrawal address',
  bank_detail_usd: 'Fiat withdrawal address',
} as const

class NewRequest extends Component<Props, State> {
  public state = {
    value: '',
    requestSend: false,
  }

  public handleValueChange = (value: string) => {
    this.setState(() => ({ value }))
  }

  public handleClose = () => {
    const { closeModal } = this.props

    closeModal()
  }

  public handleCreate = () => {
    const { changeDetails, type } = this.props
    const { value } = this.state

    if (value) {
      changeDetails({
        detail_type: type,
        detail_new_value: value,
      })
      this.setState(() => ({ requestSend: true }))
    }
  }

  public render() {
    const { currentValue, type } = this.props
    const { value, requestSend } = this.state

    return (
      <Fragment>
        {!requestSend ? (
          <Wrap>
            <KeysListener handleSubmit={this.handleCreate}>
              <CurrentAddress>
                <CurrentAddressLabel>Current {TITLE[type]}</CurrentAddressLabel>
                <CurrentAddressValue>{currentValue}</CurrentAddressValue>
              </CurrentAddress>

              <NewAddressInput
                value={value}
                label={'New ' + TITLE[type]}
                placeholder='Paste new address here'
                onChange={this.handleValueChange}
              />

              <Button onClick={this.handleCreate}>Send request</Button>
            </KeysListener>
          </Wrap>
        ) : (
          <Fragment>
            <IconWrapSend>
              <Icon name='tick' size='auto' />
            </IconWrapSend>
            <TitleSend>Request has been sent</TitleSend>
            <TextSend>Our manager will contact you in&nbsp;24&nbsp;hours</TextSend>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export { NewRequest }
