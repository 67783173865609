import { Staff } from '../@types'
import {
  SET_STAFF,
  GET_STAFF_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  REMOVE_USER_FAILURE,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_CLEAR,
} from '../constants'

export const getStaffsRequest = () => {
  return {
    type: GET_STAFF_REQUEST,
  } as const
}

export const getStaffsFailure = (errorMessage: string) => {
  return {
    type: GET_STAFF_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getStaffsSuccess = () => {
  return {
    type: GET_STAFF_SUCCESS,
  } as const
}

export const setStaffs = (data: Staff[]) => {
  return {
    type: SET_STAFF,
    payload: { data },
  } as const
}

export const removeUserRequest = (id: number) => {
  return {
    type: REMOVE_USER_REQUEST,
    payload: { id },
  } as const
}

export const removeUserFailure = (errorMessage: string) => {
  return {
    type: REMOVE_USER_FAILURE,
    payload: { errorMessage },
  } as const
}

export const removeUserSuccess = () => {
  return {
    type: REMOVE_USER_SUCCESS,
  } as const
}

export const removeUserClear = () => {
  return {
    type: REMOVE_USER_CLEAR,
  } as const
}
