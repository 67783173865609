import React, { useEffect, useState } from 'react'

import { generateString } from 'utils'

const DaoBet = () => {
  const [id, setId] = useState(generateString())
  useEffect(() => {
    setId(generateString())

    return () => {
      setId(generateString())
    }
  }, [setId])

  return (
    <svg viewBox='0 0 512 512'>
      <defs>
        <linearGradient id={id} x1='0%' x2='100%' y1='50%' y2='50%'>
          <stop offset='0%' stopColor='#597ae8' />
          <stop offset='100%' stopColor='#2b5ee2' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='nonzero'>
        <circle cx='256' cy='256' r='256' fill={`url(#${id})`} />
        <path
          fill='#fff'
          d='M334.614 265.139a84.497 84.497 0 017.753 10.716c15.875 27.053 15.872 64.561-.045 91.684a83.94 83.94 0 01-32.918 30.731 97.277 97.277 0 01-46.385 11.04H168.8v-117h41.097v74.77h53.157l.14.003c12.638.445 24.932-4.17 34.11-12.785 8.63-8.274 13.68-20.913 13.28-33.548l-.003-.127.004-.127c.402-12.59-4.67-25.313-13.243-33.517a47.465 47.465 0 00-.552-.51 116.388 116.388 0 0017.406-7.355 102.788 102.788 0 0020.418-13.975zm-124.717-91.905v60.846h49.122l.14.003c12.906.454 25.458-4.26 34.837-13.063a44.18 44.18 0 0013.588-33.29l-.004-.127.004-.128a44.054 44.054 0 00-13.558-33.225 48.459 48.459 0 00-34.867-13.092l-.14.003H168.8V103.01h90.161a96.347 96.347 0 0145.974 10.923 83.003 83.003 0 0132.57 30.429c15.69 26.738 15.69 59.858-.036 86.656a82.942 82.942 0 01-32.528 30.366 96.292 96.292 0 01-45.922 10.926H168.8v-99.076h41.097z'
        />
      </g>
    </svg>
  )
}

export { DaoBet }
