import React, { Component, Fragment, ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { Icon, IconMap } from 'components'
import { RouterContainer } from 'modules/Auth'

import { privateRoutes } from './route'
import { Aside, AsideLink, Content } from './Style'
import { OnboardingContainer, SubmitDocContainer } from './containers'

import { withInjectors } from './injectors'
import { selectCompany, selectGetCompanyRequest } from './selectors'
import { getCompanyRequest } from './actions'
import { ClientState, Company } from './@types'

type Props = {
  company: Company
  getInfo: () => void
  request: RequestState
}

class View extends Component<Props> {
  public componentDidMount() {
    const { getInfo } = this.props
    getInfo()
  }

  public render() {
    const { company } = this.props

    return (
      <Fragment>
        {company.status !== 'approved' ? (
          company.status === 'processing' ? (
            <SubmitDocContainer />
          ) : (
            <OnboardingContainer />
          )
        ) : (
          <Fragment>
            <Aside>
              {privateRoutes.map((route, index) => (
                <AsideLink key={index} to={route.path} activeClassName='active'>
                  <Icon name={route.icon as IconMap} size='fill' />
                  {route.title}
                </AsideLink>
              ))}
            </Aside>
            <Content>
              <RouterContainer routes={privateRoutes} />
            </Content>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

type ContainerProps = {
  company: Company
  request: RequestState
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInfo: getCompanyRequest,
    },
    dispatch,
  )

const mapStateToProps = () =>
  createStructuredSelector<ClientState, ContainerProps>({
    company: selectCompany(),
    request: selectGetCompanyRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const MainContainer = compose<ComponentType>(withInjectors, withConnect)(View)
