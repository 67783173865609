import React, { Component, Fragment } from 'react'

import { ZERO } from 'constant'
import {
  Input,
  Checkbox,
  Button,
  Icon,
  Select,
  Toggle,
  Range,
  DatePickers,
  InputWrap,
  CheckboxWrap,
  IconMap,
} from 'components'

import { State, Props, Option, RangeParams } from './@types'

import {
  Container,
  Title,
  MainWrapper,
  Row,
  Column,
  ContainerButtons,
  Label,
  GroupCheckbox,
  Element,
  Crypto,
  CryptoName,
  RadioItemInput,
  RadioItemLabel,
  RadioList,
  RadioListItem,
} from './Style'

const initialState = {
  filters: {
    tx_id: '',
    fee_to: '',
    status: '',
    user_ids: '',
    fee_from: '',
    currency: [],
    direction: '',
    deposit_to: '',
    deposit_from: '',
    deposit_eur_to: '',
    deposit_usd_to: '',
    deposit_eur_from: '',
    deposit_usd_from: '',
    create_to: undefined,
    create_from: undefined,
  },
  fiatCurrency: 'EUR',
  selected: { value: '', label: 'All' },
  statusSelected: { value: '', label: 'All' },
}

const options: Option[] = [
  { value: '', label: 'All' },
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdrawal', label: 'Withdrawal' },
]

const fiatRadios = [
  { idFiat: 'usd', nameFiat: 'USD' },
  { idFiat: 'eur', nameFiat: 'EUR' },
]

class TransactionFilter extends Component<Props, State> {
  public state = initialState

  public render() {
    const { cryptoCurrencies } = this.props
    const { filters, selected, fiatCurrency } = this.state
    const { tx_id, currency, user_ids, create_to, deposit_to, create_from, deposit_from } = filters

    return (
      <Fragment>
        <Container>
          <Title>Transaction</Title>
        </Container>
        <MainWrapper>
          <Toggle title='FILTERS'>
            <Row>
              <Column>
                <InputWrap>
                  <Input
                    name='tx_id'
                    type='text'
                    value={tx_id}
                    label='TX'
                    onChange={this.handleChange}
                    placeholder='Separate Transaction ID’s using commas'
                  />
                </InputWrap>
                <InputWrap>
                  <Input
                    type='text'
                    name='user_ids'
                    value={user_ids}
                    onChange={this.handleChange}
                    label='User ID'
                    placeholder='Separate ID’s using commas'
                  />
                </InputWrap>
                <InputWrap>
                  <DatePickers
                    to={create_to}
                    nameTo='create_to'
                    from={create_from}
                    nameFrom='create_from'
                    label='Date'
                    setEndDate={this.handleChange}
                    setStartDate={this.handleChange}
                  />
                </InputWrap>
              </Column>

              <Column>
                <Label>Assets</Label>
                <GroupCheckbox>
                  <CheckboxWrap>
                    <label htmlFor='allCoins'>
                      <Element>
                        All coins
                        <Checkbox
                          id='allCoins'
                          value={undefined}
                          onClick={this.handleSelectAllCurrency}
                          checked={!currency.length}
                        />
                      </Element>
                    </label>
                  </CheckboxWrap>

                  {cryptoCurrencies.map(coin => (
                    <CheckboxWrap key={coin.full_name}>
                      <label htmlFor={coin.full_name}>
                        <Element>
                          <Crypto>
                            <Icon name={coin.name.toLocaleLowerCase() as IconMap} size='medium' />
                            <CryptoName>{coin.full_name}</CryptoName>
                          </Crypto>

                          <Checkbox
                            id={coin.full_name}
                            value={coin.name}
                            onClick={this.handleSelectCryptoCurrency}
                            checked={currency.includes(coin.name as never)}
                          />
                        </Element>
                      </label>
                    </CheckboxWrap>
                  ))}
                </GroupCheckbox>
              </Column>

              <Column>
                <InputWrap>
                  <Range
                    title='Volume'
                    endValue={deposit_to}
                    startValue={deposit_from}
                    setFilter={this.handleSetDeposit}
                  />
                </InputWrap>

                <InputWrap>
                  <Label>Type</Label>
                  <Select options={options} value={selected.value} select={this.handleSelectType} />
                </InputWrap>

                <InputWrap>
                  <RadioList>
                    {fiatRadios.map(fiatRadio => (
                      <RadioListItem
                        key={fiatRadio.idFiat}
                        onClick={() => this.handleSelectCurrency(fiatRadio.nameFiat)}
                      >
                        <RadioItemInput
                          type='radio'
                          name='selector'
                          id={fiatRadio.idFiat}
                          checked={fiatCurrency === fiatRadio.nameFiat}
                        />
                        <RadioItemLabel htmlFor={fiatRadio.idFiat}>{fiatRadio.nameFiat}</RadioItemLabel>
                      </RadioListItem>
                    ))}
                  </RadioList>
                </InputWrap>
              </Column>
            </Row>

            <ContainerButtons>
              <Button onClick={this.handleGetClients}>Apply filter</Button>
            </ContainerButtons>
          </Toggle>
        </MainWrapper>
      </Fragment>
    )
  }

  private handleGetClients = () => {
    const { setFilters } = this.props

    const { filters, selected, statusSelected } = this.state
    const keysState = Object.keys(filters) as (keyof State['filters'])[]

    const dataFilters = { ...filters }

    keysState.forEach(key => {
      if (!dataFilters[key] && String(dataFilters[key]) !== String(ZERO)) {
        delete dataFilters[key]
      }
    })

    setFilters({
      ...dataFilters,
      direction: selected.value !== '' ? selected.value : undefined,
      status: statusSelected.value !== '' ? statusSelected.value : undefined,
    })
  }

  private handleSelectType = (todo: Option) => {
    this.setState(() => ({ selected: todo }))
  }

  private handleSelectCurrency = (value: string) => {
    this.setState(() => ({ fiatCurrency: value }))
  }

  private handleSelectAllCurrency = () => {
    this.handleSetFilters({ currency: [] })
  }

  private handleSelectCryptoCurrency = (value?: string) => {
    const {
      filters: { currency },
    } = this.state

    if (currency.includes(value as never)) {
      const filtered = currency.filter(i => i !== value)
      this.handleSetFilters({ currency: filtered })
    } else {
      this.handleSetFilters({ currency: [...currency, value] })
    }
  }

  private handleChange = (value: string | Date, name?: string) => {
    if (name) {
      this.setState(prev => ({ ...prev, filters: { ...prev.filters, [name]: value } }))
    }
  }

  private handleSetFilters = (data: {}) => {
    this.setState(({ filters }) => ({ filters: { ...filters, ...data } }))
  }

  private handleSetDeposit = ({ from, to }: RangeParams) => {
    this.handleSetFilters({ deposit_from: from, deposit_to: to })
  }
}

export { TransactionFilter }
