import styled from 'styled-components'
import { breakpoints, font, color } from 'theme'

import { Size, Type } from './@types'

export const AdaptiveModalWrapper = styled.div<{ size?: Size; type?: Type }>`
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  max-width: unset;
  color: inherit;
  background-color: transparent;
  border-radius: 12px;
  ${breakpoints.greaterThan<{ size?: Size; type?: Type }>('sm')`
    padding: 10px 40px 40px;
    max-width: 880px;
    background-color: #292833;
    ${({ size }) =>
      size === 'default' &&
      `
      max-width: 460px;
    `}
    ${({ size }) =>
      size === 'small' &&
      `
      max-width: 380px;
    `}
    ${({ type }) =>
      type === 'warning' &&
      `
      padding: 24px 20px 30px;
      max-width: 380px;
    `}
  ` as string};
`

export const ModalOverflowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${breakpoints.lessThan<{ size?: Size }>('sm')`
    background-color: #292833;
  ` as string};
`

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 3;
`

export const StyledHeader = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 56px;
  color: ${color.white};
`

export const IconWrapper = styled.span<{ isLeft?: boolean }>`
  position: absolute;
  top: 50%;
  right: ${({ isLeft }) => (isLeft ? 'calc(100% - 24px)' : '0')};
  transform: translateY(-50%);
  cursor: pointer;
`

export const Title = styled.h2<{ type?: Type }>`
  margin: 0;
  font-family: ${font.display};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.58px;
  text-align: center;
  color: inherit;
  ${({ type }) =>
    type === 'warning' &&
    `
      font-size: 18px;
    `}
`

export const TitleS = styled(Title)`
  text-transform: uppercase;
`

export const WarningIcon = styled.span`
  position: absolute;
  top: -54px;
  left: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: ${color.white};
  background-color: ${color.warning};
  border-radius: 50%;
  transform: translateX(-50%);
`
