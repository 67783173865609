import { serverErrors } from 'constant'

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const someFunction = () => {}

export const checkNumber = (number: number) => (isNaN(number) ? '—' : number)

export function generateString() {
  return (
    Math.random()
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      .toString(36)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      .substring(2, 15)
  )
}

type Error = {
  message: string
  locations: string
  path: string
}

type Props = {
  networkError: {
    message: string
    statusCode: number
  }
  graphQLErrors: Error[]
}

// TODO move to apollo service
export const getGraphQlError = ({ graphQLErrors, networkError }: Props) => {
  const [error] = graphQLErrors

  if (error && error.message) {
    return error.message
  }

  if (networkError) {
    switch (networkError.statusCode) {
      case serverErrors.BAD_GATEWAY:
        return 'Server not available, please again later'
      case serverErrors.GATEWAY_TIMEOUT:
        return 'Server not available, please again later'
      case serverErrors.SERVER_ERROR:
        return 'Server not available, please again later'
      case serverErrors.SERVICE_UNAVAILABLE:
        return 'Server not available, please again later'
    }
    return networkError.message
  }

  return 'Server not available'
}

export const getPageCount = (total: number, size: number) => {
  const FIRST_PAGE = 1

  const count = Math.ceil(total / size)
  return count <= FIRST_PAGE ? FIRST_PAGE : count
}
