import { color } from 'theme'
import { Type } from '../@types'

export const getTextColor = (type?: Type) => {
  const types = {
    error: color.danger,
    default: color.white,
    action: color.white,
    important: color.white,
  }

  return type ? types[type] : color.white
}
