import React from 'react'

const BtcIcon = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <path fill='#fd9419' d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M14.493 8.763c.196-1.31-.801-2.015-2.165-2.485l.442-1.774-1.08-.27-.431 1.728c-.284-.07-.576-.137-.866-.203l.434-1.74-1.08-.269-.442 1.774a36.088 36.088 0 01-.69-.162l.001-.006-1.49-.372-.287 1.154s.801.184.785.195c.437.11.516.4.503.629l-.504 2.022c.03.007.07.018.112.036L7.7 9.01l-.078-.019-.706 2.832c-.054.133-.19.333-.495.257.01.016-.786-.196-.786-.196l-.536 1.237 1.406.35.465.12h.001l.304.079-.447 1.795 1.079.27.443-1.777c.295.08.58.154.86.224l-.44 1.767 1.08.27.447-1.792c1.842.348 3.227.208 3.81-1.458.47-1.342-.023-2.116-.992-2.62.706-.163 1.237-.627 1.38-1.586zm-2.468 3.46c-.306 1.232-2.233.723-3.114.49l-.211-.055.593-2.378c.074.019.164.039.266.062.911.204 2.78.623 2.466 1.882zM9.742 9.239c.735.196 2.338.624 2.617-.495.285-1.144-1.273-1.489-2.034-1.657l-.223-.051-.538 2.157c.051.012.11.028.178.046z'
      clipRule='evenodd'
    />
  </svg>
)

export { BtcIcon }
