import { call, all, put, takeLatest } from 'redux-saga/effects'

import { query } from '../client'
import { GET_PAIRS_REQUEST } from '../constants'
import { GET_EXCHANGE_PAIR, GetExchangePairsResponse } from '../queries'
import { getPairsFailure, getPairsRequest, getPairsSuccess, setPairs } from '../actions'

export function* getPairs({ payload }: ReturnType<typeof getPairsRequest>) {
  try {
    const response: GetExchangePairsResponse = yield call(query, {
      query: GET_EXCHANGE_PAIR,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
    const {
      generalGetSecondCurrencyForExchangePairByFirstCurrency: { list },
    } = response.data

    yield put(setPairs(list))

    yield put(getPairsSuccess())
  } catch (err) {
    yield put(getPairsFailure(err.messages))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_PAIRS_REQUEST, getPairs)])
}
