import { RequestState } from '@types'
import { getPageCount } from 'utils'

import { Actions, Clients, StatsBody } from '../@types'
import {
  SET_CLIENTS,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  SELECT_CLIENTS_PAGE,
  SET_CLIENTS_FILTERS,
  SELECT_CLIENTS_PAGE_SIZE,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getClients = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case SELECT_CLIENTS_PAGE:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case SELECT_CLIENTS_PAGE_SIZE:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case SET_CLIENTS_FILTERS:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_CLIENTS_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_CLIENTS_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_CLIENTS_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const statsInitialBody: StatsBody = {
  totalNet: 0,
  totalNetUsd: 0,
  totalNetEur: 0,
  totalDeposits: 0,
  totalDepositsUsd: 0,
  totalDepositsEur: 0,
  totalWithdrawals: 0,
  totalWithdrawalsUsd: 0,
  totalWithdrawalsEur: 0,
}

const initialUiState: Clients = {
  data: [],
  filters: {},
  pageSize: 10,
  pageCount: 1,
  currentPage: 1,
  stats: [
    { currency: 'USDT', ...statsInitialBody },
    { currency: 'PAX', ...statsInitialBody },
    { currency: 'BTC', ...statsInitialBody },
    { currency: 'ETH', ...statsInitialBody },
  ],
}

export const clients = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_CLIENTS:
      const { stats, data, total } = action.payload
      const pageCount = getPageCount(total, state.pageSize)

      return { ...state, total, stats, data, pageCount }
    case SELECT_CLIENTS_PAGE:
      const { currentPage } = action.payload
      return { ...state, currentPage }
    case SELECT_CLIENTS_PAGE_SIZE:
      const { pageSize } = action.payload
      return { ...state, pageSize }
    case SET_CLIENTS_FILTERS:
      const { filters } = action.payload
      return { ...state, filters, currentPage: 1 }
    default:
      return state
  }
}
