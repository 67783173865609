import React from 'react'
import moment from 'moment'

import { Icon, Status } from 'components'
import { CryptoElement, CryptoName, EditButtonText, IconWrapper, Date } from './Style'

const ICON = {
  btc_address: 'btc',
  eth_address: 'eth',
  usdt_address: 'usdt',
  bank_detail_usd: 'usd',
  bank_detail_eur: 'eur',
}

const CustomIcon = (value: string) => {
  return (
    <CryptoElement>
      <Icon
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        name={ICON[value]}
        size='large'
      />
      <CryptoName>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          ICON[value]
        }
      </CryptoName>
    </CryptoElement>
  )
}

const RequestDateComponent = (value: string) => {
  return <Date>{moment(value).format('DD.MM.YY, HH:mm:ss')}</Date>
}

function StatusComponent(value: string) {
  return <Status status={value} />
}

const EditButton = (props: { value: string; onClick: () => void }) => {
  return (
    <EditButtonText type='link' size='medium' onClick={props.onClick}>
      <IconWrapper>
        <Icon name='change' size='extraMedium' />
      </IconWrapper>
      {props.value ? 'Change' : 'Add'}
    </EditButtonText>
  )
}

export const COLUMNS = [
  {
    title: 'Currency',
    key: 'name',
    render: (props: string) => CustomIcon(props),
  },
  {
    title: 'Request date',
    key: 'created_at',
    render: (props: string) => RequestDateComponent(props),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => StatusComponent(props),
  },
  {
    title: 'Current',
    key: 'value',
  },
  {
    title: 'Action',
    key: 'edit',
    align: 'right',
    render: (props: { value: string; onClick: () => void }) => EditButton(props),
  },
]
