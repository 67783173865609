import { Transaction, Filter, Stats } from '../@types'
import {
  SET_TRANSACTIONS,
  SELECT_TRANSACTIONS_PAGE,
  SELECT_TRANSACTIONS_PAGE_SIZE,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  SET_TRANSACTIONS_FILTERS,
} from '../constants'

export const getTransactionsRequest = () => {
  return {
    type: GET_TRANSACTIONS_REQUEST,
  } as const
}

export const getTransactionsFailure = (errorMessage: string) => {
  return {
    type: GET_TRANSACTIONS_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getTransactionsSuccess = () => {
  return {
    type: GET_TRANSACTIONS_SUCCESS,
  } as const
}

export const setTransactions = (data: Transaction[], total: number, stats: Stats[]) => {
  return {
    type: SET_TRANSACTIONS,
    payload: { data, total, stats },
  } as const
}

export const selectPageSize = (pageSize: number) => {
  return {
    type: SELECT_TRANSACTIONS_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectPage = (currentPage: number) => {
  return {
    type: SELECT_TRANSACTIONS_PAGE,
    payload: { currentPage },
  } as const
}

export const setTransactionsFilters = (filters: Filter) => {
  return {
    type: SET_TRANSACTIONS_FILTERS,
    payload: { filters },
  } as const
}
