import { all, put, takeLatest, call, select } from 'redux-saga/effects'

import { query } from '../client'
import { selectWebHooks } from '../selectors'
import { GET_WEB_HOOKS, GetWevHooksResponse } from '../queries'
import { getWebHooksFailure, getWebHooksSuccess, setWebHooks, getWebHooksRequest as request } from '../actions'
import { GET_WEB_HOOKS_REQUEST, SELECT_WEB_HOOKS_PAGE } from '../constants'

export function* getWebHooks() {
  const { page } = yield select(selectWebHooks())

  try {
    const { data }: GetWevHooksResponse = yield call(query, {
      query: GET_WEB_HOOKS,
      variables: { page },
      fetchPolicy: 'no-cache',
    })

    const { page_size, total, data: webHooks } = data.findWebhooks
    yield put(setWebHooks(webHooks, total, page_size))

    yield put(getWebHooksSuccess())
  } catch (err) {
    yield put(getWebHooksFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getWebHooksFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_WEB_HOOKS_REQUEST, getWebHooks),

    yield takeLatest(SELECT_WEB_HOOKS_PAGE, onRequest),
  ])
}
