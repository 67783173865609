import React, { Fragment, Component } from 'react'

import { RequestState } from '@types'
import { LOW_DEBOUNCE, links } from 'constant'
import { FiatRequestInput } from '../../@types'
import { copyToClipboard, debounce } from 'utils'

import { Button, Input, Textarea, Icon, KeysListener } from 'components'
import { SelectFiat } from './SelectFiat'

import {
  Wrap,
  Copied,
  Container,
  TitleSend,
  CustomLink,
  CopyAddress,
  IconWrapper,
  CopyWrapper,
  IconWrapSend,
  CopyAddressLabel,
  CopyAddressValue,
  CopyAddressWrapper,
} from './Style'

type InjectorProps = {
  company: {
    bank_detail_usd: string
    bank_detail_eur: string
  }
  getCompany: () => void
}

type Props = InjectorProps & {
  request: RequestState
  clearState: () => void
  closeModal: () => void
  onError: (message: string) => void
  newRequest: (data: FiatRequestInput) => void
}

type State = {
  amount: number
  comment?: string
  currency: string
  isCopied: boolean
  isDisabled: boolean
}

type Key = keyof Exclude<'isCopied', keyof State>

const currenciesMinValues: { [key in string]: number } = {
  USD: 2000,
  EUR: 1500,
}

const DEFAULT_MIN_VALUE = 1000
const LOW_VALUE_ERROR = 'Value is less than permissible'
class NewFiatRequest extends Component<Props, State> {
  public debounceFunc: (amount: string) => void

  public constructor(props: Props) {
    super(props)
    this.debounceFunc = debounce(this.getIsValid, LOW_DEBOUNCE)

    this.state = {
      currency: 'EUR',
      isCopied: false,
      amount: NaN,
      isDisabled: false,
      comment: undefined,
    }
  }

  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public componentDidMount() {
    const { getCompany } = this.props
    getCompany()
  }

  public getIsValid = (amount: string) => {
    if (!amount) {
      this.setState(() => ({ isDisabled: true }))
      return
    }

    if (!Number(amount)) {
      this.setState(() => ({ isDisabled: true }))

      return
    }
    this.setState(() => ({ isDisabled: false }))
  }

  public handleChange = (value: string | number, key?: Key) => {
    if (key) {
      if (key === 'amount') {
        this.debounceFunc(String(value))
      }

      this.setState(this.updateState(key, value))
    }
  }

  public updateState = <V extends string | number>(key: Key, value: V) => (prevState: State): State => ({
    ...prevState,
    [key]: value,
  })

  // TODO: use useCopy hook
  public handleCopy = () => {
    const { company } = this.props

    const TIMER = 800

    copyToClipboard(company.bank_detail_eur)
    this.setState(() => ({ isCopied: true }))

    setTimeout(() => {
      this.setState(() => ({ isCopied: false }))
    }, TIMER)
  }

  public handleReq = () => {
    const { newRequest, company, onError } = this.props
    const { comment, currency, amount, isDisabled } = this.state

    if (isDisabled) {
      return
    }

    if (!amount) {
      onError('Please enter the amount')
      return
    }

    newRequest({
      amount,
      comment,
      currency_name: currency,
      bank_detail: company.bank_detail_eur,
    })
  }

  public handleClose = () => {
    const { closeModal, clearState } = this.props

    clearState()
    closeModal()
  }

  public render() {
    const { request, company } = this.props
    const { amount, currency, comment, isCopied, isDisabled } = this.state

    return (
      <Fragment>
        {!request.isSuccess ? (
          <Wrap>
            <KeysListener handleSubmit={this.handleReq}>
              <Container>
                <Input
                  type='number'
                  name='amount'
                  label='Amount'
                  value={amount}
                  pattern='[0-9]*'
                  inputMode='decimal'
                  error={request.isError}
                  onChange={this.handleChange}
                  errorMessage={this.getError(request.errorMessage)}
                  placeholder={String(currenciesMinValues[currency] || DEFAULT_MIN_VALUE)}
                />

                <SelectFiat value={currency} onChange={this.handleChange} />
              </Container>

              <CopyAddress>
                {company.bank_detail_eur ? (
                  <Fragment>
                    <CopyAddressLabel>Bank account</CopyAddressLabel>
                    <CopyAddressWrapper>
                      {isCopied ? (
                        <Copied>Copied</Copied>
                      ) : (
                        <CopyWrapper onClick={this.handleCopy}>
                          <CopyAddressValue>{company.bank_detail_eur}</CopyAddressValue>
                          <IconWrapper>
                            <Icon name='copy' size='extraMedium' />
                          </IconWrapper>
                        </CopyWrapper>
                      )}
                    </CopyAddressWrapper>
                  </Fragment>
                ) : (
                  <CustomLink onClick={this.handleClose} to={links.requests}>
                    Please fill the bank details
                  </CustomLink>
                )}
              </CopyAddress>

              <Textarea label='Comment' name='comment' value={comment} onChange={this.handleChange} />

              <Button
                onClick={this.handleReq}
                isLoading={request.isFetching}
                disabled={isDisabled || !company.bank_detail_eur}
              >
                Create request
              </Button>
            </KeysListener>
          </Wrap>
        ) : (
          <Fragment>
            <IconWrapSend>
              <Icon name='tick' size='auto' />
            </IconWrapSend>
            <TitleSend>Request has been sent</TitleSend>
          </Fragment>
        )}
      </Fragment>
    )
  }

  private getError = (error: string) => {
    const { currency } = this.state

    if (!error) {
      return ''
    }

    if (LOW_VALUE_ERROR === error) {
      return `Min value ${currenciesMinValues[currency] || DEFAULT_MIN_VALUE} ${currency}`
    }
    return error
  }
}

export { NewFiatRequest }
