import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { font } from 'theme'

export const ReactTip = styled(ReactTooltip)`
  &.customTooltip {
    padding: 12px;
    max-width: 350px;
    font-family: ${font.display};
    font-weight: 400;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    background-color: #111114;
    border-radius: 6px;
    opacity: 1;
    &::before,
    &::after {
      display: none;
    }
  }
  &.__react_component_tooltip.show {
    opacity: 1;
  }
`
