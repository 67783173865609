import { links } from 'constant'
import { WebHooksContainer } from '../containers'

const routes = [
  {
    path: links.webHooks,
    component: WebHooksContainer,
    isPrivate: true,
  },
]

export { routes }
