import { createSelector } from 'reselect'

import { ModalState, ModalName } from './@types'
import { DOMAIN, UI_STATE } from './constants'

const selectUi = (state: ModalState) => state[DOMAIN][UI_STATE]

const ONE_MODAL = 1

export const selectModals = () => createSelector(selectUi, subState => subState.modals)
export const selectCheckIsBack = () => createSelector(selectUi, subState => subState.modals.length > ONE_MODAL)

export const selectModal = (name: ModalName) =>
  createSelector(selectModals(), subState => subState.find(m => m.name === name))
