import React, { Component, Fragment } from 'react'

import { RequestState } from '@types'
import { Modal } from 'containers/Modal/@types'
import { createColumns, NewTable } from 'components'

import { COLUMNS } from './Columns'
import { newFiatRequest } from '../../modals'
import { FiatRequest, FiatRequests } from '../../@types'

import { TotalStats } from '../TotalStats'
import { FiatFilter } from '../FiatFilter'
import { RequestHeader } from '../RequestHeader'

type Props = {
  staffs: []
  getData: () => void
  getStaff: () => void
  request: RequestState
  setFilters: () => void
  fiatRequests: FiatRequests
  openModal: (modal: Modal) => void
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
}

class FiatTable extends Component<Props> {
  public componentDidMount() {
    const { getStaff } = this.props
    getStaff()
  }

  public openNewRequest = () => {
    const { openModal } = this.props
    openModal(newFiatRequest())
  }
  public render() {
    const { setFilters, getData, selectPageSize, staffs, selectPage, request, fiatRequests } = this.props

    return (
      <Fragment>
        <RequestHeader open={this.openNewRequest} title='Fiat Balances' buttonText='Fiat withdrawal' />
        <TotalStats loading={request.isFetching} stats={fiatRequests.stats} />
        <FiatFilter setFilters={setFilters} managers={staffs} />
        <NewTable
          selection={true}
          getData={getData}
          pagination={true}
          selectPage={selectPage}
          data={fiatRequests.data}
          setPageSize={selectPageSize}
          loading={request.isFetching}
          pageSize={fiatRequests.pageSize}
          pageCount={fiatRequests.pageCount}
          currentPage={fiatRequests.currentPage}
          columns={createColumns<FiatRequest>(COLUMNS)}
        />
      </Fragment>
    )
  }
}

export { FiatTable }
