import { createSelector } from 'reselect'

import { ClientState, RequestType } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: ClientState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: ClientState) => state[DOMAIN][DOMAIN_STATE]

export const selectStaffs = () => createSelector(selectUi, subState => subState.staffs)
export const selectNewUser = () => createSelector(selectUi, subState => subState.newUser)
export const selectCompany = () => createSelector(selectUi, subState => subState.company)
export const selectCurrenciesList = () => createSelector(selectUi, subState => subState.currenciesList)
export const selectRequests = () => createSelector(selectUi, subState => subState.requests)

export const selectCurrencyRequests = () =>
  createSelector(selectUi, subState =>
    subState.requests.filter(
      i =>
        i.name === 'bank_detail_usd' ||
        i.name === 'bank_detail_eur' ||
        i.name === 'eth_address' ||
        i.name === 'btc_address',
    ),
  )

export const selectCurrentRequest = (type: RequestType) =>
  createSelector(selectRequests(), subState => {
    if (subState && subState.length) {
      return subState.find(i => i.name === type)
    }

    return undefined
  })

export const selectAddUserRequest = () => createSelector(selectDomain, subState => subState.addUserRequest)
export const selectRemoveUserRequest = () => createSelector(selectDomain, subState => subState.removeStaffRequest)
export const selectGetStaffRequest = () => createSelector(selectDomain, subState => subState.getStaffRequest)
export const selectResetApiRequest = () => createSelector(selectDomain, subState => subState.resetApiRequest)
export const selectGetCompanyRequest = () => createSelector(selectDomain, subState => subState.getCompanyRequest)
export const selectChangeDetailRequest = () => createSelector(selectDomain, subState => subState.changeDetailRequest)
export const selectSetOnboardingRequest = () => createSelector(selectDomain, subState => subState.setOnboardingRequest)
export const selectGetLatestUpdateRequest = () =>
  createSelector(selectDomain, subState => subState.getLatestUpdateRequest)
