import React from 'react'

type Props = {
  color?: string
}

const LockGreen = ({ color }: Props) => (
  <svg fill='none' viewBox='0 0 16 20'>
    <path
      fill={color}
      d='M14.25 7.848V6.25A6.257 6.257 0 008 0a6.257 6.257 0 00-6.25 6.25v1.598A2.491 2.491 0 00.5 10v7.5C.5 18.879 1.621 20 3 20h10c1.379 0 2.5-1.121 2.5-2.5V10c0-.921-.506-1.719-1.25-2.152zM8 2.5a3.754 3.754 0 013.75 3.75V7.5h-7.5V6.25A3.754 3.754 0 018 2.5zm1.25 12.013v1.737h-2.5v-1.737a1.863 1.863 0 01-.625-1.388 1.875 1.875 0 113.75 0c0 .553-.244 1.045-.625 1.388z'
    />
  </svg>
)

export { LockGreen }
