import React from 'react'

const Link = () => (
  <svg fill='none' viewBox='0 0 39 44'>
    <path
      fill='#2a5ada'
      d='M19.073 0l-4.036 2.321-11 6.358L0 11v22l4.037 2.321 11.1 6.358L19.175 44l4.037-2.321 10.9-6.358L38.146 33V11L34.11 8.679l-11-6.358L19.073 0zm-11 28.358V15.642l11-6.358 11 6.358v12.716l-11 6.358-11-6.358z'
    />
  </svg>
)

export { Link }
