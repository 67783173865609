import { IconSize } from '../@types'

export const getSize = (size: IconSize) =>
  ({
    extraSmall: {
      width: '10px',
      padding: 0,
    },
    small: {
      height: '14px',
      width: '14px',
      padding: 0,
    },
    medium: {
      height: '16px',
      width: '16px',
      padding: 0,
    },
    extraMedium: {
      height: '20px',
      width: '20px',
      padding: 0,
    },
    default: {
      height: '24px',
      width: '24px',
      padding: 0,
    },
    large: {
      height: '32px',
      width: '32px',
      padding: 0,
    },
    xlarge: {
      height: '44px',
      width: '44px',
      padding: 0,
    },
    xl: {
      height: '64px',
      width: '64px',
      padding: 0,
    },
    fill: {
      height: 'inherit',
      width: 'inherit',
      padding: 0,
    },
    auto: {
      height: 'auto',
      width: '100%',
      padding: 0,
    },
  }[size])
