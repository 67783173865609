import ReactDOM from 'react-dom'
import React, { Component, Fragment } from 'react'

import { ZERO } from 'constant'
import { Button, Toggle, Range, DatePickers, InputWrap, Input } from 'components'

import FileSaver from 'file-saver'
import makeFileRequest from 'utils/requestHelper'

import { Filters } from '../../@types'
import {
  Container,
  Title,
  MainWrapper,
  Row,
  Column,
  ContainerButtons,
  ReportContainer,
  CustomButton,
  DatepickerPopupWrap,
} from './Style'

type Props = {
  setFilters: (filters: Filters) => void
}

type RangeParams = {
  from?: string
  to?: string
}

type State = Filters

const initialState = {
  ids: '',
  fee_to: '',
  fee_from: '',
  net_to: '',
  net_from: '',
  register_from: undefined,
  register_to: undefined,
  reportdate_from: undefined,
  reportdate_to: undefined,
  withdraw_from: '',
  withdraw_to: '',
  address: '',
  deposit_from: '',
  deposit_to: '',
  isVisible: false,
}

class Filter extends Component<Props, State> {
  public state = initialState
  public popupRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.popupRef = React.createRef()
  }

  public render() {
    const {
      ids,
      address,
      deposit_to,
      register_to,
      withdraw_to,
      deposit_from,
      withdraw_from,
      register_from,
      reportdate_from,
      reportdate_to,
      isVisible,
    } = this.state

    return (
      <Fragment>
        <Container>
          <Title>Users</Title>
        </Container>

        <MainWrapper>
          <Toggle title='FILTERS'>
            <Row>
              <Column>
                <InputWrap>
                  <Input name='ids' value={ids} label='User ID' onChange={this.onHandleChange} placeholder='Type ID' />
                </InputWrap>
                <InputWrap>
                  <DatePickers
                    to={register_to}
                    nameTo='register_to'
                    from={register_from}
                    nameFrom='register_from'
                    label='Create date'
                    setEndDate={this.onHandleChange}
                    setStartDate={this.onHandleChange}
                  />
                </InputWrap>
              </Column>

              <Column>
                <InputWrap>
                  <Range
                    title='Deposit'
                    endValue={deposit_to}
                    startValue={deposit_from}
                    setFilter={this.handleSetDeposit}
                  />
                </InputWrap>
                <InputWrap>
                  <Range
                    title='Withdrawal'
                    endValue={withdraw_to}
                    startValue={withdraw_from}
                    setFilter={this.handleSetWithdraw}
                  />
                </InputWrap>
              </Column>

              <Column>
                <InputWrap>
                  <Input
                    type='text'
                    name='address'
                    value={address}
                    label='Blockchain Address'
                    placeholder='Address using commas'
                    onChange={this.onHandleChange}
                  />
                </InputWrap>
              </Column>
            </Row>

            <ContainerButtons>
              <Button onClick={this.handleGetClients}>Apply filter</Button>
              <ReportContainer>
                <CustomButton onClick={this.showReportPopup}>Download</CustomButton>
                {isVisible && (
                  <DatepickerPopupWrap ref={this.popupRef}>
                    <InputWrap>
                      <DatePickers
                        to={reportdate_to}
                        nameTo='reportdate_to'
                        from={reportdate_from}
                        nameFrom='reportdate_from'
                        label='Create date'
                        setEndDate={this.onHandleChange}
                        setStartDate={this.onHandleChange}
                      />
                    </InputWrap>
                    <Button onClick={this.downloadReport}>Download</Button>
                  </DatepickerPopupWrap>
                )}
              </ReportContainer>
            </ContainerButtons>
          </Toggle>
        </MainWrapper>
      </Fragment>
    )
  }

  componentDidMount() {
    this.hideReportPopup()
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = (event: MouseEvent) => {
    const el = event.target
    const domNode = ReactDOM.findDOMNode(this.popupRef.current)
    if (!(domNode && el instanceof Node && domNode.contains(el))) {
      this.hideReportPopup()
    }
  }

  private showReportPopup = () => {
    this.setState(() => ({ isVisible: true }))
  }

  private hideReportPopup = () => {
    this.setState(() => ({ isVisible: false }))
  }

  private handleGetClients = () => {
    const { setFilters } = this.props

    const filters = this.state
    const keysState = Object.keys(filters) as (keyof State)[]

    const dataFilters = { ...filters }

    keysState.forEach(key => {
      if (!dataFilters[key] && String(dataFilters[key]) !== String(ZERO)) {
        delete dataFilters[key]
      }
    })

    setFilters(dataFilters)
  }

  private onHandleChange = (value: string | Date, name?: string) => {
    if (name) {
      this.setState(() => ({ [name]: value }))
    }
  }

  private downloadReport = () => {
    if (this.state.reportdate_from) {
      makeFileRequest('/v2/report', 'POST', {
        from_date: this.state.reportdate_from,
        to_date: this.state.reportdate_to,
      }).then(response => {
        if (response) {
          FileSaver.saveAs(response, 'report.csv')
          this.hideReportPopup()
        }
      })
    }
  }

  private handleSetWithdraw = ({ from, to }: RangeParams) => {
    this.setState(() => ({ withdraw_from: from, withdraw_to: to }))
  }

  private handleSetDeposit = ({ from, to }: RangeParams) => {
    this.setState(() => ({ deposit_from: from, deposit_to: to }))
  }
}

export { Filter }
