import styled from 'styled-components'
import { color } from 'theme'

export const WrapId = styled.span`
  font-size: 14px;
  color: ${color.white};
`

export const UserId = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
`
