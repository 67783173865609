import React, { Component } from 'react'

import { Input, KeysListener, LogoLink } from 'components'
import { Title, Wrapper } from '../'

import { InputWrapper, StyledButton, LogoContainer } from './Style'

type SignInParams = {
  email: string
  staff_name: string
  password_hash: string
}

type State = {
  email: string
  isError: boolean
  password: string
  staff_name: string
}

type Props = {
  title: string
  buttonText: string
  children?: React.ReactNode
  push: (link: string) => void
  signIn: (params: SignInParams) => void
}

class InputSignIn extends Component<Props, State> {
  public state = {
    email: '',
    password: '',
    isError: false,
    staff_name: '',
  }

  public render() {
    const { title, buttonText, children } = this.props
    const { email, password, staff_name, isError } = this.state

    return (
      <Wrapper>
        <KeysListener handleSubmit={this.handleSignIn}>
          <LogoContainer>
            <LogoLink />
          </LogoContainer>
          <Title text={title} />
          <InputWrapper>
            <Input
              name='email'
              type='email'
              label='Email'
              error={!email && isError}
              onChange={this.handleChange}
              errorMessage={`Please check your email. You could miss '.' or '@'`}
            />
          </InputWrapper>

          {title === 'Sign up' && (
            <InputWrapper>
              <Input
                type='text'
                label='Name'
                name='staff_name'
                errorMessage='Wrong name'
                onChange={this.handleChange}
                error={!staff_name && isError}
              />
            </InputWrapper>
          )}

          <InputWrapper>
            <Input
              name='password'
              type='password'
              label='Password'
              value={password}
              error={!password && isError}
              onChange={this.handleChange}
              errorMessage='Wrong password or email address'
            />
          </InputWrapper>

          <StyledButton onClick={this.handleSignIn}>{buttonText}</StyledButton>

          {children}
        </KeysListener>
      </Wrapper>
    )
  }

  private handleChange = (value: string, name?: string) => {
    if (name) {
      this.setState(prev => ({
        ...prev,
        [name]: value,
        isError: false,
      }))
    }
  }

  private handleSignIn = () => {
    const { signIn, title } = this.props
    const { password, email, staff_name } = this.state

    if (!password || !email || (title === 'Sign up' && !staff_name)) {
      this.setState(() => ({ isError: true }))
    } else {
      signIn({ email, password_hash: password, staff_name })
    }
  }
}

export { InputSignIn }
