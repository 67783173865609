import { call, all, put, takeLatest } from 'redux-saga/effects'

import { query } from '../client'
import { GET_STAFF_REQUEST } from '../constants'
import { GET_STAFF, GetStaffResponse } from '../queries'
import { getStaffsFailure, getStaffsSuccess, setStaffs } from '../actions'

export function* staff() {
  try {
    const response: GetStaffResponse = yield call(query, {
      query: GET_STAFF,
      fetchPolicy: 'no-cache',
    })
    const { findWorker } = response.data

    yield put(setStaffs(findWorker))

    yield put(getStaffsSuccess())
  } catch (err) {
    yield put(getStaffsFailure(err.messages))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_STAFF_REQUEST, staff)])
}
