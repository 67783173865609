import styled from 'styled-components'
import { breakpoints } from 'theme'

import { Button } from 'components'
import { font, color } from 'theme'

export const InputWrapper = styled.div`
  margin-top: 36px;
  &:last-of-type {
    margin-top: 58px;
  }

  ${breakpoints.greaterThan('sm')`
    margin-top: 32px;
    &:last-of-type {
      margin-top: 32px;
    }
  ` as string};
`

export const StyledButton = styled(Button)`
  margin: 54px 0 0;
`

export const SmallText = styled.p`
  font-family: ${font.regular};
  font-size: 12px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const Error = styled(SmallText)`
  color: ${color.warning};
`

export const Text = styled.span`
  margin: auto 0 0;
  font-family: ${font.regular};
  font-size: 16px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const Link = styled(Text)`
  margin: 0;
  color: ${color.default};
  cursor: pointer;
`
