import styled from 'styled-components'
import { font } from 'theme'

export const Wrapper = styled.div`
  margin: 0 auto 30px;
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
`

export const Title = styled.h3`
  margin: 0;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
`

export const Text = styled.p`
  margin: 0 0 20px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`
