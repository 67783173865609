import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { getRate } from './Rate'
import { getExchange, rate } from './Exchange'
import { transactions, getTransactions } from './Transactions'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    rate,
    transactions,
  }),
  [DOMAIN_STATE]: combineReducers({
    getRate,
    getExchange,
    getTransactions,
  }),
})
