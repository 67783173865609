import { END, eventChannel } from 'redux-saga'

const STEP = 1
const CLOSE_CHANNEL = 0

export function countdown(secs: number, interval: number) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      secs -= STEP
      if (secs > CLOSE_CHANNEL) {
        emitter(secs)
      } else {
        emitter(END)
      }
    }, interval)
    return () => {
      clearInterval(iv)
    }
  })
}
