import styled from 'styled-components'
import { color, font } from 'theme'
import { Button } from 'components'

export const Container = styled.div`
  margin: 0 auto 30px;
  padding: 0 34px;
  max-width: 1280px;
`

export const Title = styled.h3`
  margin: 0;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
`

export const MainWrapper = styled.div`
  margin: 0 auto 20px;
  padding: 30px;
  max-width: 1280px;
  color: ${color.white};
  background-color: #292833;
  border-radius: 8px;
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px;
`

export const ColumnBig = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 320px;
`

export const Column = styled.div`
  max-width: 33.3333%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`

export const ResetButton = styled(Button)`
  margin: 0 0 0 20px;
`

export const ContainerButtons = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`

export const GroupCheckbox = styled.div`
  padding: 12px 16px;
  height: 246px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
  }
`

export const Element = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.71;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`

export const Crypto = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const CryptoName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  line-height: 1.71;
`

export const RadioList = styled.ul`
  position: absolute;
  top: 7px;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  z-index: 1;
  padding: 0;
  overflow: inherit;
`

export const RadioListItem = styled.li`
  position: relative;
  padding: 0 4px;
  display: block;
  color: ${color.default};
  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
  &:hover {
    color: ${color.white};
  }
`

export const RadioItemLabel = styled.label`
  position: relative;
  display: block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  cursor: pointer;
  transition: all 0.25s linear;
`

export const RadioItemInput = styled.input.attrs({
  type: 'radio',
})`
  position: absolute;
  visibility: hidden;
  &:checked {
    & + ${RadioItemLabel} {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`
