import React, { Fragment, useState } from 'react'

import { useCopy } from 'hooks'
import { LogoLink, CountDownTimer, Icon, QrCode, Toggle, DecimalsFixed } from 'components'

import {
  Container,
  Copied,
  Text,
  MainInfo,
  Title,
  Send,
  SendValue,
  Address,
  AddressValue,
  CopyAddress,
  CopyAddressText,
  ExpiredText,
  AdditionlInfo,
  Timer,
  ToggleWrap,
  InfoPayment,
  InfoPaymentPreTitle,
  InfoPaymentTitle,
  AlertText,
} from './Style'
import { BtnBack, Header } from '../Invoice/Style'

type Props = {
  rate: string | number
  date: string
  amount: number
  status: string
  address: string
  currency: string
  invoiceId: string
  cryptoCurrency: string
  expectedAmount: number
  paidAfterExpired: boolean
  isBack: () => void
}

const PaymentInvoice = (props: Props) => {
  const {
    rate,
    date,
    isBack,
    amount,
    status,
    address,
    currency,
    expectedAmount,
    cryptoCurrency,
    paidAfterExpired,
  } = props

  const [isAddress, setAddress] = useCopy(address)
  const [isExpired, setExpired] = useState(status === 'expired')

  const handleCallback = () => {
    if (!paidAfterExpired) {
      setExpired(true)
    }
  }

  return (
    <Fragment>
      <Header>
        <BtnBack onClick={isBack}>
          <Icon name='arrow' size='auto' />
        </BtnBack>

        <LogoLink />
      </Header>

      <AlertText>Please send not less than the displayed amount from your personal wallet.</AlertText>

      <Container>
        <MainInfo>
          <Send>
            <Title>Send</Title>
            <SendValue>
              {DecimalsFixed(expectedAmount, 'crypto')} {cryptoCurrency}
            </SendValue>
          </Send>

          {isExpired ? (
            <Fragment>
              <Address>
                <Title>To&nbsp;address</Title>
                <AddressValue>-</AddressValue>
              </Address>
              <ExpiredText>
                Transaction link expired.
                <br />
                Please return to&nbsp;the site and try again.
              </ExpiredText>
            </Fragment>
          ) : (
            <Fragment>
              <Address>
                <Title>To&nbsp;address</Title>
                <AddressValue>{address}</AddressValue>
              </Address>

              <CopyAddress onClick={setAddress}>
                {!isAddress ? (
                  <Fragment>
                    <Icon name='copy' size='extraMedium' />
                    <CopyAddressText>Copy address</CopyAddressText>
                  </Fragment>
                ) : (
                  <Copied>Copied</Copied>
                )}
              </CopyAddress>

              <QrCode size='large' value={address} />
            </Fragment>
          )}
        </MainInfo>

        <AdditionlInfo>
          <Fragment>
            <Timer>
              <CountDownTimer callback={handleCallback} timer={date} />
            </Timer>
            <Text>
              You have 15&nbsp;minutes to&nbsp;send funds otherwise the transaction will be&nbsp;canceled automatically
            </Text>
          </Fragment>
          <ToggleWrap>
            <Toggle title='Transaction details' isOpen={false} align='center'>
              <InfoPayment>
                <InfoPaymentPreTitle>Value</InfoPaymentPreTitle>
                <InfoPaymentTitle>
                  {DecimalsFixed(amount, 'fiat')} {currency}
                </InfoPaymentTitle>
              </InfoPayment>
              <InfoPayment>
                <InfoPaymentPreTitle>Rate</InfoPaymentPreTitle>
                <InfoPaymentTitle>
                  1 {cryptoCurrency} = {DecimalsFixed(rate, 'fiat')} {currency}
                </InfoPaymentTitle>
              </InfoPayment>
            </Toggle>
          </ToggleWrap>
        </AdditionlInfo>
      </Container>
    </Fragment>
  )
}

export { PaymentInvoice }
