import React, { Component } from 'react'
import Bignumber from 'bignumber.js'

import { Icon } from 'components'

import { ExchangeInput } from './Input'
import { Wrapper, ArrowWrap, Loader } from './Style'

type BG = Bignumber

const DECIMALS = 8

type Props = {
  rate: number
  balance: number
  minValue: number
  minValueTo: number
  initAmount: string
  buyCurrency: string
  isLoading?: boolean
  sellCurrency: string
  isError: boolean
  errorMessage: string
  onChange: (sellAmount: number, buyAmount: number, isValid: boolean) => void
  getRate: (params: {}) => void
}

type State = {
  isValid: boolean
  buyAmount: string
  sellAmount: string
}

class Inputs extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      isValid: false,
      buyAmount: '',
      sellAmount: '',
    }
  }

  public componentDidUpdate(prevProps: Props, preState: State) {
    const { onChange, rate, initAmount, isError } = this.props
    const { sellAmount, buyAmount, isValid } = this.state

    if (sellAmount !== preState.sellAmount) {
      onChange(Number(sellAmount), Number(buyAmount), isValid)
    }

    if (initAmount !== prevProps.initAmount || rate !== prevProps.rate) {
      this.handleChangeSellAmount(initAmount)
    }

    if (isError !== prevProps.isError) {
      if (isError) {
        this.handleChangeSellAmount('')
        this.handleChangeBuyAmount('')
      }
    }
  }

  public validate = (sellAmount: BG): boolean => {
    const { balance, minValue, minValueTo } = this.props
    return sellAmount.isGreaterThan(balance) || sellAmount.isLessThan(minValue) || sellAmount.isLessThan(minValueTo)
  }

  public handleChangeSellAmount = (amount: string) => {
    const { rate } = this.props
    const sellAmount = new Bignumber(amount)

    const buyAmount = sellAmount.multipliedBy(rate).decimalPlaces(DECIMALS)

    const isValid = this.validate(sellAmount)

    this.setState({
      isValid,
      sellAmount: amount,
      buyAmount: buyAmount.toString(),
    })
  }

  public handleChangeBuyAmount = (amount: string) => {
    const { rate } = this.props

    const buyAmount = new Bignumber(amount)

    const sellAmount = buyAmount.dividedBy(rate).decimalPlaces(DECIMALS)

    const isValid = this.validate(sellAmount)

    this.setState({
      isValid,
      buyAmount: amount,
      sellAmount: sellAmount.toString(),
    })
  }

  getErrorMessage() {
    const { balance, isError, errorMessage, minValue, minValueTo, sellCurrency } = this.props
    const { sellAmount } = this.state
    let error = ''

    const MIN_AVAILABLE_DECIMAL = 8
    const minAvailableValue = Number(Math.max(minValue, minValueTo).toFixed(MIN_AVAILABLE_DECIMAL))

    if (!this.validate(new Bignumber(sellAmount))) {
      error = `Available: ${balance}, min: ${minAvailableValue} ${sellCurrency}`
    }
    if (isError) {
      error = errorMessage
    }

    return error
  }

  public render() {
    const { isLoading, sellCurrency, buyCurrency, isError } = this.props
    const { sellAmount, buyAmount, isValid } = this.state

    return (
      <Wrapper>
        <ExchangeInput
          currency={sellCurrency}
          error={isValid || isError}
          onChange={this.handleChangeSellAmount}
          value={this.getValidateValue(sellAmount)}
          errorMessage={this.getErrorMessage()}
          disabled={isError}
        />

        {isLoading ? (
          <Loader />
        ) : (
          <ArrowWrap>
            <Icon name='arrowLight' size='auto' />
          </ArrowWrap>
        )}

        <ExchangeInput
          error={isValid}
          currency={buyCurrency}
          onChange={this.handleChangeBuyAmount}
          value={this.getValidateValue(buyAmount)}
          disabled={isError}
        />
      </Wrapper>
    )
  }

  private getValidateValue = (value: string) => {
    return isNaN(Number(value)) || !isFinite(Number(value)) ? '' : value
  }
}

export { Inputs }
