import React, { Component, Fragment } from 'react'

import { routes } from './route'
import { RouterContainer } from 'modules/Auth'

class View extends Component {
  public render() {
    return (
      <Fragment>
        <RouterContainer routes={routes} />
      </Fragment>
    )
  }
}

export { View }
