import { call, all, put, takeLatest, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'

import { query } from '../client'
import { Filters } from '../@types'
import { selectClients } from '../selectors'
import { GET_CLIENTS, GetClientsResponse } from '../queries'
import { getClientsFailure, getClientsSuccess, setClients, getClientsRequest as request } from '../actions'
import { GET_CLIENTS_REQUEST, SET_CLIENTS_FILTERS, SELECT_CLIENTS_PAGE, SELECT_CLIENTS_PAGE_SIZE } from '../constants'

export function* getClients() {
  const { filters, currentPage, pageSize } = yield select(selectClients())

  try {
    const dataFilters = getTrimming<Filters>(filters)

    const response: GetClientsResponse = yield call(query, {
      query: GET_CLIENTS,
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
      fetchPolicy: 'no-cache',
    })
    const {
      findUsers: { users, total, stats },
    } = response.data

    yield put(setClients(users, total, stats))

    yield put(getClientsSuccess())
  } catch (err) {
    yield put(getClientsFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getClientsFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_CLIENTS_REQUEST, getClients),

    yield takeLatest(SELECT_CLIENTS_PAGE, onRequest),
    yield takeLatest(SET_CLIENTS_FILTERS, onRequest),
    yield takeLatest(SELECT_CLIENTS_PAGE_SIZE, onRequest),
  ])
}
