import { CommonCurrencies } from '../@types'
import {
  SET_CURRENCIES_FROM,
  GET_CURRENCIES_FROM_REQUEST,
  GET_CURRENCIES_FROM_SUCCESS,
  GET_CURRENCIES_FROM_FAILURE,
} from '../constants'

export const getCurrenciesFromRequest = () => {
  return {
    type: GET_CURRENCIES_FROM_REQUEST,
  } as const
}

export const getCurrenciesFromFailure = (errorMessage: string) => {
  return {
    type: GET_CURRENCIES_FROM_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getCurrenciesFromSuccess = () => {
  return {
    type: GET_CURRENCIES_FROM_SUCCESS,
  } as const
}

export const setCurrenciesFrom = (data: CommonCurrencies) => {
  return {
    type: SET_CURRENCIES_FROM,
    payload: { data },
  } as const
}
