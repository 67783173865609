import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getGraphQlError } from 'utils'

import { mutate } from '../client'
import { CREATE_EXCHANGE } from '../mutation'
import { GET_EXCHANGE_REQUEST } from '../constants'
import { getExchangeRequest, getTransactionsRequest, getExchangeSuccess, getExchangeFailure } from '../actions'

import { getCompanyRequest } from 'modules/Settings/actions'

export function* getExchange({ payload }: ReturnType<typeof getExchangeRequest>) {
  try {
    yield call(mutate, {
      mutation: CREATE_EXCHANGE,
      variables: payload,
    })

    yield put(getCompanyRequest())
    yield put(getTransactionsRequest())
    yield put(getExchangeSuccess())
  } catch (err) {
    yield put(getExchangeFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_EXCHANGE_REQUEST, getExchange)])
}
