import { GET_RATE_FAILURE, SET_EXCHANGE, GET_RATE_REQUEST, GET_RATE_SUCCESS } from '../constants'
import { ExchangeRateInput, ExchangeRate } from '../@types'

export const getRateRequest = (params: ExchangeRateInput) => {
  return {
    type: GET_RATE_REQUEST,
    payload: params,
  } as const
}

export const getRateFailure = (errorMessage: string) => {
  return {
    type: GET_RATE_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getRateSuccess = () => {
  return {
    type: GET_RATE_SUCCESS,
  } as const
}

export const setRate = (params: ExchangeRate) => {
  return {
    type: SET_EXCHANGE,
    payload: { params },
  } as const
}
