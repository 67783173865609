import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { openModal } from 'containers'
import { StaffInjectors } from 'modules/Settings'

import { withInjectors } from '../injectors'
import { FiatTable as View } from '../components'
import { ClientState, FiatRequests } from '../@types'
import { selectFiatRequests, selectFiatRequest } from '../selectors'
import { getFiatRequest, selectFiatPage, selectFiatPageSize, setFiatFilters } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      getData: getFiatRequest,
      setFilters: setFiatFilters,
      selectPage: selectFiatPage,
      selectPageSize: selectFiatPageSize,
    },
    dispatch,
  )

type Props = {
  request: RequestState
  fiatRequests: FiatRequests
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectFiatRequest(),
    fiatRequests: selectFiatRequests(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const FiatContainer = compose<ComponentType>(StaffInjectors, withInjectors, withConnect)(View)
