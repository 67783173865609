import React from 'react'

import { createModal } from 'containers/Modal'
import { NewInvoiceContainer } from '../containers'

const containerWithProps = () => <NewInvoiceContainer />

const newInvoice = () =>
  createModal({
    name: 'OPEN_NEW_INVOICE',
    title: `New invoice`,
    container: containerWithProps(),
  })

export { newInvoice }
