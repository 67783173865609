import { all, put, takeLatest, call } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { links } from 'constant'
import { localStorage } from 'utils'
import { clearStore } from '../client'

import { SET_LOGOUT } from '../constants'
import { setAuth } from '../actions'

export function* forceLogOut() {
  try {
    localStorage.clear()

    yield call(clearStore)

    yield put(setAuth(false))
    yield put(push(links.signin))
  } catch (err) {}
}

export function* saga() {
  return yield all([yield takeLatest(SET_LOGOUT, forceLogOut)])
}
