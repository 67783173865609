import { gql, ApolloQueryResult } from 'apollo-boost'
import { Transaction, ExchangeRate } from './@types'

export const GET_TRANSACTIONS = gql`
  query findForcedExchanges(
    $id: String
    $currency_from: CommonCurrencyName
    $currency_to: CommonCurrencyName
    $tx_hash: String
    $status: ManualExchangeStatus
    $page: Int
    $page_size: Int
  ) {
    findForcedExchanges(
      data: {
        id: $id
        currency_from: $currency_from
        currency_to: $currency_to
        tx_hash: $tx_hash
        status: $status
        page: $page
        page_size: $page_size
      }
    ) {
      total
      list {
        id
        status
        rate
        amount_from
        amount_to
        currency_from
        currency_to
        created_at
        tx_hash
      }
    }
  }
`

export type GetTransactionsResponse = ApolloQueryResult<{
  findForcedExchanges: {
    total: number
    list: Transaction[]
  }
}>

export const GET_RATE = gql`
  query getForcedExchangeCurrencyRate(
    $currency_from: CommonCurrencyName!
    $currency_to: CommonCurrencyName!
    $amount: Float!
  ) {
    getForcedExchangeCurrencyRate(data: { currency_from: $currency_from, currency_to: $currency_to, amount: $amount }) {
      amount
      min_amount
      min_amount_to
      rate
    }
  }
`

export type GetExchangeRateResponse = ApolloQueryResult<{
  getForcedExchangeCurrencyRate: ExchangeRate
}>
