import { links } from 'constant'
import { BalancesContainer } from '../containers'

const routes = [
  {
    path: links.balances,
    component: BalancesContainer,
    isPrivate: true,
  },
]

export { routes }
