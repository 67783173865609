import { ClientContainer } from '../containers'

import { links } from 'constant'

const routes = [
  {
    path: links.users,
    component: ClientContainer,
    isPrivate: true,
  },
]

export { routes }
