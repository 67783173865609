import React from 'react'

const Pax = () => (
  <svg fill='none' viewBox='0 0 44 42'>
    <path
      fill='#0293d6'
      d='M10 36.367a14.94 14.94 0 0010.353 5.059 9.412 9.412 0 006.235-2.824 108.494 108.494 0 0110.236-7.765l2-.823c.823-.353 2.235-1.177 3.529-2.706C43.765 25.426 44 23.426 44 22.72a12.94 12.94 0 00-2.118-6.236A39.06 39.06 0 0110 36.367z'
    />
    <path
      fill='#ebe638'
      d='M14.47 2.955A9.411 9.411 0 0121.295.014a6.118 6.118 0 014.47 1.53 20.47 20.47 0 0011.53 3.764c1.06.117 3.177.47 4.589 2.235 1.058 1.412 1.176 2.941 1.176 3.647a16.352 16.352 0 01-1.176 5.294L14.47 2.954z'
    />
    <path
      fill='#bad147'
      d='M10 36.366c-1.059-.117-2.47-.47-4.118-1.176-1.647-.706-3.647-1.53-4.94-3.765C.117 30.013 0 28.601 0 27.66a16.235 16.235 0 011.765-7.176A33.176 33.176 0 004 11.895l.588-2.352C4.941 8.366 5.176 7.19 6 6.013l1.294-1.412a11.176 11.176 0 017.177-1.647A36.706 36.706 0 0010 36.366z'
    />
    <path
      fill='#78b650'
      d='M14.47 2.955c-.117 0 6.353.353 10.353 1.765l3.294 1.412c-1.294-.118-9.529-.471-15.764 5.764-7.883 8-5.294 18.941-5.177 19.53a12 12 0 01-2.588-8.118A28.236 28.236 0 0114.47 2.955z'
    />
    <path
      fill='#2f8260'
      d='M30.235 32.013a23.528 23.528 0 00-2.117-25.882c3.407.57 6.542 2.22 8.94 4.706 1.413 1.176 3.177 2.94 4.824 5.647a34.706 34.706 0 01-11.647 15.53z'
    />
    <path
      fill='#085229'
      d='M34 11.307c-1.882-3.176-4.588-4.588-5.765-5.176-.94-.235-9.764-1.647-16.47 4.823C6.353 16.131 6 22.837 6 24.131a20.118 20.118 0 004.235 12.235 28 28 0 0010.588 0c1.765-.353 5.883-1.059 9.412-4.117.47-.471 5.647-5.295 6-12.942 0-1.294 0-4.588-2.235-8zm-4.235 17.06a11.648 11.648 0 01-8.588 3.764c-.706 0-5.412-.118-8.706-4-.471-.588-2.824-3.412-2.706-7.647.235-5.647 4.706-8.941 5.647-9.647a10.573 10.573 0 015.764-2c.706-.118 3.177-.236 5.648 1.176 5.411 2.941 5.647 10 5.647 10.47 0 1.295 0 4.824-2.706 7.883z'
    />
  </svg>
)

export { Pax }
