import {
  SET_WEB_HOOKS,
  SELECT_WEB_HOOKS_PAGE,
  GET_WEB_HOOKS_SUCCESS,
  GET_WEB_HOOKS_REQUEST,
  GET_WEB_HOOKS_FAILURE,
} from '../constants'

import { WebHook } from '../@types'

export const getWebHooksRequest = () => {
  return {
    type: GET_WEB_HOOKS_REQUEST,
  } as const
}

export const getWebHooksFailure = (errorMessage: string) => {
  return {
    type: GET_WEB_HOOKS_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getWebHooksSuccess = () => {
  return {
    type: GET_WEB_HOOKS_SUCCESS,
  } as const
}

export const selectPage = (page: number) => {
  return {
    type: SELECT_WEB_HOOKS_PAGE,
    payload: { page },
  } as const
}

export const setWebHooks = (data: WebHook[], total: number, page_size: number) => {
  return {
    type: SET_WEB_HOOKS,
    payload: { data, total, page_size },
  } as const
}
