import { CommonCurrencyName, FiltersRequest, OnboardingInput } from '../@types'
import {
  SET_ONBOARDING_FAILURE,
  SET_ONBOARDING_REQUEST,
  SET_ONBOARDING_SUCCESS,
  GET_CURRENCY_LIST_REQUEST,
  SET_CURRENCY_LIST,
} from '../constants'

export const setOnboardingRequest = (data: OnboardingInput) => {
  return {
    type: SET_ONBOARDING_REQUEST,
    payload: data,
  } as const
}

export const setOnboardingFailure = (errorMessage: string) => {
  return {
    type: SET_ONBOARDING_FAILURE,
    payload: { errorMessage },
  } as const
}

export const setOnboardingSuccess = () => {
  return {
    type: SET_ONBOARDING_SUCCESS,
  } as const
}

export const getCurrencyListRequest = (data: FiltersRequest) => {
  return {
    type: GET_CURRENCY_LIST_REQUEST,
    payload: data,
  } as const
}

export const setCurrenciesList = (data: CommonCurrencyName[]) => {
  return {
    type: SET_CURRENCY_LIST,
    payload: data,
  } as const
}
