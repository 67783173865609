import { CryptoRequest, Stats, FilterCryptoRequest } from '../@types'
import {
  SET_CRYPTO,
  SELECT_CRYPTO_PAGE,
  GET_CRYPTO_FAILURE,
  GET_CRYPTO_REQUEST,
  GET_CRYPTO_SUCCESS,
  SET_CRYPTO_FILTERS,
  SELECT_CRYPTO_PAGE_SIZE,
} from '../constants'

export const getCryptoRequest = () => {
  return {
    type: GET_CRYPTO_REQUEST,
  } as const
}

export const getCryptoFailure = (errorMessage: string) => {
  return {
    type: GET_CRYPTO_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getCryptoSuccess = () => {
  return {
    type: GET_CRYPTO_SUCCESS,
  } as const
}

export const setCrypto = (data: CryptoRequest[], total: number, stats: Stats[]) => {
  return {
    type: SET_CRYPTO,
    payload: { data, total, stats },
  } as const
}

export const selectCryptoPageSize = (pageSize: number) => {
  return {
    type: SELECT_CRYPTO_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectCryptoPage = (currentPage: number) => {
  return {
    type: SELECT_CRYPTO_PAGE,
    payload: { currentPage },
  } as const
}

export const setCryptoFilters = (filters: FilterCryptoRequest) => {
  return {
    type: SET_CRYPTO_FILTERS,
    payload: { filters },
  } as const
}
