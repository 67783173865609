import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { config } from 'config'
import { localStorage } from 'utils'

type Endpoint = 'company' | 'general'

// TODO move to request, handlerError function
const createClient = (endPoint?: Endpoint) => {
  const backendUrl =
    process.env.NODE_ENV === 'development' ? 'https://my.test.cryptopayments.pro/api' : config.backend.url

  return new ApolloClient({
    uri: endPoint ? `${backendUrl}/${endPoint}` : backendUrl,
    request: operation => {
      const accessToken = localStorage.getItem(localStorage.getKey())

      if (accessToken && accessToken.jwt) {
        operation.setContext({
          headers: {
            auth: `${accessToken.jwt}`,
          },
        })
      }
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        const [{ message, locations, path }] = graphQLErrors
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    },
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })
}

export { createClient }
