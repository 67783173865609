import styled from 'styled-components'
import { font, color } from 'theme'
import { Button } from 'components'

export const TableContainer = styled.div`
  margin: 0 0 20px;
`

export const TableTitle = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const TableText = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
`

export const CryptoElement = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const CryptoName = styled.span`
  margin: 0 0 0 12px;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.43;
`

export const EditButton = styled(Button)`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  color: ${color.default};
`

export const RequestDate = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.5);
`

export const EditButtonText = styled(Button)`
  margin: 0 0 0 auto;
  padding: 10px;
  min-width: auto;
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  margin: 0 8px 0 0;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`

export const Date = styled.p`
  margin: 0;
  font-size: 14px;
  opacity: 0.5;
`
