import React, { Fragment, Component } from 'react'

import { RequestState } from '@types'
import { NewTable, createColumns } from 'components'

import { COLUMNS } from './Columns'
import { Transactions, Transaction } from '../../@types'
import { Exchange } from '../Exchange'

type Props = {
  staffs: []
  getData: () => void
  getStaff: () => void
  setFilters: () => void
  request: RequestState
  transactions: Transactions
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
}

class Main extends Component<Props> {
  public componentDidMount() {
    const { getStaff } = this.props
    getStaff()
  }

  public render() {
    const { getData, request, selectPage, selectPageSize, transactions } = this.props

    return (
      <Fragment>
        <Exchange />
        <NewTable
          selection={true}
          getData={getData}
          pagination={true}
          selectPage={selectPage}
          data={transactions.data}
          setPageSize={selectPageSize}
          loading={request.isFetching}
          pageSize={transactions.pageSize}
          pageCount={transactions.pageCount}
          currentPage={transactions.currentPage}
          columns={createColumns<Transaction>(COLUMNS)}
        />
      </Fragment>
    )
  }
}

export { Main }
