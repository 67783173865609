import { call, all, put, takeLatest, fork, take, select, takeEvery, cancel } from 'redux-saga/effects'

import { countdown } from 'utils'

import { query } from '../client'
import { selectInvoice } from '../selectors'
import { CHECK_INVOICE, CheckInvoiceResponse } from '../queries'
import { CHECK_INVOICE_REQUEST, START_RUNNER, STOP_RUNNER } from '../constants'
import { checkInvoiceRequest, checkInvoiceFailure, checkInvoiceSuccess, setInvoice } from '../actions'

const redirect = (url: string) => {
  setTimeout(() => {
    window.location.href = url
  }, Number('5000'))
}
export function* checkStatus({ payload }: ReturnType<typeof checkInvoiceRequest>) {
  try {
    const response: CheckInvoiceResponse = yield call(query, {
      query: CHECK_INVOICE,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
    const { getCompanyInvoiceLatestInfo } = response.data
    const { status, url_success, url_failure } = getCompanyInvoiceLatestInfo

    yield put(checkInvoiceSuccess())
    yield put(setInvoice(getCompanyInvoiceLatestInfo))

    if (status === 'paid' && url_success) {
      redirect(url_success)
    }

    if ((status === 'declined' || status === 'expired') && url_failure) {
      redirect(url_failure)
    }
  } catch (err) {
    yield put(checkInvoiceFailure(err.messages))
  }
}

export function* autoCheckStatus() {
  const invoice = yield select(selectInvoice())

  if (!invoice || !invoice.foreign_id) {
    return
  }

  yield put(checkInvoiceRequest(invoice.foreign_id))
}

export function* statusRunner() {
  const DURATION = 55000
  const INTERVAL = 15000

  const channel = yield call(countdown, DURATION, INTERVAL)
  yield takeEvery(channel, autoCheckStatus)
}

function* mainRunner() {
  while (yield take(START_RUNNER)) {
    const bgSyncTask = yield fork(statusRunner)

    yield take(STOP_RUNNER)

    yield cancel(bgSyncTask)
  }
}

export function* saga() {
  return yield all([yield fork(mainRunner), yield takeLatest(CHECK_INVOICE_REQUEST, checkStatus)])
}
