import { LogoIcon } from './LogoIcon'
import { Tick } from './Tick'
import { UpTableIcon } from './UpTable'
import { Change } from './Change'
import { BtcIcon } from './Btc'
import { EthIcon } from './Eth'
import { TrxIcon } from './Trx'
import { EosIcon } from './Eos'
import { ZUsd } from './ZUsd'
import { ZEur } from './ZEur'
import { BetIcon } from './Bet'
import { EditIcon } from './Edit'
import { PdfIcon } from './Pdf'
import { ArrowIcon } from './ArrowIcon'
import { Eye } from './Eye'
import { EyeOff } from './EyeOff'
import { Copy } from './Copy'
import { CloseIcon } from './CloseIcon'
import { CalendarIcon } from './CalendarIcon'
import { ExitIcon } from './ExitIcon'
import { CloseTransparentIcon } from './CloseTransparentIcon'
import { Upload } from './Upload'
import { DownLoad } from './DownLoad'
import { DaoBet } from './DaoBet'
import { Email } from './Email'
import { Users } from './Users'
import { Squares } from './Squares'
import { Cube } from './Cube'
import { Lock } from './Lock'
import { LockGreen } from './LockGreen'
import { Diskette } from './Diskette'
import { UsdIcon } from './Usd'
import { EurIcon } from './Eur'
import { HouseIcon } from './House'
import { ArrowLightIcon } from './ArrowLight'
import { Approve } from './Approve'
import { Alert } from './Alert'
import { Apply } from './Apply'
import { Declined } from './Declined'
import { Href } from './Href'
import { Bell } from './Bell'
import { ArrowHead } from './ArrowHead'
import { UsdBIcon } from './UsdB'
import { EurBIcon } from './EurB'
import { UsdTIcon } from './UsdT'
import { NotFound } from './NotFound'
import { Aud } from './Aud'
import { Pln } from './Pln'
import { Rub } from './Rub'
import { Dkk } from './Dkk'
import { Gbp } from './Gbp'
import { Cad } from './Cad'
import { Sek } from './Sek'
import { Nok } from './Nok'
import { Usdc } from './Usdc'
import { Wbtc } from './Wbtc'
import { Dai } from './Dai'
import { Pax } from './Pax'
import { Link } from './Link'
import { Info } from './Info'
import { UsersF } from './UsersF'
import { RepeatF } from './RepeatF'
import { ShoppingBagF } from './ShoppingBagF'
import { SettingF } from './SettingF'
import { TargetF } from './TargetF'
import { RefreshCcw } from './RefreshCcw'
import { Briefcase } from './Briefcase'
import { ExternalLink } from './ExternalLink'
import { Menu } from './Menu'

export const icons = {
  declined: Declined,
  exit: ExitIcon,
  close: CloseIcon,
  logo: LogoIcon,
  tick: Tick,
  arrow: ArrowIcon,
  arrowHead: ArrowHead,
  arrowLight: ArrowLightIcon,
  change: Change,
  approve: Approve,
  upTable: UpTableIcon,
  btc: BtcIcon,
  eth: EthIcon,
  trx: TrxIcon,
  eos: EosIcon,
  usdb: UsdBIcon,
  eurb: EurBIcon,
  usdt: UsdTIcon,
  daobet: DaoBet,
  edit: EditIcon,
  pdf: PdfIcon,
  eye: Eye,
  eyeOff: EyeOff,
  copy: Copy,
  calendar: CalendarIcon,
  closeTransparent: CloseTransparentIcon,
  upload: Upload,
  download: DownLoad,
  email: Email,
  users: Users,
  squares: Squares,
  cube: Cube,
  lock: Lock,
  lockGreen: LockGreen,
  diskette: Diskette,
  usd: UsdIcon,
  eur: EurIcon,
  house: HouseIcon,
  alert: Alert,
  apply: Apply,
  href: Href,
  bell: Bell,
  zusd: ZUsd,
  zeur: ZEur,
  bet: BetIcon,
  notFound: NotFound,
  aud: Aud,
  pln: Pln,
  rub: Rub,
  dkk: Dkk,
  gbp: Gbp,
  cad: Cad,
  sek: Sek,
  nok: Nok,
  usdc: Usdc,
  wbtc: Wbtc,
  dai: Dai,
  pax: Pax,
  link: Link,
  info: Info,
  usersF: UsersF,
  repeatF: RepeatF,
  shoppingBagF: ShoppingBagF,
  settingF: SettingF,
  targetF: TargetF,
  refreshCcw: RefreshCcw,
  briefcase: Briefcase,
  externalLink: ExternalLink,
  menu: Menu,
}
