import { links } from 'constant'

import { HeaderLinks } from './@types'

export const privateLinks: HeaderLinks = [
  { url: links.users, title: 'Users', icon: 'usersF' },
  { url: links.transactions, title: 'Transactions', icon: 'repeatF' },
  { url: links.balances, title: 'Balances', icon: 'shoppingBagF' },
  { url: links.invoices, title: 'Invoices', icon: 'targetF' },
  { url: links.exchange, title: 'Exchange', icon: 'refreshCcw' },
  { url: links.wallet, title: 'Hot Wallet', icon: 'briefcase' },
  { url: links.webHooks, title: 'Web hooks', icon: 'externalLink' },
  { url: links.settings, title: 'Settings', icon: 'settingF' },
]

export const commonLinks: HeaderLinks = [
  { url: links.signup, title: 'Sign up', icon: 'settingF' },
  { url: links.signin, title: 'Log in', icon: 'settingF' },
]
