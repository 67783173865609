import { call, all, put, takeLatest } from 'redux-saga/effects'
import { SHA3 } from 'crypto-js'

import { links } from 'constant'
import { getGraphQlError, localStorage, getTrimming } from 'utils'
import { push } from 'connected-react-router'

import { mutate } from '../client'
import { SignUpInput } from '../@types'
import { SIGN_UP_REQUEST } from '../constants'
import { SIGN_UP, SignUpResponse } from '../mutation'
import { signUpRequest, signUpFailure, signUpSuccess, setAuth } from '../actions'

export function* signUp({ payload }: ReturnType<typeof signUpRequest>) {
  try {
    const variables = getTrimming<SignUpInput>(payload)

    const response: SignUpResponse = yield call(mutate, {
      mutation: SIGN_UP,
      variables: {
        ...variables,
        password_hash: SHA3(variables.password_hash).toString(),
      },
    })
    const { signUp } = response.data

    localStorage.setItem(localStorage.getKey(), { jwt: signUp.token, time: Date.now() })

    yield put(setAuth(true))
    yield put(push(links.users))
    yield put(signUpSuccess())
  } catch (err) {
    yield put(signUpFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(SIGN_UP_REQUEST, signUp)])
}
