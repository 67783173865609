import React from 'react'

const Wbtc = () => (
  <svg fill='none' viewBox='0 0 44 44'>
    <path
      fill='#5a5564'
      d='M35.877 9.234l-1.208 1.208a17.103 17.103 0 010 23.084l1.208 1.208a18.831 18.831 0 000-25.528v.028zM10.47 9.339a17.103 17.103 0 0123.084 0l1.208-1.209a18.83 18.83 0 00-25.528 0L10.47 9.34zM9.339 33.538a17.103 17.103 0 010-23.071L8.131 9.259a18.83 18.83 0 000 25.527l1.208-1.248zM33.538 34.653a17.103 17.103 0 01-23.084 0l-1.208 1.208a18.83 18.83 0 0025.528 0l-1.236-1.208z'
    />
    <path
      fill='#f09242'
      d='M29.627 17.969c-.241-2.521-2.416-3.367-5.167-3.625v-3.471h-2.126v3.407h-1.7v-3.407h-2.11v3.496h-4.313v2.275s1.571-.028 1.547 0a1.1 1.1 0 011.208.934v9.569a.746.746 0 01-.258.519.737.737 0 01-.547.185c.028.024-1.547 0-1.547 0l-.403 2.541h4.273v3.552h2.126v-3.5h1.7v3.484h2.13v-3.511c3.592-.218 6.097-1.104 6.411-4.466.254-2.707-1.018-3.915-3.052-4.402 1.236-.608 2.001-1.736 1.828-3.58zm-2.98 7.563c0 2.641-4.526 2.34-5.968 2.34v-4.688c1.442.004 5.968-.41 5.968 2.348zm-.987-6.605c0 2.416-3.777 2.122-4.977 2.122v-4.26c1.2 0 4.977-.379 4.977 2.138z'
    />
    <path
      fill='#282138'
      d='M21.996 44a22 22 0 11.008-44 22 22 0 01-.008 44zm0-42.284a20.272 20.272 0 10.032 40.544 20.272 20.272 0 00-.032-40.544z'
    />
  </svg>
)

export { Wbtc }
