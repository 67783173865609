export const DOMAIN = 'modules/exchange'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_TRANSACTIONS = 'modules/exchange/ui/SET_TRANSACTIONS'
export const SELECT_TRANSACTIONS_PAGE = 'modules/exchange/ui/SELECT_TRANSACTIONS_PAGE'
export const SELECT_TRANSACTIONS_PAGE_SIZE = 'modules/exchange/ui/SELECT_TRANSACTIONS_PAGE_SIZE'
export const SET_TRANSACTIONS_FILTERS = 'modules/exchange/ui/SET_TRANSACTIONS_FILTERS'

export const GET_TRANSACTIONS_REQUEST = 'modules/exchange/domain/GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'modules/exchange/domain/GET_TRANSACTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = 'modules/exchange/domain/GET_TRANSACTIONS_FAILURE'

export const GET_RATE_REQUEST = 'modules/exchange/domain/GET_RATE_REQUEST'
export const GET_RATE_SUCCESS = 'modules/exchange/domain/GET_RATE_SUCCESS'
export const GET_RATE_FAILURE = 'modules/exchange/domain/GET_RATE_FAILURE'

export const SET_EXCHANGE = 'modules/exchange/ui/SET_EXCHANGE'
export const GET_EXCHANGE_CLEAR = 'modules/exchange/domain/GET_EXCHANGE_CLEAR'
export const GET_EXCHANGE_REQUEST = 'modules/exchange/domain/GET_EXCHANGE_REQUEST'
export const GET_EXCHANGE_SUCCESS = 'modules/exchange/domain/GET_EXCHANGE_SUCCESS'
export const GET_EXCHANGE_FAILURE = 'modules/exchange/domain/GET_EXCHANGE_FAILURE'
