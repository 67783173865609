import styled from 'styled-components'
import { font, color } from 'theme'
import { Input, Button } from 'components'

export const TableContainer = styled.div`
  margin: 0 0 20px;
`

export const TableTitle = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const InputElement = styled(Input)`
  max-width: 300px;
  border: none;
`

export const AddButton = styled(Button)`
  margin: 0 0 30px;
`
export const DelButton = styled(Button)`
  padding: 10px;
  color: ${color.danger};
`
