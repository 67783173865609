import React from 'react'

const Email = () => (
  <svg fill='none' viewBox='0 0 22 18'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M3 1h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2z'
      clipRule='evenodd'
    />
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 3l-10 7L1 3' />
  </svg>
)

export { Email }
