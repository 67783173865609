import React from 'react'

import { Select, SelectOptions } from 'components'
import { PaginationPagesText, PaginationSelect, SelectWrap } from './Style'

type Props = {
  loading: boolean
  pageSize: number
  setPageSize?: (value: number) => void
}

const options = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
]

function SelectPage({ pageSize, setPageSize }: Props) {
  const handleSetPage = (selectedOption: SelectOptions<number>) => {
    const { value } = selectedOption
    if (setPageSize) {
      setPageSize(value)
    }
  }

  return (
    <PaginationSelect>
      <PaginationPagesText>Lines per page</PaginationPagesText>
      <SelectWrap>
        <Select size='small' value={pageSize} select={handleSetPage} options={options} />
      </SelectWrap>
    </PaginationSelect>
  )
}

export { SelectPage }
