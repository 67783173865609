import React, { Fragment } from 'react'

import { useCopy } from 'hooks'
import { Copied, CopyBtn } from './Style'

type Props = {
  value: string
  children: React.ReactNode
}

function Copy({ value, children }: Props) {
  const [status, onClick] = useCopy(value)

  return (
    <Fragment>
      {status ? (
        <Copied>Copied</Copied>
      ) : (
        <CopyBtn type='link' onClick={onClick}>
          {children}
        </CopyBtn>
      )}
    </Fragment>
  )
}

export { Copy }
