import styled, { keyframes } from 'styled-components'
import { color } from 'theme'
import { Button } from 'components'

import { getTextColor } from './helpers'

const slideShow = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
`

export const ToastWrapper = styled.div<{ type?: 'default' | 'action' | 'important' | 'error' }>`
  position: fixed;
  top: 70px;
  right: 16px;
  margin: auto;
  padding: 14px 16px;
  width: auto;
  max-width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${color.white};
  background-color: #292833;
  border-radius: 8px;
  box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.15);
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-name: ${slideShow};
  animation-timing-function: ease-in;
  will-change: transform, visibility, opacity;
  z-index: 3;
  ${({ type }) =>
    type === 'action' &&
    `
    padding: 14px 20px 14px 16px;
  `}
  ${({ type }) =>
    type === 'important' &&
    `
    padding: 14px 18px 14px 16px;
  `}
`

export const ToastText = styled.h5<{ type?: 'default' | 'action' | 'important' | 'error' }>`
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  color: ${({ type }) => getTextColor(type)};
  opacity: 0.8;
`

export const CloseBtn = styled(Button)`
  margin: 0 0 0 12px;
  color: ${color.grey};
`
