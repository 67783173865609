import React from 'react'

const CloseTransparentIcon = () => (
  <svg viewBox='0 0 14 14'>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M7.182 6.475L4.707 4 4 4.707l2.475 2.475L4 9.657l.707.707 2.475-2.475 2.475 2.475.707-.707-2.475-2.475 2.475-2.475L9.657 4 7.182 6.475zM7 14A7 7 0 117 0a7 7 0 010 14z'
      opacity='.3'
    />
  </svg>
)

export { CloseTransparentIcon }
