import React, { Component } from 'react'

import { RouterContainer } from 'modules/Auth'

import { routes } from './route'
import { Container } from './Style'

class View extends Component {
  public render() {
    return (
      <Container>
        <RouterContainer routes={routes} />
      </Container>
    )
  }
}

export { View }
