import styled, { keyframes } from 'styled-components'

// TODO add width to variables
export const placeHolderShimmer = keyframes`
  0% {
    background-position: -1220px 0
  }
  100% {
    background-position: 1220px 0
  }
`

export const Wrapper = styled.div`
  position: relative;
  margin: 0 -11px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeHolderShimmer};
    animation-timing-function: linear;
    will-change: background-position;
    background: linear-gradient(to right, transparent 8%, rgba(27, 26, 32, 0.88) 18%, transparent 33%);
    background-size: 1220px 100%;
  }
`

export const LoadingPlaceholderItem = styled.div`
  margin: 0 auto;
  max-width: 1220px;
  height: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
