import styled from 'styled-components'
import { font } from 'theme'

export const Container = styled.div`
  margin: 0 auto 30px;
  padding: 0 34px;
  max-width: 1280px;
`

export const Title = styled.h3`
  margin: 0;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
`

export const Wrap = styled.div`
  margin: 0 auto 30px;
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`
