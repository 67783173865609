import styled from 'styled-components'
import { color } from 'theme'

export const Header = styled.button<{ isOpen?: boolean; align?: string }>`
  position: relative;
  margin-bottom: ${({ isOpen }) => (isOpen ? '25px' : '0')};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  background-color: transparent;
  border: 0;
  transition: all 0.3s;
  cursor: pointer;
  ${({ align }) =>
    align === 'end'
      ? `
        justify-content: flex-end;
    `
      : align === 'center'
      ? `
        justify-content: center;
    `
      : `justify-content: flex-start;`};
  &:focus {
    outline: none;
    opacity: 0.7;
  }
  svg {
    color: ${color.primary};
    transition: transform 0.2s;
    transform: rotate(${({ isOpen }) => (isOpen ? '-90deg' : '-270deg')});
  }
`

export const Title = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
`

export const IconWrap = styled.span`
  margin: 0 0 0 10px;
  width: 6px;
  height: 10px;
`
