import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { closeAllModal } from 'containers'
import { CompanyInjectors } from 'modules/Settings'

import { ClientState } from '../@types'
import { withInjectors } from '../injectors'
import { selectNewFiatRequest } from '../selectors'
import { createStructuredSelector } from 'reselect'
import { NewFiatRequest as View } from '../components'
import { newFiatRequest, newFiatClear, newFiatFailure } from '../actions'

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      onError: newFiatFailure,
      clearState: newFiatClear,
      closeModal: closeAllModal,
      newRequest: newFiatRequest,
    },
    dispatch,
  )
}

type Props = {
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectNewFiatRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const NewFiatRequestContainer = compose<ComponentType>(CompanyInjectors, withInjectors, withConnect)(View)
