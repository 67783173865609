import { Client, Filters, Stats } from '../@types'
import {
  SET_CLIENTS,
  SELECT_CLIENTS_PAGE,
  SELECT_CLIENTS_PAGE_SIZE,
  GET_CLIENTS_FAILURE,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  SET_CLIENTS_FILTERS,
} from '../constants'

export const getClientsRequest = () => {
  return {
    type: GET_CLIENTS_REQUEST,
  } as const
}

export const getClientsFailure = (errorMessage: string) => {
  return {
    type: GET_CLIENTS_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getClientsSuccess = () => {
  return {
    type: GET_CLIENTS_SUCCESS,
  } as const
}

export const setClients = (data: Client[], total: number, stats: Stats[]) => {
  return {
    type: SET_CLIENTS,
    payload: { data, total, stats },
  } as const
}

export const selectPageSize = (pageSize: number) => {
  return {
    type: SELECT_CLIENTS_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectPage = (currentPage: number) => {
  return {
    type: SELECT_CLIENTS_PAGE,
    payload: { currentPage },
  } as const
}

export const setClientsFilters = (filters: Filters) => {
  return {
    type: SET_CLIENTS_FILTERS,
    payload: { filters },
  } as const
}
