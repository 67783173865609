import { createSelector } from 'reselect'

import { WebHooksState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: WebHooksState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: WebHooksState) => state[DOMAIN][DOMAIN_STATE]

// UI
export const selectWebHooks = () => createSelector(selectUi, subState => subState.webHooks)

// DOMAIN
export const selectGetWebHooks = () => createSelector(selectDomain, subState => subState.getWebHooks)
