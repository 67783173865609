import { call, all, put, takeLatest, fork } from 'redux-saga/effects'

import { query } from '../client'
import { GET_CURRENCIES_REQUEST } from '../constants'
import { GET_COMMON_CURRENCIES, GetCommonCurrenciesResponse } from '../queries'
import { getCurrenciesFailure, getCurrenciesSuccess, setCurrencies } from '../actions'

export function* getCurrencies() {
  try {
    const response: GetCommonCurrenciesResponse = yield call(query, {
      query: GET_COMMON_CURRENCIES,
      fetchPolicy: 'no-cache',
    })
    const {
      generalGetAppCurrencies: { list },
    } = response.data

    yield put(setCurrencies(list))

    yield put(getCurrenciesSuccess())
  } catch (err) {
    yield put(getCurrenciesFailure(err.messages))
  }
}

export function* saga() {
  return yield all([yield fork(getCurrencies), yield takeLatest(GET_CURRENCIES_REQUEST, getCurrencies)])
}
