import React from 'react'

import NumberFormat from 'react-number-format'
import { CRYPTO_DECIMALS, FIAT_DECIMALS } from 'constant'
import { checkNumber } from 'utils'

type DecimalType = 'crypto' | 'fiat'

function DecimalsFixed(value: number | string, type: DecimalType, prefix?: string, newPrecision?: number) {
  const precision = type === 'crypto' ? CRYPTO_DECIMALS : FIAT_DECIMALS

  const formatNumber = checkNumber(parseFloat(Number(value).toFixed(newPrecision ? newPrecision : precision)))

  return (
    <NumberFormat
      prefix={prefix}
      value={formatNumber}
      displayType='text'
      isNumericString={true}
      decimalSeparator={'.'}
      allowLeadingZeros={true}
      thousandSeparator={','}
      fixedDecimalScale={true}
    />
  )
}

export { DecimalsFixed }
