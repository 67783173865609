import { AddUserInput } from '../@types'
import { SET_USER, SET_USER_CLEAR, ADD_USER_REQUEST, ADD_USER_FAILURE, ADD_USER_SUCCESS } from '../constants'

export const addUserRequest = (params: AddUserInput) => {
  return {
    type: ADD_USER_REQUEST,
    payload: params,
  } as const
}

export const addUserFailure = (errorMessage: string) => {
  return {
    type: ADD_USER_FAILURE,
    payload: { errorMessage },
  } as const
}

export const addUserSuccess = () => {
  return {
    type: ADD_USER_SUCCESS,
  } as const
}

export const setUser = (otpSecret: string) => {
  return {
    type: SET_USER,
    payload: { otpSecret },
  } as const
}

export const setUserClear = () => {
  return {
    type: SET_USER_CLEAR,
  } as const
}
