import React, { ReactNode, Component } from 'react'
import { Main } from 'components'

import { HeaderContainer } from 'modules/Auth'
import { MainWrapper, SideOffset } from 'modules/Auth/components/Header/Style'

type Props = {
  children: ReactNode
  auth: boolean
}

class View extends Component<Props> {
  public render() {
    const { children, auth } = this.props

    return (
      <MainWrapper>
        {auth && <HeaderContainer />}
        <SideOffset side={auth}>
          <Main>{children}</Main>
        </SideOffset>
      </MainWrapper>
    )
  }
}

export { View }
