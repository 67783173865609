import React, { Component } from 'react'

import { RequestState } from '@types'
import { Button, DecimalsFixed, VerificationCode, KeysListener } from 'components'

import { BtnWrap, InfoModal, InfoText, ItemModal, ItemTextModal, ItemValueModal, TitleModal } from '../Style'

type State = {
  two_factor_code: string
}

type Props = {
  rate: {
    rate: number
    min_amount: number
  }
  buyAmount: number
  sellAmount: number
  buyCurrency: string
  isModalOpen: boolean
  sellCurrency: string
  toggleModal: () => void
  exchangeRequest: RequestState
  exchange: (params: {}) => void
  getDecimals: (currency: string) => number
}

class ConfirmModal extends Component<Props, State> {
  public state = {
    two_factor_code: '',
  }

  public componentDidUpdate({ isModalOpen: prevOpen }: Props) {
    const { isModalOpen } = this.props

    if (isModalOpen !== prevOpen) {
      this.onClearState()
    }
  }

  public handleConfirm = () => {
    const { sellAmount, sellCurrency, buyCurrency, exchange, isModalOpen } = this.props
    const { two_factor_code } = this.state

    if (!two_factor_code || !isModalOpen) {
      return
    }

    exchange({
      two_factor_code,
      amount: sellAmount,
      currency_to: buyCurrency,
      currency_from: sellCurrency,
    })
  }

  public render() {
    const { rate, buyAmount, sellAmount, buyCurrency, getDecimals, sellCurrency, exchangeRequest } = this.props
    const { two_factor_code } = this.state

    return (
      <KeysListener handleSubmit={this.handleConfirm}>
        <TitleModal>Confirm transaction</TitleModal>
        <InfoModal>
          <ItemModal>
            <ItemTextModal>You sent</ItemTextModal>
            <ItemValueModal>
              - {DecimalsFixed(sellAmount || '0', 'fiat', '', getDecimals(sellCurrency))} {sellCurrency}
            </ItemValueModal>
          </ItemModal>
          <ItemModal>
            <ItemTextModal>You get</ItemTextModal>
            <ItemValueModal colorValue='green'>
              + {DecimalsFixed(buyAmount || '0', 'fiat', '', getDecimals(buyCurrency))} {buyCurrency}
            </ItemValueModal>
          </ItemModal>
          <ItemModal>
            <ItemTextModal>Rate</ItemTextModal>
            <ItemValueModal>
              1 {sellCurrency} = {DecimalsFixed(rate.rate || '0', 'fiat', '', getDecimals(buyCurrency))} {buyCurrency}
            </ItemValueModal>
          </ItemModal>
        </InfoModal>
        <InfoText>Please note that the transaction may take some time due to&nbsp;processing.</InfoText>
        <VerificationCode
          value={two_factor_code}
          isError={exchangeRequest.isError}
          onActive={this.handleSetCodeValue}
          errorMessage={exchangeRequest.errorMessage}
        />
        <BtnWrap>
          <Button type='ghost' onClick={this.toggleModal}>
            Cancel
          </Button>
          <Button onClick={this.handleConfirm} disabled={!two_factor_code} isLoading={exchangeRequest.isFetching}>
            Confirm
          </Button>
        </BtnWrap>
      </KeysListener>
    )
  }

  private toggleModal = () => {
    const { toggleModal } = this.props

    this.onClearState()
    toggleModal()
  }

  private handleSetCodeValue = (value: string) => {
    this.setState(() => ({ two_factor_code: value }))
  }
  private onClearState = () => {
    this.setState(() => ({ two_factor_code: '' }))
  }
}

export { ConfirmModal }
