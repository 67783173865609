import styled from 'styled-components'
import { color, font } from 'theme'
import { Button } from 'components'

export const TableContainer = styled.div`
  margin: 0 0 20px;
`

export const TableTitle = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const TableText = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
`

export const Wrapper = styled.div`
  display: flex;
`

export const RateBlock = styled.div<{ statusActive?: boolean }>`
  margin: 0;
  padding: 30px;
  max-width: 275px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin: 0 40px 0 0;
  }
  ${({ statusActive }) =>
    !!statusActive &&
    ` background-color: ${color.default};
  `}
`

export const RateBlockTitle = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${color.white};
`

export const RateBlockText = styled.p`
  margin: 0 0 20px;
  height: 100%;
  max-height: 140px;
  font-family: ${font.regular};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: ${color.white};
  opacity: 0.8;
`

export const SelectButton = styled(Button)<{ isActive: boolean; isRequested: boolean }>`
  margin: 0;
  max-width: 215px;
  ${({ isActive }) =>
    isActive &&
    `
      background-color: ${color.primary};
  `}
  &:disabled {
    opacity: 1;
  }
`
