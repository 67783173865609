import { CommonCurrencies } from '../@types'
import {
  SET_CRYPTO_CURRENCIES,
  GET_CRYPTO_CURRENCIES_FAILURE,
  GET_CRYPTO_CURRENCIES_REQUEST,
  GET_CRYPTO_CURRENCIES_SUCCESS,
} from '../constants'

export const getCryptoCurrenciesRequest = () => {
  return {
    type: GET_CRYPTO_CURRENCIES_REQUEST,
  } as const
}

export const getCryptoCurrenciesFailure = (errorMessage: string) => {
  return {
    type: GET_CRYPTO_CURRENCIES_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getCryptoCurrenciesSuccess = () => {
  return {
    type: GET_CRYPTO_CURRENCIES_SUCCESS,
  } as const
}

export const setCryptoCurrencies = (data: CommonCurrencies) => {
  return {
    type: SET_CRYPTO_CURRENCIES,
    payload: { data },
  } as const
}
