import { createSelector } from 'reselect'

import { TransactionState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: TransactionState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: TransactionState) => state[DOMAIN][DOMAIN_STATE]

// UI
export const selectTransactions = () => createSelector(selectUi, subState => subState.transactions)

// DOMAIN
export const selectGetTransactions = () => createSelector(selectDomain, subState => subState.getTransactions)
