import { call, all, put, takeLatest } from 'redux-saga/effects'

import { query } from '../client'
import { GET_CURRENCIES_FROM_REQUEST } from '../constants'
import { GET_COMMON_CURRENCIES_FROM, GetCommonCurrenciesFromResponse } from '../queries'
import { getCurrenciesFromFailure, getCurrenciesFromSuccess, setCurrenciesFrom } from '../actions'

export function* getPairs() {
  try {
    const response: GetCommonCurrenciesFromResponse = yield call(query, {
      query: GET_COMMON_CURRENCIES_FROM,
      fetchPolicy: 'no-cache',
    })

    const {
      generalGetSecondCurrencyForExchangePairAppList: { list },
    } = response.data

    yield put(setCurrenciesFrom(list))

    yield put(getCurrenciesFromSuccess())
  } catch (err) {
    yield put(getCurrenciesFromFailure(err.messages))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_CURRENCIES_FROM_REQUEST, getPairs)])
}
