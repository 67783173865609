import React from 'react'

const UsersF = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    className='feather feather-users'
    viewBox='0 0 24 24'
  >
    <path d='M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2'></path>
    <circle cx='9' cy='7' r='4'></circle>
    <path d='M23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75'></path>
  </svg>
)

export { UsersF }
