import React from 'react'

const DownLoad = () => (
  <svg viewBox='0 0 20 22'>
    <path
      fillRule='evenodd'
      d='M11 1a1 1 0 10-2 0v11.586l-2.293-2.293a1 1 0 10-1.414 1.414l4 4 .006.007a.996.996 0 00.698.286h.006c.272 0 .518-.11.697-.286l.008-.008 4-3.999a1 1 0 00-1.415-1.414L11 12.586V1zM1 15a1 1 0 011 1v3a1 1 0 001 1h14a1 1 0 001-1v-3a1 1 0 112 0v3a3 3 0 01-3 3H3a3 3 0 01-3-3v-3a1 1 0 011-1z'
      clipRule='evenodd'
    />
  </svg>
)

export { DownLoad }
