import { SET_RESEND, ON_RESEND_REQUEST, ON_RESEND_SUCCESS, ON_RESEND_FAILURE } from '../constants'

export const setResend = (result: boolean) => {
  return {
    type: SET_RESEND,
    payload: { result },
  } as const
}

export const onResendRequest = (id: string) => {
  return {
    type: ON_RESEND_REQUEST,
    payload: { id },
  } as const
}

export const onResendSuccess = () => {
  return {
    type: ON_RESEND_SUCCESS,
  } as const
}

export const onResendFailure = (errorMessage: string) => {
  return {
    type: ON_RESEND_FAILURE,
    payload: { errorMessage },
  } as const
}
