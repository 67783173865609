import { all, put, takeLatest, call, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'

import { query } from '../client'
import { Filter } from '../@types'
import { selectTransactions } from '../selectors'
import { GET_TRANSACTIONS, GetTransactionsResponse } from '../queries'
import {
  setTransactions,
  getTransactionsFailure,
  getTransactionsSuccess,
  getTransactionsRequest as request,
} from '../actions'
import {
  GET_TRANSACTIONS_REQUEST,
  SET_TRANSACTIONS_FILTERS,
  SELECT_TRANSACTIONS_PAGE,
  SELECT_TRANSACTIONS_PAGE_SIZE,
} from '../constants'

export function* getTransactions() {
  const { filters, currentPage, pageSize } = yield select(selectTransactions())

  try {
    const dataFilters = getTrimming<Filter>(filters)

    const response: GetTransactionsResponse = yield call(query, {
      query: GET_TRANSACTIONS,
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
      fetchPolicy: 'no-cache',
    })
    const {
      findForcedExchanges: { total, list },
    } = response.data

    yield put(setTransactions(list, total, []))

    yield put(getTransactionsSuccess())
  } catch (err) {
    yield put(getTransactionsFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getTransactionsFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_TRANSACTIONS_REQUEST, getTransactions),

    yield takeLatest(SELECT_TRANSACTIONS_PAGE, onRequest),
    yield takeLatest(SET_TRANSACTIONS_FILTERS, onRequest),
    yield takeLatest(SELECT_TRANSACTIONS_PAGE_SIZE, onRequest),
  ])
}
