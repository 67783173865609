import { ChangeDetails, Request } from '../@types'
import {
  SET_DETAILS,
  CHANGE_DETAIL_CLEAR,
  CHANGE_DETAIL_FAILURE,
  CHANGE_DETAIL_REQUEST,
  CHANGE_DETAIL_SUCCESS,
  GET_LATEST_UPDATE_FAILURE,
  GET_LATEST_UPDATE_REQUEST,
  GET_LATEST_UPDATE_SUCCESS,
} from '../constants'

export const changeDetailRequest = (data: ChangeDetails) => {
  return {
    type: CHANGE_DETAIL_REQUEST,
    payload: data,
  } as const
}

export const changeDetailClear = () => {
  return {
    type: CHANGE_DETAIL_CLEAR,
  } as const
}

export const changeDetailFailure = (errorMessage: string) => {
  return {
    type: CHANGE_DETAIL_FAILURE,
    payload: { errorMessage },
  } as const
}

export const changeDetailSuccess = () => {
  return {
    type: CHANGE_DETAIL_SUCCESS,
  } as const
}

export const setDetails = (data: Request[]) => {
  return {
    type: SET_DETAILS,
    payload: { data },
  } as const
}

export const getLatestUpdateRequest = () => {
  return {
    type: GET_LATEST_UPDATE_REQUEST,
  } as const
}

export const getLatestUpdateFailure = (errorMessage: string) => {
  return {
    type: GET_LATEST_UPDATE_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getLatestUpdateSuccess = () => {
  return {
    type: GET_LATEST_UPDATE_SUCCESS,
  } as const
}
