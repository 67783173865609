import styled, { css } from 'styled-components'

import { font, color } from 'theme'
import { Button } from '../Button'

export const StyledTable = styled.div<{ noBorderBottom?: boolean }>`
  display: block;
  width: 100%;
  max-width: 100%;

  table {
    margin: 0 auto;
    width: 100%;
    max-width: 1220px;
    border-spacing: 2px;

    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    td {
      text-align: left;
    }
  }

  ${({ noBorderBottom }) =>
    noBorderBottom &&
    `
      tbody {
        tr {
          opacity: 0.5;
          border-bottom: none;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
  `}
`

export const Th = styled.th<{ isSorting?: boolean; alignText?: string }>`
  margin: 0;
  padding: 0 8px 10px;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  word-break: keep-all;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  pointer-events: none;
  &:hover {
    span {
      color: ${color.white};
    }
  }

  ${({ isSorting }) =>
    isSorting &&
    css`
      pointer-events: all;
    `}

  ${({ alignText }) =>
    alignText === 'right'
      ? `
        text-align: right;
    `
      : alignText === 'center'
      ? `
        text-align: center;
    `
      : `text-align: left;`};
`

export const Td = styled.td<{ alignText?: string }>`
  margin: 0;
  padding: 0 8px;
  height: 60px;
  text-align: left;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  ${({ alignText }) =>
    alignText === 'right'
      ? `
        text-align: right;
    `
      : alignText === 'center'
      ? `
        text-align: center;
    `
      : `text-align: left;`};
`

export const WrapInput = styled.div`
  max-width: 100px;
`

export const PaginationWrapper = styled.div<{ isSelected: boolean }>`
  margin: 0 auto;
  padding: 14px 0 34px;
  max-width: 1220px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${({ isSelected }) => (isSelected ? 'space-between' : 'flex-end')};
  align-items: center;
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

export const HeadersWrap = styled.span`
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-out;
`

export const SortedIconWrap = styled.div`
  margin: 0 0 0 20px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`

export const SortedIcon = styled.span<{
  isTop: boolean
  isBottom: boolean
  isTopActive: boolean
  isBottomActive: boolean
}>`
  width: 0;
  height: 0;
  border-style: solid;

  ${({ isTop }) =>
    isTop &&
    css`
      margin: 0 0 2px;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent #757f9c transparent;
    `}
  ${({ isTopActive }) =>
    isTopActive &&
    css`
      border-color: transparent transparent ${color.white} transparent;
    `}
  ${({ isBottom }) =>
    isBottom &&
    `
      margin: 2px 0 0;
      border-width: 4px 4px 0 4px;
      border-color: #757f9c transparent transparent transparent;
  `}
  ${({ isBottomActive }) =>
    isBottomActive &&
    `
      border-color: ${color.white} transparent transparent transparent;
  `}
`

export const PaginationPages = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 10rem;
  position: relative;
`

export const PaginationPagesText = styled.span`
  margin: 0 8px 0 0;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  strong {
    color: ${color.white};
  }
`

export const SelectWrap = styled.div`
  width: 72px;
`

export const Pretext = styled.span`
  margin: 0 5px;
  display: inline-block;
`

export const PaginationBtn = styled.button<{ isLeft?: boolean; isRight?: boolean }>`
  position: relative;
  margin: 0 0 0 8px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #393451;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    border: 1px solid ${color.white};
    outline: none;
  }
  ${({ isLeft }) =>
    isLeft &&
    `
    &::before {
      content: '';
      position: relative;
      right: -2px;
      width: 10px;
      height: 10px;
      border-top: 2px solid ${color.white};
      border-right: 2px solid ${color.white};
      transform: rotate(-135deg);
    }
  `}
  ${({ isRight }) =>
    isRight &&
    `
    &::after {
      content: '';
      position: relative;
      right: 2px;
      width: 10px;
      height: 10px;
      border-top: 2px solid ${color.white};
      border-right: 2px solid ${color.white};
      transform: rotate(45deg);
    }
  `}
`

export const Input = styled.input`
  padding: 12px 16px;
  width: 100px;
  height: 44px;
  font-family: ${font.regular};
  font-size: 14px;
  color: ${color.white};
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  transition: all 0.3s ease-out;
  outline: none;
  &:hover {
    border-color: ${color.white};
  }
  &:focus {
    border-color: ${color.default};
  }
`

export const EditButton = styled(Button)`
  margin: 0 0 0 auto;
  padding: 10px;
  min-width: auto;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const IconWrapper = styled.div`
  margin: 0 8px 0 0;
`
