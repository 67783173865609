import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { openModal } from 'containers'
import { StaffInjectors } from 'modules/Settings'
import { CurrenciesInjectors } from 'modules/Global'

import { withInjectors } from '../injectors'
import { CryptoTable as View } from '../components'
import { ClientState, CryptoRequests } from '../@types'
import { selectCryptoRequests, selectCryptoRequest } from '../selectors'
import { getCryptoRequest, setCryptoFilters, selectCryptoPage, selectCryptoPageSize } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      openModal,
      getData: getCryptoRequest,
      setFilters: setCryptoFilters,
      selectPage: selectCryptoPage,
      selectPageSize: selectCryptoPageSize,
    },
    dispatch,
  )

type Props = {
  request: RequestState
  cryptoRequests: CryptoRequests
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectCryptoRequest(),
    cryptoRequests: selectCryptoRequests(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const CryptoRequestContainer = compose<ComponentType>(
  CurrenciesInjectors,
  StaffInjectors,
  withInjectors,
  withConnect,
)(View)
