import React from 'react'
import { defaultTheme } from 'theme'
import { Icon } from 'components'

import { IconWrapper, StyledHeader, TitleS } from './Style'
import { HeaderProps, CustomIconProps } from './@types'

const CustomIcon = ({ name, onAction, isLeft = false }: CustomIconProps) =>
  name && (
    <IconWrapper onClick={onAction} isLeft={isLeft}>
      <Icon name={name} />
    </IconWrapper>
  )

function Title({ text, iconRight, iconLeft, type = 'black', theme = defaultTheme }: HeaderProps) {
  return (
    <StyledHeader type={type} theme={theme}>
      {iconLeft && <CustomIcon isLeft {...iconLeft} />}
      <TitleS as='h1'>{text}</TitleS>
      {iconRight && <CustomIcon {...iconRight} />}
    </StyledHeader>
  )
}

export { Title }
