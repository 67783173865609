import React from 'react'

import { EMAIL_NEW_CLIENT, B2BAPPLICATION } from 'constant'
import { Href, Button, Icon } from 'components'
import {
  Warning,
  WarningTitle,
  WarningText,
  Title,
  Text,
  SignDocContainer,
  DocumentationItem,
  IconWrap,
  DocumentationInfo,
  DocumentationName,
  DocumentationType,
  BtnContainer,
  EmailLink,
} from './Style'

type Props = {
  onNext: () => void
  onBack: () => void
  onConfirm: () => void
}

function SignDoc({ onBack, onNext, onConfirm }: Props) {
  const handleClick = () => {
    onConfirm()
    onNext()
  }

  return (
    <form noValidate>
      <Warning>
        <WarningTitle>Please fill in&nbsp;the form</WarningTitle>
        <WarningText noMargin>
          For successful onboarding you have to&nbsp;download the following form, type all necessary information, sign
          it&nbsp;and send it&nbsp;to&nbsp;our email&nbsp;
          <Href href={`mailto:` + EMAIL_NEW_CLIENT}>
            <EmailLink>{EMAIL_NEW_CLIENT}</EmailLink>
          </Href>
          .
        </WarningText>
      </Warning>

      <SignDocContainer>
        <Title>Sign the document</Title>
        <Text>Download&nbsp;it, sign it&nbsp;and send it&nbsp;back to&nbsp;us.</Text>

        <Href href={B2BAPPLICATION}>
          <DocumentationItem>
            <IconWrap>
              <Icon size='fill' name='pdf' />
            </IconWrap>
            <DocumentationInfo>
              <DocumentationName>B2B Application</DocumentationName>
              <DocumentationType>PDF file</DocumentationType>
            </DocumentationInfo>
          </DocumentationItem>
        </Href>
      </SignDocContainer>

      <BtnContainer>
        <Button type='ghost' onClick={onBack}>
          Back
        </Button>
        <Button onClick={handleClick}>Next</Button>
      </BtnContainer>
    </form>
  )
}

export { SignDoc }
