import styled from 'styled-components'
import { color, breakpoints } from 'theme'

export const Copied = styled.span`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
  color: ${color.success};
  text-align: center;
`

export const AlertText = styled.p`
  margin: 0;
  padding: 1.6rem;
  width: 100%;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  border-radius: 8px
  color: ${color.alert};
  background-color: ${color.sun};
`

export const Text = styled.p`
  margin: 0 auto 20px;
  padding: 0 32px;
  width: 100%;
  max-width: 460px;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: ${color.black};
  opacity: 0.5;
  ${breakpoints.greaterThan('xsm')`
    padding: 0;
  `}
`

export const Container = styled.div`
  margin: 15px 12px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  box-shadow: 0 12px 40px #e6ebf0;
  ${breakpoints.greaterThan('xsm')`
    margin: 15px auto 0;
    border-radius: 8px;
  `}
`

export const MainInfo = styled.div`
  padding: 20px 6px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h5`
  margin: 0 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
`

export const Send = styled.div`
  margin: 0 0 20px;
`

export const SendValue = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
  color: ${color.black};
`

export const Address = styled.div`
  margin: 0 0 20px;
`

export const AddressValue = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: ${color.black};
`

export const CopyAddress = styled.button`
  margin: 0 0 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.default};
  background-color: transparent;
  border: none;
  transition: all 0.3s linear;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${color.primary};
  }
`

export const CopyAddressText = styled.span`
  margin: 0 0 0 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
`

export const ExpiredText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: ${color.black};
`

export const ExpiredLink = styled.a`
  color: ${color.default};
  text-decoration: none;
`

export const AdditionlInfo = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${color.default};
  border-top: 1px solid #e6e9f2;
`

export const Timer = styled.div`
  margin: 0 0 4px;
  color: ${color.black};
`

export const ToggleWrap = styled.div`
  width: 100%;
  color: ${color.default};
  &:hover {
    color: ${color.primary};
  }
`

export const InfoPayment = styled.div`
  margin: 0 0 16px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const InfoPaymentPreTitle = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.black};
  opacity: 0.5;
`

export const InfoPaymentTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.black};
`

export const InfoPaymentTotal = styled.div`
  margin: 0 20px;
  padding: 16px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  border-top: 1px solid #e6e9f2;
`

export const InfoPaymentTotalTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: ${color.black};
`
