import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { withInjectors } from './withInjectors'
import { selectCompany } from '../selectors'
import { getCompanyRequest } from '../actions'
import { Company, ClientState } from '../@types'

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getCompany: getCompanyRequest }, dispatch)

type Props = {
  company: Company
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    company: selectCompany(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const CompanyInjectors = compose(withInjectors, withConnect)
