import { all, fork } from 'redux-saga/effects'

import { saga as Pairs } from './Pairs'
import { saga as Currencies } from './Currencies'
import { saga as CurrenciesFrom } from './CurrenciesFrom'
import { saga as CryptoCurrencies } from './CryptoCurrencies'

const GlobalSagas = [Pairs, Currencies, CurrenciesFrom, CryptoCurrencies]

export function* saga() {
  return yield all([...GlobalSagas.map(fork)])
}
