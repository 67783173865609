import React from 'react'
import { RadioButton, RadioButtonIcon, RadioButtonLabel, RadioWrapper } from './Style'

type RadioItemProps<T> = {
  value: T
  selected: T
  name?: string
  disabled?: boolean
  children?: React.ReactNode
  onClick: (value: T) => void
}

function RadioItem<T>({ value, onClick, selected, children, name = 'radio', disabled = false }: RadioItemProps<T>) {
  const handleClick = () => {
    onClick(value)
  }

  return (
    <RadioWrapper onClick={handleClick} disabled={disabled}>
      <RadioButton type='radio' name={name} checked={selected === value} onChange={handleClick} disabled={disabled} />
      <RadioButtonIcon disabled={disabled} />
      <RadioButtonLabel>{children}</RadioButtonLabel>
    </RadioWrapper>
  )
}

export { RadioItem }
