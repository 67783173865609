import { InvoiceList, Filter, Stats } from '../@types'
import {
  SET_INVOICES,
  SET_INVOICES_FILTERS,
  SELECT_INVOICES_PAGE,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_REQUEST,
  GET_INVOICES_FAILURE,
  SELECT_INVOICES_PAGE_SIZE,
} from '../constants'

export const getInvoicesRequest = () => {
  return {
    type: GET_INVOICES_REQUEST,
  } as const
}

export const getInvoicesFailure = (errorMessage: string) => {
  return {
    type: GET_INVOICES_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getInvoicesSuccess = () => {
  return {
    type: GET_INVOICES_SUCCESS,
  } as const
}

export const setTxFilters = (filters: Filter) => {
  return {
    type: SET_INVOICES_FILTERS,
    payload: { filters },
  } as const
}

export const selectPageSize = (pageSize: number) => {
  return {
    type: SELECT_INVOICES_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectPage = (currentPage: number) => {
  return {
    type: SELECT_INVOICES_PAGE,
    payload: { currentPage },
  } as const
}

export const setInvoices = (data: InvoiceList[], total: number, stats: Stats[]) => {
  return {
    type: SET_INVOICES,
    payload: { data, total, stats },
  } as const
}
