import styled from 'styled-components'
import { font, color } from 'theme'

export const Container = styled.div`
  margin: 0 auto 30px;
  padding: 0 34px;
  max-width: 1280px;
`

export const Title = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
`

export const Text = styled.p`
  margin: 0 0 20px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const MainWrapper = styled.div`
  margin: 0 auto 20px;
  padding: 30px;
  max-width: 1280px;
  color: ${color.white};
  background-color: #292833;
  border-radius: 8px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ColumnBig = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 320px;
`

export const Column = styled.div`
  max-width: 275px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const ContainerButtons = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`

export const Element = styled.div`
  margin: 0 0 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.71;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`

export const FiatName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  line-height: 1.71;
  font-family: ${font.regular};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: uppercase;
`

export const Fiat = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const ManagerName = styled.span`
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`
