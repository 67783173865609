import { all, takeLatest, call, put } from 'redux-saga/effects'

import { mutate } from '../client'
import { RESET_API } from '../mutation'
import { RESET_API_REQUEST } from '../constants'
import { resetApiFailure, resetApiSuccess, getCompanyRequest } from '../actions'

export function* resetApi() {
  try {
    yield call(mutate, { mutation: RESET_API })

    yield put(resetApiSuccess())
    yield put(getCompanyRequest())
  } catch (err) {
    yield put(resetApiFailure(err.message))
  }
}

export function* saga() {
  return yield all([yield takeLatest(RESET_API_REQUEST, resetApi)])
}
