import React from 'react'

const Apply = () => (
  <svg viewBox='0 0 22 22'>
    <path
      fill='#4caf50'
      fillRule='evenodd'
      d='M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11a9 9 0 1118 0 9 9 0 01-18 0zm13.707-2.293a1 1 0 00-1.414-1.414L9.5 12.086l-1.793-1.793a1 1 0 10-1.414 1.414l2.5 2.5a1 1 0 001.414 0l5.5-5.5z'
      clipRule='evenodd'
    />
  </svg>
)

export { Apply }
