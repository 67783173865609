import { gql, ApolloQueryResult } from 'apollo-boost'
import { FiatRequest, CryptoRequest, Stats } from './@types'

export const GET_FIAT_REQ = gql`
  query findFiatReqs(
    $ids: String
    $date_from: String
    $date_to: String
    $status: FiatRequestStatus
    $currency_names: [FiatCurrencyName]
    $staff_ids: String
    $amount_from: Float
    $amount_to: Float
    $comment: String
    $page: Int
    $page_size: Int
  ) {
    findFiatReqs(
      data: {
        ids: $ids
        status: $status
        date_from: $date_from
        date_to: $date_to
        currency_names: $currency_names
        staff_ids: $staff_ids
        amount_from: $amount_from
        amount_to: $amount_to
        comment: $comment
        page: $page
        page_size: $page_size
      }
    ) {
      total
      stats {
        currency
        total
        available
        pending
        send
      }
      fiat_requests {
        id
        staff {
          id
          email
          name
          role_name
        }
        bank_detail
        currency_name
        amount
        comment
        signed_paper
        status
        created_at
        updated_at
      }
    }
  }
`

export type GetFiatReqResponse = ApolloQueryResult<{
  findFiatReqs: {
    stats: Stats[]
    total: number
    fiat_requests: FiatRequest[]
  }
}>

export const GET_CRYPTO_REQ = gql`
  query findCryptoReqs(
    $status: CryptoRequestStatus
    $ids: String
    $date_from: String
    $date_to: String
    $currency_names: [CommonCurrencyName]
    $staff_ids: String
    $amount_from: Float
    $amount_to: Float
    $comment: String
    $page: Int
    $page_size: Int
  ) {
    findCryptoReqs(
      data: {
        status: $status
        ids: $ids
        date_from: $date_from
        date_to: $date_to
        currency_names: $currency_names
        staff_ids: $staff_ids
        amount_from: $amount_from
        amount_to: $amount_to
        comment: $comment
        page: $page
        page_size: $page_size
      }
    ) {
      total
      stats {
        currency
        total
        available
        pending
        send
      }
      crypto_requests {
        id
        staff {
          id
          email
          name
          role_name
        }
        currency_name
        address
        amount
        comment
        tx_id
        signed_paper
        status
        created_at
        updated_at
      }
    }
  }
`

export type GetCryptoReqResponse = ApolloQueryResult<{
  findCryptoReqs: {
    stats: Stats[]
    total: number
    crypto_requests: CryptoRequest[]
  }
}>
