import styled from 'styled-components'
import { color, breakpoints } from 'theme'

export const Container = styled.div`
  margin: 15px 12px 0;
  padding: 0;
  max-width: 376px;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  box-shadow: 0 12px 40px #e6ebf0;
  ${breakpoints.greaterThan('xsm')`
    margin: 15px auto 0;
    border-radius: 8px;
  `}
`

export const MainInfo = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
`

export const TitleWrap = styled.div<{ isDeclined: boolean }>`
  margin: 0 0 30px;
  display: flex;
  align-items: center;
  color: ${({ isDeclined }) => (isDeclined ? color.warning : color.success)};
`

export const Title = styled.h3`
  margin: 0 0 0 14px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
`

export const InfoFunds = styled.div`
  &:not(:last-child) {
    margin: 0 0 24px;
  }
`

export const InfoFundsPreTitle = styled.h5`
  margin: 0 0 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.black};
  opacity: 0.5;
`

export const InfoFundsTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: ${color.black};
`

export const ExploreLink = styled.a`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
  color: ${color.default};
  border-top: 1px solid #e6e9f2;
  border-radius: 0 0 8px 8px;
  transition: all 0.3s linear;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${color.primary};
  }
  &:focus,
  &:active {
    color: ${color.primary};
    background-color: #f6f7fb;
  }
`

export const ExplorerLinkText = styled.span`
  margin: 0 0 0 11px;
  display: inline-block;
`
