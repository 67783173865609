import styled from 'styled-components'
import { color, font } from 'theme'

export const WrapperInput = styled.div`
  position: relative;
  padding: 0 0 15px;
  text-align: center;
`

export const Input = styled.input<{ third: boolean }>`
  width: 44px;
  height: 56px;
  padding: 4px;
  margin-left: 3px;
  margin-right: ${({ third }) => (third ? '6px' : '3px')};
  font-family: ${font.display};
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  border: 1px solid ${color.grey};
  border-radius: 8px;
  outline: none;
  transition: border-color 200ms;
  &:focus {
    border-color: ${color.default};
  }
`

export const Error = styled.span`
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${color.warning};
  transform: translate(-50%, 10px);
`
