import styled from 'styled-components'
import { font, color } from 'theme'

export const Wrapper = styled.ol`
  margin: 0 40px 0 0;
  padding: 0 20px;
  width: 275px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  list-style: none;
`

export const Bubble = styled.span`
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: ${color.default};
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 24px;
    bottom: -48px;
    width: 1px;
    background-color: #292833;
  }
`

export const BubbleText = styled.span`
  display: block;
  font-family: ${font.display};
  font-weight: 700;
  text-align: center;
`

export const BubbleIcon = styled.span`
  display: none;
`

export const Item = styled.li<{ status?: string }>`
  position: relative;
  margin: 0 0 46px;
  padding: 0;
  width: 100%;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  list-style: none;

  &:last-child ${Bubble}::before {
    display: none;
  }

  ${({ status }) =>
    status === 'passed'
      ? `
      opacity: 1;
      ${Bubble} {
        &:before {
          background-color: ${color.default};
        }
      }
      ${BubbleIcon} {
        display: block;
      }
      ${BubbleText} {
        display: none;
      }
    `
      : status === 'future'
      ? `
      opacity: 0.5;
      ${Bubble} {
        background-color: #292833;
      }
    `
      : null};
`

export const BubbleContent = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Title = styled.h4<{ status?: string }>`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  text-transform: uppercase;
  color: ${color.default};
  ${({ status }) =>
    status === 'passed'
      ? `
      opacity: 1;
    `
      : status === 'future'
      ? `
      color: ${color.white};
      opacity: 0.5;
    `
      : null};
`

export const Text = styled.p`
  margin: 0;
  max-width: 325px;
  font-size: 14px;
  line-height: 1.4;
  color: ${color.white};
`
