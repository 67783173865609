import styled from 'styled-components'
import { breakpoints } from 'theme'

import { Button } from 'components'
import { font, color } from 'theme'

export const InputWrapper = styled.div`
  margin: 30px 0 0;
`

export const LogoContainer = styled.div`
  margin: 20px auto 30px;
`

export const StyledButton = styled(Button)`
  margin: 54px 0 0;
`

export const Text = styled.span`
  font-family: ${font.regular};
  margin: auto 0 0;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const SmallText = styled.p`
  font-family: ${font.regular};
  font-size: 12px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const Error = styled(SmallText)`
  color: ${color.warning};
`
