import React, { Fragment, useMemo } from 'react'
import { NewTable, createColumns } from 'components'

import { RequestState } from '@types'
import { Clients, Client, CommonCurrencies } from '../../@types'

import { COLUMNS } from './Columns'
import { Filter } from '../Filter'
import { Stats } from '../Stats'

type Props = {
  clients: Clients
  getData: () => void
  setFilters: () => void
  request: RequestState
  cryptoCurrencies: CommonCurrencies
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
}

function Table({ getData, request, clients, selectPage, setFilters, selectPageSize, cryptoCurrencies }: Props) {
  const total = useMemo(
    () =>
      (clients.stats || []).filter(({ currency }) =>
        cryptoCurrencies.find(({ name }) => name.toLowerCase() === currency.toLowerCase()),
      ),
    [cryptoCurrencies, clients.stats],
  )

  return (
    <Fragment>
      <Stats total={total.length ? total : clients.stats} loading={request.isFetching} />
      <Filter setFilters={setFilters} />
      <NewTable
        selection={true}
        getData={getData}
        pagination={true}
        data={clients.data}
        selectPage={selectPage}
        pageSize={clients.pageSize}
        pageCount={clients.pageCount}
        setPageSize={selectPageSize}
        loading={request.isFetching}
        currentPage={clients.currentPage}
        columns={createColumns<Client>(COLUMNS)}
      />
    </Fragment>
  )
}

export { Table }
