export const DOMAIN = 'modules/settings'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_STAFF = 'modules/settings/ui/SET_STAFF'
export const GET_STAFF_REQUEST = 'modules/settings/domain/GET_STAFF_REQUEST'
export const GET_STAFF_SUCCESS = 'modules/settings/domain/GET_STAFF_SUCCESS'
export const GET_STAFF_FAILURE = 'modules/settings/domain/GET_STAFF_FAILURE'

export const SET_COMPANY = 'modules/settings/ui/SET_COMPANY'
export const GET_COMPANY_REQUEST = 'modules/settings/domain/GET_COMPANY_REQUEST'
export const GET_COMPANY_SUCCESS = 'modules/settings/domain/GET_COMPANY_SUCCESS'
export const GET_COMPANY_FAILURE = 'modules/settings/domain/GET_COMPANY_FAILURE'

export const SET_CURRENCY_LIST = 'modules/settings/ui/SET_CURRENCY_LIST'
export const GET_CURRENCY_LIST_REQUEST = 'modules/settings/domain/GET_CURRENCY_LIST_REQUEST'

export const SET_USER = 'modules/settings/ui/SET_USER'
export const SET_USER_CLEAR = 'modules/settings/ui/SET_USER_CLEAR'
export const ADD_USER_REQUEST = 'modules/settings/domain/ADD_USER_REQUEST'
export const ADD_USER_SUCCESS = 'modules/settings/domain/ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'modules/settings/domain/ADD_USER_FAILURE'

export const REMOVE_USER_CLEAR = 'modules/settings/domain/REMOVE_USER_CLEAR'
export const REMOVE_USER_REQUEST = 'modules/settings/domain/REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'modules/settings/domain/REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'modules/settings/domain/REMOVE_USER_FAILURE'

export const CHANGE_DETAIL_CLEAR = 'modules/settings/domain/CHANGE_DETAIL_CLEAR'
export const CHANGE_DETAIL_REQUEST = 'modules/settings/domain/CHANGE_DETAIL_REQUEST'
export const CHANGE_DETAIL_SUCCESS = 'modules/settings/domain/CHANGE_DETAIL_SUCCESS'
export const CHANGE_DETAIL_FAILURE = 'modules/settings/domain/CHANGE_DETAIL_FAILURE'

export const SET_DETAILS = 'modules/settings/domain/SET_DETAILS'
export const GET_LATEST_UPDATE_REQUEST = 'modules/settings/domain/GET_LATEST_UPDATE_REQUEST'
export const GET_LATEST_UPDATE_SUCCESS = 'modules/settings/domain/GET_LATEST_UPDATE_SUCCESS'
export const GET_LATEST_UPDATE_FAILURE = 'modules/settings/domain/GET_LATEST_UPDATE_FAILURE'

export const SET_ONBOARDING_REQUEST = 'modules/settings/domain/SET_ONBOARDING_REQUEST'
export const SET_ONBOARDING_SUCCESS = 'modules/settings/domain/SET_ONBOARDING_SUCCESS'
export const SET_ONBOARDING_FAILURE = 'modules/settings/domain/SET_ONBOARDING_FAILURE'

export const RESET_API_REQUEST = 'modules/settings/domain/RESET_API_REQUEST'
export const RESET_API_SUCCESS = 'modules/settings/domain/RESET_API_SUCCESS'
export const RESET_API_FAILURE = 'modules/settings/domain/RESET_API_FAILURE'
