import { call, all, put, takeLatest, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'
import { query } from '../client'
import { FilterCryptoRequest } from '../@types'
import { selectCryptoRequests } from '../selectors'
import { GET_CRYPTO_REQ, GetCryptoReqResponse } from '../queries'
import { getCryptoFailure, getCryptoSuccess, setCrypto, getCryptoRequest as request } from '../actions'
import { GET_CRYPTO_REQUEST, SELECT_CRYPTO_PAGE_SIZE, SELECT_CRYPTO_PAGE, SET_CRYPTO_FILTERS } from '../constants'

export function* getCryptoRequest() {
  const { filters, currentPage, pageSize } = yield select(selectCryptoRequests())

  try {
    const dataFilters = getTrimming<FilterCryptoRequest>(filters)

    const response: GetCryptoReqResponse = yield call(query, {
      query: GET_CRYPTO_REQ,
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
      fetchPolicy: 'no-cache',
    })
    const {
      findCryptoReqs: { total, crypto_requests, stats },
    } = response.data

    yield put(setCrypto(crypto_requests, total, stats))

    yield put(getCryptoSuccess())
  } catch (err) {
    yield put(getCryptoFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getCryptoFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_CRYPTO_REQUEST, getCryptoRequest),

    yield takeLatest(SELECT_CRYPTO_PAGE, onRequest),
    yield takeLatest(SET_CRYPTO_FILTERS, onRequest),
    yield takeLatest(SELECT_CRYPTO_PAGE_SIZE, onRequest),
  ])
}
