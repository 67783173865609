export const DOMAIN = 'modules/request'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_CRYPTO = 'modules/request/ui/SET_CRYPTO'
export const SET_CRYPTO_FILTERS = 'modules/request/ui/SET_CRYPTO_FILTERS'
export const SELECT_CRYPTO_PAGE = 'modules/request/ui/SELECT_CRYPTO_PAGE'
export const SELECT_CRYPTO_PAGE_SIZE = 'modules/request/ui/SELECT_CRYPTO_PAGE_SIZE'

export const GET_CRYPTO_REQUEST = 'modules/request/domain/GET_CRYPTO_REQUEST'
export const GET_CRYPTO_SUCCESS = 'modules/request/domain/GET_CRYPTO_SUCCESS'
export const GET_CRYPTO_FAILURE = 'modules/request/domain/GET_CRYPTO_FAILURE'

export const SET_FIAT = 'modules/request/ui/SET_FIAT'
export const SELECT_FIAT_PAGE = 'modules/request/ui/SELECT_FIAT_PAGE'
export const SET_FIAT_FILTERS = 'modules/request/ui/SET_FIAT_FILTERS'
export const SELECT_FIAT_PAGE_SIZE = 'modules/request/ui/SELECT_FIAT_PAGE_SIZE'

export const GET_FIAT_REQUEST = 'modules/request/domain/GET_FIAT_REQUEST'
export const GET_FIAT_SUCCESS = 'modules/request/domain/GET_FIAT_SUCCESS'
export const GET_FIAT_FAILURE = 'modules/request/domain/GET_FIAT_FAILURE'

export const NEW_CRYPTO_CLEAR = 'modules/request/domain/NEW_CRYPTO_CLEAR'
export const NEW_CRYPTO_REQUEST = 'modules/request/domain/NEW_CRYPTO_REQUEST'
export const NEW_CRYPTO_SUCCESS = 'modules/request/domain/NEW_CRYPTO_SUCCESS'
export const NEW_CRYPTO_FAILURE = 'modules/request/domain/NEW_CRYPTO_FAILURE'
export const NEW_CRYPTO_ERROR_CLEAR = 'modules/request/domain/NEW_CRYPTO_ERROR_CLEAR'

export const NEW_FIAT_CLEAR = 'modules/request/domain/NEW_FIAT_CLEAR'
export const NEW_FIAT_REQUEST = 'modules/request/domain/NEW_FIAT_REQUEST'
export const NEW_FIAT_SUCCESS = 'modules/request/domain/NEW_FIAT_SUCCESS'
export const NEW_FIAT_FAILURE = 'modules/request/domain/NEW_FIAT_FAILURE'
