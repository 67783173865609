import { createSelector } from 'reselect'

import { GlobalState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: GlobalState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: GlobalState) => state[DOMAIN][DOMAIN_STATE]

export const selectPairs = () => createSelector(selectUi, subState => subState.pairs)
export const selectGetPairsRequest = () => createSelector(selectDomain, subState => subState.getPairs)

export const selectCurrencies = () => createSelector(selectUi, subState => subState.currencies)
export const selectGetCurrenciesRequest = () => createSelector(selectDomain, subState => subState.getCurrencies)

// remove filter
export const selectCryptoCurrencies = () => {
  return createSelector(selectUi, subState => {
    return subState.cryptoCurrencies.filter(({ extra_type }) => extra_type === 'crypto' || extra_type === 'erc20')
  })
}

export const selectMainCryptoCurrencies = () => {
  return createSelector(selectUi, subState => {
    return subState.cryptoCurrencies.filter(({ extra_type, name }) => extra_type === 'crypto' || name === 'USDT')
  })
}

export const selectGetCryptoCurrenciesRequest = () =>
  createSelector(selectDomain, subState => subState.getCryptoCurrencies)

export const selectCurrenciesFrom = () => createSelector(selectUi, subState => subState.currenciesFrom)
export const selectGetCurrenciesFromRequest = () => createSelector(selectDomain, subState => subState.getCurrenciesFrom)
