import React, { Component, ComponentType } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux'

import getInjectors from './getInjectors'
import { ApplicationState } from 'store/@types'
import { InjectedSaga, ReduxInjectorStore } from './@types'

// TODO: Add runtime check

type Props = InjectedSaga & { key: string }

export const injectSaga = ({ key, saga, mode, done = false }: Props) => <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  class SagaInjector extends Component<P> {
    public static contextType = ReactReduxContext
    public static WrappedComponent = WrappedComponent
    public static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

    private injectors: {
      injectSaga: (key: string, { saga, mode, done }: InjectedSaga, props: P) => void
      ejectSaga: (key: string) => void
    }

    public constructor(props: P, context: ReactReduxContextValue<ApplicationState>) {
      super(props, context)

      this.injectors = getInjectors<ApplicationState>(context.store as ReduxInjectorStore<ApplicationState>)
      this.injectors.injectSaga(key, { saga, mode, done }, this.props)
    }

    public componentWillUnmount() {
      this.injectors.ejectSaga(key)
    }

    public render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistNonReactStatics(SagaInjector, WrappedComponent)
}
