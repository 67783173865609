import styled from 'styled-components'

import { font, color } from 'theme'

import { NavLink } from 'react-router-dom'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  margin: 0 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: no-drop;

  .SelectFiat__control {
    margin: 6px 0 0 12px;
    padding: 0 8px 0 12px;
    width: 100px;
    height: 44px;
    color: ${color.white};
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      border-color: ${color.white};
    }
  }
  .SelectFiat__value-container {
    padding: 0;
    &--has-value {
      overflow: visible;
    }
  }
  .SelectFiat__single-value {
    font-family: ${font.regular};
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    color: ${color.white};
  }
  .SelectFiat__placeholder {
    position: static;
    margin: 0 10px 0 0;
    font-size: 14px;
    color: ${color.white};
    transform: none;
  }
  .SelectFiat__indicator-separator {
    display: none;
  }
  .SelectFiat__indicator.SelectFiat__dropdown-indicator {
    padding: 0;
  }
  .SelectFiat__menu {
    top: 6px;
    right: 0;
    margin: 0;
    padding: 0;
    max-width: 100px;
    background-color: #292833;
    border: solid 1px rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    transition: all 0.3s ease-out;
  }
  .SelectFiat__menu-list {
    padding: 0;
    max-height: 154px;
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 3px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
    }
  }
  .SelectFiat__option {
    padding: 8px 12px;
    color: ${color.grey};
    font-family: ${font.regular};
    font-size: 14px;
    line-height: 1.71;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &:hover {
      color: ${color.white};
    }
    &--is-focused {
      color: ${color.white};
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`

export const CopyAddress = styled.div`
  position: relative;
  margin: 0 0 30px;
`

export const CopyAddressLabel = styled.span`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const CopyAddressWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  box-shadow: none;
  transition: all 0.3s ease-in;
`

export const CopyAddressValue = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${color.white};
  overflow: hidden;
`

export const Copied = styled.span`
  display: block;
  width: 100%;
  padding: 14px 36px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: ${color.success};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  z-index: 2;
`

export const CopyWrapper = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 14px 50px 14px 16px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: ${color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    color: ${color.default};
  }
`

export const IconWrapSend = styled.div`
  margin: 0 auto 20px;
  display: flex;
  width: 64px;
  height: 64px;
  color: ${color.success};
  border: 2px solid ${color.success};
  border-radius: 50%;
  svg {
    width: 100%;
  }
`

export const TitleSend = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${color.white};
`

export const CustomLink = styled(NavLink)`
  color: ${color.danger};
  text-align: center;
  text-decoration: underline;
`
