import React from 'react'
import { useHistory } from 'react-router-dom'
import { links } from 'constant'

import { Button, Icon } from 'components'
import { Wrap, IconWrap, Title, Text } from './Style'

const NotFound = () => {
  const history = useHistory()
  const handleApprove = () => {
    history.push(links.users)
  }

  return (
    <Wrap>
      <IconWrap>
        <Icon name='notFound' size='auto' />
      </IconWrap>
      <Title>Not found</Title>
      <Text>Page you are looking for does not exist. Please return back to&nbsp;the site.</Text>
      <Button type='link' onClick={handleApprove}>
        Return to&nbsp;main page
      </Button>
    </Wrap>
  )
}

export { NotFound }
