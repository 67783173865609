import styled from 'styled-components'
import { font, color } from 'theme'

import { StyledHeaderProps } from './@types'

export const StyledHeader = styled.header<StyledHeaderProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${color.white};
  z-index: 2;
`

export const IconWrapper = styled.span<{ isLeft?: boolean }>`
  position: absolute;
  top: 50%;
  right: ${({ isLeft }) => (isLeft ? 'calc(100% - 24px)' : '0')};
  transform: translateY(-50%);
  cursor: pointer;
`

export const TitleS = styled.h2`
  margin: 0;
  font-family: ${font.display};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-align: center;
  text-transform: uppercase;
  color: inherit;
`
