import React, { Fragment } from 'react'

import { CurrencyCard, IconMap } from 'components'
import { Container, Title, Wrap } from './Style'

import { Stats } from '../../@types'

type Props = {
  stats: Stats[]
  loading: boolean
}

function TransactionTotalInfo({ stats, loading }: Props) {
  return (
    <Fragment>
      <Container>
        <Title>Total NET</Title>
      </Container>
      <Wrap>
        {stats.map(item => {
          const icon = item.currency.toLowerCase() as IconMap
          return <CurrencyCard key={item.currency} iconName={icon} loading={loading} {...item} />
        })}
      </Wrap>
    </Fragment>
  )
}

export { TransactionTotalInfo }
