// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CallFunction = (...args: any[]) => any

export const debounce = <F extends CallFunction>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null

  const debounceFunction = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => {
      return func(...args)
    }, waitFor)
  }

  return debounceFunction as (...args: Parameters<F>) => ReturnType<F>
}
