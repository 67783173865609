import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { selectAuth } from '../selectors'
import { logOut } from '../actions'
import { withInjectors } from './withInjectors'
import { AuthState } from '../@types'

type Props = {
  auth: boolean
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logOut,
    },
    dispatch,
  )

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    auth: selectAuth(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const UserInjectors = compose(withInjectors, withConnect)
