import styled, { css, keyframes } from 'styled-components'
import { color, font } from 'theme'

export const placeHolder = keyframes`
  0% {
    background-position: -1220px 0
  }
  100% {
    background-position: 1220px 0
  }
`

export const Container = styled.div<{ loading?: boolean }>`
  position: relative;
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  background-color: #292833;
  border-radius: 8px;
  cursor: pointer;
  ${({ loading }) =>
    loading &&
    css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${placeHolder};
        animation-timing-function: linear;
        will-change: background-position;
        background: linear-gradient(241.67deg, transparent 18%, rgba(232, 230, 255, 0.1) 44%, transparent 77%);
        background-size: 1220px 100%;
        border-radius: 8px;
      }
    `}
`

export const Currency = styled.div`
  margin: 0;
`

export const BottomWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoWrap = styled.div<{ small?: boolean }>`
  margin: 0 0 0 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 auto;
  ${({ small }) =>
    small &&
    `
      margin: 0;
    }
  `}
`

export const InfoTitle = styled.h4`
  margin: 0 0 4px;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.5);
`

export const InfoValue = styled.h5<{ small?: boolean; loading?: boolean }>`
  margin: 0;
  max-width: 500px;
  font-family: ${font.display};
  font-size: ${({ small }) => (small ? '14px' : '18px')};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${color.white};
  overflow: hidden;
  opacity: ${({ loading }) => (loading ? '0' : '1')};
`

export const InfoMoney = styled.h6<{ small?: boolean; loading?: boolean }>`
  margin: 4px 0 0;
  font-family: ${font.regular};
  font-size: ${({ small }) => (small ? '12px' : '14px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.5);
  opacity: ${({ loading }) => (loading ? '0' : '1')};
`

export const HrLine = styled.hr`
  width: 100%;
  border: 1px solid #fff;
  opacity: 0.1;
`

export const IconInfo = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
`
