import { CryptoRequestInput } from '../@types'
import {
  NEW_CRYPTO_CLEAR,
  NEW_CRYPTO_FAILURE,
  NEW_CRYPTO_REQUEST,
  NEW_CRYPTO_SUCCESS,
  NEW_CRYPTO_ERROR_CLEAR,
} from '../constants'

export const newCryptoClear = () => {
  return {
    type: NEW_CRYPTO_CLEAR,
  } as const
}

export const newCryptoRequest = (params: CryptoRequestInput) => {
  return {
    type: NEW_CRYPTO_REQUEST,
    payload: params,
  } as const
}

export const newCryptoErrorClear = () => {
  return {
    type: NEW_CRYPTO_ERROR_CLEAR,
  } as const
}

export const newCryptoFailure = (errorMessage: string) => {
  return {
    type: NEW_CRYPTO_FAILURE,
    payload: { errorMessage },
  } as const
}

export const newCryptoSuccess = () => {
  return {
    type: NEW_CRYPTO_SUCCESS,
  } as const
}
