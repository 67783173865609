import React from 'react'

const Diskette = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M17 19H3a2 2 0 01-2-2V3a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z'
      clipRule='evenodd'
    />
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 19v-8H5v8M5 1v5h8' />
  </svg>
)

export { Diskette }
