import React from 'react'

import { TopCol, TopInfo, TopWrapper } from './Style'

function Titles() {
  return (
    <TopWrapper>
      <TopCol>
        <TopInfo>Volume</TopInfo>
      </TopCol>
    </TopWrapper>
  )
}

export { Titles }
