import { createSelector } from 'reselect'

import { ClientState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: ClientState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: ClientState) => state[DOMAIN][DOMAIN_STATE]

export const selectTransactions = () => createSelector(selectUi, subState => subState.transactions)
export const selectRate = () => createSelector(selectUi, subState => subState.rate)

export const selectGetTransactions = () => createSelector(selectDomain, subState => subState.getTransactions)
export const selectGetRate = () => createSelector(selectDomain, subState => subState.getRate)
export const selectGetExchange = () => createSelector(selectDomain, subState => subState.getExchange)
