import styled from 'styled-components'
import { Button } from 'components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CurrentAddress = styled.div`
  position: relative;
  margin: 0 0 28px;
`

export const WrapBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CancelBtn = styled(Button)`
  max-width: 160px;
`

export const CustomBtn = styled(Button)`
  max-width: 160px;
`
