import React from 'react'

const EthIcon = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <path fill='#5285e7' d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z' />
    <path fill='#fff' fillOpacity='.602' d='M9.999 3.125v5.082l4.373 1.919L10 3.125z' />
    <path fill='#fff' d='M9.999 3.125l-4.374 7.001 4.374-1.92V3.126z' />
    <path fill='#fff' fillOpacity='.602' d='M9.999 13.42v3.452l4.376-5.946L10 13.419z' />
    <path fill='#fff' d='M9.999 16.872V13.42l-4.374-2.493 4.374 5.946z' />
    <path fill='#fff' fillOpacity='.2' d='M9.999 12.62l4.373-2.494L10 8.208v4.412z' />
    <path fill='#fff' fillOpacity='.602' d='M5.625 10.126l4.374 2.494V8.208l-4.374 1.918z' />
  </svg>
)

export { EthIcon }
