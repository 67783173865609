import { createSelector } from 'reselect'

import { AuthState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: AuthState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: AuthState) => state[DOMAIN][DOMAIN_STATE]

export const selectAuth = () => createSelector(selectUi, subState => subState.auth)

export const selectSignInRequest = () => createSelector(selectDomain, subState => subState.signIn)
export const selectSignUpRequest = () => createSelector(selectDomain, subState => subState.signUp)
