import styled from 'styled-components'
import { color, breakpoints } from 'theme'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  margin: 15px 12px 0;
  padding: 0;
  max-width: 376px;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  box-shadow: 0 12px 40px #e6ebf0;
  ${breakpoints.greaterThan('xsm')`
    margin: 15px auto 0;
    border-radius: 8px;
  `}
`

export const MainInfo = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h3`
  margin: 0 0 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: ${color.black};
  text-align: center;
`

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
`

export const BackBtn = styled(NavLink)`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.14;
  color: ${color.default};
  border-top: 1px solid #e6e9f2;
  border-radius: 0 0 8px 8px;
  transition: all 0.3s linear;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${color.primary};
  }
  &:focus,
  &:active {
    color: ${color.primary};
    background-color: #f6f7fb;
  }
`
