import { RequestState } from '@types'
import { Actions, Company } from '../@types'
import { GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_FAILURE, SET_COMPANY } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getCompanyRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_COMPANY_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_COMPANY_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: Company = {
  name: '',
  api_key: '',
  api_secret: '',
  callback_url: '',
  main_currency: '',
  withdrawal_btc_address: '',
  withdrawal_eth_address: '',
  withdrawal_usdt_address: '',
  bank_detail_usd: '',
  bank_detail_eur: '',
  approach_currency: '',
  status: '',
  created_at: '',
  stable_rate_zeur: '',
}

export const company = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_COMPANY:
      const { data } = action.payload
      return { ...state, ...data }
    default:
      return state
  }
}
