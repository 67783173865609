import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { ModalConductor as View } from './ModalConductor'
import { Modal, ModalState } from './@types'
import { selectModals } from './selector'
import { withInjectors } from './withInjectors'

type Props = {
  modals: Modal[]
}

const mapStateToProps = createStructuredSelector<ModalState, Props>({
  modals: selectModals(),
})

const withConnect = connect(mapStateToProps)

export const ModalConductor = compose<ComponentType>(withInjectors, withConnect)(View)
