import { gql, ApolloQueryResult } from 'apollo-boost'

export const SIGN_UP = gql`
  mutation signUp(
    $staff_name: String!
    $email: String!
    $password_hash: String!
    $company_name: String!
    $otp_secret: String!
    $two_factor_code: String!
  ) {
    signUp(
      data: {
        email: $email
        otp_secret: $otp_secret
        staff_name: $staff_name
        password_hash: $password_hash
        company_name: $company_name
        two_factor_code: $two_factor_code
      }
    ) {
      token
    }
  }
`

export type SignUpResponse = ApolloQueryResult<{
  signUp: {
    token: string
  }
}>
