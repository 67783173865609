import { DEFAULT_CONFIG, URL_CONFIG } from './default'

type ConfigType = {
  backend: {
    url: string
  }
}

class Config {
  public config: ConfigType

  public constructor(url: string) {
    this.config = DEFAULT_CONFIG

    this.init(url)
      .then(() => {
        console.info('Set config is success')
      })
      .catch(r => {
        console.info(`Set config is crashed, use default: ${r}`)
      })
  }

  public getConfig = () => {
    return this.config
  }

  private init = async (url: string) => {
    try {
      const response = await fetch(url)
      this.config = await response.json()
    } catch (err) {
      throw new Error(`Set config is crashed, use default: ${err}`)
    }
  }
}

const config = new Config(URL_CONFIG).getConfig()

export { config }
