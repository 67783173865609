import { generateMedia } from 'styled-media-query'

import { Theme } from './@types'
import { color } from './Color'
import { button } from './Button'
import { radioItem } from './radioItem'

export const breakpoints = generateMedia({
  xs: '375px',
  xsm: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1224px',
  xl: '1440px',
})

export const font = {
  display: `'Gilroy', 'Helvetica Neue', 'Helvetica', 'Verdana', 'Arial', sans-serif`,
  regular: `'Gilroy', 'Helvetica Neue', 'Helvetica', 'Verdana', 'Arial', sans-serif`,
  weight: 'bold',
}

export const defaultTheme: Theme = {
  font,
  color,
  button,
  radioItem,
  breakpoints,
}

export const theme = defaultTheme
