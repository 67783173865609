import { call, all, put, takeLatest } from 'redux-saga/effects'

import { getGraphQlError } from 'utils'

import { mutate } from '../client'
import { CREATE_INVOICE_REQUEST } from '../constants'
import { CREATE_INVOICE, CreateInvoiceResponse } from '../mutation'
import { createInvoiceRequest, createInvoiceFailure, createInvoiceSuccess, setInvoice } from '../actions'

export function* create({ payload }: ReturnType<typeof createInvoiceRequest>) {
  try {
    const response: CreateInvoiceResponse = yield call(mutate, {
      mutation: CREATE_INVOICE,
      variables: payload,
    })
    const { addCompanyInvoice } = response.data

    yield put(createInvoiceSuccess())
    yield put(setInvoice(addCompanyInvoice))
  } catch (err) {
    yield put(createInvoiceFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(CREATE_INVOICE_REQUEST, create)])
}
