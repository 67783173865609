import { RequestState } from '@types'

import { Actions } from '../@types'
import { GET_RATE_FAILURE, GET_RATE_REQUEST, GET_RATE_SUCCESS } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getRate = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_RATE_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_RATE_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_RATE_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}
