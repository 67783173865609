import React from 'react'

const UsdBIcon = () => (
  <svg fill='none' viewBox='0 0 56 56'>
    <path fill='#597ae8' d='M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z' />
    <path fill='#073394' d='M54.13 38.08C50.084 48.563 39.91 56 28 56 16.09 56 5.916 48.563 1.87 38.08h52.26z' />
    <path
      fill='#fff'
      d='M17.6 41.643H16v5.788c0 1.953 1.414 3.304 3.676 3.304 2.269 0 3.676-1.351 3.676-3.303v-5.79h-1.593v5.647c0 1.22-.756 2.051-2.083 2.051-1.326 0-2.076-.83-2.076-2.051v-5.646zM24.573 48.07c.068 1.648 1.419 2.665 3.476 2.665 2.164 0 3.527-1.066 3.527-2.764 0-1.333-.768-2.083-2.584-2.498l-1.03-.236c-1.096-.26-1.549-.607-1.549-1.202 0-.744.682-1.24 1.692-1.24 1.023 0 1.723.502 1.798 1.34h1.524c-.037-1.575-1.338-2.641-3.31-2.641-1.945 0-3.328 1.072-3.328 2.659 0 1.277.781 2.07 2.43 2.448l1.159.273c1.128.266 1.587.638 1.587 1.283 0 .743-.75 1.276-1.829 1.276-1.09 0-1.915-.539-2.014-1.363h-1.55zM32.785 41.643v8.943h3.266c2.677 0 4.252-1.66 4.252-4.493s-1.58-4.45-4.252-4.45h-3.266zm1.599 1.35h1.5c1.785 0 2.789 1.104 2.789 3.106 0 2.039-.992 3.136-2.79 3.136h-1.5v-6.241z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M24.642 19.256v-5.039h-3.404v8.205h7.472a7.975 7.975 0 003.803-.904 6.87 6.87 0 002.694-2.515 7.087 7.087 0 00.003-7.177 6.873 6.873 0 00-2.697-2.52 7.98 7.98 0 00-3.808-.905h-7.467v3.16H28.72a4.014 4.014 0 012.888 1.084 3.648 3.648 0 011.123 2.751V15.417a3.658 3.658 0 01-1.125 2.758 4.011 4.011 0 01-2.886 1.081h-4.08zm10.97 3.51a6.993 6.993 0 00-.641-.887c-.513.45-1.08.84-1.691 1.157a9.63 9.63 0 01-1.442.609l.046.042c.71.68 1.13 1.733 1.097 2.776V26.484c.033 1.047-.386 2.093-1.1 2.779a3.928 3.928 0 01-2.825 1.058h-4.414V24.13h-3.404v9.69h7.803a8.056 8.056 0 003.842-.915 6.952 6.952 0 002.726-2.545c1.318-2.246 1.319-5.353.004-7.593z'
      clipRule='evenodd'
    />
  </svg>
)

export { UsdBIcon }
