import React from 'react'

import { createModal } from 'containers/Modal'
import { NewFiatRequestContainer } from '../containers'

const containerWithProps = () => <NewFiatRequestContainer />

const newFiatRequest = () =>
  createModal({
    name: 'OPEN_NEW_FIAT_REQUEST',
    title: `New fiat request`,
    container: containerWithProps(),
  })

export { newFiatRequest }
