import { ExchangeContainer } from '../containers'

import { links } from 'constant'

const routes = [
  {
    path: links.exchange,
    component: ExchangeContainer,
    isPrivate: true,
  },
]

export { routes }
