import { Component } from 'react'
import { Location } from 'history'

interface Props {
  location?: Location
}

const X_WINDOW = 0
const Y_WINDOW = 0

class ScrollToTop extends Component<Props> {
  public componentDidUpdate({ location }: Props) {
    const { location: newValue } = this.props
    if (newValue !== location) {
      window.scrollTo(X_WINDOW, Y_WINDOW)
    }
  }

  public render() {
    const { children } = this.props
    return children
  }
}

export { ScrollToTop }
