import { gql, ApolloQueryResult } from 'apollo-boost'

type ManualExchangeResult = {
  id: number
  currency_from: string
  currency_to: string
  amount_from: number
  amount_to: number
  status: string
  created_at: string
}

export const CREATE_EXCHANGE = gql`
  mutation addForcedExchange(
    $currency_from: CommonCurrencyName!
    $currency_to: CommonCurrencyName!
    $amount: Float!
    $two_factor_code: String!
  ) {
    addForcedExchange(
      data: {
        currency_from: $currency_from
        currency_to: $currency_to
        amount: $amount
        two_factor_code: $two_factor_code
      }
    ) {
      id
      currency_from
      currency_to
      amount_from
      amount_to
      status
      created_at
    }
  }
`

export type CreateExchangeResponse = ApolloQueryResult<{
  addForcedExchange: ManualExchangeResult
}>
