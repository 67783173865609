import React from 'react'

const Cube = () => (
  <svg fill='none' viewBox='0 0 22 24'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M11.89 1.209l8 4A2 2 0 0121 6.999v9.53a2 2 0 01-1.11 1.79l-8 4a2 2 0 01-1.79 0l-8-4a2 2 0 01-1.1-1.8v-9.52a2 2 0 011.11-1.79l8-4a2 2 0 011.78 0z'
      clipRule='evenodd'
    />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M1.32 5.919l9.68 4.84 9.68-4.84M11 22.519v-11.76'
    />
  </svg>
)

export { Cube }
