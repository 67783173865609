import React from 'react'

import { Icon, Status, DateComponent } from 'components'
import { Url, ChangeBtn, IconWrapper } from './Style'

function UrlComponent(value: string) {
  return <Url>{value}</Url>
}

function ChangeBtnComponent(onClick: () => void) {
  return (
    <ChangeBtn type='link' size='medium' onClick={onClick}>
      <IconWrapper>
        <Icon name='change' size='extraMedium' />
      </IconWrapper>
      Change
    </ChangeBtn>
  )
}

export const CALLBACK = [
  {
    title: 'Request date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => Status({ status: props }),
  },
  {
    title: 'URL',
    key: 'value',
    render: (props: string) => UrlComponent(props),
  },
  {
    title: 'Action',
    key: 'edit',
    align: 'right',
    render: (props: () => void) => ChangeBtnComponent(props),
  },
]
