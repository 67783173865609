import React, { Fragment } from 'react'

import { DataItem, DataItemTitle, DataItemContent } from './Style'

type Data = {
  body?: string
  header?: string
}

const WebHooksData = ({ header, body }: Data) => {
  return (
    <Fragment>
      <DataItem>
        <DataItemTitle>Header:</DataItemTitle>
        <DataItemContent>{header || 'No data'}</DataItemContent>
      </DataItem>
      <DataItem>
        <DataItemTitle>Body:</DataItemTitle>
        <DataItemContent>{body || 'No data'}</DataItemContent>
      </DataItem>
    </Fragment>
  )
}

export { WebHooksData }
