import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { transactions, getTransactions } from './Transactions'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    transactions,
  }),
  [DOMAIN_STATE]: combineReducers({
    getTransactions,
  }),
})
