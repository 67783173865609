import React from 'react'

import { Button } from 'components'
import { Wrapper, Title } from './Style'

type Props = {
  title: string
  buttonText: string
  open: () => void
}

function RequestHeader({ title, buttonText, open }: Props) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Button onClick={open}>{buttonText}</Button>
    </Wrapper>
  )
}

export { RequestHeader }
