import styled from 'styled-components'
import { Input } from 'components'
import { font, color } from 'theme'

export const Warning = styled.div`
  margin: 0 0 30px;
  padding: 16px 20px;
  background-color: rgba(245, 166, 35, 0.1);
  border-radius: 6px;
`

export const WarningTitle = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.regular};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${color.danger};
`

export const WarningText = styled.p<{ noMargin?: boolean }>`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.danger};
  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`

export const Title = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const Text = styled.p<{ noMargin?: boolean }>`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`

export const SignDocContainer = styled.div`
  margin: 0 0 50px;
`

export const IconWrap = styled.div`
  width: 16px;
  height: 20px;
`

export const DocumentationItem = styled.a`
  margin: 0;
  padding: 12px 32px 12px 24px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  color: ${color.white};
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
`

export const DocumentationInfo = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const DocumentationName = styled.h5`
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
`

export const DocumentationType = styled.span`
  margin: 0;
  font-size: 12px;
  opacity: 0.5;
`

export const UploadDoc = styled.div`
  margin: 0 0 40px;
`

export const FileUpload = styled.div`
  position: relative;
`

export const FileLabel = styled.label`
  margin: 0;
  padding: 12px;
  width: 100%;
  max-width: 380px;
  height: 94px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border: dashed 1px rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  transition: all 0.3s;

  &:hover {
    color: ${color.white};
    cursor: pointer;
  }

  svg {
    width: 12px;
    height: 15px;
  }
`

export const FileText = styled.span`
  margin: 0 0 0 10px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
`

export const FileInput = styled(Input)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
`

export const BtnContainer = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`

export const EmailLink = styled.span`
  color: ${color.default};
  text-decoration: none;
`
