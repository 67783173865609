import { SelectSize } from '../@types'

export const getSize = (size: SelectSize) =>
  ({
    default: {
      padding: '0 8px 0 12px',
      height: '44px',
      paddingMenu: '9px 12px',
      fontSize: '14px',
    },
    large: {
      padding: '0 14px',
      height: '56px',
      paddingMenu: '16px 40px 16px 16px',
      fontSize: '16px',
    },
    small: {
      padding: '0 8px 0 12px',
      height: '32px',
      paddingMenu: '5px 12px',
      fontSize: '14px',
    },
  }[size])
