import styled from 'styled-components'

export const Date = styled.span`
  margin: 0;
  font-size: 14px;
  word-break: keep-all;
`

export const Time = styled.span`
  font-size: 12px;
  word-break: keep-all;
  color: rgba(255, 255, 255, 0.8);
`
