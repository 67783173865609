import React, { Component, ReactNode } from 'react'

type Props = {
  children: ReactNode
  handleClose?: () => void
  handleSubmit?: () => void
}

class KeysListener extends Component<Props> {
  public constructor(props: Props) {
    super(props)
  }

  public componentDidMount() {
    window.addEventListener('keydown', this.onSubmit)
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.onSubmit)
  }

  public onSubmit = (event: { key: string; shiftKey: boolean }) => {
    const { handleSubmit, handleClose } = this.props

    const { key, shiftKey } = event

    if (shiftKey) return

    const escapeCondition = key === 'Escape'
    const submitCondition = key === 'Enter'

    if (submitCondition) {
      if (handleSubmit) {
        handleSubmit()
      }
    }

    if (escapeCondition) {
      if (handleClose) {
        handleClose()
      }
    }
  }

  public render() {
    const { children } = this.props
    return <>{children}</>
  }
}

export { KeysListener }
