import React, { Component } from 'react'
import RcTable from 'rc-table'

import uuid from 'react-uuid'

import { PAGE, PAGE_SIZE_DEFAULT } from 'constant'
import { LoadingPlaceholder } from 'components'

import { SelectPage } from './SelectPage'
import { Pagination } from './Pagination'
import { EditableCell } from './EditableCell'
import { Props, EditableColumns } from './@types'
import { createEditableColumns } from './helpers'
import { PaginationWrapper, StyledTable, Th, Td } from './Style'

type State<RecordType> = {
  select?: string
  editData?: RecordType
}

class NewTable<RecordType> extends Component<Props<RecordType>, State<RecordType>> {
  public state = {
    select: '',
    editData: {} as RecordType,
  }

  public componentDidMount() {
    const { getData } = this.props

    if (typeof getData === 'function') {
      getData()
    }
  }

  public shouldComponentUpdate(nextProps: Props<RecordType>, nextState: State<RecordType>) {
    if (this.state.editData !== nextState.editData) {
      return false
    }

    return true
  }

  public handleSelect = (key: string) => {
    this.setState(() => ({ select: key }))
  }

  public isEditable = (key: string) => {
    const { select } = this.state
    return select === key
  }

  public handleChange = (key: string, value: string) => {
    const { editData } = this.state

    const newValue = { ...editData, [key]: Number(value) }

    this.setState(() => ({ editData: newValue }))
  }

  public handleSave = (rowId: number) => {
    const { onSave, data } = this.props
    const { editData } = this.state

    const findElement = data.find((_, index) => index === rowId)

    if (typeof onSave === 'function') {
      onSave({
        ...editData,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        currency: findElement.currency,
      })
    }

    this.setState(() => ({ select: '' }))
  }

  public render() {
    const {
      data,
      selectPage,
      setPageSize,
      loading = false,
      selection = false,
      pagination = false,
      pageCount = PAGE,
      currentPage = PAGE,
      pageSize = PAGE_SIZE_DEFAULT,
    } = this.props

    return (
      <StyledTable noBorderBottom={loading || this.hasData()}>
        <RcTable
          data={data}
          rowKey={this.getUniqKey}
          columns={this.getColumns()}
          emptyText={this.emptyRender()}
          components={this.getComponents()}
        />
        {this.hideWrapper() && (
          <PaginationWrapper isSelected={selection}>
            {selection && <SelectPage loading={loading} pageSize={pageSize} setPageSize={setPageSize} />}
            {pagination && (
              <Pagination loading={loading} total={pageCount} current={currentPage} selectPage={selectPage} />
            )}
          </PaginationWrapper>
        )}
      </StyledTable>
    )
  }

  private getUniqKey = () => uuid()

  private getComponents = () => {
    const { editing } = this.props

    return {
      header: { cell: Th },
      body: { cell: editing ? EditableCell : Td },
    }
  }

  private hideWrapper = () => {
    const { selection, pagination, loading } = this.props
    return !this.hasData() && (selection || pagination || loading)
  }

  private emptyRender = () => {
    const { loading } = this.props

    return loading ? <LoadingPlaceholder /> : <span>Currently there is no data to share</span>
  }

  private hasData = () => {
    const { data } = this.props
    return !data || !data.length
  }

  private getColumns = () => {
    const { columns, editing } = this.props

    return editing
      ? createEditableColumns(columns as EditableColumns<RecordType>, {
          onChange: this.handleChange,
          save: this.handleSave,
          isEditing: this.isEditable,
          selected: this.state.select,
          edit: this.handleSelect,
        })
      : columns
  }
}

export { NewTable }
