import { RequestState } from '@types'
import { Actions, NewUser } from '../@types'
import { ADD_USER_SUCCESS, ADD_USER_FAILURE, ADD_USER_REQUEST, SET_USER, SET_USER_CLEAR } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const addUserRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case ADD_USER_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case ADD_USER_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: NewUser = {
  otpSecret: '',
}

export const newUser = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_USER:
      const { otpSecret } = action.payload

      return {
        ...state,
        otpSecret,
      }
    case SET_USER_CLEAR:
      return initialUiState
    default:
      return state
  }
}
