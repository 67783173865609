import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { withInjectors } from '../injectors'
import { Invoice, InvoiceState } from '../@types'
import { InvoiceComponent as View } from '../components'
import { checkInvoiceRequest, startRunner, stopRunner } from '../actions'
import { selectInvoice, selectCheckInvoiceRequest } from '../selectors'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      stopRunner,
      startRunner,
      check: checkInvoiceRequest,
    },
    dispatch,
  )

type Props = {
  invoice: Invoice
  checkRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<InvoiceState, Props>({
    invoice: selectInvoice(),
    checkRequest: selectCheckInvoiceRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const InvoiceContainer = compose<ComponentType>(withInjectors, withConnect)(View)
