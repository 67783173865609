import React from 'react'

const UpTableIcon = () => (
  <svg width='8px' height='4px' viewBox='0 0 8 4'>
    <g id='admin-panel' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.5'>
      <g id='1440_admin_fiatrequest' transform='translate(-383.000000, -706.000000)' fill='#fff'>
        <g id='Group-10' transform='translate(0.000000, 62.000000)'>
          <polyline
            id='Shape-Copy-6'
            transform='translate(387.000000, 646.000000) scale(1, -1) translate(-387.000000, -646.000000) '
            points='383 644 387 648 391 644'
          />
        </g>
      </g>
    </g>
  </svg>
)

export { UpTableIcon }
