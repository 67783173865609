import { DateComponent } from 'components'
import { AddressCopy, ValueComponent, TxIdComponent, CurrencyElement, CoinComponent, Balance } from './Style'

export const ADDRESS = [
  {
    title: 'Asset',
    key: 'currency',
    render: (props: string) => CurrencyElement(props),
  },
  {
    title: 'Address',
    key: 'address',
    width: 596,
    render: (props: string) => AddressCopy(props),
  },
  {
    title: 'Balance',
    key: 'amount',
    render: (props: string, render: { currency: string }) => Balance(props, render.currency),
  },
]

export const COLUMNS = [
  {
    title: 'Id',
    key: 'id',
  },
  {
    title: 'Transaction date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Transaction Id',
    key: 'tx_id',
    render: (props: string, render: { currency: string }) => TxIdComponent(props, render.currency),
  },
  {
    title: 'Asset',
    key: 'currency',
    render: (props: string) => CoinComponent(props),
  },
  {
    title: 'Amount',
    key: 'value',
    render: (props: string, render: { currency: string; value_eur: number }) =>
      ValueComponent(`${props} ${render.value_eur}`, render.currency),
  },
]
