import React, { Component } from 'react'

import { ToastProps } from './@types'
import { ToastLayout } from './StyledToast'

const TIME_OUT = 3000
const autoClosingToasts = ['default', 'error']

class Toast extends Component<ToastProps> {
  public mounted: boolean
  public timer?: number

  public constructor(props: ToastProps) {
    super(props)
    this.mounted = true
  }

  public componentDidMount() {
    const { type } = this.props

    if (this.mounted && autoClosingToasts.includes(type)) {
      this.timer = setTimeout(() => {
        this.onClose()
      }, TIME_OUT)
    }
  }

  public componentWillUnmount() {
    this.mounted = false
    clearTimeout(this.timer)
  }

  public render() {
    const { children, type, text = '' } = this.props

    return (
      <ToastLayout type={type} text={text} onClose={this.onClose}>
        {children}
      </ToastLayout>
    )
  }

  private onClose = () => {
    const { closeToast, name } = this.props
    closeToast(name)
  }
}

export { Toast }
