import styled from 'styled-components'
import { color, font } from 'theme'
import { Button } from 'components'

export const Container = styled.div`
  margin: 0 auto 30px;
  padding: 0 34px;
  max-width: 1280px;
`

export const Title = styled.h3`
  margin: 0;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
`

export const MainWrapper = styled.div`
  margin: 0 auto 20px;
  padding: 30px;
  max-width: 1280px;
  color: ${color.white};
  background-color: #292833;
  border-radius: 8px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: -10px;
  margin-right: -10px;
`

export const ColumnBig = styled.div`
  display: flex;
  max-width: 320px;
  justify-content: space-between;
  flex-direction: column;
`

export const Column = styled.div`
  max-width: 33.3333%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`

export const ResetButton = styled(Button)`
  margin: 0 0 0 20px;
`

export const ContainerButtons = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ReportContainer = styled.div`
  position: relative;
`
export const CustomButton = styled.div`
  position: relative;

  margin: 0;
  padding: 18px 32px;
  min-width: 160px;
  height: 56px;

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: ${({ theme }) => theme.font.regular};
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  text-decoration: none;

  color: ${color.default};
  background-color: ${color.transparent};

  border: 1px solid ${color.default};
  border-radius: 6px;

  transition: all 0.3s ease-in;
  cursor: pointer;

  overflow: hidden;
  outline: none;
  z-index: 1;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    color: ${color.white};
    background-color: ${color.default};
    border: 1px solid ${color.default};
  }

  &:focus {
    color: ${color.white};
    background-color: ${color.default};
    border: 1px solid ${color.default};
  }

  &:active {
    color: ${color.white};
    background-color: ${color.default};
    border: 1px solid ${color.default};
  }
`
export const DatepickerPopupWrap = styled.div`
  position: absolute;
  background: #292833;
  top: calc(100% + 10px);
  right: 50%;
  z-index: 10;
  min-width: 300px;
  padding: 20px 30px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);

  button {
    width: 100%;
  }
`
