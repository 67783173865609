import styled from 'styled-components'

export const MainWrapper = styled.div`
  margin: 0 auto 60px;
  padding: 40px 210px;
  max-width: 1280px;
  background-color: #292833;
  border-radius: 8px;
`

export const ContainerButtons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
