import React from 'react'
import { Wrapper, LoadingPlaceholderItem } from './Style'

type Props = {
  count?: number
}

const DEFAULT_COUNT = 2

function LoadingPlaceholder({ count = DEFAULT_COUNT }: Props) {
  return (
    <Wrapper>
      {Array.from(Array(count), (_, i) => (
        <LoadingPlaceholderItem key={i} />
      ))}
    </Wrapper>
  )
}

export { LoadingPlaceholder }
