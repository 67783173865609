import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'

import { withInjectors } from './withInjectors'

import { GlobalState, CommonCurrencies, ExchangePairs } from '../@types'
import { getCurrenciesRequest, getPairsRequest, getCurrenciesFromRequest, getCryptoCurrenciesRequest } from '../actions'

import {
  selectPairs,
  selectCurrencies,
  selectCurrenciesFrom,
  selectGetPairsRequest,
  selectCryptoCurrencies,
  selectGetCurrenciesRequest,
  selectMainCryptoCurrencies,
  selectGetCurrenciesFromRequest,
  selectGetCryptoCurrenciesRequest,
} from '../selectors'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPairs: getPairsRequest,
      getCurrencies: getCurrenciesRequest,
      getCurrenciesFrom: getCurrenciesFromRequest,
      getCryptoCurrencies: getCryptoCurrenciesRequest,
    },
    dispatch,
  )

type Props = {
  pairs: ExchangePairs
  currencies: CommonCurrencies
  getPairsRequest: RequestState
  currenciesFrom: ExchangePairs
  cryptoCurrencies: CommonCurrencies
  getCurrenciesRequest: RequestState
  mainCryptoCurrencies: CommonCurrencies
  getCurrenciesFromRequest: RequestState
  getCryptoCurrenciesRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<GlobalState, Props>({
    pairs: selectPairs(),
    currencies: selectCurrencies(),
    currenciesFrom: selectCurrenciesFrom(),
    getPairsRequest: selectGetPairsRequest(),
    cryptoCurrencies: selectCryptoCurrencies(),
    mainCryptoCurrencies: selectMainCryptoCurrencies(),
    getCurrenciesRequest: selectGetCurrenciesRequest(),
    getCurrenciesFromRequest: selectGetCurrenciesFromRequest(),
    getCryptoCurrenciesRequest: selectGetCryptoCurrenciesRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const CurrenciesInjectors = compose(withInjectors, withConnect)
