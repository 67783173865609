import React, { Component } from 'react'

import { links } from 'constant'
import { RequestState } from '@types'
import { notify } from 'containers/Notification/notify'
import { VerificationCode, KeysListener } from 'components'

import { SignInInput } from '../../@types'
import { InputSignIn, Title, Wrapper } from '../'

import { Text, SmallText, StyledButton, Link } from './Style'

type State = {
  hasError: boolean
  email: string
  two_factor_code: string
  password_hash: string
  isOpen: boolean
}

type SignInParams = {
  email: string
  password_hash: string
}

type Props = {
  auth: boolean
  signInRequest: RequestState
  push: (link: string) => void
  signIn: (params: SignInInput) => void
}

class SignIn extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    const { push, auth } = props

    if (auth) {
      push(links.users)
    }

    this.state = {
      email: '',
      isOpen: false,
      hasError: false,
      password_hash: '',
      two_factor_code: '',
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const { signInRequest } = this.props

    if (signInRequest.isError && signInRequest.isError !== prevProps.signInRequest.isError) {
      this.setState(() => ({ hasError: true }))
    }
  }

  public render() {
    const { push, signInRequest } = this.props
    const { isOpen, hasError, two_factor_code } = this.state

    return !isOpen ? (
      <InputSignIn signIn={this.handleSetData} push={push} title='Log In' buttonText='Log In'>
        <Text>
          Not a user?&nbsp;
          <Link onClick={this.redirectToSignUp}>Sign up</Link>
        </Text>
      </InputSignIn>
    ) : (
      <Wrapper>
        <KeysListener handleSubmit={this.handleSignIn}>
          <Title text='Log In' iconLeft={{ name: 'arrow', onAction: this.handleIsOpen }} />
          <SmallText>Enter the six-digit code from the application</SmallText>
          <VerificationCode
            isError={hasError}
            value={two_factor_code}
            onActive={this.handleSetCodeValue}
            errorMessage={signInRequest.errorMessage}
          />
          <StyledButton onClick={this.handleSignIn} isLoading={signInRequest.isFetching}>
            Continue
          </StyledButton>
        </KeysListener>
      </Wrapper>
    )
  }

  private handleSetCodeValue = (value: string) => {
    this.setState(() => ({ two_factor_code: value, hasError: false }))
  }

  private handleSetData = ({ password_hash, email }: SignInParams) => {
    this.setState(() => ({ password_hash, email, hasError: false }))
    this.handleIsOpen()
  }

  private handleSignIn = () => {
    const { signIn } = this.props
    const { password_hash, two_factor_code, email } = this.state

    if (!two_factor_code) {
      const errorText = 'This action is not available, please check the completed fields'

      notify({ type: 'error', text: errorText })
      return
    }
    signIn({ password_hash, two_factor_code, email })
  }

  private handleIsOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  private toRedirect = (link: string) => {
    const { push } = this.props
    push(link)
  }

  private redirectToSignUp = () => {
    this.toRedirect(links.signup)
  }
}

export { SignIn }
