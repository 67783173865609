import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { CurrenciesInjectors } from 'modules/Global'

import { withInjectors } from '../injectors'
import { Table as View } from '../components'
import { ClientState, Clients } from '../@types'
import { selectClients, selectGetClients } from '../selectors'
import { getClientsRequest, selectPage, selectPageSize, setClientsFilters } from '../actions'

// TODO create combine container function
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectPage,
      selectPageSize,
      getData: getClientsRequest,
      setFilters: setClientsFilters,
    },
    dispatch,
  )

type Props = {
  clients: Clients
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    clients: selectClients(),
    request: selectGetClients(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const ClientContainer = compose<ComponentType>(CurrenciesInjectors, withInjectors, withConnect)(View)
