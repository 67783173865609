import { SET_AUTH, SET_LOGOUT } from '../constants'

export const setAuth = (auth: boolean) => {
  return {
    type: SET_AUTH,
    payload: { auth },
  } as const
}

export const logOut = () => {
  return {
    type: SET_LOGOUT,
  } as const
}
