import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { resetApiRequest } from './Api'
import { setOnboardingRequest, currenciesList } from './Onboarding'

import { staffs, getStaffRequest, removeStaffRequest } from './Staff'
import { addUserRequest, newUser } from './User'
import { company, getCompanyRequest } from './Company'
import { requests, changeDetailRequest, getLatestUpdateRequest } from './Requests'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    staffs,
    newUser,
    company,
    currenciesList,
    requests,
  }),
  [DOMAIN_STATE]: combineReducers({
    addUserRequest,
    getStaffRequest,
    resetApiRequest,
    getCompanyRequest,
    removeStaffRequest,
    changeDetailRequest,
    setOnboardingRequest,
    getLatestUpdateRequest,
  }),
})
