import React, { Fragment } from 'react'

import { FIRST_EL_ARRAY } from 'constant'
import { Address, CryptoFiat, DateComponent, DecimalsFixed, ColumnElements } from 'components'
import { WrapId, UserId } from './Style'

function IdComponent(cell: string) {
  const [first, second] = cell.split(' ')
  return (
    <ColumnElements>
      <WrapId>{first}</WrapId>
      <UserId>{second}</UserId>
    </ColumnElements>
  )
}

function ValueComponent(value: string, currency: string, fiat: number) {
  return <CryptoFiat cryptoAmount={value} cryptoCurrency={currency} fiatAmount={String(fiat)} fiatCurrency='EUR' />
}

function WithdrawalComponent(cell: string, currency: string) {
  return (
    <Fragment>
      {DecimalsFixed(cell, 'crypto')} {currency}
    </Fragment>
  )
}

export const COLUMNS = [
  {
    title: 'User ID',
    key: 'foreign_id',
    render: (props: string, render: { id: number }) => IdComponent(`${props} ${render.id}`),
  },
  {
    title: 'Created',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'NET',
    key: 'net',
    render: (props: string, render: { currency: string; net_eur: number }) =>
      ValueComponent(props, render.currency, render.net_eur),
  },
  {
    title: 'Deposit',
    key: 'deposit',
    render: (props: string, render: { currency: string; deposit_eur: number }) =>
      ValueComponent(props, render.currency, render.deposit_eur),
  },
  {
    title: 'Withdrawal',
    key: 'withdraw',
    render: (props: string, render: { currency: string }) => WithdrawalComponent(props, render.currency),
  },
  {
    title: 'Fee',
    key: 'fee',
    render: (props: string, render: { currency: string; fee_eur: number }) =>
      ValueComponent(props, render.currency, render.fee_eur),
  },
  {
    title: 'Assigned address',
    key: 'address',
    width: 144,
    align: 'right',
    render: (props: string[]) => Address({ value: props[FIRST_EL_ARRAY] }),
  },
]
