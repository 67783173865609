import { ButtonType, Theme } from '../@types'

export const getColor = (theme: Theme, type: ButtonType) => {
  const { background, color, border } = theme.button

  const { COLOR_FOCUS, COLOR_HOVER, COLOR_ACTIVE, COLOR_DEFAULT } = {
    COLOR_HOVER: color.hover[type],
    COLOR_FOCUS: color.focus[type],
    COLOR_ACTIVE: color.pressed[type],
    COLOR_DEFAULT: color.default[type],
  }

  const { BACKGROUND_HOVER, BACKGROUND_FOCUS, BACKGROUND_ACTIVE, BACKGROUND_DEFAULT } = {
    BACKGROUND_HOVER: background.hover[type],
    BACKGROUND_FOCUS: background.focus[type],
    BACKGROUND_ACTIVE: background.pressed[type],
    BACKGROUND_DEFAULT: background.default[type],
  }

  const { BORDER_HOVER, BORDER_FOCUS, BORDER_ACTIVE, BORDER_DEFAULT } = {
    BORDER_HOVER: border.hover[type],
    BORDER_FOCUS: border.focus[type],
    BORDER_ACTIVE: border.pressed[type],
    BORDER_DEFAULT: border.default[type],
  }

  const THEME_STATUS_MAP = {
    hover: {
      color: COLOR_HOVER,
      background: BACKGROUND_HOVER,
      border: BORDER_HOVER,
    },
    focus: {
      color: COLOR_FOCUS,
      background: BACKGROUND_FOCUS,
      border: BORDER_FOCUS,
    },
    active: {
      color: COLOR_ACTIVE,
      background: BACKGROUND_ACTIVE,
      border: BORDER_ACTIVE,
    },
    default: {
      color: COLOR_DEFAULT,
      background: BACKGROUND_DEFAULT,
      border: BORDER_DEFAULT,
    },
  }

  return {
    default: THEME_STATUS_MAP,
    warning: THEME_STATUS_MAP,
    ghost: THEME_STATUS_MAP,
    ghostWarning: THEME_STATUS_MAP,
    ghostAccent: THEME_STATUS_MAP,
    link: THEME_STATUS_MAP,
  }[type]
}
