import styled from 'styled-components'
import { font } from 'theme'

export const MainWrapper = styled.div`
  position: relative;
  display: block;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const Style = styled.div`
  margin: 0 7px;
  flex: 1 0 auto;
  width: 7px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
