import styled from 'styled-components'
import { color } from 'theme'

export const Wrap = styled.div`
  margin: 0 auto 50px;
  padding: 0 34px;
  width: 100%;
  max-width: 1280px;
  display: block;
`

export const CryptoElement = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const CryptoName = styled.span`
  margin: 0 0 0 12px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.43;
`

export const TextWarning = styled.span`
  color: ${color.danger};
`

export const TextSuccess = styled.span`
  color: ${color.success};
`
