import React, { Component, ChangeEvent } from 'react'
import { Wrapper, Label, InputElement, ErrorMessage, CrossIcon } from './Style'

import { Props } from './@types'

// TODO: create more options for API
class Textarea extends Component<Props> {
  public render() {
    const { value, error, errorMessage, crossError, label, placeholder, ...rest } = this.props

    return (
      <Wrapper>
        {label && <Label>{label}</Label>}
        <InputElement
          {...rest}
          value={value}
          placeholder={placeholder}
          error={error}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        {error && crossError && <CrossIcon name='close' />}
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Wrapper>
    )
  }

  private handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onChange, name } = this.props
    const value = event.target.value

    onChange(value, name)
  }

  private handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onBlur } = this.props
    const value = event.target.value

    if (typeof onBlur === 'function') {
      onBlur(value)
    }
  }
}

export { Textarea }
