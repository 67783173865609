import { RequestState } from '@types'
import { Actions, Invoice } from '../@types'
import {
  SET_INVOICE,
  CREATE_INVOICE_CLEAR,
  CHECK_INVOICE_FAILURE,
  CHECK_INVOICE_REQUEST,
  CHECK_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const checkInvoiceRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case CHECK_INVOICE_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case CHECK_INVOICE_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case CHECK_INVOICE_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

export const createInvoiceRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case CREATE_INVOICE_CLEAR:
      return initialDomainState
    case CREATE_INVOICE_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case CREATE_INVOICE_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case CREATE_INVOICE_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: Invoice = {
  foreign_id: '',
  addresses: [],
  client_amount: 0,
  client_currency: '',
  expired_at: '',
  status: 'created',
  paid_at: '',
  paid_currency: '',
  paid_tx: '',
  allow_paid_after_expired: false,
}

export const invoice = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_INVOICE:
      const { invoice } = action.payload
      return invoice
    default:
      return state
  }
}
