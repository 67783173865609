import React from 'react'

const Change = () => (
  <svg fill='none' viewBox='0 0 20 21'>
    <path
      fill='#4678ff'
      fillRule='evenodd'
      d='M7.24 17H3a1 1 0 01-1-1v-4.24a1 1 0 01.29-.71l6.94-6.93 2.82-2.83a1 1 0 011.42 0l4.24 4.29a1 1 0 010 1.42l-2.84 2.78-6.92 6.93a1 1 0 01-.71.29zm8.35-10.76l-2.83-2.83-1.42 1.42 2.83 2.83 1.42-1.42zM4 12.17l5.93-5.93 2.83 2.83L6.83 15H4v-2.83zM1 19h18a1 1 0 010 2H1a1 1 0 110-2z'
      clipRule='evenodd'
    />
  </svg>
)

export { Change }
