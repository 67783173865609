import styled from 'styled-components'
import { Input, Textarea } from 'components'
import { font, color } from 'theme'

export const Title = styled.h2<{ error?: boolean }>`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${({ error }) => (!error ? color.white : color.warning)};
`

export const Text = styled.p<{ noMargin?: boolean }>`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`

export const SelectApproach = styled.div`
  margin: 0 0 50px;
`

export const BankDetails = styled.div`
  margin: 0 0 50px;
`

export const CryptoAddresses = styled.div`
  margin: 0 0 50px;
`

export const Address = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin: 0 0 20px;
  }
`

export const CustomTextarea = styled(Textarea)`
  padding: 10px 20px;
  min-height: 88px;
`

export const AddressCurrency = styled.span`
  padding: 10px 30px 10px 20px;
  width: 120px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  background-color: #292833;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right: none;
  border-radius: 6px 0 0 6px;
`

export const Currency = styled.span`
  margin: 0 0 0 10px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${color.white};
`

export const AddressInput = styled(Input)`
  padding: 10px 46px 10px 16px;
  border-radius: 0 6px 6px 0;
`

export const CallbackUrl = styled.div`
  position: relative;
  margin: 0 0 30px;
`

export const CustomInput = styled(Input)`
  padding: 10px 46px 10px 16px;
`

export const BtnContainer = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`
export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`
