import React from 'react'

import { shortString } from 'utils'
import { Href } from 'components'

import { ValueAddress } from './Style'

const START = 0
const END = 4

type LinkProps = {
  link: string
  value: string
}

function TransactionLink({ link, value }: LinkProps) {
  return (
    <Href href={link}>
      <ValueAddress>{shortString(value, START, END)}</ValueAddress>
    </Href>
  )
}

export { TransactionLink }
