import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { getPairs, pairs } from './Pairs'
import { getCurrencies, currencies } from './Currencies'
import { getCurrenciesFrom, currenciesFrom } from './CurrenciesFrom'
import { cryptoCurrencies, getCryptoCurrencies } from './CryptoCurrencies'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    pairs,
    currencies,
    currenciesFrom,
    cryptoCurrencies,
  }),
  [DOMAIN_STATE]: combineReducers({
    getPairs,
    getCurrencies,
    getCurrenciesFrom,
    getCryptoCurrencies,
  }),
})
