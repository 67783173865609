import React, { ComponentType } from 'react'
import uuid from 'react-uuid'

import { CreateToastProps, Toast } from './@types'
import { ToastContainer } from './ToastContainer'

export const createConfig = (name: string, container: ComponentType): Toast => {
  return {
    name,
    container,
  }
}

const createToast = ({ type, text }: CreateToastProps) => {
  const name = uuid()
  const toastWrapper = () => <ToastContainer name={name} text={text} type={type} />

  return createConfig(name, toastWrapper)
}

export { createToast }
