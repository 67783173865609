import React from 'react'
import { GetProps } from 'react-redux'

import { createModal } from 'containers/Modal'
import { RemoveUserContainer } from '../containers'

type Props = GetProps<typeof RemoveUserContainer>

const containerWithProps = (props: Props) => <RemoveUserContainer {...props} />

const staffRemoveRequest = (props: Props) =>
  createModal({
    type: 'warning',
    name: 'SET_DATA',
    title: 'Delete User',
    container: containerWithProps(props),
  })

export { staffRemoveRequest }
