import { createSelector } from 'reselect'

import { ClientState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: ClientState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: ClientState) => state[DOMAIN][DOMAIN_STATE]

export const selectClients = () => createSelector(selectUi, subState => subState.clients)

export const selectGetClients = () => createSelector(selectDomain, subState => subState.getClients)
