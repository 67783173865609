import { call, all, put, takeLatest, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'

import { query } from '../client'
import { Filter } from '../@types'
import { GET_TX, GetTxResponse } from '../queries'
import { selectTransactions } from '../selectors'
import { getTxFailure, getTxSuccess, setTx, getTxRequest as request } from '../actions'
import { GET_TX_REQUEST, SELECT_TX_PAGE_SIZE, SET_TX_FILTERS, SELECT_TX_PAGE } from '../constants'

export function* getTransactions() {
  const { filters, currentPage, pageSize } = yield select(selectTransactions())

  try {
    const dataFilters = getTrimming<Filter>(filters)

    const response: GetTxResponse = yield call(query, {
      query: GET_TX,
      fetchPolicy: 'no-cache',
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
    })
    const {
      findUserDeposits: { transactions, total, stats },
    } = response.data

    yield put(setTx(transactions, total, stats))

    yield put(getTxSuccess())
  } catch (err) {
    yield put(getTxFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getTxFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_TX_REQUEST, getTransactions),

    yield takeLatest(SET_TX_FILTERS, onRequest),
    yield takeLatest(SELECT_TX_PAGE, onRequest),
    yield takeLatest(SELECT_TX_PAGE_SIZE, onRequest),
  ])
}
