import { RequestState } from '@types'
import { getPageCount } from 'utils'

import { Actions, Transactions } from '../@types'
import {
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  SELECT_TRANSACTIONS_PAGE,
  SELECT_TRANSACTIONS_PAGE_SIZE,
  SET_TRANSACTIONS,
  SET_TRANSACTIONS_FILTERS,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getTransactions = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case SELECT_TRANSACTIONS_PAGE:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case SELECT_TRANSACTIONS_PAGE_SIZE:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case SET_TRANSACTIONS_FILTERS:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_TRANSACTIONS_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_TRANSACTIONS_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_TRANSACTIONS_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: Transactions = {
  pageSize: 10,
  currentPage: 1,
  pageCount: 1,
  data: [],
  filters: {},
  stats: [],
}

export const transactions = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      const { stats, data, total } = action.payload
      const pageCount = getPageCount(total, state.pageSize)

      return { ...state, total, stats, data, pageCount }
    case SELECT_TRANSACTIONS_PAGE:
      const { currentPage } = action.payload
      return { ...state, currentPage }
    case SELECT_TRANSACTIONS_PAGE_SIZE:
      const { pageSize } = action.payload
      return { ...state, pageSize }
    case SET_TRANSACTIONS_FILTERS:
      const { filters } = action.payload
      return { ...state, filters, currentPage: 1 }
    default:
      return state
  }
}
