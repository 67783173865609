import React from 'react'
import { defaultTheme } from 'theme'

import { Props } from './@types'
import { StyledButton, Loader } from './Style'

function Button({
  onClick,
  children,
  isLoading = false,
  disabled = false,
  type = 'default',
  size = 'default',
  theme = defaultTheme,
  ...rest
}: Props) {
  return (
    <StyledButton size={size} type={type} theme={theme} onClick={onClick} disabled={disabled} {...rest}>
      {isLoading ? <Loader /> : children}
    </StyledButton>
  )
}

export { Button }
