import React, { Fragment } from 'react'
import { Address } from '../../@types'

import { LogoLink } from 'components'
import { Item } from './Item'
import { Text, Header, ListChoice } from './Style'

type BlockProps = {
  currency: string
  addresses: Address[]
  select: (params: Address) => void
}

const ChoiceInvoice = ({ addresses, currency, select }: BlockProps) => {
  return (
    <Fragment>
      <Header>
        <LogoLink />
      </Header>
      <Text>Choose cryptocurrency you are going to pay</Text>

      <ListChoice>
        {addresses.map(item => (
          <Item address={item} currency={currency} key={item.address} select={select} />
        ))}
      </ListChoice>
    </Fragment>
  )
}

export { ChoiceInvoice }
