import styled from 'styled-components'
import { Icon } from 'components'

import { StyledInputProps } from './@types'
import { font, color } from 'theme'

export const Wrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
`

export const Container = styled.div`
  position: relative;
`

export const InputElement = styled.input<StyledInputProps>`
  position: relative;
  margin: 0;
  padding: ${({ type }) =>
    type === 'password'
      ? `
    9px 52px 9px 16px;
  `
      : `
    9px 16px;
  `};
  display: block;
  width: 100%;
  color: ${color.white};
  font-family: ${font.display};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.72;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-color: ${({ error }) => (error ? color.warning : 'rgba(255, 255, 255, 0.2)')};
  border-radius: 6px;
  box-shadow: none;
  transition: border-color 0.3s ease-in;
  &::placeholder {
    font-family: ${font.regular};
    transform-origin: left center;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
  }
  &:hover,
  &:focus {
    -moz-appearance: number-input;
  }
  &:hover {
    border-color: ${color.white};
  }
  &:focus {
    border-color: ${color.default};
    caret-color: ${color.white};
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  &[readonly] {
    cursor: no-drop;
    opacity: 0.5;
  }
  &[type='password'] {
    font-family: caption;
    letter-spacing: 5px;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
  color: rgba(255, 255, 255, 0.8);
`

export const InputIcon = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  z-index: 3;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
  }
`

export const CrossIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 12px;
  height: 12px;
  color: red;
  transform: translate(0, -50%);
`

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  font-size: 12px;
  line-height: 1.4;
  color: ${color.warning};
  transition: all 0.3s ease-in;
`
