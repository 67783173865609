import React, { Component } from 'react'

import { routes } from './route'
import { RouterContainer } from 'modules/Auth'

class View extends Component {
  public render() {
    return <RouterContainer routes={routes} />
  }
}

export { View }
