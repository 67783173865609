import { gql, ApolloQueryResult } from 'apollo-boost'

export const NEW_CRYPTO_REQ = gql`
  mutation addCryptoReq($amount: Float!, $currency_name: CommonCurrencyName!, $comment: String, $signed_paper: Upload) {
    addCryptoReq(
      data: { amount: $amount, currency_name: $currency_name, comment: $comment, signed_paper: $signed_paper }
    ) {
      id
      currency_name
      address
      amount
      staff {
        id
        email
        name
        role_name
      }
      comment
      signed_paper
      status
    }
  }
`

export type NewCryptoReqResponse = ApolloQueryResult<{
  addCryptoReq: boolean
}>

export const NEW_FIAT_REQ = gql`
  mutation addFiatReq(
    $amount: Float!
    $currency_name: FiatCurrencyName!
    $bank_detail: String!
    $comment: String
    $signed_paper: Upload
  ) {
    addFiatReq(
      data: {
        amount: $amount
        currency_name: $currency_name
        bank_detail: $bank_detail
        comment: $comment
        signed_paper: $signed_paper
      }
    ) {
      id
      staff {
        id
        email
        name
        role_name
      }
      currency_name
      amount
      bank_detail
      comment
      signed_paper
      status
    }
  }
`

export type NewFiatReqResponse = ApolloQueryResult<{
  addFiatReq: boolean
}>
