export const DOMAIN = 'modules/client'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_CLIENTS = 'modules/client/ui/SET_CLIENTS'
export const SELECT_CLIENTS_PAGE = 'modules/client/ui/SELECT_CLIENTS_PAGE'
export const SELECT_CLIENTS_PAGE_SIZE = 'modules/client/ui/SELECT_CLIENTS_PAGE_SIZE'
export const SET_CLIENTS_FILTERS = 'modules/client/ui/SET_CLIENTS_FILTERS'

export const GET_CLIENTS_REQUEST = 'modules/client/domain/GET_CLIENTS_REQUEST'
export const GET_CLIENTS_SUCCESS = 'modules/client/domain/GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAILURE = 'modules/client/domain/GET_CLIENTS_FAILURE'
