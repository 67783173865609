import styled from 'styled-components'
import { font, color } from 'theme'

export const Wrapper = styled.div`
  margin: 0;
  display: block;
  z-index: 2;

  .react-datepicker__input-container {
    & input {
      padding: 9px 16px;
      display: block;
      width: 100%;
      height: 44px;
      font-family: ${font.regular};
      font-weight: normal;
      font-size: 12px;
      text-align: left;
      color: ${color.white};
      background-color: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 6px;
      box-shadow: none;
      transition: all 0.3s ease-in;

      &::placeholder {
        font-family: ${font.regular};
        font-weight: 400;
        font-size: 14px;
        line-height: 1.71;
        color: rgba(255, 255, 255, 0.5);
        transform-origin: left center;
      }

      &:hover {
        border-color: ${color.white};
      }

      &:focus {
        outline: none;
        border-color: ${color.default};
        caret-color: ${color.white};
      }

      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .react-datepicker-popper {
    background: transparent;
    border: none;
    z-index: 3;
  }
  .react-datepicker {
    padding: 20px;
    font-size: 12px;
    background-color: #292833;
    border: solid 1px rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    &__header {
      padding: 0;
      background: transparent;
      border: none;
    }
    &__navigation {
      position: absolute;
      top: 20px;
      width: 24px;
      height: 24px;
      background: transparent;
      border: none;
      border-right-color: transparent;
      border-left-color: transparent;
      overflow: hidden;
      &::before,
      &::after {
        content: '';
        position: absolute;
        transition: all 0.5s ease-in-out;
      }
      &--previous {
        transform: rotate(-180deg);
        left: 15px;
        &::before {
          top: 50%;
          right: 20%;
          width: 1em;
          height: 1em;
          transform-origin: 50% 50%;
          transform: translate(0, -0.5em) rotate(45deg);
          box-shadow: inset -0.2em 0.2em 0 0 #8c8ca3;
          border-radius: 0 0.15em 0 0;
        }
        &::after {
          top: 50%;
          right: 20%;
          width: 1.3em;
          height: 0.2em;
          transform: translate(0, -0.1em);
          background-color: #8c8ca3;
        }
      }
      &--next {
        right: 15px;
        &::before {
          top: 50%;
          right: 20%;
          width: 1em;
          height: 1em;
          transform-origin: 50% 50%;
          transform: translate(0, -0.5em) rotate(45deg);
          box-shadow: inset -0.2em 0.2em 0 0 #8c8ca3;
          border-radius: 0 0.15em 0 0;
        }
        &::after {
          top: 50%;
          right: 20%;
          width: 1.3em;
          height: 0.2em;
          transform: translate(0, -0.1em);
          background-color: #8c8ca3;
        }
      }
    }
    &__current-month {
      margin: 0 0 20px;
      font-family: ${font.display};
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
    }
    &__day-name {
      margin: 0;
      padding: 0.166rem;
      font-family: ${font.regular};
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
    }
    &__month {
      margin: 0;
    }
    &__day-names,
    &__week {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    &__current-month,
    &__day,
    &__time-name {
      color: ${color.white};
    }
    &__day {
      margin: 0;
      padding: 0.166rem;
      font-family: ${font.regular};
      font-weight: normal;
      line-height: 1.67;
      letter-spacing: normal;
      &:hover {
        color: ${color.black};
        border-radius: 0;
      }
      &--disabled {
        color: #5e5e7d;
        &:hover {
          color: #5e5e7d;
        }
      }
      &--selected,
      &--keyboard-selected {
        color: ${color.white};
        background-color: ${color.default};
        border-radius: 50%;
        &:hover {
          color: ${color.white};
          background-color: ${color.default};
          border-radius: 50%;
        }
      }
      &--in-selecting-range {
        border-radius: 0;
      }
      &--in-range {
        border-radius: 0;
        background-color: #29367c;
      }
      &--range-start {
        background-color: ${color.default};
        border-radius: 50% 0 0 50%;
        &:hover {
          color: ${color.white};
          background-color: ${color.default};
          border-radius: 50% 0 0 50%;
        }
      }
      &--range-end {
        background-color: ${color.default};
        border-radius: 0 50% 50% 0;
        &:hover {
          color: ${color.white};
          background-color: ${color.default};
          border-radius: 0 50% 50% 0;
        }
      }
      &--selecting-range-start {
        border-radius: 50% 0 0 50%;
      }
      &--selecting-range-end {
        &:hover {
          color: ${color.white};
          background-color: ${color.default};
          border-radius: 0 50% 50% 0;
        }
      }
    }
  }
  .react-datepicker__day {
    padding: 3px;
    width: 26px;
  }
  .react-datepicker__day--in-selecting-range.react-datepicker__day--range-start {
    border-radius: 50% 0 0 50%;
    background-color: ${color.default};
    &:hover {
      border-radius: 50% 0 0 50%;
    }
  }
  .react-datepicker__day--in-selecting-range.react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0;
    background-color: ${color.default};
    &:hover {
      border-radius: 0 50% 50% 0;
    }
  }
  .react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--range-end.react-datepicker__day--range-end {
    border-radius: 50%;
    background-color: ${color.default};
  }

  .react-datepicker__input-time-container {
    margin: 20px 0 0;
  }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    margin: 0 0 4px;
    display: inline-block;
    font-family: ${font.regular};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.8);
  }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: block;
  }
  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    margin: 0;
    display: block;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type='time'] {
    padding: 9px 16px;
    display: block;
    width: 100%;
    height: 44px;
    font-family: ${font.regular};
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: ${color.white};
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    box-shadow: none;
    transition: all 0.3s ease-in;
    background-repeat: no-repeat;
    background-position: center right 14px;
    background-size: 20px 22px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%234678FF' fill-rule='nonzero' d='M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0010 0zm0 18a8 8 0 110-16 8 8 0 010 16zm3.1-7.37L11 9.42V5a1 1 0 00-2 0v5.12a.65.65 0 00.05.2.89.89 0 00.08.17.86.86 0 00.1.16l.16.13.09.09 2.6 1.5a1 1 0 00.5.13 1 1 0 00.5-1.87h.02z'/%3E%3C/svg%3E");
    cursor: text;

    &::placeholder {
      font-family: ${font.regular};
      font-weight: 400;
      font-size: 14px;
      line-height: 1.71;
      color: rgba(255, 255, 255, 0.5);
      transform-origin: left center;
    }

    &:hover {
      border-color: ${color.white};
    }

    &:focus {
      border-color: ${color.default};
      caret-color: ${color.white};
      outline: none;
    }

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .react-datepicker-wrapper {
    flex-grow: 1;
  }
`

export const Style = styled.div`
  margin: 0 7px;
  flex: 1 0 7px;
  max-width: 7px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const Row = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`
