import React from 'react'
import { GetProps } from 'react-redux'

import { createModal } from 'containers/Modal'
import { NewRequestContainer } from '../containers'

type Props = GetProps<typeof NewRequestContainer>

const containerWithProps = (props: Props) => <NewRequestContainer {...props} />

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TITLE: any = {
  eth_address: 'Ethereum withdrawal address',
  btc_address: 'Bitcoin withdrawal address',
  bank_detail_eur: 'Fiat withdrawal address',
  bank_detail_usd: 'Fiat withdrawal address',
  approach_currency: 'Approach company',
} as const

const NewRequest = (props: Props) =>
  createModal({
    name: 'SET_DATA',
    title: `${TITLE[props.type]}`,
    container: containerWithProps(props),
  })

export { NewRequest }
