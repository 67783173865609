import styled from 'styled-components'

import { theme } from 'theme'

const { radioItem } = theme

export const RadioButtonIcon = styled.label<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  margin: 0 16px 0 0;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: ${radioItem.icon.default.borderRadius}%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: border-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #4678ff;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;
  }
`

export const RadioWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${radioItem.color.default.default};
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  will-change: background-color;
  transition: background-color 0.3s;

  &:not(:last-child) {
    margin: 0 0 20px;
  }

  &:hover {
    color: ${radioItem.color.hover.default};

    & ${RadioButtonIcon} {
      color: ${radioItem.icon.hover.color};
      background: ${radioItem.icon.hover.background};
      border-radius: ${radioItem.icon.hover.borderRadius}%;
      border-color: #4678ff;
    }
  }

  ${({ disabled }) =>
    disabled
      ? `
    cursor: 'not-allowed';
    color: ${radioItem.color.disabled.default};
    background: ${radioItem.background.disabled.default};

    &:hover {
      color: ${radioItem.color.disabled.default};
      background: ${radioItem.background.disabled.default};

      & ${RadioButtonIcon} {
        color: ${radioItem.icon.disabled.color};
        background: ${radioItem.icon.disabled.background};
        border-radius: ${radioItem.icon.disabled.borderRadius}%;
        border: ${radioItem.icon.disabled.border};
        opacity: ${radioItem.icon.disabled.opacity};
      }
    }
  `
      : ``}
`

export const RadioButton = styled.input`
  margin-right: 10px;
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 1;

  &:checked + ${RadioButtonIcon} {
    color: ${radioItem.color.checked.default};
    background: ${radioItem.background.checked.default};
    border-color: #4678ff;
    border-radius: ${radioItem.icon.checked.borderRadius}%;
    &::before {
      opacity: 1;
    }
  }
`

export const RadioButtonLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
