import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'

import { AuthState } from '../@types'
import { signInRequest } from '../actions'
import { withInjectors } from '../injectors'
import { SignIn as View } from '../components'
import { createStructuredSelector } from 'reselect'
import { selectSignInRequest, selectAuth } from '../selectors'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      signIn: signInRequest,
    },
    dispatch,
  )

type Props = {
  auth: boolean
  signInRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    auth: selectAuth(),
    signInRequest: selectSignInRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const SignInContainer = compose<ComponentType>(withInjectors, withConnect)(View)
