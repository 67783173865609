import { all, fork } from 'redux-saga/effects'

import { saga as Resend } from './Resend'
import { saga as WebHooks } from './WebHooks'

const WebHooksSagas = [WebHooks, Resend]

export function* saga() {
  return yield all([...WebHooksSagas.map(fork)])
}
