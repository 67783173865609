import React from 'react'

const Sek = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#006AA7'
      d='M27.48 0h4.08c13.32.55 25.41 10.87 27.85 24.01C47.61 23.99 35.81 24 24 24c-.01-7.8.01-15.6 0-23.4l3.48-.6zM.59 23.99c1.46-7.13 5.6-13.65 11.4-18.04.02 6.01.01 12.03.01 18.05-3.8 0-7.61.01-11.41-.01zM.59 36H12c0 6 .01 12-.01 18C6.1 49.71 2.08 43.1.59 36zM24 36c11.81 0 23.61-.01 35.42.01C56.93 49.13 44.9 59.46 31.55 60h-3.08c-1.5-.15-3-.35-4.48-.59.03-7.8 0-15.6.01-23.41z'
    />
    <path
      fill='#FECC00'
      d='M11.99 5.95C15.57 3.35 19.67 1.5 24 .6c.01 7.8-.01 15.6 0 23.4 11.81 0 23.61-.01 35.41.01.25 1.48.45 2.98.59 4.49v2.81c-.14 1.57-.34 3.14-.58 4.7C47.61 35.99 35.81 36 24 36c-.01 7.81.02 15.61-.01 23.41-4.34-.91-8.45-2.74-12-5.41.02-6 .01-12 .01-18H.59C.34 34.5.14 33 0 31.49v-2.93c.13-1.53.33-3.05.59-4.57 3.8.02 7.61.01 11.41.01 0-6.02.01-12.04-.01-18.05z'
    />
  </svg>
)

export { Sek }
