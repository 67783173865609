import React from 'react'

const Pln = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#fff'
      d='M28.56 0h3c14.98.53 28 13.58 28.44 28.57v1.34c-20 0-40 .01-60 0v-1.42C.49 13.5 13.56.45 28.56 0z'
    />
    <path
      fill='#DC143C'
      d='M0 29.91c20 .01 40 0 60 0v1.74C59.41 46.56 46.45 59.5 31.53 60H28.4C13.45 59.45.48 46.44 0 31.49v-1.58z'
    />
  </svg>
)

export { Pln }
