import { links } from 'constant'
import { InvoiceContainer, InvoicesContainer } from '../containers'

const routes = [
  {
    path: [`${links.invoice}/:id`, links.invoice],
    component: InvoiceContainer,
    isPrivate: false,
  },
  {
    path: links.invoices,
    component: InvoicesContainer,
    isPrivate: true,
  },
]

export { routes }
