import { RequestState } from '@types'

import { CommonCurrencies, Actions } from '../@types'
import { SET_CURRENCIES, GET_CURRENCIES_FAILURE, GET_CURRENCIES_REQUEST, GET_CURRENCIES_SUCCESS } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getCurrencies = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_CURRENCIES_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_CURRENCIES_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_CURRENCIES_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: CommonCurrencies = []

export const currencies = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_CURRENCIES:
      return action.payload.data
    default:
      return state
  }
}
