import { Transaction, Filter, Stats } from '../@types'
import {
  SET_TX,
  GET_TX_REQUEST,
  GET_TX_FAILURE,
  GET_TX_SUCCESS,
  SET_TX_FILTERS,
  SELECT_TX_PAGE,
  SELECT_TX_PAGE_SIZE,
} from '../constants'

export const getTxRequest = () => {
  return {
    type: GET_TX_REQUEST,
  } as const
}

export const getTxFailure = (errorMessage: string) => {
  return {
    type: GET_TX_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getTxSuccess = () => {
  return {
    type: GET_TX_SUCCESS,
  } as const
}

export const setTxFilters = (filters: Filter) => {
  return {
    type: SET_TX_FILTERS,
    payload: { filters },
  } as const
}

export const selectPageSize = (pageSize: number) => {
  return {
    type: SELECT_TX_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectPage = (currentPage: number) => {
  return {
    type: SELECT_TX_PAGE,
    payload: { currentPage },
  } as const
}

export const setTx = (data: Transaction[], total: number, stats: Stats[]) => {
  return {
    type: SET_TX,
    payload: { data, total, stats },
  } as const
}
