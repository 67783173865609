import React, { Fragment, Component } from 'react'

import { Copy, Input, QrCode, Select, KeysListener } from 'components'

import { AddUserInput, RoleName } from '../../@types'
import { Wrap, InputWrap, CustomButton, Text, CopyWrap, WrapSecret } from './Style'

type Options = {
  value: RoleName
  label: string
}

const options: Options[] = [
  {
    value: 'MANAGER',
    label: 'Manager',
  },
  {
    value: 'ADMIN',
    label: 'Admin',
  },
]

type State = {
  email: string
  name: string
  password: string
  role: RoleName
}

type Props = {
  onClose: () => void
  clearState: () => void
  newUser: { otpSecret: string }
  addUserRequest: {
    isError: boolean
    isFetching: boolean
    errorMessage: string
  }
  addUser: (data: AddUserInput) => void
}

class AddUser extends Component<Props, State> {
  public state = {
    email: '',
    name: '',
    password: '',
    role: 'ADMIN',
  } as const

  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public handleChange = (value: string, name?: string) => {
    if (name) {
      this.setState(prev => ({ ...prev, [name]: value }))
    }
  }

  public handleRoleChange = (params: Options) => {
    this.setState(() => ({ role: params.value }))
  }

  public handleCreate = () => {
    const { addUser } = this.props
    const { email, name, password, role } = this.state

    addUser({
      name: name,
      email: email,
      role_name: role,
      password_hash: password,
    })
  }

  public handleClose = () => {
    const { onClose, clearState } = this.props

    clearState()
    onClose()
  }

  public render() {
    const { newUser, addUserRequest } = this.props
    const { email, name, password, role } = this.state

    return (
      <Fragment>
        {!newUser.otpSecret ? (
          <Wrap>
            <KeysListener handleSubmit={this.handleCreate}>
              <InputWrap>
                <Input
                  name='name'
                  type='text'
                  value={name}
                  label='User name'
                  autoComplete='off'
                  onChange={this.handleChange}
                />
              </InputWrap>

              <InputWrap>
                <Input
                  type='email'
                  name='email'
                  value={email}
                  autoComplete='off'
                  label='User e-mail'
                  onChange={this.handleChange}
                  error={addUserRequest.isError}
                  errorMessage={addUserRequest.errorMessage}
                />
              </InputWrap>

              <InputWrap>
                <Input
                  name='password'
                  type='password'
                  value={password}
                  autoComplete='off'
                  label='User password'
                  onChange={this.handleChange}
                  errorMessage='Wrong password or email address'
                />
              </InputWrap>

              <Select label='User role' options={options} value={role} select={this.handleRoleChange} />

              <CustomButton onClick={this.handleCreate} isLoading={addUserRequest.isFetching}>
                Create
              </CustomButton>
            </KeysListener>
          </Wrap>
        ) : (
          <Fragment>
            <QrCode value={`otpauth://totp/${email}?secret=${newUser.otpSecret}&issuer=B2B%20DAOWallet`} />
            <Text>
              Please make a&nbsp;screenshot of&nbsp;the code above or click button below for copy 2FA code and send
              it&nbsp;to&nbsp;a&nbsp;new member
            </Text>
            <CopyWrap>
              <Copy value={newUser.otpSecret}>
                <WrapSecret>{newUser.otpSecret}</WrapSecret>
              </Copy>
            </CopyWrap>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export { AddUser }
