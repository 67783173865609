import React from 'react'

type Props = {
  color?: string
}

const Bell = ({ color }: Props) => (
  <svg viewBox='0 0 16 20'>
    <path
      fill={color}
      fillRule='evenodd'
      d='M14 8v3.18A3 3 0 0116 14v2a1 1 0 01-1 1h-3.14a4 4 0 01-7.72 0H1a1 1 0 01-1-1v-2a3 3 0 012-2.82V8a6 6 0 015-5.91V1a1 1 0 012 0v1.09A6 6 0 0114 8zM8 4a4 4 0 00-4 4v3h8V8a4 4 0 00-4-4zm0 14a2 2 0 01-1.72-1h3.44A2 2 0 018 18zm-6-3h12v-1a1 1 0 00-1-1H3a1 1 0 00-1 1v1z'
      clipRule='evenodd'
    />
  </svg>
)

export { Bell }
