import React, { useState, useEffect } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Wrapper, Label, Row, Style } from './Style'

type Props = {
  to?: Date
  from?: Date
  nameTo?: string
  nameFrom?: string
  label?: string
  disabled?: boolean
  setEndDate: (to: Date, name?: string) => void
  setStartDate: (from: Date, name?: string) => void
}

function DatePickers({ nameTo, nameFrom, setStartDate, setEndDate, from, to, label, disabled = false }: Props) {
  const [endDate, setEnd] = useState<undefined | Date>(undefined)
  const [startDate, setStart] = useState<undefined | Date>(undefined)

  useEffect(() => {
    setEnd(to)
    setStart(from)
  }, [from, to])

  const handleSet = (date: Date) => {
    setStart(date)
    setStartDate(date, nameFrom)
  }

  const handleEnd = (date: Date) => {
    setEnd(date)
    setEndDate(date, nameTo)
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Row disabled={disabled}>
        <DatePicker
          disabled={disabled}
          calendarClassName='DatePickerCalendar'
          showPopperArrow={false}
          dateFormat='dd/MM/yy  HH:mm'
          selected={startDate}
          onChange={handleSet}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          showTimeInput
          timeInputLabel='Time'
          placeholderText='From'
        />

        <Style />

        <DatePicker
          disabled={disabled}
          calendarClassName='DatePickerCalendar'
          showPopperArrow={false}
          dateFormat='dd/MM/yy  HH:mm'
          selected={endDate}
          onChange={handleEnd}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          showTimeInput
          timeInputLabel='Time'
          placeholderText='To'
        />
      </Row>
    </Wrapper>
  )
}

export { DatePickers }
