import React from 'react'

const Dkk = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#C60C30'
      d='M27.26 0h4.23c13.94.5 26.44 11.79 28.2 25.65-11.37-.02-22.74.01-34.11-.02-.02-8.45-.01-16.9 0-25.35L27.26 0zM.31 25.61C1.7 15.92 8.11 7.12 16.99 2.95c0 7.56.01 15.13-.01 22.69-5.55-.01-11.11.03-16.67-.03zM.29 34.22c5.57.03 11.13.01 16.7.02 0 7.61.01 15.23-.01 22.85C8.09 52.79 1.6 44.03.29 34.22zM25.57 34.24c11.38.01 22.76-.04 34.14.02C57.97 48.18 45.44 59.53 31.44 60h-2.85c-.75-.08-2.26-.23-3.01-.31-.01-8.48 0-16.97-.01-25.45z'
    />
    <path
      fill='#fff'
      d='M16.99 2.95C19.71 1.66 22.63.83 25.58.28c-.01 8.45-.02 16.9 0 25.35 11.37.03 22.74 0 34.11.02.08.75.23 2.24.31 2.99v2.84c-.07.69-.22 2.09-.29 2.78-11.38-.06-22.76-.01-34.14-.02.01 8.48 0 16.97.01 25.45-2.96-.52-5.86-1.36-8.6-2.6.02-7.62.01-15.24.01-22.85-5.57-.01-11.13.01-16.7-.02-.07-.72-.22-2.15-.29-2.87v-2.83c.08-.73.23-2.18.31-2.91 5.56.06 11.12.02 16.67.03.02-7.56.01-15.13.01-22.69z'
    />
  </svg>
)

export { Dkk }
