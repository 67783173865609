import styled from 'styled-components'
import { font, color } from 'theme'
import { Input, Textarea } from 'components'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CurrentAddress = styled.div`
  position: relative;
  margin: 0 0 28px;
`

export const CurrentAddressLabel = styled.span`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const CurrentAddressValue = styled.p`
  margin: 0;
  padding: 10px 20px;
  min-height: 68px;
  font-size: 14px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
`

export const NewAddressInput = styled(Textarea)`
  max-height: 68px;
  min-height: auto;
  font-size: 14px;
`

export const InputAddUser = styled(Input)`
  margin: 32px 0;
`

export const EmailLink = styled.span`
  color: ${color.default};
  text-decoration: none;
`

export const IconWrap = styled.div`
  width: 16px;
  height: 20px;
`

export const IconWrapSend = styled.div`
  margin: 0 auto 20px;
  display: flex;
  width: 64px;
  height: 64px;
  color: ${color.success};
  border: 2px solid ${color.success};
  border-radius: 50%;
  svg {
    width: 100%;
  }
`

export const TitleSend = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${color.white};
`

export const TextSend = styled.p`
  margin: 0 0 10px;
  font-family: ${font.regular};
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .SelectRole__control {
    margin: 0 0 32px;
    padding: 0 8px 0 12px;
    width: 100%;
    min-width: 380px;
    height: 44px;
    color: ${color.white};
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .SelectRole__value-container {
    padding: 0;
    &--has-value {
      overflow: visible;
    }
  }
  .SelectRole__single-value {
    font-family: ${font.regular};
    font-size: 14px;
    line-height: normal;
    color: ${color.white};
  }
  .SelectRole__placeholder {
    position: static;
    margin: 0 10px 0 0;
    font-size: 14px;
    color: ${color.white};
    transform: none;
  }
  .SelectRole__indicator-separator {
    display: none;
  }
  .SelectRole__indicator.SelectRole__dropdown-indicator {
    padding: 0;
  }
  .SelectRole__menu {
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    max-width: auto;
    background-color: rgb(29, 28, 84);
    border-radius: 6px;
    border: solid 1px rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease-out;
    z-index: 3;
  }
  .SelectRole__menu-list {
    padding: 0;
    max-height: 154px;
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 3px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
    }
  }
  .SelectRole__option {
    padding: 12px;
    font-family: ${font.regular};
    font-size: 14px;
    line-height: normal;
    color: ${color.white};
    background-color: #1d1c54;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &--is-focused {
      background-color: #31305d;
    }
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`
