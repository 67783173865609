import React, { MouseEvent, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { color } from 'theme'

import { StyledButtonProps } from './@types'
import { getColor, getSize } from './helpers'

export const ButtonWithType = ({ onClick, children, submit, ...props }: StyledButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const btnType = submit ? 'submit' : 'button'

  const handleClick = (event: MouseEvent<Element>) => {
    if (typeof onClick === 'function') {
      onClick(event)

      if (buttonRef && buttonRef.current) {
        const element = document.createElement('div')

        const pos = buttonRef.current.getBoundingClientRect()

        const x = event.clientX - pos.left
        const y = event.clientY - pos.top

        element.style.left = `${x}px`
        element.style.top = `${y}px`
        element.classList.add('circle')

        buttonRef.current.appendChild(element)

        const TIME_OUT = 3000

        setTimeout(() => {
          element.remove()
        }, TIME_OUT)
      }
    }
  }
  return (
    <button {...props} type={btnType} ref={buttonRef} onClick={handleClick}>
      {children}
    </button>
  )
}

const ripple = keyframes`
  0%  {
    transform: translate(-100%, -100%);
  }
  80%  {
    transform: translate(-100%, -100%) scale(50);
  }
  100% {
    transform: translate(-100%, -100%) scale(50);
    opacity: 0;
  }
`

export const StyledButton = styled(ButtonWithType)`
  position: relative;

  margin: 0;
  padding: ${({ size }) => getSize(size).padding};
  min-width: ${({ size }) => getSize(size).minWidth};
  height: ${({ size }) => getSize(size).height};

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: ${({ theme }) => theme.font.regular};
  font-weight: bold;
  font-size: ${({ size }) => getSize(size).fontSize};
  line-height: ${({ size }) => getSize(size).lineHeight};
  letter-spacing: normal;
  text-decoration: none;

  color: ${({ type, theme }) => getColor(theme, type).default.color};
  background-color: ${({ type, theme }) => getColor(theme, type).default.background};

  border: ${({ type, theme }) => getColor(theme, type).default.border};
  border-radius: 6px;

  transition: all 0.3s ease-in;
  opacity: ${({ disabled, theme }) => (disabled ? theme.button.opacityDisabled : 'true')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  overflow: hidden;
  outline: none;
  z-index: 1;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    color: ${({ disabled, type, theme }) => !disabled && getColor(theme, type).hover.color};
    background-color: ${({ disabled, type, theme }) => !disabled && getColor(theme, type).hover.background};
    border: ${({ type, theme }) => getColor(theme, type).hover.border};
  }

  &:focus {
    color: ${({ type, theme }) => getColor(theme, type).focus.color};
    background-color: ${({ type, theme }) => getColor(theme, type).focus.background};
    border: ${({ type, theme }) => getColor(theme, type).focus.border};
  }

  &:active {
    color: ${({ type, theme }) => getColor(theme, type).active.color};
    background-color: ${({ type, theme }) => getColor(theme, type).active.background};
    border: ${({ type, theme }) => getColor(theme, type).active.border};
  }

  ${props =>
    props.type === 'link' &&
    css`
      display: inline;
      width: auto;
      max-width: 100%;
    `}

  .circle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translateX(-100%) translateY(-100%);
    animation: ${ripple} 1000ms ease-out forwards;
    animation-name: ${ripple};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: -1;
    ${props =>
      props.type === 'ghost' &&
      css`
        background-color: ${color.grey};
        opacity: 0.3;
      `}
    ${props =>
      props.type === 'ghostWarning' &&
      css`
        background-color: ${color.warning};
        opacity: 0.3;
      `}
  }
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loader = styled.span`
  margin: 0;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid ${color.white};
  border-top: 2px solid #435da3;
  border-width: 2px;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`
