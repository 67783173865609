import { compose } from 'redux'
import { injectReducer } from 'lib/redux-injector'

import { DOMAIN } from './constants'
import { rootReducer as reducer } from './reducer'

const withReducer = injectReducer({
  key: DOMAIN,
  reducer,
})

export const withInjectors = compose(withReducer)
