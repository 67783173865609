import React, { Component, RefObject } from 'react'

type Props = {
  onClick: () => void
}

class OutsideClick extends Component<Props> {
  private wrapperRef?: RefObject<HTMLDivElement>
  public constructor(props: Props) {
    super(props)

    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  public handleClickOutside(event: MouseEvent) {
    const { onClick } = this.props

    const node = (this.wrapperRef as unknown) as Node

    if (event.target instanceof Element && node) {
      if (!node.contains(event.target)) {
        onClick()
      }
    }
  }

  public render() {
    const { children } = this.props
    return <div ref={this.wrapperRef}>{children}</div>
  }
}

export { OutsideClick }
