import React from 'react'

const Users = () => (
  <svg fill='none' viewBox='0 0 24 20'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M17 19v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2' />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M9 9a4 4 0 100-8 4 4 0 000 8z'
      clipRule='evenodd'
    />
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M23 19v-2a4 4 0 00-3-3.87M16 1.13a4 4 0 010 7.75'
    />
  </svg>
)

export { Users }
