import React, { ChangeEvent, Component } from 'react'
import { Icon } from 'components'

import { CheckboxWrapper, CheckboxLabel, CheckboxInput, CheckboxContainer, CheckboxIcon } from './Style'

type Props = {
  id?: string
  value?: string
  checked?: boolean
  onClick?: (value?: string) => void
}

class Checkbox extends Component<Props> {
  public handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onClick } = this.props

    const value = event.target.value

    if (typeof onClick === 'function') {
      onClick(value)
    }
  }

  public render() {
    const { id, value, checked } = this.props

    return (
      <CheckboxWrapper>
        <CheckboxLabel>
          <CheckboxInput checked={checked} id={id} value={value} onChange={this.handleChange} type='checkbox' />
          <CheckboxContainer>
            <CheckboxIcon>
              <Icon name='tick' />
            </CheckboxIcon>
          </CheckboxContainer>
        </CheckboxLabel>
      </CheckboxWrapper>
    )
  }
}

export { Checkbox }
