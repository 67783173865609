import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators, compose } from 'redux'

import { Modal as View } from './Modal'
import { withInjectors } from './withInjectors'
import { ModalContainerOwnProps, ModalState } from './@types'
import { openModal, closeModal, closeAllModal } from './actions'
import { createStructuredSelector } from 'reselect'
import { selectCheckIsBack } from './selector'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      closeAllModal,
    },
    dispatch,
  )

type Props = {
  isBack: boolean
}

const mapStateToProps = createStructuredSelector<ModalState, Props>({
  isBack: selectCheckIsBack(),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const ModalContainer = compose<ComponentType<ModalContainerOwnProps>>(withInjectors, withConnect)(View)
