import React from 'react'

const Aud = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#012169'
      d='M27.23 0h4.03c-6.05 3.04-12.11 6.05-18.15 9.12-.01-.98-.04-2.95-.05-3.93C17.28 2.33 22.21.73 27.23 0zM38.1 1.1C49.93 4.22 58.78 15.14 60 27.23v4.8C59.18 46.75 46.41 59.42 31.65 60h-3.69c-8.09-.69-15.97-4.5-21.06-10.89 2.08.73 3.57 2.39 5.03 3.95-.28-1.86-.54-3.72-.8-5.58 1.87-.15 3.73-.29 5.59-.44-1.59-.98-3.19-1.96-4.78-2.93 1.02-1.56 2.05-3.12 3.07-4.69L9.73 41.4c-.57-1.8-1.15-3.61-1.72-5.41-.59 1.81-1.19 3.61-1.78 5.42-1.55-.61-3.11-1.22-4.67-1.82C.76 37 .25 34.32 0 31.63v-3.24c.26-2.79.76-5.58 1.62-8.25 1.88 0 2.07-1.89 2.38 1.36.52 0 .48 8.47 1 8.48 2 .03 4 .03 6 0 .52-.01 1.57-.02 2.09-.03-.01-3.02-.01-6.03-.02-9.04 5.32 2.64 10.63 5.31 15.94 7.99 2.72 1.64 5.97.97 8.96 1.09l.26.02c-.07-.53-.2-1.6-.27-2.14a57.1 57.1 0 01-.18-1.29c-4.27-2.31-8.71-4.27-12.94-6.64 4.37-.06 8.74-.04 13.1.16.01-.53.03-1.58.03-2.1.03-2 .04-4 .04-6v-1.91c-4.42-.15-8.85-.05-13.27-.01 4.3-2.27 8.64-4.46 13.03-6.53.08-.61.25-1.84.33-2.45zm14.08 23.42c-.59-.21-1.78-.64-2.38-.85.31.56.93 1.7 1.24 2.27-.74.41-1.47.82-2.21 1.24l2.65.28c-.07.67-.22 2-.3 2.67l1.8-1.96c.46.48 1.39 1.44 1.85 1.91l-.32-2.62c.65-.06 1.96-.19 2.61-.25-.55-.34-1.67-1-2.22-1.33.32-.56.98-1.67 1.31-2.22-.6.2-1.79.62-2.38.83l-.84-2.53c-.2.64-.61 1.92-.81 2.56z'
    />
    <path
      fill='#FEFEFE'
      d='M31.26 0h.65l1.14.13c-6.38 3.47-13.03 6.42-19.38 9.94 2.11-.19 4.38 0 6.31-1.05 5.51-2.75 11.01-5.51 16.5-8.3.4.09 1.21.29 1.62.38-.08.61-.25 1.84-.33 2.45-4.39 2.07-8.73 4.26-13.03 6.53 4.42-.04 8.85-.14 13.27.01V12H11c0-1.74-.01-3.49-.01-5.23.51-.4 1.55-1.19 2.07-1.58.01.98.04 2.95.05 3.93C19.15 6.05 25.21 3.04 31.26 0zM2.45 18.1c.64-.06 1.91-.17 2.55-.22v12.1c-.52-.01-2.09-.03-2.09-.03V21l-1.69.5s.19-.85.4-1.36c.21-.51.62-1.53.83-2.04zM11 18h26.97c0 .52-.02 1.57-.03 2.1-4.36-.2-8.73-.22-13.1-.16 4.23 2.37 8.67 4.33 12.94 6.64.04.32.13.97.18 1.29-4.3-2.4-8.78-4.47-13.15-6.73-2.03-1.16-4.41-1.05-6.66-1.2 6.52 3.52 13.22 6.7 19.82 10.05-2.99-.12-6.24.55-8.96-1.09-5.31-2.68-10.62-5.35-15.94-7.99.01 3.01.01 6.02.02 9.04-.52.01-1.57.02-2.09.03V18zM52.18 24.52c.2-.64.61-1.92.81-2.56l.84 2.53c.59-.21 1.78-.63 2.38-.83-.33.55-.99 1.66-1.31 2.22.55.33 1.67.99 2.22 1.33-.65.06-1.96.19-2.61.25l.32 2.62c-.46-.47-1.39-1.43-1.85-1.91l-1.8 1.96c.08-.67.23-2 .3-2.67l-2.65-.28c.74-.42 1.47-.83 2.21-1.24-.31-.57-.93-1.71-1.24-2.27.6.21 1.79.64 2.38.85zM6.23 41.41c.59-1.81 1.19-3.61 1.78-5.42.57 1.8 1.15 3.61 1.72 5.41l5.28-1.98c-1.02 1.57-2.05 3.13-3.07 4.69 1.59.97 3.19 1.95 4.78 2.93-1.86.15-3.72.29-5.59.44.26 1.86.52 3.72.8 5.58L6.9 49.11c-2.45-2.75-3.87-6.19-5.34-9.52 1.56.6 3.12 1.21 4.67 1.82z'
    />
    <path
      fill='#E4002B'
      d='M13.67 10.07c6.35-3.52 13-6.47 19.38-9.94.86.15 2.57.44 3.43.59-5.49 2.79-10.99 5.55-16.5 8.3-1.93 1.05-4.2.86-6.31 1.05z'
    />
    <path
      fill='#E4002B'
      d='M2.45 18.1c1.95-4.35 4.82-8.33 8.54-11.33 0 1.74.01 3.49.01 5.23h27.01c0 2-.01 4-.04 6H11v11.98c-2 .03-4 .03-6 0v-12.1c-.64.05-1.91.16-2.55.22z'
    />
    <path
      fill='#E4002B'
      d='M18.15 19.94c2.25.15 4.63.04 6.66 1.2 4.37 2.26 8.85 4.33 13.15 6.73.07.54.2 1.61.27 2.14l-.26-.02c-6.6-3.35-13.3-6.53-19.82-10.05z'
    />
  </svg>
)

export { Aud }
