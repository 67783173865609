import { gql, ApolloQueryResult } from 'apollo-boost'
import { Transaction, Stats } from './@types'

export const GET_TX = gql`
  query findUserDeposits(
    $status: TransactionStatus
    $tx_id: String
    $user_ids: String
    $create_from: String
    $create_to: String
    $fee_from: Float
    $fee_to: Float
    $currency: [CommonCurrencyName]
    $direction: TransactionDirection
    $deposit_from: Float
    $deposit_to: Float
    $deposit_usd_from: Float
    $deposit_usd_to: Float
    $deposit_eur_from: Float
    $deposit_eur_to: Float
    $page: Int
    $page_size: Int
  ) {
    findUserDeposits(
      data: {
        status: $status
        currency: $currency
        direction: $direction
        deposit_usd_from: $deposit_usd_from
        deposit_usd_to: $deposit_usd_to
        deposit_eur_from: $deposit_eur_from
        deposit_eur_to: $deposit_eur_to
        tx_id: $tx_id
        fee_from: $fee_from
        fee_to: $fee_to
        user_ids: $user_ids
        create_from: $create_from
        create_to: $create_to
        deposit_from: $deposit_from
        deposit_to: $deposit_to
        page: $page
        page_size: $page_size
      }
    ) {
      stats {
        currency
        totalDeposits
        totalDepositsUsd
        totalDepositsEur

        totalWithdrawals
        totalWithdrawalsUsd
        totalWithdrawalsEur

        totalNet
        totalNetUsd
        totalNetEur
      }
      total
      transactions {
        id
        company_id
        tx_id
        user_id
        foreign_id
        created_at
        currency
        direction
        value
        value_usd
        value_eur
        fee
        fee_usd
        fee_eur
        risk_score
        status
        gambling
      }
    }
  }
`

export type GetTxResponse = ApolloQueryResult<{
  findUserDeposits: {
    total: number
    transactions: Transaction[]
    stats: Stats[]
  }
}>
