export const getTrimming = <P>(payload: P) => {
  type Keys = keyof P
  const variablesArr = Object.keys(payload) as Keys[]

  const variables = variablesArr.reduce((acc, curr) => {
    const value = payload[curr]
    if (typeof value === 'string') {
      return { ...acc, [curr]: value.trim() }
    }
    return { ...acc, [curr]: value }
  }, {} as P)

  return variables
}
