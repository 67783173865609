import React from 'react'
import { Icon } from 'components'

import { Props } from './@types'
import { ToastWrapper, ToastText, CloseBtn } from './Style'

function ToastLayout({ type, text, onClose, children }: Props) {
  return (
    <ToastWrapper>
      <ToastText type={type}>{text}</ToastText>
      {children}

      {type === 'important' && (
        <CloseBtn type='link' onClick={onClose}>
          <Icon name='close' />
        </CloseBtn>
      )}
    </ToastWrapper>
  )
}

export { ToastLayout }
