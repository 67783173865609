import { combineReducers } from 'redux'

import { Actions, Toasts } from './@types'
import { UI_STATE } from './constants'

import { SHOW_TOAST, CLOSE_TOAST, CLOSE_ALL_TOAST } from './constants'

const initialState: Toasts = []

export const toasts = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SHOW_TOAST:
      const { name, container } = action.payload
      return [{ name, container }, ...state]
    case CLOSE_TOAST:
      const { name: modalName } = action.payload
      return state.filter(m => m.name !== modalName)
    case CLOSE_ALL_TOAST:
      return []
    default:
      return state
  }
}

export const rootReducer = combineReducers({
  [UI_STATE]: combineReducers({
    toasts,
  }),
})
