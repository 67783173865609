import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { openModal } from 'containers'

import { withInjectors } from '../injectors'
import { General as View } from '../components'
import { getLatestUpdateRequest, getCompanyRequest } from '../actions'
import { ClientState, Staffs, Company, Request } from '../@types'
import { selectCompany, selectCurrentRequest, selectStaffs } from '../selectors'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      getCompany: getCompanyRequest,
      getRequest: getLatestUpdateRequest,
    },
    dispatch,
  )

type Props = {
  staffs: Staffs
  company: Company
  request?: Request
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    staffs: selectStaffs(),
    company: selectCompany(),
    request: selectCurrentRequest('approach_currency'),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const GeneralContainer = compose<ComponentType>(withInjectors, withConnect)(View)
