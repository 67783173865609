export const DOMAIN = 'global'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_CURRENCIES = 'modules/auth/domain/SET_CURRENCIES'
export const GET_CURRENCIES_REQUEST = 'modules/auth/domain/GET_CURRENCIES_REQUEST'
export const GET_CURRENCIES_SUCCESS = 'modules/auth/domain/GET_CURRENCIES_SUCCESS'
export const GET_CURRENCIES_FAILURE = 'modules/auth/domain/GET_CURRENCIES_FAILURE'

export const SET_CRYPTO_CURRENCIES = 'modules/auth/domain/SET_CURRENCIES'
export const GET_CRYPTO_CURRENCIES_REQUEST = 'modules/auth/domain/GET_CRYPTO_CURRENCIES_REQUEST'
export const GET_CRYPTO_CURRENCIES_SUCCESS = 'modules/auth/domain/GET_CRYPTO_CURRENCIES_SUCCESS'
export const GET_CRYPTO_CURRENCIES_FAILURE = 'modules/auth/domain/GET_CRYPTO_CURRENCIES_FAILURE'

export const SET_PAIRS = 'modules/auth/domain/SET_PAIRS'
export const GET_PAIRS_REQUEST = 'modules/auth/domain/GET_PAIRS_REQUEST'
export const GET_PAIRS_SUCCESS = 'modules/auth/domain/GET_PAIRS_SUCCESS'
export const GET_PAIRS_FAILURE = 'modules/auth/domain/GET_PAIRS_FAILURE'

export const SET_CURRENCIES_FROM = 'modules/auth/domain/SET_CURRENCIES_FROM'
export const GET_CURRENCIES_FROM_FAILURE = 'modules/auth/domain/GET_CURRENCIES_FROM_FAILURE'
export const GET_CURRENCIES_FROM_REQUEST = 'modules/auth/domain/GET_CURRENCIES_FROM_REQUEST'
export const GET_CURRENCIES_FROM_SUCCESS = 'modules/auth/domain/GET_CURRENCIES_FROM_SUCCESS'
