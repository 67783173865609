import { FiatRequestInput } from '../@types'
import { NEW_FIAT_FAILURE, NEW_FIAT_REQUEST, NEW_FIAT_SUCCESS, NEW_FIAT_CLEAR } from '../constants'

export const newFiatClear = () => {
  return {
    type: NEW_FIAT_CLEAR,
  } as const
}

export const newFiatRequest = (params: FiatRequestInput) => {
  return {
    type: NEW_FIAT_REQUEST,
    payload: params,
  } as const
}

export const newFiatFailure = (errorMessage: string) => {
  return {
    type: NEW_FIAT_FAILURE,
    payload: { errorMessage },
  } as const
}

export const newFiatSuccess = () => {
  return {
    type: NEW_FIAT_SUCCESS,
  } as const
}
