import React from 'react'
import { links } from 'constant'
import { IconWrapper, LogoImg, StyledNavLink } from './Style'

export type LogoLink = {
  iconWidth?: string
  iconHeight?: string
}

export const LogoLink: React.FC<LogoLink> = ({ iconWidth = '176px', iconHeight = '56px' }) => (
  <StyledNavLink to={links.users}>
    <IconWrapper>
      <LogoImg className='logoImg' src='/logo.png' alt='Logo' width={iconWidth} height={iconHeight} />
    </IconWrapper>
  </StyledNavLink>
)
