export const currenciesMock: { [key in string]: string } = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  USDT: 'Tether',
  USDC: 'USD Coin',
  WBTC: 'Wrapped Bitcoin',
  DAI: 'Dai Stablecoin',
  PAX: 'Paxos Standard',
  LINK: 'ChainLink',
  TRX: 'TRON',
}
