import React from 'react'

const ExitIcon = () => (
  <svg viewBox='0 0 16 20'>
    <g id='admin-panel' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='1440_admin_logs' transform='translate(-1306.000000, -80.000000)' fill='currentColor' fillRule='nonzero'>
        <g id='Group-15-Copy' transform='translate(0.000000, 62.000000)'>
          <path
            d='M1306,28 C1306,28.5522847 1306.44772,29 1307,29 L1314.59,29 L1312.29,31.29 C1312.10069,31.4777666 1311.9942,31.7333625 1311.9942,32 C1311.9942,32.2666375 1312.10069,32.5222334 1312.29,32.71 C1312.47777,32.8993127 1312.73336,33.0057983 1313,33.0057983 C1313.26664,33.0057983 1313.52223,32.8993127 1313.71,32.71 L1317.71,28.71 C1317.80104,28.6148963 1317.87241,28.5027513 1317.92,28.38 C1318.02002,28.1365386 1318.02002,27.8634614 1317.92,27.62 C1317.87241,27.4972487 1317.80104,27.3851037 1317.71,27.29 L1313.71,23.29 C1313.45634,23.0363414 1313.08663,22.9372765 1312.74012,23.0301219 C1312.39362,23.1229674 1312.12297,23.3936179 1312.03012,23.740122 C1311.93728,24.086626 1312.03634,24.4563414 1312.29,24.71 L1314.59,27 L1307,27 C1306.44772,27 1306,27.4477153 1306,28 Z M1319,18 L1309,18 C1307.34315,18 1306,19.3431458 1306,21 L1306,24 C1306,24.5522847 1306.44772,25 1307,25 C1307.55228,25 1308,24.5522847 1308,24 L1308,21 C1308,20.4477153 1308.44772,20 1309,20 L1319,20 C1319.55228,20 1320,20.4477153 1320,21 L1320,35 C1320,35.5522847 1319.55228,36 1319,36 L1309,36 C1308.44772,36 1308,35.5522847 1308,35 L1308,32 C1308,31.4477153 1307.55228,31 1307,31 C1306.44772,31 1306,31.4477153 1306,32 L1306,35 C1306,36.6568542 1307.34315,38 1309,38 L1319,38 C1320.65685,38 1322,36.6568542 1322,35 L1322,21 C1322,19.3431458 1320.65685,18 1319,18 Z'
            id='Shape'
          />
        </g>
      </g>
    </g>
  </svg>
)

export { ExitIcon }
