import styled from 'styled-components'
import { color } from 'theme'

export const DataItem = styled.div`
  margin: 0 0 2rem;
  &:last-child {
    margin: 0;
  }
`

export const DataItemContent = styled.p`
  margin: 0;
  padding: 1rem;
  font-family: 'Lucida Console', 'Courier New', monospace;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.2;
  color: ${color.white};
  border: 0.1rem solid ${color.grey};
  border-radius: 0.6rem;
`
