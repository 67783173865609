import React, { useEffect, useState } from 'react'

import { generateString } from 'utils'

const EosIcon = () => {
  const [id, setId] = useState(generateString())
  useEffect(() => {
    setId(generateString())

    return () => {
      setId(generateString())
    }
  }, [setId])

  return (
    <svg viewBox='0 0 32 32'>
      <defs>
        <linearGradient id={id} x1='0%' y1='0%' y2='100%'>
          <stop offset='0%' stopColor='#ff6fd8' />
          <stop offset='100%' stopColor='#3813c2' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='nonzero'>
        <circle cx='16' cy='16' r='16' fill={`url(#${id})`} />
        <g fill='#fff'>
          <path d='M12.235 14.913l3.34-5.793-.002-4.163-4.476 6.321zM10.683 12.737L8.811 20.85l2.864-4.967zM20.945 11.28l-4.567-6.34.001 4.186 3.39 5.849zM8.571 22.922l5.328.002-1.865-5.96zM12.562 15.956l2.18 6.968h2.494l2.202-6.914-3.462-5.976zM22.781 23.732l-4.957-.003-1.147 3.603zM9.232 23.729l6.05 3.601-1.13-3.6zM18.08 22.925h5.365L20.76 18.29l-.777-1.336zM20.328 15.942l1.123 1.937 1.782 3.067-1.894-8.21zM15.983 26.857l.99-3.128h-1.93z' />
        </g>
      </g>
    </svg>
  )
}

export { EosIcon }
