import * as React from 'react'
import { FiatContainer } from './FiatRequest'
import { CryptoRequestContainer } from './CryptoRequest'

export function BalancesAll() {
  return (
    <>
      <FiatContainer />
      <br />
      <br />
      <CryptoRequestContainer />
    </>
  )
}
