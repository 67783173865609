import styled from 'styled-components'

export const CountDown = styled.span`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

export const CountDownItem = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
`
