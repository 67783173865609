import React, { Component } from 'react'

import {
  Input,
  Icon,
  Button,
  Select,
  Toggle,
  DatePickers,
  Range,
  Checkbox,
  IconMap,
  InputWrap,
  CheckboxWrap,
} from 'components'
import { Filter as Type, CommonCurrencies } from '../../@types'

import { MainWrapper, Row, Column, Label, ContainerButtons } from './Style'
import { Crypto, CryptoName, Element, GroupCheckbox } from '../../../Balances/components/CryptoFilter/Style'
import { ZERO } from 'constant'

// TODO: '' fixed
const initialState = {
  filters: {
    invoice_ids: '',
    crypto_currency: [],
    crypto_fee_from: '',
    crypto_fee_to: '',
    invoice_date_from: undefined,
    invoice_date_to: undefined,
    fiat_amount_from: '',
    fiat_amount_to: '',
    crypto_addresses: '',
    crypto_amount_from: '',
    crypto_amount_to: '',
    fiat_currency: '',
    tx_ids: '',
    status: '',
  },
  statusSelected: { value: '', label: 'All' },
}

type RangeParams = { from?: string; to?: string }

type Props = {
  cryptoCurrencies: CommonCurrencies
  setFilters: (filters: Type) => void
}

type Option = {
  value: string | number
  label: string
}

type State = {
  filters: Type
  statusSelected: Option
}

const options: Option[] = [
  { value: '', label: 'All' },
  { value: 'created', label: 'Сreated' },
  { value: 'paid', label: 'Paid' },
  { value: 'paid_after_expired', label: 'Paid after expired' },
  { value: 'expired', label: 'Expired' },
  { value: 'declined', label: 'Declined' },
]

class Filter extends Component<Props, State> {
  public state = initialState

  public render() {
    const { cryptoCurrencies } = this.props
    const {
      filters: {
        crypto_currency,
        invoice_ids,
        invoice_date_from,
        invoice_date_to,
        fiat_amount_from,
        fiat_amount_to,
        crypto_addresses,
      },
      statusSelected,
    } = this.state

    return (
      <MainWrapper>
        <Toggle title='Search'>
          <Row>
            <Column>
              <InputWrap>
                <Input
                  type='text'
                  name='invoice_ids'
                  value={invoice_ids}
                  placeholder='ID'
                  onChange={this.handleChange}
                  label='ID'
                />
              </InputWrap>
              <InputWrap>
                <Input
                  type='text'
                  label='Destination address'
                  placeholder='Address'
                  name='crypto_addresses'
                  value={crypto_addresses}
                  onChange={this.handleChange}
                />
              </InputWrap>
            </Column>

            <Column>
              <Label>Asset</Label>
              <GroupCheckbox>
                <CheckboxWrap>
                  <label htmlFor='allCoins'>
                    <Element>
                      All asset
                      <Checkbox
                        id='allCoins'
                        value={undefined}
                        onClick={this.handleSelectAllCurrency}
                        checked={!crypto_currency.length}
                      />
                    </Element>
                  </label>
                </CheckboxWrap>

                {cryptoCurrencies.map(coin => (
                  <CheckboxWrap key={coin.full_name}>
                    <label htmlFor={coin.full_name}>
                      <Element>
                        <Crypto>
                          <Icon name={coin.name.toLocaleLowerCase() as IconMap} size='medium' />
                          <CryptoName>{coin.full_name}</CryptoName>
                        </Crypto>

                        <Checkbox
                          id={coin.full_name}
                          value={coin.name}
                          onClick={this.handleSelectCryptoCurrency}
                          checked={crypto_currency.includes(coin.name as never)}
                        />
                      </Element>
                    </label>
                  </CheckboxWrap>
                ))}
              </GroupCheckbox>
            </Column>

            <Column>
              <InputWrap>
                <DatePickers
                  label='Date'
                  to={invoice_date_to}
                  nameTo='invoice_date_to'
                  from={invoice_date_from}
                  nameFrom='invoice_date_from'
                  setEndDate={this.handleChange}
                  setStartDate={this.handleChange}
                />
              </InputWrap>
              <InputWrap>
                <Label>Status</Label>
                <Select options={options} value={statusSelected.value} select={this.handleSelectStatus} />
              </InputWrap>
            </Column>

            <Column>
              <InputWrap>
                <Range
                  title='Fiat range'
                  endValue={fiat_amount_to}
                  startValue={fiat_amount_from}
                  setFilter={this.handleSetDepositUsd}
                />
              </InputWrap>
            </Column>
          </Row>

          <ContainerButtons>
            <Button onClick={this.handleGetClients}>Search</Button>
          </ContainerButtons>
        </Toggle>
      </MainWrapper>
    )
  }

  private handleGetClients = () => {
    const { setFilters } = this.props

    const { filters, statusSelected } = this.state
    const keysState = Object.keys(filters) as (keyof State['filters'])[]

    const dataFilters = { ...filters }

    keysState.forEach(key => {
      if (!dataFilters[key] && String(dataFilters[key]) !== String(ZERO)) {
        delete dataFilters[key]
      }
    })

    setFilters({
      ...dataFilters,
      status: statusSelected.value !== '' ? statusSelected.value : undefined,
    })
  }

  private handleSelectStatus = (todo: Option) => {
    this.setState(() => ({
      statusSelected: {
        label: todo.label,
        value: todo.value,
      },
    }))
  }

  private handleSelectAllCurrency = () => {
    this.handleSetFilters({ crypto_currency: [] })
  }

  private handleSelectCryptoCurrency = (value?: string) => {
    const {
      filters: { crypto_currency },
    } = this.state

    if (crypto_currency.includes(value as never)) {
      const filtered = crypto_currency.filter(i => i !== value)
      this.handleSetFilters({ crypto_currency: filtered })
    } else {
      this.handleSetFilters({ crypto_currency: [...crypto_currency, value] })
    }
  }

  private handleSetFilters = (data: {}) => {
    this.setState(({ filters }) => ({
      filters: { ...filters, ...data },
    }))
  }

  private handleChange = (value: string | Date, name?: string) => {
    if (name) {
      this.setState(prev => ({ ...prev, filters: { ...prev.filters, [name]: value } }))
    }
  }

  private handleSetDepositUsd = ({ from, to }: RangeParams) => {
    this.handleSetFilters({ fiat_amount_from: from, fiat_amount_to: to })
  }
}

export { Filter }
