import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'

import { AuthState } from '../@types'
import { signUpRequest } from '../actions'
import { withInjectors } from '../injectors'
import { SignUp as View } from '../components'
import { selectSignUpRequest } from '../selectors'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      signUp: signUpRequest,
    },
    dispatch,
  )

type Props = {
  signUpRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    signUpRequest: selectSignUpRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const SignUpContainer = compose<ComponentType>(withInjectors, withConnect)(View)
