import { gql, ApolloQueryResult } from 'apollo-boost'
import { Client, Stats } from './@types'

export const GET_CLIENTS = gql`
  query findUsers(
    $ids: String
    $register_from: String
    $register_to: String
    $address: String
    $net_from: Float
    $fee_from: Float
    $fee_to: Float
    $net_to: Float
    $withdraw_from: Float
    $withdraw_to: Float
    $deposit_from: Float
    $deposit_to: Float
    $page: Int
    $page_size: Int
  ) {
    findUsers(
      data: {
        fee_from: $fee_from
        fee_to: $fee_to
        ids: $ids
        net_to: $net_to
        net_from: $net_from
        page_size: $page_size
        register_from: $register_from
        register_to: $register_to
        address: $address
        withdraw_from: $withdraw_from
        withdraw_to: $withdraw_to
        deposit_from: $deposit_from
        deposit_to: $deposit_to
        page: $page
      }
    ) {
      stats {
        currency
        totalDeposits
        totalDepositsUsd
        totalDepositsEur

        totalWithdrawals
        totalWithdrawalsUsd
        totalWithdrawalsEur

        totalNet
        totalNetUsd
        totalNetEur
      }
      total
      users {
        id
        created_at
        foreign_id
        currency
        deposit
        deposit_count
        withdraw
        withdraw_count
        fee
        net
        rating
        address
        deposit_usd
        deposit_eur
        fee_usd
        fee_eur
        net_usd
        net_eur
      }
    }
  }
`

export type GetClientsResponse = ApolloQueryResult<{
  findUsers: {
    total: number
    users: Client[]
    stats: Stats[]
  }
}>
