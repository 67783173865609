import { SignInInput } from '../@types'
import { SIGN_IN_REQUEST, SIGN_IN_FAILURE, SIGN_IN_SUCCESS } from '../constants'

export const signInRequest = (params: SignInInput) => {
  return {
    type: SIGN_IN_REQUEST,
    payload: params,
  } as const
}

export const signInFailure = (errorMessage: string) => {
  return {
    type: SIGN_IN_FAILURE,
    payload: { errorMessage },
  } as const
}

export const signInSuccess = () => {
  return {
    type: SIGN_IN_SUCCESS,
  } as const
}
