import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { newFiatRequest } from './NewFiat'
import { newCryptoRequest } from './NewCrypto'

import { fiatRequests, getFiatRequests } from './FiatRequest'
import { cryptoRequests, getCryptoRequests } from './CryptoRequest'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    fiatRequests,
    cryptoRequests,
  }),
  [DOMAIN_STATE]: combineReducers({
    getFiatRequests,
    newFiatRequest,
    getCryptoRequests,
    newCryptoRequest,
  }),
})
