import { gql, ApolloQueryResult } from 'apollo-boost'

export const ADD_STAFF = gql`
  mutation createCompanyWorker(
    $email: String!
    $name: String!
    $password_hash: String!
    $role_name: CompanyStaffRoleName
  ) {
    createCompanyWorker(data: { email: $email, name: $name, password_hash: $password_hash, role_name: $role_name }) {
      otp_secret
    }
  }
`

export type AddStaffResponse = ApolloQueryResult<{
  createCompanyWorker: {
    otp_secret: string
  }
}>

export const REMOVE_USER = gql`
  mutation deleteWorker($id: Int!) {
    deleteWorker(data: { id: $id })
  }
`

export type RemoveUserResponse = ApolloQueryResult<{
  deleteWorker: boolean
}>

export const RESET_API = gql`
  mutation forcedCreateCompanyKeyPair {
    forcedCreateCompanyKeyPair
  }
`

export type ResetApiResponse = ApolloQueryResult<{
  forcedCreateCompanyKeyPair: boolean
}>

export const SET_ONBOARDING = gql`
  mutation putCompanyAdditionalInfo(
    $btc_address: String!
    $eth_address: String!
    $trx_address: String!
    $callback_url: String!
    $approach_currency: CommonCurrencyName!
    $bank_detail_usd: String
    $bank_detail_eur: String
  ) {
    putCompanyAdditionalInfo(
      data: {
        btc_address: $btc_address
        bank_detail_eur: $bank_detail_eur
        bank_detail_usd: $bank_detail_usd
        eth_address: $eth_address
        trx_address: $trx_address
        callback_url: $callback_url
        approach_currency: $approach_currency
      }
    )
  }
`

export type SetDataResponse = ApolloQueryResult<{
  putCompanyAdditionalInfo: boolean
}>

export const CHANGE_COMPANY_DETAILS = gql`
  mutation updateCompanyAdditionalInfo($detail_type: ChangeCompanyDetail!, $detail_new_value: String!) {
    updateCompanyAdditionalInfo(data: { detail_type: $detail_type, detail_new_value: $detail_new_value })
  }
`

export type ChangeCompanyDetailsResponse = ApolloQueryResult<{
  updateCompanyAdditionalInfo: boolean
}>
