export const FIRST_EL_ARRAY = 0
export const PAGE = 1
export const FIAT_DECIMALS = 2
export const CRYPTO_DECIMALS = 8
export const PAGE_SIZE_DEFAULT = 10
export const ZERO = 0

export const LOW_DEBOUNCE = 500

const SERVER_ERROR = 500
const BAD_GATEWAY = 502
const GATEWAY_TIMEOUT = 504
const SERVICE_UNAVAILABLE = 503

export const USD_PREFIX = '$'
export const EUR_PREFIX = '€'

export const EMAIL_NEW_CLIENT = 'newclient@payler.com'
export const EMAIL_REQUEST = 'request@payler.com'
export const WALLET_EMAIL_REQUEST = 'request@payler.com'
export const TELEGRAM_SUPPORT = 'https://t.me/payler'

export const TERMS = 'https://static.payler.com/terms_of_use.pdf'
export const PRIVACYPOLICY = 'https://static.payler.com/privacy_policy.pdf'
export const B2BAPPLICATION = 'https://static.payler.com/application_form.pdf'
export const FIXEDFLOATRATEREQUEST = 'https://static.payler.com/rate.pdf'
export const CHANGECRYPTOCURRENCYWITHDRAWAL = 'https://static.payler.com/withdrawal.pdf'
export const CHANGEOFBANKACCOUNTDETAILS = 'https://static.payler.com/withdrawal.pdf'

export const serverErrors = {
  SERVER_ERROR,
  BAD_GATEWAY,
  GATEWAY_TIMEOUT,
  SERVICE_UNAVAILABLE,
}
