import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { openModal } from 'containers/Modal'
import { CurrenciesInjectors } from 'modules/Global'

import { RequestState } from '@types'
import { withInjectors } from '../injectors'
import { Invoices as View } from '../components'
import { InvoiceState, Invoices } from '../@types'
import { selectInvoices, selectGetInvoices } from '../selectors'
import { getInvoicesRequest, selectPageSize, selectPage, setTxFilters } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      selectPage,
      selectPageSize,
      setFilters: setTxFilters,
      getData: getInvoicesRequest,
    },
    dispatch,
  )

type Props = {
  request: RequestState
  invoices: Invoices
}

const mapStateToProps = () =>
  createStructuredSelector<InvoiceState, Props>({
    invoices: selectInvoices(),
    request: selectGetInvoices(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const InvoicesContainer = compose<ComponentType>(CurrenciesInjectors, withInjectors, withConnect)(View)
