import { RequestState } from '@types'
import { Actions, Requests } from '../@types'
import {
  SET_DETAILS,
  CHANGE_DETAIL_CLEAR,
  CHANGE_DETAIL_SUCCESS,
  CHANGE_DETAIL_REQUEST,
  CHANGE_DETAIL_FAILURE,
  GET_LATEST_UPDATE_SUCCESS,
  GET_LATEST_UPDATE_REQUEST,
  GET_LATEST_UPDATE_FAILURE,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getLatestUpdateRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_LATEST_UPDATE_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_LATEST_UPDATE_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_LATEST_UPDATE_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

export const changeDetailRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case CHANGE_DETAIL_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case CHANGE_DETAIL_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case CHANGE_DETAIL_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    case CHANGE_DETAIL_CLEAR:
      return initialDomainState
    default:
      return state
  }
}

const initialUiState: Requests = []

export const requests = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_DETAILS:
      const { data } = action.payload
      return data
    default:
      return state
  }
}
