import styled from 'styled-components'

const InputWrap = styled.div<{ noMargin?: boolean }>`
  margin: 0 0 24px;
  &:last-child {
    margin: 0;
  }
  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`

export { InputWrap }
