import { color } from 'theme'

const ACCEPT_STATUSES = ['sent', 'complete', 'approved', 'paid', 'checked', 'completed', 'true', 'paid after expired']
const DANGER_STATUSES = ['created', 'pending', 'checking', 'processing', 'transferring']
const WARNING_STATUSES = ['expired', 'declined', 'failed', 'false']

export const getStatus = (status?: string) => {
  if (!status) return null

  if (ACCEPT_STATUSES.includes(status)) {
    return `color: ${color.success}; background-color: ${color.kiwi};`
  }

  if (DANGER_STATUSES.includes(status)) {
    return `color: ${color.danger}; background-color: ${color.blood};`
  }

  if (WARNING_STATUSES.includes(status)) {
    return `color: ${color.warning}; background-color: ${color.dirtyYellow};`
  }

  return null
}
