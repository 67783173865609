import styled from 'styled-components'
import { Button } from 'components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`

export const WrapBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CancelBtn = styled(Button)`
  max-width: 160px;
`

export const CustomBtn = styled(Button)`
  max-width: 160px;
`
