import React, { Fragment } from 'react'

import { USD_PREFIX, EUR_PREFIX } from 'constant'
import { DateComponent, DecimalsFixed, Status } from 'components'

function StatusComponent(value: string) {
  return <Status status={value} />
}

function FiatComponent(value = '0', currency: string) {
  return (
    <Fragment>
      {DecimalsFixed(value, 'fiat')} {(currency === 'USD' && USD_PREFIX) || (currency === 'EUR' && EUR_PREFIX)}
    </Fragment>
  )
}

export const COLUMNS = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Currency',
    key: 'currency_name',
  },
  {
    title: 'Amount',
    key: 'amount',
    render: (props: string, render: { currency_name: string }) => FiatComponent(props, render.currency_name),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => StatusComponent(props),
  },
]
