import React, { Fragment } from 'react'

import { currenciesMock } from 'constant'
import { Icon, DecimalsFixed, DateComponent, Status, TransactionLink } from 'components'

import { CryptoElement, CryptoName } from './Style'

function CoinComponent(value: string) {
  return (
    <CryptoElement>
      <Icon
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        name={value.toLowerCase()}
        size='small'
      />
      <CryptoName>{currenciesMock[value]}</CryptoName>
    </CryptoElement>
  )
}

function AddressLinkComponent(value: string, currency: string) {
  const url = currency === 'BTC' ? 'https://www.blockchain.com/btc/address' : 'https://etherscan.io/address'
  const link = `${url}/${value}`
  return <Fragment>{value ? <TransactionLink link={link} value={value} /> : <Fragment>-</Fragment>}</Fragment>
}

function AmountComponent(value: string, currency: string) {
  return (
    <span>
      {DecimalsFixed(value, 'crypto')} {currency}
    </span>
  )
}

function StatusComponent(value: string) {
  return <Status status={value} />
}

export const COLUMNS = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Asset',
    key: 'currency_name',
    render: (props: string) => CoinComponent(props),
  },
  {
    title: 'Amount',
    key: 'amount',
    render: (props: string, render: { currency_name: string }) => AmountComponent(props, render.currency_name),
  },
  {
    title: 'Sent to',
    key: 'address',
    render: (props: string, render: { currency_name: string }) => AddressLinkComponent(props, render.currency_name),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => StatusComponent(props),
  },
]
