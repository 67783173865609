import React from 'react'
import moment from 'moment'

import { ColumnElements } from 'components'
import { Date, Time } from './Style'

export function DateComponent(value: string) {
  return (
    <ColumnElements>
      {value ? (
        <>
          <Date>{moment(value).format('DD/MM/YY')}</Date>
          <Time>{moment(value).format('HH:mm:ss')}</Time>
        </>
      ) : (
        '—'
      )}
    </ColumnElements>
  )
}
