import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { logOut } from '../actions'
import { AuthState } from '../@types'
import { selectAuth } from '../selectors'
import { withInjectors } from '../injectors'
import { Header as View } from '../components'

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ logOut }, dispatch)

type Props = {
  auth: boolean
}

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    auth: selectAuth(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const HeaderContainer = compose<ComponentType>(withInjectors, withConnect)(View)
