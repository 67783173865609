import { Component, ReactChild, ReactNode } from 'react'
import ReactDOM from 'react-dom'

type Props = {
  children: ReactChild | ReactNode
}

class Portal extends Component<Props> {
  private readonly el: HTMLDivElement
  private readonly portal: HTMLElement | null

  public constructor(props: Props) {
    super(props)

    this.el = document.createElement('div')
    this.portal = document.getElementById('portal')
  }

  public componentDidMount(): void {
    if (this.portal) {
      this.portal.appendChild(this.el)
    }
  }

  public componentWillUnmount(): void {
    if (this.portal) {
      this.portal.removeChild(this.el)
    }
  }

  public render() {
    const { children } = this.props
    return ReactDOM.createPortal(children, this.el)
  }
}

export { Portal }
