import React, { useEffect, useState } from 'react'

import { Button, Icon, RadioItem, Select, SelectOptions, InputWrap } from 'components'

import {
  Title,
  Text,
  Label,
  SelectApproach,
  BankDetails,
  CustomTextarea,
  CryptoAddresses,
  Address,
  AddressCurrency,
  Currency,
  AddressInput,
  CallbackUrl,
  CustomInput,
  BtnContainer,
} from './Style'
import { CommonCurrencyName } from 'modules/Settings/@types'

type CurrencyOptions = { value: CommonCurrencyName; label: CommonCurrencyName }[]
const btcStyle = { fontSize: 13 }
type Props = {
  btc: string
  eth: string
  trx: string
  url: string
  detail: string
  rate: string
  selectedCurrency: CommonCurrencyName
  currencies: CommonCurrencyName[]
  onClick: (params: {
    btc: string
    trx: string
    detail: string
    eth: string
    url: string
    rate: string
    currency: CommonCurrencyName
  }) => void

  request: {
    isFetching: boolean
    isError: boolean
    errorMessage: string
  }
}

function FillForm({ onClick, request, btc, eth, trx, rate, detail, url, selectedCurrency, currencies }: Props) {
  const [bankComment, setBankComment] = useState(detail)
  const [addressBtc, setBtc] = useState<string>(btc)
  const [addressEth, setEth] = useState<string>(eth)
  const [addressTrx, setTrx] = useState<string>(trx)
  const [callUrl, setUrl] = useState<string>(url)
  const [isRate, setRate] = useState<string>(rate)
  const [commonCurrency, setCommonCurrency] = useState<CommonCurrencyName>(selectedCurrency)
  const [options, setOptions] = useState<CurrencyOptions>([])
  const [isError, setError] = useState(false)

  const handleClick = () => {
    if ((!bankComment && isRate !== 'float') || !addressBtc || !addressEth || !addressTrx || !callUrl) {
      setError(true)

      const TIME = 800
      setTimeout(() => {
        setError(false)
      }, TIME)

      return
    }

    onClick({
      detail: bankComment,
      btc: addressBtc,
      eth: addressEth,
      trx: addressTrx,
      url: callUrl,
      rate: isRate,
      currency: commonCurrency,
    })
  }

  const getCurrencySelectOptions = (currencies: CommonCurrencyName[]): CurrencyOptions => {
    const options: CurrencyOptions = currencies.map(currency => {
      return {
        value: currency,
        label: currency,
      }
    })

    return options
  }

  useEffect(() => {
    if (currencies?.length) {
      setOptions(getCurrencySelectOptions(currencies))
    }
  }, [currencies])

  const handleSelectCurrency = (selectedOption: SelectOptions<CommonCurrencyName>) => {
    const { value } = selectedOption
    setCommonCurrency(value)
  }

  return (
    <form noValidate>
      <SelectApproach>
        <Title>Choose the workflow</Title>

        <RadioItem value='float' selected={isRate} onClick={setRate}>
          <Title error={isError && !isRate}>Flexible workflow.</Title>
          <Text noMargin>
            Deposits and withdrawals are only in crypto. You can change the selected workflow at any time.
          </Text>
        </RadioItem>

        <RadioItem value='fix_eur' selected={isRate} onClick={setRate}>
          <Title error={isError && !isRate}>Autoexchange workflow.</Title>
          <Text noMargin>
            All incoming crypto will be converted to EUR by current market rate. You can change the selected workflow at
            any time.
          </Text>
        </RadioItem>
        {isRate === 'fix_eur' && (
          <InputWrap>
            <Label>Select currency</Label>
            <Select value={commonCurrency} select={handleSelectCurrency} options={options} />
          </InputWrap>
        )}
      </SelectApproach>

      {isRate === 'fix_eur' && (
        <BankDetails>
          <Title>Bank details</Title>
          <Text>To&nbsp;the following bank details we&nbsp;will make withdrawals.</Text>

          <CustomTextarea value={bankComment} onChange={setBankComment} error={isError && !bankComment} crossError />
        </BankDetails>
      )}

      <CryptoAddresses>
        <Title>Blockchain addresses</Title>
        <Text>To these addresses we will send your funds. You will be able to change them at any moment.</Text>

        <Address>
          <AddressCurrency>
            <Icon name='btc' />
            <Currency style={btcStyle}>btc</Currency>
            {/* TODO: btc не влезало в блок, костыль нужно будет выпилить */}
          </AddressCurrency>
          <AddressInput
            type='text'
            error={isError && !addressBtc}
            value={addressBtc}
            placeholder='Type BTC address here'
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onChange={setBtc}
            crossError
          />
        </Address>

        <Address>
          <AddressCurrency>
            <Icon name='eth' />
            <Currency>eth</Currency>
          </AddressCurrency>
          <AddressInput
            type='text'
            error={isError && !addressEth}
            value={addressEth}
            placeholder='Type ETH address here'
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onChange={setEth}
            crossError
          />
        </Address>

        <Address>
          <AddressCurrency>
            <Icon name='trx' />
            <Currency>trx</Currency>
          </AddressCurrency>
          <AddressInput
            type='text'
            error={isError && !addressTrx}
            value={addressTrx}
            placeholder='Type TRX address here'
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onChange={setTrx}
            crossError
          />
        </Address>
      </CryptoAddresses>

      <CallbackUrl>
        <Title>Callback URL</Title>
        <Text>We will send to this address webhooks.</Text>
        <CustomInput
          type='text'
          value={callUrl}
          error={(isError && !callUrl) || request.isError}
          placeholder='Type callback URL here'
          errorMessage={request.errorMessage}
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          onChange={setUrl}
          crossError
        />
      </CallbackUrl>

      <BtnContainer>
        <Button isLoading={request.isFetching} onClick={handleClick}>
          Continue
        </Button>
      </BtnContainer>
    </form>
  )
}

export { FillForm }
