import React, { Component } from 'react'

import { RequestState } from '@types'
import { ChangeDetails, DetailType } from '../../@types'
import { notify } from 'containers/Notification/notify'

import { Input, KeysListener } from 'components'
import { CurrentAddress, CancelBtn, CustomBtn, WrapBtn } from './Style'

type State = {
  value: string
}

type Props = {
  type: DetailType
  currentValue: string
  request: RequestState
  clearState: () => void
  closeAllModal: () => void
  changeDetails: (data: ChangeDetails) => void
}

class NewWarningRequest extends Component<Props, State> {
  public state = {
    value: '',
  }

  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public componentDidUpdate() {
    const { request, closeAllModal } = this.props

    if (request.isSuccess) {
      notify({ text: 'Callback URL has been changed.' })
      closeAllModal()
    }
  }

  public handleValueChange = (value: string) => {
    this.setState(() => ({ value }))
  }

  public render() {
    const { currentValue, closeAllModal, request } = this.props

    return (
      <KeysListener handleSubmit={this.handleCreate}>
        <CurrentAddress>
          <Input value={currentValue} label='Current Callback URL' disabled />
        </CurrentAddress>

        <CurrentAddress>
          <Input
            label='New Callback URL'
            error={request.isError}
            placeholder='Paste new url here'
            onChange={this.handleValueChange}
            errorMessage={request.errorMessage}
          />
        </CurrentAddress>

        <WrapBtn>
          <CancelBtn type='ghost' size='medium' onClick={closeAllModal}>
            Cancel
          </CancelBtn>
          <CustomBtn type='warning' size='medium' isLoading={request.isFetching} onClick={this.handleCreate}>
            Confirm
          </CustomBtn>
        </WrapBtn>
      </KeysListener>
    )
  }

  public handleCreate = () => {
    const { changeDetails, type } = this.props
    const { value } = this.state

    if (!value) {
      const errorText = 'This action is not available, please check the completed fields'

      notify({ type: 'error', text: errorText })
      return
    }

    changeDetails({
      detail_type: type,
      detail_new_value: value,
    })
  }
}

export { NewWarningRequest }
