import { all, fork } from 'redux-saga/effects'

import { saga as Create } from './Create'
import { saga as Invoices } from './Invoices'
import { saga as CheckStatus } from './CheckStatus'

const InvoicesSagas = [CheckStatus, Create, Invoices]

export function* saga() {
  return yield all([...InvoicesSagas.map(fork)])
}
