import { localStorage } from './localStorage'
import { config } from 'config'

const serverUrl = process.env.NODE_ENV === 'development' ? 'https://my.test.cryptopayments.pro/api' : config.backend.url

type MethodType = 'GET' | 'POST' | 'PUT' | 'UPDATE' | 'DELETE'

const makeFileRequest = async (url: string, method: MethodType, body?: any) => {
  const { jwt } = localStorage.getItem(localStorage.getKey())

  const token = jwt
  const commonHeaders: HeadersInit = new Headers()
  commonHeaders.set('Content-Type', 'application/json')

  commonHeaders.append('auth', token)

  const fetchConfig: any = { method, headers: commonHeaders }
  if (body) fetchConfig.body = JSON.stringify(body)
  const response = await fetch(`${serverUrl}${url}`, fetchConfig)

  if (response.status > 300) {
    const data = await response?.json()
    return Promise.reject(data)
  }
  const data = await (response.status !== 204 ? await response?.blob() : undefined)
  commonHeaders.delete('auth')
  return data
}

export default makeFileRequest
