import React from 'react'

const Rub = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#fff'
      d='M28.54 0h3.05c11.87.57 22.88 8.73 26.72 19.98-18.87.04-37.75.02-56.63.02C5.55 8.7 16.61.5 28.54 0z'
    />
    <path
      fill='#0039A6'
      d='M0 28.53c.2-2.9.81-5.76 1.68-8.53 18.88 0 37.76.02 56.63-.02.89 2.74 1.45 5.58 1.69 8.45v3.27c-.25 2.82-.82 5.61-1.68 8.3-18.88 0-37.76-.01-56.64.01C.79 37.26.23 34.4 0 31.52v-2.99z'
    />
    <path
      fill='#D52B1E'
      d='M1.68 40.01c18.88-.02 37.76-.01 56.64-.01-3.86 11.27-14.86 19.44-26.75 20h-3.23C16.5 59.39 5.53 51.25 1.68 40.01z'
    />
  </svg>
)

export { Rub }
