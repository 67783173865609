import React, { Fragment } from 'react'

import { CurrencyCard, IconMap } from 'components'
import { Container, Title, Wrap } from './Style'

type Props = {
  total: {
    currency: string
    totalDeposits: number
    totalDepositsEur: number
    totalWithdrawals: number
    totalWithdrawalsEur: number
    totalNet: number
    totalNetUsd: number
    totalNetEur: number
  }[]
  loading: boolean
}

function Stats({ total, loading }: Props) {
  return (
    <Fragment>
      <Container>
        <Title>Total NET</Title>
      </Container>
      <Wrap>
        {total.map(item => {
          const icon = item.currency.toLowerCase() as IconMap
          return <CurrencyCard key={item.currency} iconName={icon} loading={loading} {...item} />
        })}
      </Wrap>
    </Fragment>
  )
}

export { Stats }
