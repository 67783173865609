import React, { useState } from 'react'

import { CustomLink, Icon, LogoLink } from 'components'
import { commonLinks, privateLinks } from './Links'

import { Action, GlobalStyle, IconWrapper, List, MenuToggle, Nav, SideContainer, SideFooter, SideNav } from './Style'

type Props = {
  auth: boolean
  logOut: () => void
}

const Header = ({ auth, logOut }: Props) => {
  const links = auth ? privateLinks : commonLinks
  const [side, setSide] = useState(false)

  const onSide = () => {
    setSide(!side)
    document.body.setAttribute('data-side', String(side))
  }

  return (
    <SideNav>
      <GlobalStyle />
      <SideContainer>
        <Action>
          <LogoLink iconWidth='152px' iconHeight='inherit' />
          <MenuToggle onClick={onSide}>
            <Icon name='menu' size='auto' />
          </MenuToggle>
        </Action>
        <Nav>
          <List>
            {links.map(link => (
              <CustomLink {...link} key={link.title} />
            ))}
          </List>
        </Nav>
        {auth ? (
          <SideFooter>
            <IconWrapper onClick={logOut}>
              <Icon name='exit' size='small' />
              <span className='link-title'>Log Out</span>
            </IconWrapper>
          </SideFooter>
        ) : null}
      </SideContainer>
    </SideNav>
  )
}

export { Header }
