// TODO have to implements configure
import { Reducer } from 'redux'
import { createReducer } from 'store/RootReducer'
import { InjectedSaga, ReduxInjectorStore } from './@types'
import { SAGA_MODE_DAEMON, SAGA_MODE_ONCE_TILL_UNMOUNT } from './constants'

export const reducerInjector = <ApplicationState>(store: ReduxInjectorStore<ApplicationState>) =>
  function injectReducer<K extends string, State>(
    key: Exclude<K, keyof ApplicationState>,
    reducer: Reducer<ApplicationState>,
  ) {
    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) {
      return
    }

    store.injectedReducers[key] = reducer

    const injectedReducers = createReducer<ApplicationState>(store.injectedReducers)
    store.replaceReducer(injectedReducers)
  }

export const sagaInjector = <ApplicationState>(store: ReduxInjectorStore<ApplicationState>) =>
  function injectSaga(key: string, descriptor: InjectedSaga, args: {}) {
    const modifiedDescriptor = {
      ...descriptor,
      mode: descriptor.mode || SAGA_MODE_DAEMON,
      done: false,
    }

    const { saga, mode } = modifiedDescriptor

    let hasSaga = Reflect.has(store.injectedSagas, key)

    const oldDescriptor = store.injectedSagas[key]

    if (hasSaga && oldDescriptor.saga !== saga && oldDescriptor.task) {
      oldDescriptor.task.cancel()
      hasSaga = false
    }

    if (!hasSaga || (hasSaga && mode !== SAGA_MODE_DAEMON && mode !== SAGA_MODE_ONCE_TILL_UNMOUNT)) {
      store.injectedSagas[key] = {
        ...modifiedDescriptor,
        task: store.runSaga(saga, args),
      }
    }
  }

export const sagaEjector = <ApplicationState>(store: ReduxInjectorStore<ApplicationState>) =>
  function ejectSaga(key: string) {
    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key] as InjectedSaga & {
        task: { cancel: () => void }
      }

      if (descriptor.mode && descriptor.mode !== SAGA_MODE_DAEMON) {
        descriptor.task.cancel()

        if (process.env.NODE_ENV === 'production') {
          store.injectedSagas[key].done = true
        }
      }
    }
  }

export default <ApplicationState>(store: ReduxInjectorStore<ApplicationState>) => ({
  injectSaga: sagaInjector(store),
  ejectSaga: sagaEjector(store),
  injectReducer: reducerInjector(store),
})
