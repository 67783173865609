import styled from 'styled-components'
import { font, color } from 'theme'
import { Button } from 'components'

export const Copied = styled.span`
  display: block;
  width: 100%;
  padding: 12px;
  font-family: ${font.display};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  color: ${color.success};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  z-index: 2;
`

export const CopyBtn = styled(Button)`
  padding: 0;
  min-width: auto;
`
