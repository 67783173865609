import React from 'react'

const EurIcon = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <rect width='20' height='20' fill='#073394' rx='10' />
    <path
      fill='#f8cd46'
      d='M16.982 8.96l.238.733h.77l-.623.453.238.733-.623-.453-.624.453.238-.733-.623-.453h.77l.239-.733zM16.054 12.435l.238.733h.77l-.623.453.238.733-.623-.453-.624.453.238-.733-.623-.453h.77l.239-.733zM13.503 14.988l.239.733h.77l-.623.454.238.733-.624-.453-.623.453.238-.733-.624-.454h.771l.238-.733zM9.994 15.92l.238.734h.77l-.623.453.239.733-.624-.453-.624.453.238-.733-.623-.453h.77l.239-.734zM6.519 14.976l.238.733h.77l-.623.454.238.733-.623-.453-.624.453.238-.733-.623-.454h.77l.239-.733zM3.982 12.423l.238.733h.771l-.624.453.239.733-.624-.453-.624.453.239-.733-.624-.453h.77l.239-.733zM3.061 8.905l.238.733h.771l-.624.454.239.733-.624-.453-.624.453.239-.733-.624-.454h.77l.239-.733zM4.007 5.455l.238.733h.77l-.623.453.238.733-.623-.453-.624.453.238-.733-.623-.453h.77l.239-.733zM6.563 2.918l.238.733h.771l-.624.453.239.733-.624-.453-.624.453.239-.733-.624-.453h.77l.239-.733zM10.103 2l.239.733h.77l-.623.453.238.733-.624-.453-.623.453.238-.733-.624-.453h.771L10.103 2zM13.542 2.954l.238.734h.771l-.623.453.238.733-.624-.453-.623.453.238-.733-.624-.453h.77l.24-.734zM16.078 5.528l.239.733h.77l-.623.454.238.733-.624-.453-.623.453.238-.733-.624-.454h.771l.238-.733z'
    />
  </svg>
)

export { EurIcon }
