import React, { Component } from 'react'

import { FormContainer } from '../../containers'
import { MainWrapper } from './Style'

class Exchange extends Component {
  public render() {
    return (
      <MainWrapper>
        <FormContainer />
      </MainWrapper>
    )
  }
}

export { Exchange }
