import React, { Fragment } from 'react'

import moment from 'moment'
import { Href, Button } from 'components'
import { openModal } from 'containers/Modal'

import { store } from 'store'
import { WebHooksData } from '../../modals'

import { StyledDate } from './Style'

type Resend = (value: number) => void

const URL = (url: string) => {
  return <Fragment>{url ? <Href href={url}>Call Back URL</Href> : ''}</Fragment>
}

const Date = (value: string) => {
  const day = moment(value)
    .format('yyyy-MM-DD')
    .valueOf()

  const time = moment(value)
    .format('HH:mm:ss')
    .valueOf()

  return (
    <StyledDate>
      <span>{day}</span>
      <span>{time}</span>
    </StyledDate>
  )
}

const Resend = (value: number, onResend: Resend) => {
  const resendFunctions = () => {
    onResend(value)
  }

  return (
    <Button size='small' type='link' onClick={resendFunctions}>
      resend
    </Button>
  )
}

const ShowModal = (header?: string, body?: string, type?: string) => {
  const onHandleClick = () => {
    store.dispatch(openModal(WebHooksData({ header, body })))
  }

  return (
    <Button size='small' onClick={onHandleClick}>
      Show {type || ''} data
    </Button>
  )
}

export const COLUMNS = (onResend: Resend) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: URL,
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    key: 'created_at',
    render: Date,
  },
  {
    title: 'Request',
    dataIndex: 'request_headers',
    key: 'request_headers',
    render: (data: string, render: { request_headers?: string; request_body?: string }) =>
      ShowModal(render.request_headers, render.request_body, 'request'),
  },
  {
    title: 'Response',
    dataIndex: 'response_headers',
    key: 'response_headers',
    render: (data: string, render: { response_headers?: string; response_body?: string }) =>
      ShowModal(render.response_headers, render.response_body, 'response'),
  },
  {
    width: 50,
    title: 'Resend',
    dataIndex: 'id',
    key: 'id',
    render: (value: number) => Resend(value, onResend),
  },
]
