import React from 'react'
import { GetProps } from 'react-redux'

import { createModal } from 'containers/Modal'
import { NewWarningRequestContainer } from '../containers'

type Props = GetProps<typeof NewWarningRequestContainer>

const containerWithProps = (props: Props) => <NewWarningRequestContainer {...props} />

const NewWarningRequest = (props: Props) =>
  createModal({
    type: 'warning',
    name: 'SET_DATA',
    title: 'Change callback URL',
    container: containerWithProps(props),
  })

export { NewWarningRequest }
