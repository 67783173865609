import { RequestState } from '@types'
import { getPageCount } from 'utils'

import { Actions, Transactions } from '../@types'
import {
  SET_TX,
  SET_TX_FILTERS,
  GET_TX_SUCCESS,
  GET_TX_FAILURE,
  GET_TX_REQUEST,
  SELECT_TX_PAGE,
  SELECT_TX_PAGE_SIZE,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getTransactions = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_TX_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_TX_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_TX_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const dataBody = {
  totalDeposits: 0,
  totalDepositsUsd: 0,
  totalDepositsEur: 0,
  totalWithdrawals: 0,
  totalWithdrawalsUsd: 0,
  totalWithdrawalsEur: 0,
  totalNet: 0,
  totalNetUsd: 0,
  totalNetEur: 0,
}
const initialUiState: Transactions = {
  pageSize: 10,
  currentPage: 1,
  pageCount: 1,
  data: [],
  filters: {},
  stats: [
    { currency: 'USDT', ...dataBody },
    { currency: 'PAX', ...dataBody },
    { currency: 'BTC', ...dataBody },
    { currency: 'ETH', ...dataBody },
  ],
}

export const transactions = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_TX:
      const { stats, data, total } = action.payload
      const pageCount = getPageCount(total, state.pageSize)

      return { ...state, total, stats, data, pageCount }
    case SELECT_TX_PAGE:
      const { currentPage } = action.payload
      return { ...state, currentPage }
    case SELECT_TX_PAGE_SIZE:
      const { pageSize } = action.payload
      return { ...state, pageSize }
    case SET_TX_FILTERS:
      const { filters } = action.payload
      return { ...state, currentPage: 1, filters }
    default:
      return state
  }
}
