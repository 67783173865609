import { compose } from 'redux'
import { injectReducer, injectSaga, SAGA_MODE_DAEMON } from 'lib/redux-injector'

import { saga } from '../sagas'
import { reducer } from '../reducers'
import { DOMAIN } from '../constants'

const withSaga = injectSaga({
  key: DOMAIN,
  saga,
  mode: SAGA_MODE_DAEMON,
})

const withReducer = injectReducer({
  key: DOMAIN,
  reducer,
})

export const withInjectors = compose(withReducer, withSaga)
