const START = 0
const END = 4

export function shortString(str: string, start = START, end = END) {
  if (str) {
    return str.substr(start, end) + '...' + str.substr(str.length - end)
  }

  return '—'
}
