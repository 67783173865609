import { FiatRequest, Stats, FilterFiatRequest } from '../@types'
import {
  SET_FIAT,
  GET_FIAT_FAILURE,
  GET_FIAT_REQUEST,
  GET_FIAT_SUCCESS,
  SET_FIAT_FILTERS,
  SELECT_FIAT_PAGE,
  SELECT_FIAT_PAGE_SIZE,
} from '../constants'

export const getFiatRequest = () => {
  return {
    type: GET_FIAT_REQUEST,
  } as const
}

export const getFiatFailure = (errorMessage: string) => {
  return {
    type: GET_FIAT_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getFiatSuccess = () => {
  return {
    type: GET_FIAT_SUCCESS,
  } as const
}

export const setFiat = (data: FiatRequest[], total: number, stats: Stats[]) => {
  return {
    type: SET_FIAT,
    payload: { data, total, stats },
  } as const
}

export const selectFiatPageSize = (pageSize: number) => {
  return {
    type: SELECT_FIAT_PAGE_SIZE,
    payload: { pageSize },
  } as const
}

export const selectFiatPage = (currentPage: number) => {
  return {
    type: SELECT_FIAT_PAGE,
    payload: { currentPage },
  } as const
}

export const setFiatFilters = (filters: FilterFiatRequest) => {
  return {
    type: SET_FIAT_FILTERS,
    payload: { filters },
  } as const
}
