import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getGraphQlError } from 'utils'

import { mutate } from '../client'
import { GET_RATE, GetExchangeRateResponse } from '../queries'
import { GET_RATE_REQUEST } from '../constants'
import { getRateRequest, getRateFailure, getRateSuccess, setRate } from '../actions'

export function* getExchangeRate({ payload }: ReturnType<typeof getRateRequest>) {
  try {
    const { data }: GetExchangeRateResponse = yield call(mutate, {
      mutation: GET_RATE,
      variables: payload,
    })

    yield put(setRate(data.getForcedExchangeCurrencyRate))
    yield put(getRateSuccess())
  } catch (err) {
    yield put(getRateFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_RATE_REQUEST, getExchangeRate)])
}
