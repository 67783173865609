import React, { Component } from 'react'
import moment from 'moment'

import { Props } from './@types'
import { CountDown, CountDownItem } from './Style'

const SECOND = 1000
const STOP_TIMER = 0

type State = {
  isOver: boolean
  countdown: number
}

class CountDownTimer extends Component<Props, State> {
  public timer?: number

  public constructor(props: Props) {
    super(props)

    this.state = {
      countdown: this.getTime(props.timer),
      isOver: this.getTime(props.timer) <= STOP_TIMER,
    }
  }

  public componentDidMount() {
    const { callback } = this.props
    const { isOver } = this.state

    this.timer = setInterval(() => this.handleTick(), SECOND)

    if (isOver) {
      callback()
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timer)
  }

  public componentDidUpdate(_: Readonly<Props>, { countdown: oldValue }: Readonly<State>) {
    const { callback } = this.props
    const { countdown, isOver } = this.state

    if (isOver) {
      callback()
    }

    if (countdown !== oldValue && countdown <= STOP_TIMER) {
      this.setState(() => ({ isOver: true }))
    }
  }

  public handleTick = () => {
    const { countdown } = this.state

    if (countdown > STOP_TIMER) {
      this.setState(() => ({
        countdown: countdown - SECOND,
      }))
    }
  }

  public render() {
    const { isOver, countdown } = this.state

    return (
      <CountDown>
        <CountDownItem>{isOver ? '00:00' : moment.utc(countdown).format('mm:ss')}</CountDownItem>
      </CountDown>
    )
  }

  private getTime = (date: string) => {
    const TO_SEC = 1000
    const expiredDate = new Date(date).getTime()
    const seconds = (expiredDate - Date.now()) / TO_SEC

    return Number(moment.duration(seconds, 'second'))
  }
}

export { CountDownTimer }
