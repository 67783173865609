import styled from 'styled-components'
import { color } from 'theme'

import { getSize } from './helpers'
import { QrCodeSize } from './@types'

export const StyledQrCode = styled.div<{ size: QrCodeSize }>`
  box-sizing: border-box;
  margin: 0 auto;
  width: ${({ size }) => getSize(size).width};
  height: ${({ size }) => getSize(size).height};
  padding: ${({ size }) => getSize(size).padding};
  background-color: ${color.white};
  border-radius: 10px;
  z-index: 2;
`
