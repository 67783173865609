import React from 'react'

const Cad = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#fff'
      d='M28.3 0h3.67c7.36.56 14.37 3.82 19.58 9.03-.09 13.98-.07 27.97-.01 41.95-5.24 5.31-12.43 8.53-19.87 9.02h-3.63c-7.34-.58-14.41-3.78-19.57-9.05.03-13.97.07-27.95-.02-41.93C13.7 3.72 20.87.5 28.3 0zm-1.35 17.51l-3.71-1.52c.74 3.41 1.42 6.84 1.91 10.29-1.99-.94-3.25-2.79-4.67-4.38-.47.84-.94 1.69-1.4 2.54-1.71-.35-3.42-.69-5.14-1.01.53 1.71 1.08 3.42 1.65 5.12-.68.6-1.37 1.19-2.05 1.79 2.89 2.18 5.67 4.49 8.48 6.76-.25.83-.76 2.48-1.01 3.3 2.8-.47 5.61-.98 8.42-1.41.1 2.92-.13 5.84-.19 8.76.38-.01 1.15-.02 1.53-.02-.08-2.92-.3-5.83-.17-8.75 2.81.44 5.62.95 8.43 1.42-.27-.82-.8-2.46-1.07-3.28 2.86-2.3 5.68-4.66 8.62-6.87-.55-.42-1.66-1.25-2.21-1.67.66-1.69 1.23-3.42 1.6-5.2-1.69.36-3.39.73-5.08 1.11-.3-.66-.92-1.99-1.22-2.65-1.49 1.62-2.88 3.4-4.84 4.47.52-3.44 1.21-6.85 1.86-10.27-1.15.63-2.31 1.25-3.47 1.87-1.05-2.03-2.06-4.07-3.09-6.11-1.29 1.77-2.11 3.81-3.18 5.71z'
    />
    <path
      fill='#D52B1E'
      d='M0 28.33c.45-7.2 3.52-14.07 8.45-19.31.09 13.98.05 27.96.02 41.93C3.46 45.8.51 38.85 0 31.71v-3.38zM51.55 9.03c4.69 4.83 7.42 11.23 8.45 17.82v5.44c-.67 6.97-3.65 13.6-8.46 18.69-.06-13.98-.08-27.97.01-41.95zM26.95 17.51c1.07-1.9 1.89-3.94 3.18-5.71 1.03 2.04 2.04 4.08 3.09 6.11 1.16-.62 2.32-1.24 3.47-1.87-.65 3.42-1.34 6.83-1.86 10.27 1.96-1.07 3.35-2.85 4.84-4.47.3.66.92 1.99 1.22 2.65 1.69-.38 3.39-.75 5.08-1.11-.37 1.78-.94 3.51-1.6 5.2.55.42 1.66 1.25 2.21 1.67-2.94 2.21-5.76 4.57-8.62 6.87.27.82.8 2.46 1.07 3.28-2.81-.47-5.62-.98-8.43-1.42-.13 2.92.09 5.83.17 8.75-.38 0-1.15.01-1.53.02.06-2.92.29-5.84.19-8.76-2.81.43-5.62.94-8.42 1.41.25-.82.76-2.47 1.01-3.3-2.81-2.27-5.59-4.58-8.48-6.76.68-.6 1.37-1.19 2.05-1.79-.57-1.7-1.12-3.41-1.65-5.12 1.72.32 3.43.66 5.14 1.01.46-.85.93-1.7 1.4-2.54 1.42 1.59 2.68 3.44 4.67 4.38-.49-3.45-1.17-6.88-1.91-10.29l3.71 1.52z'
    />
  </svg>
)

export { Cad }
