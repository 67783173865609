import React from 'react'

import { createModal } from 'containers/Modal'
import { AddUserContainer } from '../containers'

const containerWithProps = () => <AddUserContainer />

const addUser = () =>
  createModal({
    name: 'ADD_USER',
    title: `Add user`,
    container: containerWithProps(),
  })

export { addUser }
