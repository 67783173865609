import React from 'react'

const Dai = () => (
  <svg fill='none' viewBox='0 0 44 44'>
    <path fill='#f5ac37' d='M22 0c12.151 0 22 9.85 22 22 0 12.151-9.849 22-22 22C9.85 44 0 34.15 0 22S9.85 0 22 0z' />
    <path
      fill='#fefefd'
      d='M22.803 23.543h8.36c.178 0 .262 0 .275-.234.069-.85.069-1.706 0-2.557 0-.165-.082-.234-.261-.234H14.54c-.206 0-.261.069-.261.262v2.447c0 .316 0 .316.33.316h8.194zm7.702-5.885a.267.267 0 000-.192 5.439 5.439 0 00-.497-.866 7.01 7.01 0 00-1.018-1.28 3.373 3.373 0 00-.632-.618 9.882 9.882 0 00-4.126-2.09 10.248 10.248 0 00-2.337-.247H14.51c-.206 0-.233.082-.233.26v4.882c0 .206 0 .261.26.261h15.868s.137-.028.165-.11h-.067zm0 8.745a3.214 3.214 0 00-.703 0H14.554c-.206 0-.275 0-.275.275v4.772c0 .22 0 .275.275.275h7.04c.336.026.673.002 1.003-.068a10.478 10.478 0 002.984-.66c.349-.121.686-.279 1.003-.468h.096a8.933 8.933 0 003.837-3.862s.096-.208-.012-.262v-.002zm-18.99 7.796v-7.603c0-.18 0-.206-.22-.206H8.313c-.166 0-.234 0-.234-.22v-2.612h3.19c.178 0 .247 0 .247-.234v-2.585c0-.165 0-.206-.22-.206H8.313c-.166 0-.234 0-.234-.22v-2.42c0-.15 0-.191.22-.191h2.956c.206 0 .261 0 .261-.262v-7.41c0-.22 0-.276.276-.276h10.312c.748.03 1.492.112 2.227.247 1.516.28 2.972.822 4.304 1.595a12.13 12.13 0 012.42 1.87 13.252 13.252 0 011.47 1.829c.433.66.792 1.366 1.075 2.104a.358.358 0 00.41.289h2.462c.315 0 .315 0 .33.303v2.255c0 .22-.083.275-.304.275H34.6c-.192 0-.247 0-.233.248.075.837.075 1.678 0 2.515 0 .234 0 .262.262.262H36.8c.096.123 0 .247 0 .372.014.16.014.32 0 .48v1.664c0 .234-.068.303-.275.303h-2.599a.344.344 0 00-.399.262 10.994 10.994 0 01-2.888 4.207c-.467.42-.958.817-1.47 1.182-.551.317-1.087.647-1.651.908a14.868 14.868 0 01-3.245 1.03c-1.062.19-2.138.277-3.218.262h-9.542v-.014l.003-.003z'
    />
  </svg>
)

export { Dai }
