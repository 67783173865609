import React from 'react'

const EditIcon = () => (
  <svg viewBox='0 0 12 12'>
    <g fill='none' fillRule='evenodd' stroke='#4678FF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'>
      <path d='M10 7.33V10a1 1 0 01-1 1H2a1 1 0 01-1-1V3a1 1 0 011-1h2.67' />
      <path d='M9 1l2 2-5 5H4V6z' />
    </g>
  </svg>
)

export { EditIcon }
