import styled from 'styled-components'
import { font, color } from 'theme'
import { Button } from 'components'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  margin: 0 0 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const InvoiceInputWrapper = styled.div`
  max-width: 268px;
`

export const InputsWrap = styled.div`
  height: 11em;
  display: flex;
  margin-bottom: 1em;
  flex-direction: column;
  justify-content: space-between;
`

export const PaginationSelect = styled.div`
  margin: 6px 0 0 12px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: no-drop;
`

export const Wrapper = styled.div`
  margin: 0 0 0 12px;
  .Select__control {
    margin: 7px 0 0;
    min-width: 100px;
  }
`

export const CreateBtn = styled(Button)`
  margin: 20px 0 0;
`

export const CopyAddress = styled.div`
  position: relative;
  margin: 0 0 30px;
`

export const CopyAddressLabel = styled.span`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const CopyAddressWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  box-shadow: none;
  transition: all 0.3s ease-in;
`

export const CopyAddressValue = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${color.white};
  overflow: hidden;
`

export const Copied = styled.span`
  display: block;
  width: 100%;
  padding: 14px 36px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: ${color.success};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  z-index: 2;
`

export const CopyWrapper = styled.button`
  position: relative;
  display: block;
  width: 100%;
  padding: 14px 50px 14px 16px;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  color: ${color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`

export const IconWrapSend = styled.div`
  margin: 0 auto 20px;
  display: flex;
  width: 64px;
  height: 64px;
  color: ${color.success};
  border: 2px solid ${color.success};
  border-radius: 50%;
  svg {
    width: 100%;
  }
`

export const TitleSend = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${color.white};
`

export const TextSend = styled.p`
  margin: 0 0 10px;
  font-family: ${font.regular};
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`

export const InvoiceIdWrap = styled.div`
  margin: 0 auto 20px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InvoiceId = styled.span`
  padding: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
`

export const IconCopyWrap = styled.div`
  margin: 0 0 0 10px;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledText = styled.div`
  margin: 0 0 0 10px;
  font-size: 14px;
  cursor: pointer;
`
