import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { font, color } from 'theme'

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1220px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const Aside = styled.aside`
  margin: 0 40px 0 0;
  width: 100%;
  max-width: 275px;
  height: 100%;
  display: block;
  z-index: 2;
`

export const AsideLink = styled(NavLink)`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  font-family: ${font.display};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${color.grey};
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  outline: none;
  &:not(:last-child) {
    margin: 0 0 8px;
  }
  svg {
    margin: 0 14px 0 0;
    width: 18px;
    stroke: ${color.grey};
  }
  &:hover,
  &:focus {
    color: ${color.white};
    svg {
      stroke: ${color.default};
      transition: all 0.3s ease-out;
    }
  }
  &.active {
    color: ${color.white};
    background-color: #292833;
    svg {
      stroke: ${color.default};
      transition: all 0.3s ease-out;
    }
  }
`

export const Content = styled.div`
  margin: 0;
  width: 100%;
  max-width: 905px;
`
