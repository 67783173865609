import React from 'react'
import ReactSelect, { ValueType } from 'react-select'
import { PaginationSelect } from './Style'

const optionsFiats = [
  {
    value: 'usd',
    label: '$ USD',
  },
  {
    value: 'eur',
    label: '€ EUR',
  },
]

type Options = {
  value: string
}

type Props = {
  name?: string
  value: string
  onChange: (value: string, name?: string) => void
}

function SelectFiat({ value, onChange, name }: Props) {
  const handleOnChange = (value: ValueType<Options>) => {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange(value.value, name)
    }
  }

  const selected = optionsFiats.find(i => i.value === value.toLowerCase())

  return (
    <PaginationSelect>
      <ReactSelect
        className='SelectFiat'
        classNamePrefix='SelectFiat'
        value={selected}
        placeholder={selected}
        onChange={handleOnChange}
        isSearchable={false}
        minMenuHeight={96}
        menuPlacement='bottom'
        options={optionsFiats}
        isDisabled
      />
    </PaginationSelect>
  )
}

export { SelectFiat }
