import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'

import { ApplicationState } from './@types'

import { history } from 'utils'

export const createReducer = <State>(injectedReducer?: { [key: string]: Reducer<State> }) =>
  combineReducers<ApplicationState>({
    router: connectRouter(history),
    ...injectedReducer,
  }) as Reducer
