import { ReactNode } from 'react'
import { ColumnsType, RenderedCell } from 'rc-table/lib/interface'

// TODO add type to render value string function or something else
type InputParams<RecordType> = {
  key: string
  title: string | JSX.Element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, record: RecordType, index: number) => ReactNode | RenderedCell<RecordType>
}

const createColumns = <RecordType>(columns: InputParams<RecordType>[]): ColumnsType<RecordType> =>
  columns.map(({ title, key, render, ...rest }) => ({
    ...rest,
    title,
    key,
    render,
    dataIndex: key,
  }))

export { createColumns }
