import React, { Component } from 'react'

import { Requests } from '../../@types'
import { NewRequest } from '../../modals'

import { RequestState } from '@types'
import { NewTable, createColumns } from 'components'

import { COLUMNS } from './Columns'
import { TableContainer, TableText, TableTitle } from './Style'

type Props = {
  request: RequestState
  requests: Requests
  getData: () => void
  openModal: (data: {}) => void
}

class Request extends Component<Props> {
  public handleOpen = (type: string, value: string) => {
    const { openModal } = this.props

    openModal(
      NewRequest({
        type,
        currentValue: value,
      }),
    )
  }

  public render() {
    const { requests, getData, request } = this.props

    const data = requests
      .map(i => ({
        ...i,
        edit: { value: i.value, onClick: () => this.handleOpen(i.name, i.value) },
      }))
      .filter(i => i.name !== 'bank_detail_usd')

    return (
      <div>
        <TableContainer>
          <TableTitle>Currency details</TableTitle>
          <TableText>
            The list of&nbsp;approved addresses for withdrawal funds. Here you can change them by&nbsp;sending
            a&nbsp;request.
          </TableText>
          <NewTable getData={getData} loading={request.isFetching} columns={createColumns(COLUMNS)} data={data} />
        </TableContainer>
      </div>
    )
  }
}

export { Request }
