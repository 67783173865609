export const DOMAIN = 'modules/transaction'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_TX = 'modules/transaction/ui/SET_TX'
export const SET_TX_FILTERS = 'modules/transaction/ui/SET_TX_FILTERS'
export const SELECT_TX_PAGE = 'modules/transaction/ui/SELECT_TX_PAGE'
export const SELECT_TX_PAGE_SIZE = 'modules/transaction/ui/SELECT_TX_PAGE_SIZE'

export const GET_TX_REQUEST = 'modules/transaction/domain/GET_TX_REQUEST'
export const GET_TX_SUCCESS = 'modules/transaction/domain/GET_TX_SUCCESS'
export const GET_TX_FAILURE = 'modules/transaction/domain/GET_TX_FAILURE'
