import React from 'react'
import { GetProps } from 'react-redux'

import { createModal } from 'containers/Modal'
import { ResetApiContainer } from '../containers'

type Props = GetProps<typeof ResetApiContainer>

const containerWithProps = (props: Props) => <ResetApiContainer {...props} />

const ResetApi = (props: Props) =>
  createModal({
    type: 'warning',
    name: 'RESET_API',
    title: 'Reset API',
    container: containerWithProps(props),
  })

export { ResetApi }
