import { gql, ApolloQueryResult } from 'apollo-boost'
import { WebHook } from './@types'

export const GET_WEB_HOOKS = gql`
  query findWebhooks($page: Int) {
    findWebhooks(page: $page) {
      total
      page_size
      data {
        id
        url
        created_at
        request_body
        response_body
        request_headers
        response_headers
      }
    }
  }
`

export type GetWevHooksResponse = ApolloQueryResult<{
  findWebhooks: {
    total: number
    page_size: number
    data: WebHook[]
  }
}>
