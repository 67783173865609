import React from 'react'

const EurBIcon = () => (
  <svg fill='none' viewBox='0 0 56 56'>
    <path fill='#597ae8' d='M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z' />
    <path fill='#073394' d='M54.13 38.08C50.084 48.563 39.91 56 28 56 16.09 56 5.916 48.563 1.87 38.08h52.26z' />
    <path
      fill='#fff'
      d='M22.552 49.236h-4.19v-2.554h3.96v-1.27h-3.96v-2.418h4.19v-1.35h-5.789v8.943h5.789v-1.352zM25.54 41.643h-1.6v5.789c0 1.952 1.413 3.303 3.676 3.303 2.268 0 3.675-1.35 3.675-3.303v-5.789h-1.593v5.646c0 1.221-.756 2.052-2.082 2.052-1.327 0-2.077-.83-2.077-2.052v-5.646zM34.477 42.938h1.772c1.01 0 1.636.583 1.636 1.525 0 .967-.588 1.531-1.605 1.531h-1.803v-3.056zm0 4.283h1.729l1.76 3.366h1.822l-1.952-3.608c1.066-.384 1.686-1.37 1.686-2.553 0-1.71-1.16-2.783-3.093-2.783h-3.551v8.944h1.599V47.22z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M24.684 19.256v-5.039H21.28v8.206h7.472a7.975 7.975 0 003.803-.905 6.87 6.87 0 002.694-2.515 7.087 7.087 0 00.003-7.177 6.875 6.875 0 00-2.697-2.52 7.98 7.98 0 00-3.808-.905H21.28v3.16h7.484a4.014 4.014 0 012.887 1.084 3.649 3.649 0 011.123 2.752v.02a3.659 3.659 0 01-1.125 2.758 4.012 4.012 0 01-2.885 1.081H24.684zm10.97 3.51a7 7 0 00-.641-.888c-.513.45-1.08.84-1.691 1.158a9.645 9.645 0 01-1.442.609l.046.042c.71.68 1.13 1.733 1.097 2.776V26.484c.033 1.046-.386 2.093-1.1 2.778a3.928 3.928 0 01-2.825 1.06h-4.414v-6.193H21.28v9.69h7.803a8.056 8.056 0 003.842-.915 6.951 6.951 0 002.726-2.545c1.318-2.246 1.319-5.353.004-7.593z'
      clipRule='evenodd'
    />
  </svg>
)

export { EurBIcon }
