import { Theme } from './@types'

const palette = {
  blue: '#4678ff',
  darkBlue: '#171828',
  white: '#fff',
  black01: 'rgba(0, 0, 0, 0.1)',
  grey: '#4e4e4e',
}

const COLOR_DISABLED = palette.grey
const COLOR_WHITE = palette.white
const COLOR_BG_DEFAULT = palette.black01
const COLOR_ICON = palette.white
const BORDER_COLOR_ICON = palette.white
const DEFAULT_RADIO_ITEM_COLOR = {
  default: COLOR_WHITE,
}

const radioItem: Theme['radioItem'] = {
  color: {
    default: DEFAULT_RADIO_ITEM_COLOR,
    hover: DEFAULT_RADIO_ITEM_COLOR,
    checked: DEFAULT_RADIO_ITEM_COLOR,
    disabled: {
      default: COLOR_DISABLED,
    },
  },
  background: {
    default: {
      default: COLOR_BG_DEFAULT,
    },
    hover: {
      default: palette.blue,
    },
    checked: {
      default: COLOR_BG_DEFAULT,
    },
    disabled: {
      default: palette.darkBlue,
    },
  },
  icon: {
    default: {
      color: COLOR_ICON,
      background: 'none',
      borderRadius: 50,
      border: '2px solid #fff',
      opacity: 1,
    },
    hover: {
      color: COLOR_ICON,
      background: palette.black01,
      borderRadius: 50,
      border: `2px solid ${BORDER_COLOR_ICON}`,
      opacity: 0.15,
    },
    checked: {
      color: COLOR_ICON,
      background: 'none',
      borderRadius: 50,
      border: `2px solid ${BORDER_COLOR_ICON}`,
      opacity: 1,
    },
    disabled: {
      color: COLOR_DISABLED,
      background: 'none',
      borderRadius: 50,
      border: `2px solid ${BORDER_COLOR_ICON}`,
      opacity: 0,
    },
  },
}

export { radioItem }
