import { Company } from '../@types'
import { GET_COMPANY_FAILURE, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, SET_COMPANY } from '../constants'

export const getCompanyRequest = () => {
  return {
    type: GET_COMPANY_REQUEST,
  } as const
}

export const getCompanyFailure = (errorMessage: string) => {
  return {
    type: GET_COMPANY_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getCompanySuccess = () => {
  return {
    type: GET_COMPANY_SUCCESS,
  } as const
}

export const setCompany = (data: Company) => {
  return {
    type: SET_COMPANY,
    payload: { data },
  } as const
}
