import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { auth } from './Auth'
import { signIn } from './SignIn'
import { signUp } from './SignUp'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    auth,
  }),
  [DOMAIN_STATE]: combineReducers({
    signIn,
    signUp,
  }),
})
