import React from 'react'
import { Address } from '../../@types'

import { Icon, IconMap } from 'components'
import { IconWrap, ItemChoice, ItemChoiceInfo, ItemChoiceTitle } from './Style'

type ItemProps = {
  key: string
  currency: string
  address: Address
  select: (params: Address) => void
}

const Item = ({ address, select, key }: ItemProps) => {
  const handleSelect = () => {
    select(address)
  }

  return (
    <ItemChoice key={key} onClick={handleSelect}>
      <Icon name={address.crypto_currency.toLowerCase() as IconMap} size='xlarge' />

      <ItemChoiceInfo>
        <ItemChoiceTitle>{address.crypto_currency}</ItemChoiceTitle>
      </ItemChoiceInfo>

      <IconWrap>
        <Icon name='arrowHead' size='auto' />
      </IconWrap>
    </ItemChoice>
  )
}

export { Item }
