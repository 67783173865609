import React from 'react'

const Copy = () => (
  <svg viewBox='0 0 18 20'>
    <path
      fillRule='evenodd'
      d='M17.94 6.67c.03.088.05.178.06.27V13a3 3 0 01-3 3h-1v1a3 3 0 01-3 3H3a3 3 0 01-3-3V7a3 3 0 013-3h1V3a3 3 0 013-3h4.05a.88.88 0 01.33.11.32.32 0 01.09 0c.103.048.197.112.28.19l6 6c.078.083.142.177.19.28v.09zM14.59 6L12 3.41V5a1 1 0 001 1h1.59zM12 17a1 1 0 01-1 1H3a1 1 0 01-1-1V7a1 1 0 011-1h1v7a3 3 0 003 3h5v1zm3-3a1 1 0 001-1V8h-3a3 3 0 01-3-3V2H7a1 1 0 00-1 1v10a1 1 0 001 1h8z'
      clipRule='evenodd'
    />
  </svg>
)

export { Copy }
