import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { logOut } from 'modules/Auth/actions'
import { AuthState } from 'modules/Auth/@types'
import { selectAuth } from 'modules/Auth/selectors'
import { withInjectors } from 'modules/Auth/injectors'
import { View } from '../View'

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ logOut }, dispatch)

type Props = {
  auth: boolean
}

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    auth: selectAuth(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const GlobalContainer = compose<ComponentType>(withInjectors, withConnect)(View)
