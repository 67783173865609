import React from 'react'

const BetIcon = () => (
  <svg fill='none' viewBox='0 0 56 56'>
    <path fill='#597ae8' d='M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z' />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M22.432 25.602v-6.654h-4.494v10.836h9.867a10.532 10.532 0 005.023-1.195 9.072 9.072 0 003.558-3.321 9.359 9.359 0 00.003-9.479 9.079 9.079 0 00-3.562-3.328 10.539 10.539 0 00-5.028-1.194h-9.862v4.173h9.884a5.3 5.3 0 013.813 1.431 4.818 4.818 0 011.483 3.634v.028a4.832 4.832 0 01-1.486 3.641 5.298 5.298 0 01-3.81 1.429h-5.389zm14.49 4.57a9.243 9.243 0 00-.849-1.173 11.24 11.24 0 01-2.233 1.529c-.614.32-1.25.589-1.904.804l.06.056c.938.897 1.493 2.289 1.45 3.666l-.001.014v.014c.044 1.382-.508 2.764-1.452 3.67a5.188 5.188 0 01-3.731 1.398h-5.83V31.97h-4.494v12.797h10.305a10.64 10.64 0 005.073-1.207 9.181 9.181 0 003.6-3.362c1.741-2.966 1.742-7.069.005-10.028z'
      clipRule='evenodd'
    />
  </svg>
)

export { BetIcon }
