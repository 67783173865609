import { ButtonSize } from '../@types'

export const getSize = (size: ButtonSize) =>
  ({
    default: {
      padding: '18px 32px',
      minWidth: '160px',
      height: '56px',
      fontSize: '16px',
      lineHeight: 1.25,
    },
    medium: {
      padding: '14px 24px',
      minWidth: '120px',
      height: '44px',
      fontSize: '14px',
      lineHeight: 1.14,
    },
    small: {
      padding: '8px 12px',
      minWidth: '80px',
      height: '32px',
      fontSize: '12px',
      lineHeight: 1.33,
    },
  }[size])
