import { ExchangePairs } from '../@types'
import { SET_PAIRS, GET_PAIRS_FAILURE, GET_PAIRS_REQUEST, GET_PAIRS_SUCCESS } from '../constants'

export const getPairsRequest = (currency_from: string) => {
  return {
    type: GET_PAIRS_REQUEST,
    payload: { currency_from },
  } as const
}

export const getPairsFailure = (errorMessage: string) => {
  return {
    type: GET_PAIRS_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getPairsSuccess = () => {
  return {
    type: GET_PAIRS_SUCCESS,
  } as const
}

export const setPairs = (data: ExchangePairs) => {
  return {
    type: SET_PAIRS,
    payload: { data },
  } as const
}
