import React, { useEffect } from 'react'

import { NewTable, createColumns } from 'components'

import { NewWarningRequest, ResetApi } from '../../modals'
import { Company as CompanyType, Request } from '../../@types'

import { COLUMNS } from './Columns'
import { CALLBACK } from './Callback'

import { TableContainer, TableWrap, TableTitle, TableText, BtnContainer, ResetButton } from './Style'

type Props = {
  request: Request
  reset: () => void
  company: CompanyType
  getCompany: () => void
  getLatestRequest: () => void
  openModal: (data: {}) => void
}

const TITLE = {
  api_key: 'Api Key',
  api_secret: 'Api Secret',
} as const

function Company({ reset, company, request, openModal, getLatestRequest }: Props) {
  useEffect(() => {
    getLatestRequest()
  }, [getLatestRequest])

  const handleOpen = (value: string) => {
    openModal(
      NewWarningRequest({
        type: 'callback_url',
        currentValue: value,
      }),
    )
  }

  const handleReset = () => {
    openModal(ResetApi({ resetApiRequest: reset }))
  }

  const reqData = [
    {
      ...request,
      edit: () => handleOpen(request.value),
    },
  ]

  const data = Object.keys(company)
    .map(key => ({
      title: TITLE[key as keyof typeof TITLE],
      text: company[key as keyof CompanyType],
    }))
    .filter(i => i.title === 'Api Key' || i.title === 'Api Secret')

  return (
    <TableContainer>
      <TableTitle>API keys</TableTitle>
      <TableText>
        Bellow you can find both public and secret keys. If&nbsp;something went wrong you can reset them.
      </TableText>
      <NewTable data={data} columns={createColumns(COLUMNS)} />
      <BtnContainer>
        <ResetButton type='ghostWarning' size='medium' onClick={handleReset}>
          Reset API
        </ResetButton>
      </BtnContainer>

      <TableTitle>Callback URL</TableTitle>
      <TableText>At&nbsp;the following URL you will receive all answers from our service</TableText>
      <TableWrap>
        <NewTable columns={createColumns(CALLBACK)} data={reqData} />
      </TableWrap>
    </TableContainer>
  )
}

export { Company }
