import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

import { History, StateStore } from './@types'
import { createReducer } from './RootReducer'

const persistConfig = {
  key: 'root',
  storage,
}

export const configureStore = (initialState = {}, history: History) => {
  const storeEnhancers = []

  const sagaMiddleware = createSagaMiddleware()
  const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools({}) : compose

  const middlewareEnhancer = applyMiddleware(sagaMiddleware, routerMiddleware(history))

  storeEnhancers.unshift(composeEnhancers(middlewareEnhancer))

  const persistedReducer = persistReducer(persistConfig, createReducer())
  const store = createStore(persistedReducer, compose(...storeEnhancers)) as StateStore
  const persistor = persistStore(store)

  store.runSaga = sagaMiddleware.run
  store.injectedSagas = {}
  store.injectedReducers = {}

  if (module.hot) {
    module.hot.accept('./RootReducer', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return { store, persistor }
}
