import React from 'react'

import { Copy, Icon } from 'components'
import { ApiName, CopyWrap, IconWrapper } from './Style'

function TextComponent(value: string) {
  return <ApiName>{value}</ApiName>
}

const CopyBtn = (value: string) => (
  <Copy value={value}>
    <CopyWrap>
      <IconWrapper>
        <Icon name='copy' size='extraMedium' />
      </IconWrapper>
      Copy
    </CopyWrap>
  </Copy>
)

export const COLUMNS = [
  {
    title: 'Title',
    key: 'title',
    render: (props: string) => TextComponent(props),
  },
  {
    title: 'Value',
    key: 'text',
    render: (props: string) => TextComponent(props),
  },
  {
    title: 'Action',
    key: 'action',
    width: 144,
    align: 'right',
    render: (_: string, render: { text: string }) => CopyBtn(render.text),
  },
]
