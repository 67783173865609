import React, { Component, Fragment } from 'react'

import { RequestState } from '@types'
import { Button, DecimalsFixed } from 'components'
import { CommonCurrencies, ExchangePairs } from 'modules/Global/@types'

import { Titles } from './Titles'
import { Inputs } from './Inputs'
import { Headers } from './Headers'
import { ConfirmModal, InfoModal } from './modals'

import {
  Modal,
  Course,
  Overlay,
  WrapBtn,
  CloseBtn,
  WrapInfo,
  CustomBtn,
  CourseTitle,
  CourseValue,
  ModalContainer,
} from './Style'

import { Balance } from '../../../Wallet/@types'
import { ContainerButtons } from '../Exchange/Style'

type State = {
  isValid: boolean
  buyAmount: number
  initAmount: number
  sellAmount: number
  buyCurrency: string
  sellCurrency: string
  isModalOpen: boolean
}

type Props = {
  company: {
    balance: Balance[]
    stable_rate_zeur: number
  }
  rate: {
    rate: number
    min_amount: number
    min_amount_to: number
  }
  pairs: ExchangePairs
  rateRequest: RequestState
  currencies: CommonCurrencies
  currenciesFrom: ExchangePairs
  getPairsRequest: RequestState
  exchangeRequest: RequestState
  getCompany: () => void
  clearState: () => void
  getCurrenciesFrom: () => void
  getRate: (params: {}) => void
  exchange: (params: {}) => void
  getPairs: (currency: string) => string
}

class Form extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    const { getPairs, getCurrenciesFrom } = props
    this.state = {
      buyAmount: 0,
      sellAmount: 0,
      initAmount: 0,
      isModalOpen: false,
      sellCurrency: 'BTC',
      buyCurrency: 'EUR',
      isValid: false,
    }

    getCurrenciesFrom()
    getPairs(this.state.sellCurrency)
  }

  public handleChange = (sellAmount: number, buyAmount: number, isValid: boolean) => {
    this.setState(() => ({ sellAmount, buyAmount, isValid }))
  }

  public componentDidMount() {
    const { getCompany } = this.props
    getCompany()

    this.handleChangeRate()
  }

  public componentDidUpdate(prev: Props) {
    const { pairs, rateRequest } = this.props
    if (pairs !== prev.pairs) {
      if (pairs && pairs[Number('0')]) {
        this.selectBuyCurrency(pairs[Number('0')].name)
      }
    }

    if (rateRequest.isError !== prev.rateRequest.isError) {
    }
  }

  public toggleModal = () => {
    const { clearState } = this.props

    this.setState(
      prev => ({ isModalOpen: !prev.isModalOpen }),
      () => clearState(),
    )
  }

  public getBalance = (name: string) => {
    const { company } = this.props

    if (!company || !company.balance) {
      return Number('0')
    }

    const currency = company.balance.find(i => i.currency_name === name)

    if (currency) {
      return currency.value
    }

    return Number('0')
  }

  public handleMinAmount = () => {
    const { rate } = this.props
    this.setState(() => ({ initAmount: rate.min_amount }))
  }

  public handleAmount = () => {
    const { sellCurrency } = this.state
    const amount = this.getBalance(sellCurrency) / Number('2')

    this.setState(() => ({ initAmount: amount }))
  }

  public handleMaxAmount = () => {
    const { sellCurrency } = this.state
    const amount = this.getBalance(sellCurrency)

    this.setState(() => ({ initAmount: amount }))
  }

  public selectSellCurrency = (sellCurrency: string) => {
    const { getPairs } = this.props
    getPairs(sellCurrency)
    this.setState(() => ({ sellCurrency }))
  }

  public selectBuyCurrency = (buyCurrency: string) => {
    this.setState(
      () => ({ buyCurrency }),
      () => {
        this.handleChangeRate()
      },
    )
  }

  public getDecimals = (currency: string) => {
    const { currencies } = this.props

    const find = currencies.find(i => i.name === currency)

    if (find) {
      return find.decimal
    }

    return Number('4')
  }

  public handleChangeRate = () => {
    const { getRate } = this.props
    const { sellAmount, sellCurrency, buyCurrency } = this.state

    getRate({
      currency_to: buyCurrency,
      currency_from: sellCurrency,
      amount: sellAmount ? Number(sellAmount) : Number('100'),
    })
  }

  public render() {
    const { currencies, currenciesFrom, pairs, rate, ...methods } = this.props
    const { getPairsRequest, exchangeRequest, rateRequest, getRate, getPairs, exchange } = methods

    const { buyAmount, isModalOpen, buyCurrency, sellAmount, sellCurrency, initAmount } = this.state

    const isDisabled = !sellAmount || !buyAmount

    return (
      <Fragment>
        <form>
          <Headers
            pairs={pairs}
            getPairs={getPairs}
            currencies={currencies}
            buyCurrency={buyCurrency}
            sellCurrency={sellCurrency}
            getBalance={this.getBalance}
            currenciesFrom={currenciesFrom}
            selectBuy={this.selectBuyCurrency}
            selectSell={this.selectSellCurrency}
            isLoading={getPairsRequest.isFetching}
          />
          <Titles />
          <Inputs
            rate={rate.rate}
            getRate={getRate}
            buyCurrency={buyCurrency}
            minValue={rate.min_amount}
            minValueTo={rate.min_amount_to}
            sellCurrency={sellCurrency}
            onChange={this.handleChange}
            isError={rateRequest.isError}
            balance={this.getBalance(sellCurrency)}
            errorMessage={rateRequest.errorMessage}
            initAmount={String(initAmount === Number('0') ? '' : initAmount)}
            isLoading={rateRequest.isFetching || getPairsRequest.isFetching}
          />
          <WrapBtn>
            <CustomBtn onClick={this.handleMinAmount} type='ghost' size='small' disabled={rateRequest.isError}>
              Min
            </CustomBtn>
            <CustomBtn onClick={this.handleAmount} type='ghost' size='small' disabled={rateRequest.isError}>
              50%
            </CustomBtn>
            <CustomBtn onClick={this.handleMaxAmount} type='ghost' size='small' disabled={rateRequest.isError}>
              Max
            </CustomBtn>
          </WrapBtn>
          {!rateRequest.isError && (
            <WrapInfo>
              <Course>
                <CourseTitle>Rate</CourseTitle>
                <CourseValue isLoading={rateRequest.isFetching}>
                  1 {sellCurrency} = {DecimalsFixed(rate.rate || '0', 'fiat', '', this.getDecimals(buyCurrency))}{' '}
                  {buyCurrency}
                </CourseValue>
              </Course>
              <Course>
                <CourseTitle>You will receive</CourseTitle>
                <CourseValue isLoading={rateRequest.isFetching}>
                  {DecimalsFixed(buyAmount || '0', 'fiat', '', this.getDecimals(buyCurrency))} {buyCurrency}
                </CourseValue>
              </Course>
            </WrapInfo>
          )}
          <ContainerButtons>
            <Button onClick={this.handleExchange} disabled={isDisabled}>
              Convert
            </Button>
          </ContainerButtons>
        </form>

        <Modal isModalOpen={isModalOpen}>
          <ModalContainer>
            <CloseBtn type='button' onClick={this.toggleModal} />
            {!exchangeRequest.isSuccess ? (
              <ConfirmModal
                rate={rate}
                exchange={exchange}
                buyAmount={buyAmount}
                sellAmount={sellAmount}
                isModalOpen={isModalOpen}
                buyCurrency={buyCurrency}
                sellCurrency={sellCurrency}
                getDecimals={this.getDecimals}
                toggleModal={this.toggleModal}
                exchangeRequest={exchangeRequest}
              />
            ) : (
              <InfoModal
                buyAmount={buyAmount}
                buyCurrency={buyCurrency}
                toggleModal={this.toggleModal}
                getDecimals={this.getDecimals}
              />
            )}
          </ModalContainer>

          <Overlay />
        </Modal>
      </Fragment>
    )
  }

  public handleExchange = () => {
    const { isValid } = this.state

    if (!isValid) {
      this.setState(() => ({ isModalOpen: true }))
    }
  }
}

export { Form }
