import { all, takeLatest, call, put } from 'redux-saga/effects'

import { getGraphQlError, getTrimming } from 'utils'

import { mutate, query } from '../client'
import { ChangeDetails } from '../@types'
import { CHANGE_COMPANY_DETAILS } from '../mutation'
import { GET_LATEST_REQUEST, GetLatestUpdateResponse } from '../queries'
import { GET_LATEST_UPDATE_REQUEST, CHANGE_DETAIL_REQUEST } from '../constants'
import {
  setDetails,
  changeDetailFailure,
  changeDetailRequest,
  changeDetailSuccess,
  getLatestUpdateFailure,
  getLatestUpdateSuccess,
  getLatestUpdateRequest,
} from '../actions'

export function* changeDetails({ payload }: ReturnType<typeof changeDetailRequest>) {
  try {
    yield call(mutate, {
      mutation: CHANGE_COMPANY_DETAILS,
      variables: getTrimming<ChangeDetails>(payload),
    })

    yield put(changeDetailSuccess())
    yield put(getLatestUpdateRequest())
  } catch (err) {
    yield put(changeDetailFailure(getGraphQlError(err)))
  }
}

export function* getLatest() {
  try {
    const response: GetLatestUpdateResponse = yield call(query, {
      query: GET_LATEST_REQUEST,
      fetchPolicy: 'no-cache',
    })

    const {
      data: { findCompanyInfoUpdateNewerReq },
    } = response

    yield put(getLatestUpdateSuccess())
    yield put(setDetails(findCompanyInfoUpdateNewerReq))
  } catch (err) {
    yield put(getLatestUpdateFailure(err.message))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(CHANGE_DETAIL_REQUEST, changeDetails),
    yield takeLatest(GET_LATEST_UPDATE_REQUEST, getLatest),
  ])
}
