import React, { Fragment } from 'react'

import { RequestState } from '@types'
import { createColumns, NewTable } from 'components'
import { Transaction, Transactions, Address, Balance } from '../../@types'

import { Desc } from '../Desc'
import { COLUMNS, ADDRESS } from './Columns'

type InjectorProps = {
  company: {
    balance: Balance[]
    addresses: Address[]
  }
  getCompany: () => void
}

type Props = InjectorProps & {
  getData: () => void
  setFilters: () => void
  request: RequestState
  transactions: Transactions
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
}

class TransactionTable extends React.Component<Props> {
  public componentDidMount() {
    const { getCompany } = this.props
    getCompany()
  }

  public handleMergeData = () => {
    const {
      company: { addresses, balance },
    } = this.props

    return addresses && addresses.length
      ? addresses.map(item => {
          const find = balance.find(i => i.currency_name === item.currency)

          if (find) {
            return {
              ...item,
              amount: find.value,
            }
          }

          return {
            ...item,
            amount: 0,
          }
        })
      : []
  }

  public render() {
    const { getData, request, selectPage, selectPageSize, transactions } = this.props

    return (
      <Fragment>
        <Desc title='Hot wallets' />
        <NewTable data={this.handleMergeData()} columns={createColumns(ADDRESS)} />
        <Desc title='History' />
        <NewTable<Transaction>
          selection={true}
          getData={getData}
          pagination={true}
          selectPage={selectPage}
          data={transactions.data}
          setPageSize={selectPageSize}
          loading={request.isFetching}
          pageSize={transactions.pageSize}
          pageCount={transactions.pageCount}
          currentPage={transactions.currentPage}
          columns={createColumns<Transaction>(COLUMNS)}
        />
      </Fragment>
    )
  }
}

export { TransactionTable }
