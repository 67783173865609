import { all, put, takeLatest, call, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'

import { Filter } from '../@types'
import { query } from '../client'
import { selectInvoices } from '../selectors'
import { GET_INVOICES, GetInvoicesResponse } from '../queries'
import { getInvoicesFailure, getInvoicesSuccess, setInvoices, getInvoicesRequest as request } from '../actions'
import {
  GET_INVOICES_REQUEST,
  SET_INVOICES_FILTERS,
  SELECT_INVOICES_PAGE,
  SELECT_INVOICES_PAGE_SIZE,
} from '../constants'

export function* getInvoices() {
  const { filters, currentPage, pageSize } = yield select(selectInvoices())

  try {
    const dataFilters = getTrimming<Filter>(filters)

    const response: GetInvoicesResponse = yield call(query, {
      query: GET_INVOICES,
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
      fetchPolicy: 'no-cache',
    })
    const {
      findCompanyInvoices: { total, invoices, stats },
    } = response.data

    yield put(setInvoices(invoices, total, stats))

    yield put(getInvoicesSuccess())
  } catch (err) {
    yield put(getInvoicesFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getInvoicesFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_INVOICES_REQUEST, getInvoices),

    yield takeLatest(SET_INVOICES_FILTERS, onRequest),
    yield takeLatest(SELECT_INVOICES_PAGE, onRequest),
    yield takeLatest(SELECT_INVOICES_PAGE_SIZE, onRequest),
  ])
}
