import { call, all, put, takeLatest } from 'redux-saga/effects'

import { getGraphQlError } from 'utils'

import { mutate } from '../client'
import { NEW_CRYPTO_REQUEST } from '../constants'
import { NEW_CRYPTO_REQ } from '../mutation'
import { newCryptoFailure, newCryptoRequest, newCryptoSuccess, getCryptoRequest } from '../actions'

export function* newCrypto({ payload }: ReturnType<typeof newCryptoRequest>) {
  try {
    yield call(mutate, {
      mutation: NEW_CRYPTO_REQ,
      variables: payload,
    })

    yield put(getCryptoRequest())
    yield put(newCryptoSuccess())
  } catch (err) {
    yield put(newCryptoFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(NEW_CRYPTO_REQUEST, newCrypto)])
}
