import React, { Fragment, Component } from 'react'

import { RequestState } from '@types'
import { Button, Icon, KeysListener } from 'components'

import { ChangeDetails } from '../../@types'

import { Wrap, Text, TextSend, TitleSend, IconWrapSend } from './Style'

type Props = {
  type: string
  request: RequestState
  closeModal: () => void
  newRequest: (data: ChangeDetails) => void
}

class ChangeRateRequest extends Component<Props> {
  public render() {
    const { request } = this.props

    return (
      <Fragment>
        {!request.isSuccess ? (
          <Wrap>
            <KeysListener handleSubmit={this.handleReq}>
              <Text>Confiirm your reequest for switching workflow</Text>

              <Button onClick={this.handleReq}>Send request</Button>
            </KeysListener>
          </Wrap>
        ) : (
          <Fragment>
            <IconWrapSend>
              <Icon name='tick' size='auto' />
            </IconWrapSend>
            <TitleSend>Request has been sent</TitleSend>
            <TextSend>Our manager will contact you in&nbsp;24&nbsp;hours</TextSend>
          </Fragment>
        )}
      </Fragment>
    )
  }

  public handleClose = () => {
    const { closeModal } = this.props

    closeModal()
  }

  public handleReq = () => {
    const { newRequest, type } = this.props
    newRequest({
      detail_type: 'approach_currency',
      detail_new_value: type,
    })
  }
}

export { ChangeRateRequest }
