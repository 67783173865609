import styled from 'styled-components'
import { getStatus } from './helpers'

export const StatusElement = styled.span<{ status?: string }>`
  padding: 2px 8px;
  display: inline-block;
  text-transform: capitalize;
  word-break: keep-all;
  border-radius: 12px;
  ${({ status }) => getStatus(status)};
`
