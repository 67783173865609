import React, { ComponentType } from 'react'

import { CreateModalProps, ModalName } from './@types'
import { ModalContainer } from './ModalContainer'

export const createConfig = (name: ModalName, container: ComponentType) => {
  return {
    name,
    container,
    isOpen: false,
  }
}

const createModal = ({ name, container, title = '', type = 'default', size = 'default' }: CreateModalProps) => {
  const modalWrapper = () => (
    <ModalContainer name={name} type={type} size={size} title={title}>
      {container}
    </ModalContainer>
  )

  return createConfig(name, modalWrapper)
}

export { createModal }
