import { gql, ApolloQueryResult } from 'apollo-boost'
import { CommonCurrencies, ExchangePairs } from './@types'

// todo add filters
export const GET_COMMON_CURRENCIES = gql`
  query generalGetAppCurrencies {
    generalGetAppCurrencies {
      total
      list {
        name
        type
        extra_type
        decimal
        image_url
        full_name
      }
    }
  }
`

export type GetCommonCurrenciesResponse = ApolloQueryResult<{
  generalGetAppCurrencies: {
    total: number
    list: CommonCurrencies
  }
}>

export const GET_COMMON_CURRENCIES_FROM = gql`
  query generalGetSecondCurrencyForExchangePairAppList {
    generalGetSecondCurrencyForExchangePairAppList {
      total
      list {
        name
        type
        decimal
      }
    }
  }
`

export type GetCommonCurrenciesFromResponse = ApolloQueryResult<{
  generalGetSecondCurrencyForExchangePairAppList: {
    total: number
    list: CommonCurrencies
  }
}>

export const GET_EXCHANGE_PAIR = gql`
  query generalGetSecondCurrencyForExchangePairByFirstCurrency($currency_from: CommonCurrencyName!) {
    generalGetSecondCurrencyForExchangePairByFirstCurrency(currency_from: $currency_from) {
      total
      list {
        name
        type
        decimal
      }
    }
  }
`

export type GetExchangePairsResponse = ApolloQueryResult<{
  generalGetSecondCurrencyForExchangePairByFirstCurrency: {
    total: number
    list: ExchangePairs
  }
}>
