import styled from 'styled-components'

const ColumnElements = styled.span<{ align?: 'center' | 'end' }>`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ align }) =>
    align === 'center' &&
    `
    align-items: center;
  `}
  ${({ align }) =>
    align === 'end' &&
    `
    align-items: flex-end;
  `}
`

export { ColumnElements }
