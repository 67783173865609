import { call, all, put, takeLatest } from 'redux-saga/effects'

import { SHA3 } from 'crypto-js'
import { getGraphQlError, getTrimming } from 'utils'

import { mutate } from '../client'
import { AddUserInput } from '../@types'
import { ADD_USER_REQUEST, REMOVE_USER_REQUEST } from '../constants'
import { ADD_STAFF, AddStaffResponse, REMOVE_USER } from '../mutation'
import {
  setUser,
  addUserRequest,
  addUserFailure,
  addUserSuccess,
  getStaffsRequest,
  removeUserFailure,
  removeUserRequest,
  removeUserSuccess,
} from '../actions'

export function* user({ payload }: ReturnType<typeof addUserRequest>) {
  try {
    const variables = getTrimming<AddUserInput>(payload)

    const { data }: AddStaffResponse = yield call(mutate, {
      mutation: ADD_STAFF,
      variables: {
        ...variables,
        password_hash: SHA3(variables.password_hash).toString(),
      },
    })

    yield put(addUserSuccess())
    yield put(setUser(data.createCompanyWorker.otp_secret))
    yield put(getStaffsRequest())
  } catch (err) {
    yield put(addUserFailure(getGraphQlError(err)))
  }
}

export function* removeUser({ payload }: ReturnType<typeof removeUserRequest>) {
  try {
    yield call(mutate, {
      mutation: REMOVE_USER,
      variables: payload,
    })

    yield put(removeUserSuccess())
    yield put(getStaffsRequest())
  } catch (err) {
    yield put(removeUserFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(ADD_USER_REQUEST, user), yield takeLatest(REMOVE_USER_REQUEST, removeUser)])
}
