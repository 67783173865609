import React, { Fragment } from 'react'
import { Icon, IconMap } from 'components'
import { OutsideClick } from 'containers/OutSide'

import { Props } from './@types'
import {
  Title,
  TitleS,
  Overlay,
  IconWrapper,
  WarningIcon,
  StyledHeader,
  ModalOverflowWrapper,
  AdaptiveModalWrapper,
} from './Style'

type CustomIconProps = {
  name: IconMap
  onAction: () => void
}

const CustomIcon = ({ name, onAction }: CustomIconProps) =>
  name && (
    <IconWrapper onClick={onAction}>
      <Icon name={name} />
    </IconWrapper>
  )

function DefaultLayout({ title, onClose, children }: Props) {
  return (
    <Fragment>
      <StyledHeader>
        <TitleS>{title}</TitleS>
        <CustomIcon name='close' onAction={onClose} />
      </StyledHeader>

      {children}
    </Fragment>
  )
}

function WarningLayout({ title, type, children }: Props) {
  return (
    <Fragment>
      <StyledHeader>
        <WarningIcon>!</WarningIcon>
        <Title type={type}>{title}?</Title>
      </StyledHeader>

      {children}
    </Fragment>
  )
}

function SelectModalType({ type = 'default', size = 'default', ...rest }: Props) {
  return (
    <Overlay>
      <ModalOverflowWrapper>
        <AdaptiveModalWrapper size={size} type={type}>
          <OutsideClick onClick={rest.onClose}>
            {type === 'warning' ? <WarningLayout type={type} {...rest} /> : <DefaultLayout {...rest} />}
          </OutsideClick>
        </AdaptiveModalWrapper>
      </ModalOverflowWrapper>
    </Overlay>
  )
}

export { SelectModalType }
