import { Clients, Transactions, Balances, Exchange, Invoices, Settings, Wallet, Auth, WebHooks } from 'modules'

import { links } from 'constant'

export const routes = [
  {
    path: links.balances,
    component: Balances,
  },
  {
    path: [links.limits, links.company, links.requests, links.staff, links.settings],
    component: Settings,
  },
  {
    path: [links.invoice, links.invoices],
    component: Invoices,
  },
  {
    path: links.exchange,
    component: Exchange,
  },
  {
    path: links.wallet,
    component: Wallet,
  },
  {
    path: links.transactions,
    component: Transactions,
  },
  {
    path: links.webHooks,
    component: WebHooks,
  },
  {
    path: [links.users, links.exchange],
    component: Clients,
  },
  {
    path: [links.signin, links.signup],
    component: Auth,
  },
]
