import React, { Component, Fragment } from 'react'

import { RequestState } from '@types'
import { createColumns, NewTable } from 'components'

import { COLUMNS, STATS } from './Columns'

import { Filter } from '../Filter'
import { newInvoice } from '../../modals'
import { RequestHeader } from '../RequestHeader'
import { Invoices as Type, InvoiceList, CreateInvoice, CommonCurrencies } from '../../@types'

type Props = {
  invoices: Type
  request: RequestState
  getData: () => void
  setFilters: () => void
  openModal: (data: {}) => void
  cryptoCurrencies: CommonCurrencies
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
  createInvoice: (params: CreateInvoice) => void
}

class Invoices extends Component<Props> {
  public handleOpenModal = () => {
    const { openModal } = this.props
    openModal(newInvoice())
  }

  public render() {
    const { getData, request, setFilters, invoices, selectPage, selectPageSize, cryptoCurrencies } = this.props

    return (
      <Fragment>
        <RequestHeader title='Invoices' withButton={true} open={this.handleOpenModal} />
        <NewTable data={invoices.stats} loading={request.isFetching} columns={createColumns(STATS)} />
        <br />
        <RequestHeader open={this.handleOpenModal} title='List of Invoices' />
        <Filter setFilters={setFilters} cryptoCurrencies={cryptoCurrencies} />
        <NewTable
          selection={true}
          getData={getData}
          pagination={true}
          data={invoices.data}
          selectPage={selectPage}
          setPageSize={selectPageSize}
          loading={request.isFetching}
          pageSize={invoices.pageSize}
          pageCount={invoices.pageCount}
          currentPage={invoices.currentPage}
          columns={createColumns<InvoiceList>(COLUMNS)}
        />
      </Fragment>
    )
  }
}

export { Invoices }
