import styled from 'styled-components'
import { font, color } from 'theme'
import { Button } from 'components'

export const TableContainer = styled.div`
  margin: 0 0 20px;
`

export const TableWrap = styled.div`
  margin: 0 0 40px;
`

export const TableTitle = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const TableText = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
`

export const CryptoElement = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const ApiName = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.43;
`

export const CopyWrap = styled.span`
  width: 100%;
  max-width: max-content;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
`

export const IconWrapper = styled.div`
  margin: 0 8px 0 0;
`

export const Copied = styled.span`
  max-width: 88px;
  display: block;
  width: 100%;
  padding: 14px;
  font-family: ${font.display};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${color.success};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  z-index: 2;
`

export const AddButton = styled(Button)`
  margin: 0 0 20px;
`

export const BtnContainer = styled.div`
  margin: 0 0 40px;
  display: flex;
`

export const ResetButton = styled(Button)`
  margin: 20px 0 0;
  max-width: 178px;
`

export const Date = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: ${color.white};
  opacity: 0.5;
`

export const Url = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: ${color.white};
`

export const ChangeBtn = styled(Button)`
  padding: 10px;
  min-width: auto;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`
