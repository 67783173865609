import { RequestState } from '@types'

import { Actions, Staffs } from '../@types'
import {
  SET_STAFF,
  GET_STAFF_FAILURE,
  REMOVE_USER_CLEAR,
  REMOVE_USER_FAILURE,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getStaffRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_STAFF_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_STAFF_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_STAFF_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

export const removeStaffRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case REMOVE_USER_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case REMOVE_USER_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case REMOVE_USER_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    case REMOVE_USER_CLEAR:
      return initialDomainState
    default:
      return state
  }
}

const initialUiState: Staffs = []

export const staffs = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_STAFF:
      const { data } = action.payload
      return data
    default:
      return state
  }
}
