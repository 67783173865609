/* eslint-disable react/display-name */
import React, { Fragment, Component } from 'react'

import { RequestState } from '@types'
import { LOW_DEBOUNCE, CRYPTO_DECIMALS } from 'constant'
import { copyToClipboard, debounce, cutDecimals } from 'utils'

import { Button, Input, Textarea, Icon, KeysListener } from 'components'

import { CryptoRequestInput, CommonCurrencies } from '../../@types'

import { SelectCoin } from './SelectCoin'

import {
  Wrap,
  Copied,
  Container,
  TitleSend,
  IconWrapper,
  CopyWrapper,
  IconWrapSend,
  CopyAddress,
  CopyAddressValue,
  CopyAddressLabel,
  CopyAddressWrapper,
} from './Style'

type InjectorProps = {
  company: {
    withdrawal_btc_address: string
    withdrawal_eth_address: string
    withdrawal_usdt_address: string
  }
  getCompany: () => void
}

type Props = InjectorProps & {
  request: RequestState
  clearState: () => void
  closeModal: () => void
  clearErrors: () => void
  onError: (message: string) => void
  cryptoCurrencies: CommonCurrencies
  newRequest: (data: CryptoRequestInput) => void
}

type State = {
  decimal: number
  amount: string
  comment?: string
  currency: string
  isCopied: boolean
  isDisabled: boolean
}

type Key = keyof Exclude<'isCopied', keyof State>

const currenciesMinValues: { [key in string]: number } = {
  BTC: 0.1,
  ETH: 1,
}

const DEFAULT_MIN_VALUE = 1000
const LOW_VALUE_ERROR = 'Value is less than permissible'

class NewCryptoRequest extends Component<Props, State> {
  public debounceFunc: (amount: string) => void

  public constructor(props: Props) {
    super(props)
    this.debounceFunc = debounce(this.getIsValid, LOW_DEBOUNCE)

    this.state = {
      amount: '',
      currency: 'BTC',
      isCopied: false,
      isDisabled: false,
      comment: undefined,
      decimal: this.getDecimal('BTC'),
    }
  }

  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public componentDidMount() {
    const { getCompany } = this.props
    getCompany()
  }

  public handleSubmit = () => {
    const { isDisabled } = this.state

    if (!isDisabled) {
      this.handleReq()
    }
  }

  public handleClose = () => {
    const { closeModal, clearState } = this.props

    clearState()
    closeModal()
  }

  public render() {
    const { request, cryptoCurrencies } = this.props
    const { amount, currency, comment, isCopied, isDisabled } = this.state

    return (
      <Fragment>
        {!request.isSuccess ? (
          <Wrap>
            <KeysListener handleSubmit={this.handleSubmit}>
              <Container>
                <Input
                  type='number'
                  name='amount'
                  label='Amount'
                  value={amount}
                  pattern='[0-9]*'
                  inputMode='decimal'
                  error={request.isError}
                  onChange={this.handleChangeAmount}
                  errorMessage={this.getError(request.errorMessage)}
                  placeholder={String(currenciesMinValues[currency] || DEFAULT_MIN_VALUE)}
                />

                <SelectCoin
                  value={currency}
                  name='currency'
                  onChange={this.handleChange}
                  cryptoCurrencies={cryptoCurrencies}
                />
              </Container>

              <CopyAddress>
                <CopyAddressLabel>To</CopyAddressLabel>
                <CopyAddressWrapper>
                  {isCopied ? (
                    <Copied>Copied</Copied>
                  ) : (
                    <CopyWrapper onClick={this.handleCopy}>
                      <CopyAddressValue>{this.getAddress()}</CopyAddressValue>
                      <IconWrapper>
                        <Icon name='copy' size='extraMedium' />
                      </IconWrapper>
                    </CopyWrapper>
                  )}
                </CopyAddressWrapper>
              </CopyAddress>

              <Textarea label='Comment' name='comment' value={comment} onChange={this.handleChange} />

              <Button isLoading={request.isFetching} onClick={this.handleReq} disabled={isDisabled}>
                Create request
              </Button>
            </KeysListener>
          </Wrap>
        ) : (
          <Fragment>
            <IconWrapSend>
              <Icon name='tick' size='auto' />
            </IconWrapSend>
            <TitleSend>Request has been sent</TitleSend>
          </Fragment>
        )}
      </Fragment>
    )
  }

  private getDecimal = (value: string) => {
    const { cryptoCurrencies } = this.props

    const observedCurrency = cryptoCurrencies.find(({ name }) => name === value.toUpperCase())

    if (observedCurrency) {
      return observedCurrency.decimal
    }
    return CRYPTO_DECIMALS
  }

  private getIsValid = (amount: string) => {
    if (!amount) {
      this.setState(() => ({ isDisabled: true }))
      return
    }

    if (!Number(amount)) {
      this.setState(() => ({ isDisabled: true }))

      return
    }
    this.setState(() => ({ isDisabled: false }))
  }

  private handleChange = (value: string | number, key?: Key) => {
    const { currency } = this.state
    const { clearErrors } = this.props

    if (key) {
      if (key === 'currency' && value !== currency) {
        clearErrors()

        this.setState({ decimal: this.getDecimal(String(value)), amount: '' })
      }

      this.setState(prevState => ({ ...prevState, [key]: value }))
    }
  }

  private handleChangeAmount = (value: string | number, key?: Key, targetValue?: string) => {
    const { decimal } = this.state
    if (key) {
      this.debounceFunc(String(value))

      this.setState(prevState => ({ ...prevState, [key]: cutDecimals(targetValue, decimal) }))
    }
  }

  private getAddress = () => {
    const { currency } = this.state
    const { company } = this.props

    const mockedAddresses: { [key in string]: string } = {
      BTC: company.withdrawal_btc_address,
      ETH: company.withdrawal_eth_address,
    }
    return mockedAddresses[currency] || company.withdrawal_usdt_address
  }

  private handleCopy = () => {
    const TIMER = 800

    copyToClipboard(this.getAddress())
    this.setState(() => ({ isCopied: true }))

    setTimeout(() => {
      this.setState(() => ({ isCopied: false }))
    }, TIMER)
  }

  private handleReq = () => {
    const { comment, currency, amount } = this.state
    const { newRequest, onError } = this.props

    if (!amount) {
      onError('Please enter the amount')
      return
    }

    newRequest({
      amount: Number(amount),
      comment,
      currency_name: currency,
    })
  }

  private getError = (error: string) => {
    const { currency } = this.state

    if (!error) {
      return ''
    }

    if (LOW_VALUE_ERROR === error) {
      return `Min value ${currenciesMinValues[currency] || DEFAULT_MIN_VALUE} ${currency}`
    }
    return error
  }
}
export { NewCryptoRequest }
