import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { routes } from './Route'

const allRoute = (
  <Switch>
    {routes.map(({ path, component }, index: number) => (
      <Route path={path} component={component} key={index} />
    ))}
  </Switch>
)

export { allRoute }
