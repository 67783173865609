import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { withInjectors } from '../injectors'
import { SubmitDoc as View } from '../components'
import { ClientState, Company } from '../@types'
import { selectCompany } from '../selectors'

type Props = {
  company: Company
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    company: selectCompany(),
  })

const withConnect = connect(mapStateToProps)

export const SubmitDocContainer = compose<ComponentType>(withInjectors, withConnect)(View)
