import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators, compose } from 'redux'

import { Toast as View } from './Toast'
import { withInjectors } from './withInjectors'
import { ToastContainerOwnProps } from './@types'
import { showToast, closeAllToast, closeToast } from './actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showToast,
      closeToast,
      closeAllToast,
    },
    dispatch,
  )

const withConnect = connect(null, mapDispatchToProps)

export const ToastContainer = compose<ComponentType<ToastContainerOwnProps>>(withInjectors, withConnect)(View)
