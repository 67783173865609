import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { font, color } from 'theme'

export const Item = styled.li``

export const StyledAnchor = styled.span`
  font-family: ${font.display};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${color.grey};
  transition: color 200ms;
  cursor: pointer;
  &:hover {
    color: ${color.white};
  }
`

export const StyledLink = styled(NavLink)`
  font-family: ${font.display};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${color.grey};
  text-decoration: none;
  transition: color 200ms, background-color 200ms;
  outline: none;
  cursor: pointer;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &:hover,
  &:focus,
  &.activeNavLink {
    color: ${color.white};
  }
  &.activeNavLink {
    background-color: #32313e;
  }
  .icon-wrapper {
    font-size: 14px;
    width: auto;
    height: auto;
    > svg {
      width: 1em;
      height: 1em;
    }
  }
  .link-title {
    margin-left: 6px;
    transition: all 0.3s ease;
  }
`
