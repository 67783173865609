import React from 'react'

import { createModal } from 'containers/Modal'
import { ChangeRateRequestContainer } from '../containers'
import { GetProps } from 'react-redux'

type Props = GetProps<typeof ChangeRateRequestContainer>

const containerWithProps = (props: Props) => <ChangeRateRequestContainer {...props} />

const newChangeRateRequest = (props: Props) =>
  createModal({
    name: 'OPEN_NEW_FIXED_RATE_REQUEST',
    title: props.title,
    container: containerWithProps(props),
  })

export { newChangeRateRequest }
