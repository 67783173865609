import styled from 'styled-components'
import { color } from 'theme'

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 2.7px;
  background-color: transparent;
  border: solid 2px rgba(255, 255, 255, 0.15);
  transition: background-color 200ms;
`

export const CheckboxIcon = styled.div`
  display: block;
  opacity: 0;
  transition: opacity 200ms;
`

export const CheckboxInput = styled.input`
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  &:hover ~ ${CheckboxContainer} {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:focus ~ ${CheckboxContainer} {
    border-color: ${color.default};
  }
  &:checked ~ ${CheckboxContainer} {
    background-color: ${color.default};
  }
  &:checked ~ ${CheckboxContainer} > ${CheckboxIcon} {
    opacity: 1;
  }
`
