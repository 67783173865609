import React from 'react'

const ArrowHead = () => (
  <svg viewBox='0 0 7 12'>
    <path
      fillRule='evenodd'
      d='M5.95 5.29L1.71 1.05A1 1 0 10.29 2.46L3.83 6 .29 9.54a1 1 0 000 1.41 1 1 0 00.71.29 1 1 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z'
      clipRule='evenodd'
    />
  </svg>
)

export { ArrowHead }
