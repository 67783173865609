import React from 'react'

const Usdc = () => (
  <svg fill='none' viewBox='0 0 44 44'>
    <path fill='#2775ca' d='M22 44c12.192 0 22-9.808 22-22S34.192 0 22 0 0 9.808 0 22s9.808 22 22 22z' />
    <path
      fill='#fff'
      d='M28.05 25.484c0-3.209-1.925-4.309-5.775-4.767-2.75-.367-3.3-1.1-3.3-2.383 0-1.284.917-2.109 2.75-2.109 1.65 0 2.567.55 3.025 1.925a.69.69 0 00.642.459h1.466a.627.627 0 00.642-.642v-.092a4.58 4.58 0 00-4.125-3.758v-2.2c0-.367-.275-.642-.733-.733h-1.375c-.367 0-.642.275-.734.733v2.108c-2.75.367-4.491 2.2-4.491 4.492 0 3.025 1.833 4.217 5.683 4.675 2.567.458 3.392 1.008 3.392 2.475s-1.284 2.475-3.025 2.475c-2.384 0-3.209-1.008-3.484-2.383-.091-.367-.366-.55-.641-.55h-1.559a.627.627 0 00-.641.641v.092c.366 2.292 1.833 3.942 4.858 4.4v2.2c0 .367.275.642.733.733h1.375c.367 0 .642-.275.734-.733v-2.2c2.75-.458 4.583-2.383 4.583-4.858z'
    />
    <path
      fill='#fff'
      d='M17.325 35.108c-7.15-2.566-10.817-10.541-8.158-17.6 1.375-3.85 4.4-6.783 8.158-8.158.367-.183.55-.458.55-.916V7.15c0-.366-.183-.641-.55-.733-.092 0-.275 0-.367.092C8.25 9.259 3.483 18.517 6.233 27.225A16.49 16.49 0 0016.958 37.95c.367.183.734 0 .825-.367.092-.091.092-.183.092-.366v-1.283c0-.276-.275-.642-.55-.825zm9.717-28.6c-.367-.183-.734 0-.825.367-.092.092-.092.184-.092.367v1.283c0 .367.275.734.55.917 7.15 2.566 10.817 10.541 8.158 17.6-1.375 3.85-4.4 6.783-8.158 8.158-.367.183-.55.458-.55.917V37.4c0 .367.183.642.55.733.092 0 .275 0 .367-.091 8.708-2.75 13.475-12.009 10.725-20.717C36.117 12.1 32.083 8.16 27.042 6.51z'
    />
  </svg>
)

export { Usdc }
