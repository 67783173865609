import React, { Fragment, Component } from 'react'

import { ZERO } from 'constant'

import { Input, Checkbox, Button, Toggle, DatePickers, Select, InputWrap, CheckboxWrap } from 'components'

import { FilterCryptoRequest, Staff, CommonCurrencies } from '../../@types'
import { Container, Title, MainWrapper, Row, Column, ContainerButtons, Element, ManagerName } from './Style'

type Props = {
  managers: Staff[]
  cryptoCurrencies: CommonCurrencies
  setFilters: (filters: FilterCryptoRequest) => void
}

// TODO: undefined fixed
const initialState = {
  filters: {
    ids: '',
    date_from: undefined,
    date_to: undefined,
    currency_names: [],
    staff_ids: [],
    amount_from: '',
    amount_to: '',
    comment: '',
    status: '',
  },
  selected: { value: '', label: 'All' },
}

type Option = {
  value: string
  label: string
}

type State = {
  filters: FilterCryptoRequest
  selected: Option
}

const options: Option[] = [
  { value: '', label: 'All' },
  { value: 'created', label: 'Created' },
  { value: 'approved', label: 'Approved' },
  { value: 'declined', label: 'Declined' },
  { value: 'transferring', label: 'Transferring' },
  { value: 'complete', label: 'Complete' },
]

class CryptoFilter extends Component<Props, State> {
  public state = initialState

  public renderManger = ({ name, id }: Staff) => {
    const { filters } = this.state

    const handleClick = () => {
      this.handleSetStaffId(String(id))
    }

    return (
      <CheckboxWrap key={name}>
        <label htmlFor={name}>
          <Element>
            <ManagerName>{name}</ManagerName>
            <Checkbox id={name} onClick={handleClick} checked={filters.staff_ids.includes(String(id) as never)} />
          </Element>
        </label>
      </CheckboxWrap>
    )
  }

  public render() {
    const {
      selected,
      filters: { date_to, date_from, ids },
    } = this.state

    return (
      <Fragment>
        <Container>
          <Title>Crypto withdrawal history</Title>
        </Container>

        <MainWrapper>
          <Toggle title='Search withdrawal'>
            <Row>
              <Column>
                <InputWrap>
                  <Input type='text' value={ids} label='ID' placeholder='ID' onChange={this.handleSetId} />
                </InputWrap>
              </Column>

              <Column>
                <InputWrap>
                  <DatePickers
                    to={date_to}
                    from={date_from}
                    setEndDate={this.handleSetDateTo}
                    setStartDate={this.handleSetDateFrom}
                    label='Date'
                  />
                </InputWrap>
              </Column>

              <Column>
                <InputWrap>
                  <Select label='Status' options={options} value={selected.value} select={this.handleSelectType} />
                </InputWrap>
              </Column>
            </Row>

            <ContainerButtons>
              <Button onClick={this.handleGetClients}>Search</Button>
            </ContainerButtons>
          </Toggle>
        </MainWrapper>
      </Fragment>
    )
  }

  private handleGetClients = () => {
    const { setFilters } = this.props

    const { filters, selected } = this.state
    const keysState = Object.keys(filters) as (keyof State['filters'])[]

    const dataFilters = { ...filters }

    keysState.forEach(key => {
      if (!dataFilters[key] && String(dataFilters[key]) !== String(ZERO)) {
        delete dataFilters[key]
      }
    })

    setFilters({
      ...dataFilters,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      staff_ids: filters.staff_ids.join(','),
      status: selected.value !== '' ? selected.value : undefined,
    })
  }

  private handleSetFilters = (data: {}) => {
    this.setState(state => ({
      filters: {
        ...state.filters,
        ...data,
      },
    }))
  }

  private handleSelectType = (params: Option) => {
    this.setState(() => ({
      selected: {
        label: params.label,
        value: params.value.toLowerCase(),
      },
    }))
  }

  private handleSetId = (id: string) => {
    this.handleSetFilters({ ids: id })
  }

  private handleSetStaffId = (staffId?: string) => {
    const {
      filters: { staff_ids },
    } = this.state

    if (staff_ids.includes(staffId as never)) {
      const filtered = staff_ids.filter(i => i !== staffId)
      this.handleSetFilters({ staff_ids: filtered })
    } else {
      this.handleSetFilters({ staff_ids: [...staff_ids, staffId] })
    }
  }

  private handleSetDateFrom = (date: Date) => {
    this.handleSetFilters({ date_from: date })
  }

  private handleSetDateTo = (date: Date) => {
    this.handleSetFilters({ date_to: date })
  }
}

export { CryptoFilter }
