import React from 'react'

import { createModal } from 'containers/Modal'
import { WebHooksData as View } from '../components'

type Data = {
  body?: string
  header?: string
}

const containerWithProps = ({ body, header }: Data) => <View body={body} header={header} />

const WebHooksData = (data: Data) =>
  createModal({
    name: 'OPEN_WEB_HOOKS_DATA',
    title: `Webhook information`,
    container: containerWithProps(data),
  })

export { WebHooksData }
