import React, { Component, Fragment } from 'react'
import * as speakeasy from 'speakeasy'

import { RequestState } from '@types'
import { links, TERMS, PRIVACYPOLICY } from 'constant'
import { notify } from 'containers/Notification/notify'
import { Href, Copy, Checkbox, Icon, VerificationCode, QrCode, KeysListener } from 'components'

import { SignUpInput } from '../../@types'
import { Title, InputSignIn, Wrapper } from '../'

import {
  Text,
  Link,
  BgWrap,
  SmallText,
  CopyWrapper,
  IconWrapper,
  StyledButton,
  CheckboxText,
  LinkCheckbox,
  CheckboxWrapper,
} from './Style'

type State = {
  hasError: boolean
  email: string
  otp_url: string
  isOpen: boolean
  otp_secret: string
  staff_name: string
  isChecked: boolean
  password_hash: string
  privateSecret: string
  two_factor_code: string
}

type SignInParams = {
  email: string
  staff_name: string
  password_hash: string
}

type Props = {
  otp_secret: string
  signUpRequest: RequestState
  push: (link: string) => void
  signUp: (params: SignUpInput) => void
}

class SignUp extends Component<Props, State> {
  public state = {
    email: '',
    otp_url: '',
    isOpen: false,
    otp_secret: '',
    staff_name: '',
    hasError: false,
    isChecked: false,
    password_hash: '',
    privateSecret: '',
    two_factor_code: '',
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const { signUpRequest } = this.props

    if (signUpRequest.isError && signUpRequest.isError !== prevProps.signUpRequest.isError) {
      this.setState(() => ({ hasError: true }))
    }
  }

  public generateSecret = (email: string) => {
    const SECRET_LENGTH = 32
    const secret = speakeasy.generateSecret({ length: SECRET_LENGTH })

    this.setState(() => ({
      otp_secret: secret.base32,
      otp_url: `otpauth://totp/${email}?secret=${secret.base32}&issuer=Cryptopayments`,
      privateSecret: secret.ascii,
    }))
  }

  public getIcon = () => {
    return { name: 'arrow', onAction: this.handleIsOpen } as const
  }
  public render() {
    const { isOpen, otp_secret, otp_url, hasError, isChecked, two_factor_code } = this.state
    const { push, signUpRequest } = this.props

    const isValid = !isChecked

    return !isOpen ? (
      <Fragment>
        <InputSignIn signIn={this.handleSetData} push={push} title='Sign up' buttonText='Continue'>
          <Text>
            Already have an&nbsp;account?{' '}
            <Link onClick={this.redirectToSignIn} data-test='sign_up_link_to_main'>
              Log In
            </Link>
          </Text>
        </InputSignIn>
      </Fragment>
    ) : (
      <Wrapper>
        <KeysListener handleSubmit={this.handleSignUp}>
          <Title text='Sign up' iconLeft={this.getIcon()} />
          <BgWrap />

          <QrCode size='medium' value={otp_url} />

          <Text marginText='0 0 16px;'>
            Scan the&nbsp;QR code with the two-factor authentication app on&nbsp;your phone. If&nbsp;you can&rsquo;t use
            camera enter this code instead in&nbsp;the app.
          </Text>

          <Copy value={otp_secret}>
            <CopyWrapper>
              {otp_secret}
              <IconWrapper>
                <Icon name='copy' size='extraMedium' />
              </IconWrapper>
            </CopyWrapper>
          </Copy>

          <SmallText>Enter the six-digit code from the application</SmallText>
          <VerificationCode
            isError={hasError}
            value={two_factor_code}
            onActive={this.handleSetCodeValue}
            errorMessage={signUpRequest.errorMessage || 'Wrong code'}
          />

          <StyledButton isLoading={signUpRequest.isFetching} onClick={this.handleSignUp} disabled={isValid}>
            Create account
          </StyledButton>

          <CheckboxWrapper>
            <Checkbox id='termsAndPrivacy' onClick={this.handleCheckboxChange} />

            <label htmlFor='termsAndPrivacy'>
              <CheckboxText>
                By&nbsp;clicking Create account, you confirm that you have read and agree to&nbsp;our{' '}
                <Href href={TERMS}>
                  <LinkCheckbox>Terms of use</LinkCheckbox>
                </Href>{' '}
                and{' '}
                <Href href={PRIVACYPOLICY}>
                  <LinkCheckbox>Privacy Policy</LinkCheckbox>
                </Href>
                .
              </CheckboxText>
            </label>
          </CheckboxWrapper>
        </KeysListener>
      </Wrapper>
    )
  }

  private handleSetCodeValue = (value: string) => {
    this.setState(() => ({ two_factor_code: value, hasError: false }))
  }

  public handleCheckboxChange = () => {
    this.setState(state => ({ isChecked: !state.isChecked }))
  }

  private handleSetData = ({ password_hash, email, staff_name }: SignInParams) => {
    this.generateSecret(email)
    this.setState(() => ({ password_hash, email, staff_name }))
    this.handleIsOpen()
  }

  private handleSignUp = () => {
    const { signUp } = this.props
    const { password_hash, two_factor_code, email, staff_name, privateSecret, isChecked } = this.state

    if (!two_factor_code || !isChecked) {
      this.setState(() => ({ hasError: true }))

      const errorText = 'This action is not available, please check the completed fields'

      notify({ type: 'error', text: errorText })
    } else {
      signUp({
        email,
        staff_name,
        password_hash,
        two_factor_code,
        company_name: 'default',
        otp_secret: privateSecret,
      })
    }
  }

  private handleIsOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen, isChecked: false }))
  }

  private redirectToSignIn = () => {
    const { push } = this.props
    push(links.signin)
  }
}

export { SignUp }
