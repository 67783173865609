import React from 'react'

const Gbp = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#C8102E'
      d='M28.42 0h3.23c1.46.14 2.92.34 4.36.6C35.98 8.4 36 16.2 36 24h23.41c.24 1.47.45 2.94.59 4.42v3.24c-.14 1.46-.34 2.91-.61 4.35C51.6 35.99 43.8 36 36 36v23.4c-1.47.24-2.94.46-4.42.6h-3.24a63.16 63.16 0 01-4.35-.6c.03-7.8.01-15.6.01-23.4H.59C.35 34.53.14 33.06 0 31.58v-3.24c.14-1.46.34-2.91.6-4.35 7.8.03 15.6.01 23.4.01V.6c1.47-.25 2.94-.46 4.42-.6z'
    />
    <path
      fill='#C8102E'
      d='M54.87 13.09c.71 1.13 1.35 2.31 1.96 3.5-2.26 1.05-4.4 2.35-6.72 3.26-2.95.19-5.91.01-8.87.03 4.56-2.24 9.09-4.52 13.63-6.79zM3.1 16.57c2.24 1.1 4.47 2.2 6.7 3.32-2.68.03-5.37.03-8.05.02.41-1.13.86-2.24 1.35-3.34zM3.17 43.41c2.28-1.06 4.45-2.38 6.81-3.29 2.89-.55 5.86-.03 8.76.15-4.63 2-9.06 4.44-13.6 6.64-.7-1.14-1.36-2.31-1.97-3.5zM50.21 40.11c2.72-.02 5.44.08 8.15-.25-.45 1.21-.93 2.4-1.46 3.57-2.23-1.1-4.47-2.2-6.69-3.32z'
    />
    <path
      fill='#fff'
      d='M19.92 1.71C21.26 1.27 22.62.9 24 .6V24c-7.8 0-15.6.02-23.4-.01.32-1.38.7-2.74 1.15-4.08 2.68.01 5.37.01 8.05-.02-2.23-1.12-4.46-2.22-6.7-3.32.97-1.76 2.09-3.43 3.27-5.06 4.53 2.2 9 4.51 13.53 6.71.04-5.5 0-11 .02-16.51zM36.01.6c1.38.32 2.74.71 4.09 1.15-.02 5.5-.04 11 .01 16.49 4.51-2.23 8.99-4.55 13.53-6.73.3.39.92 1.18 1.23 1.58-4.54 2.27-9.07 4.55-13.63 6.79 2.96-.02 5.92.16 8.87-.03 2.32-.91 4.46-2.21 6.72-3.26 1.2 2.34 2.03 4.84 2.58 7.41H36c0-7.8-.02-15.6.01-23.4zM.59 36H24c0 7.8.02 15.6-.01 23.4-1.38-.32-2.74-.71-4.09-1.15-.01-5.45.01-10.9.19-16.35-4.74 1.85-9.12 4.48-13.72 6.61-.43-.52-.84-1.06-1.23-1.6 4.54-2.2 8.97-4.64 13.6-6.64-2.9-.18-5.87-.7-8.76-.15-2.36.91-4.53 2.23-6.81 3.29C1.98 41.07 1.13 38.57.59 36zM36 36c7.8 0 15.6-.01 23.39.01-.3 1.3-.64 2.58-1.03 3.85-2.71.33-5.43.23-8.15.25 2.22 1.12 4.46 2.22 6.69 3.32-.97 1.76-2.09 3.43-3.27 5.06-4.53-2.21-9-4.52-13.53-6.72-.04 5.51 0 11.01-.02 16.52-1.34.44-2.7.81-4.08 1.11V36z'
    />
    <path
      fill='#012169'
      d='M6.37 11.51c3.4-4.54 8.28-7.82 13.55-9.8-.02 5.51.02 11.01-.02 16.51-4.53-2.2-9-4.51-13.53-6.71zM40.1 1.75c5.29 1.9 10.11 5.29 13.54 9.76-4.54 2.18-9.02 4.5-13.53 6.73-.05-5.49-.03-10.99-.01-16.49zM6.37 48.51c4.6-2.13 8.98-4.76 13.72-6.61-.18 5.45-.2 10.9-.19 16.35-5.29-1.89-10.09-5.3-13.53-9.74zM40.1 41.77c4.53 2.2 9 4.51 13.53 6.72-3.4 4.54-8.27 7.82-13.55 9.8.02-5.51-.02-11.01.02-16.52z'
    />
  </svg>
)

export { Gbp }
