import { color } from './Color'

const button = {
  opacityDisabled: 0.5,
  color: {
    default: {
      default: color.white,
      warning: color.white,
      ghost: color.grey,
      ghostWarning: color.warning,
      ghostAccent: color.default,
      link: color.link,
    },
    hover: {
      default: color.white,
      warning: color.white,
      ghost: color.greyLight,
      ghostWarning: color.ghostWarningBorder,
      ghostAccent: color.white,
      link: color.linkHover,
    },
    focus: {
      default: color.white,
      warning: color.white,
      ghost: color.greyLight,
      ghostWarning: color.ghostWarningBorder,
      ghostAccent: color.white,
      link: color.linkHover,
    },
    pressed: {
      default: color.white,
      warning: color.white,
      ghost: color.greyLight,
      ghostWarning: color.ghostWarningBorder,
      ghostAccent: color.white,
      link: color.linkHover,
    },
  },
  background: {
    default: {
      default: color.default,
      warning: color.warning,
      ghost: color.transparent,
      ghostWarning: color.transparent,
      ghostAccent: color.transparent,
      link: color.transparent,
    },
    hover: {
      default: color.primary,
      warning: color.warningHover,
      ghost: color.ghostHover,
      ghostWarning: color.ghostWarningHover,
      ghostAccent: color.default,
      link: color.transparent,
    },
    focus: {
      default: color.primary,
      warning: color.warningHover,
      ghost: color.ghostHover,
      ghostWarning: color.ghostWarningHover,
      ghostAccent: color.default,
      link: color.transparent,
    },
    pressed: {
      default: color.primary,
      warning: color.warningHover,
      ghost: color.ghostHover,
      ghostWarning: color.ghostWarningHover,
      ghostAccent: color.default,
      link: color.transparent,
    },
  },
  border: {
    default: {
      default: 'none',
      warning: 'none',
      ghost: `1px solid ${color.grey}`,
      ghostWarning: `1px solid ${color.warning}`,
      ghostAccent: `1px solid ${color.default}`,
      link: 'none',
    },
    hover: {
      default: 'none',
      warning: 'none',
      ghost: `1px solid ${color.greyLight}`,
      ghostWarning: `1px solid ${color.ghostWarningBorder}`,
      ghostAccent: `1px solid ${color.default}`,
      link: 'none',
    },
    focus: {
      default: 'none',
      warning: 'none',
      ghost: `1px solid ${color.greyLight}`,
      ghostWarning: `1px solid ${color.ghostWarningBorder}`,
      ghostAccent: `1px solid ${color.default}`,
      link: 'none',
    },
    pressed: {
      default: 'none',
      warning: 'none',
      ghost: `1px solid ${color.greyLight}`,
      ghostWarning: `1px solid ${color.ghostWarningBorder}`,
      ghostAccent: `1px solid ${color.default}`,
      link: 'none',
    },
  },
}

export { button }
