import React from 'react'

const Info = () => (
  <svg fill='none' viewBox='0 0 16 16'>
    <path
      fill='#fff'
      d='M8 1.333A6.67 6.67 0 001.333 8 6.669 6.669 0 008 14.666 6.669 6.669 0 0014.667 8 6.669 6.669 0 008 1.333zm.667 10H7.334v-4h1.333v4zm0-5.333H7.334V4.666h1.333V6z'
      opacity='.5'
    />
  </svg>
)

export { Info }
