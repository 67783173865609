import { RESET_API_REQUEST, RESET_API_FAILURE, RESET_API_SUCCESS } from '../constants'

export const resetApiRequest = () => {
  return {
    type: RESET_API_REQUEST,
  } as const
}

export const resetApiFailure = (errorMessage: string) => {
  return {
    type: RESET_API_FAILURE,
    payload: { errorMessage },
  } as const
}

export const resetApiSuccess = () => {
  return {
    type: RESET_API_SUCCESS,
  } as const
}
