import React, { Fragment } from 'react'
import moment from 'moment'

import { LogoLink, Icon, DecimalsFixed } from 'components'

import {
  Container,
  MainInfo,
  TitleWrap,
  Title,
  InfoFunds,
  InfoFundsPreTitle,
  InfoFundsTitle,
  ExploreLink,
  ExplorerLinkText,
} from './Style'
import { Header } from '../Invoice/Style'

type Props = {
  date: string
  status: string
  amount: number
  currency: string
  paid_tx?: string
  paid_currency?: string
}

const FundsRecievedInvoice = ({ status, amount, currency, date, paid_currency, paid_tx }: Props) => {
  const link =
    paid_currency === 'BTC' ? `https://www.blockchain.com/btc/tx/${paid_tx}` : `https://etherscan.io/tx/${paid_tx}`

  return (
    <Fragment>
      <Header>
        <LogoLink />
      </Header>
      <Container>
        <MainInfo>
          <TitleWrap isDeclined={status === 'declined'}>
            {status === 'declined' ? (
              <Fragment>
                <Icon name='declined' size='extraMedium' />
                <Title>Declined</Title>
              </Fragment>
            ) : (
              <Fragment>
                <Icon name='apply' size='extraMedium' />
                <Title>Funds received</Title>
              </Fragment>
            )}
          </TitleWrap>
          {status !== 'paid_after_expired' && (
            <InfoFunds>
              <InfoFundsPreTitle>Value</InfoFundsPreTitle>
              <InfoFundsTitle>
                {DecimalsFixed(amount, 'fiat')} {currency}
              </InfoFundsTitle>
            </InfoFunds>
          )}
          {status !== 'declined' && (
            <InfoFunds>
              <InfoFundsPreTitle>Funds send</InfoFundsPreTitle>
              <InfoFundsTitle>{moment(date).format('DD.MM.YY, HH:mm:ss')}</InfoFundsTitle>
            </InfoFunds>
          )}
        </MainInfo>

        {status !== 'declined' && (
          <ExploreLink href={link} target='_blank' rel='noopener noreferrer'>
            <Icon name='href' size='medium' color='#4678ff' />
            <ExplorerLinkText>View in&nbsp;explorer</ExplorerLinkText>
          </ExploreLink>
        )}
      </Container>
    </Fragment>
  )
}

export { FundsRecievedInvoice }
