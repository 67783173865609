import { createSelector } from 'reselect'

import { InvoiceState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: InvoiceState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: InvoiceState) => state[DOMAIN][DOMAIN_STATE]

// UI
export const selectInvoice = () => createSelector(selectUi, subState => subState.invoice)
export const selectInvoices = () => createSelector(selectUi, subState => subState.invoices)

// DOMAIN
export const selectGetInvoices = () => createSelector(selectDomain, subState => subState.getInvoices)
export const selectCheckInvoiceRequest = () => createSelector(selectDomain, subState => subState.checkInvoiceRequest)
export const selectCrateInvoiceRequest = () => createSelector(selectDomain, subState => subState.createInvoiceRequest)
