import { CommonCurrencies } from '../@types'
import { SET_CURRENCIES, GET_CURRENCIES_FAILURE, GET_CURRENCIES_REQUEST, GET_CURRENCIES_SUCCESS } from '../constants'

export const getCurrenciesRequest = () => {
  return {
    type: GET_CURRENCIES_REQUEST,
  } as const
}

export const getCurrenciesFailure = (errorMessage: string) => {
  return {
    type: GET_CURRENCIES_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getCurrenciesSuccess = () => {
  return {
    type: GET_CURRENCIES_SUCCESS,
  } as const
}

export const setCurrencies = (data: CommonCurrencies) => {
  return {
    type: SET_CURRENCIES,
    payload: { data },
  } as const
}
