import { all, put, takeLatest, call } from 'redux-saga/effects'
import { notify } from 'containers/Notification/notify'

import { mutate } from '../client'

import { ON_RESEND_REQUEST } from '../constants'
import { RESEND_WEB_HOOK } from '../mutation'
import { onResendRequest, onResendFailure, getWebHooksRequest } from '../actions'

export function* resendHook({ payload }: ReturnType<typeof onResendRequest>) {
  try {
    yield call(mutate, {
      mutation: RESEND_WEB_HOOK,
      variables: payload,
    })

    yield put(getWebHooksRequest())
    notify({ type: 'action', text: `webhook for id ${payload.id} was resend` })
  } catch (err) {
    yield put(onResendFailure(err.messages))
    notify({ type: 'important', text: `webhook for id ${payload.id} was not resend` })
  }
}

export function* saga() {
  return yield all([yield takeLatest(ON_RESEND_REQUEST, resendHook)])
}
