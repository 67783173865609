import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { closeAllModal } from 'containers'
import { CompanyInjectors } from 'modules/Settings'
import { CurrenciesInjectors } from 'modules/Global'

import { ClientState } from '../@types'
import { withInjectors } from '../injectors'
import { createStructuredSelector } from 'reselect'
import { selectNewCryptoRequest } from '../selectors'
import { NewCryptoRequest as View } from '../components'
import { newCryptoRequest, newCryptoClear, newCryptoFailure, newCryptoErrorClear } from '../actions'

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      onError: newCryptoFailure,
      closeModal: closeAllModal,
      clearState: newCryptoClear,
      newRequest: newCryptoRequest,
      clearErrors: newCryptoErrorClear,
    },
    dispatch,
  )
}

type Props = {
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectNewCryptoRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const NewCryptoRequestContainer = compose<ComponentType>(
  CompanyInjectors,
  CurrenciesInjectors,
  withInjectors,
  withConnect,
)(View)
