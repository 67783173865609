import React, { Component } from 'react'

import { addUser, staffRemoveRequest } from '../../modals'
import { Staffs } from '../../@types'

import { NewTable, createColumns } from 'components'

import { COLUMNS } from './Columns'
import { TableContainer, TableTitle, AddButton } from './Style'

type Props = {
  staffs: Staffs
  getData: () => void
  openModal: (data: {}) => void
  removeStaff: (id: number) => void
}

class Staff extends Component<Props> {
  public handleOpenModal = () => {
    const { openModal } = this.props
    openModal(addUser())
  }

  public handleOpen2Modal = (id: number) => {
    const { openModal, removeStaff } = this.props
    openModal(
      staffRemoveRequest({
        removeRequest: () => removeStaff(id),
      }),
    )
  }

  public render() {
    const { staffs, getData } = this.props

    const data = staffs.map(i => ({
      ...i,
      action: () => this.handleOpen2Modal(i.id),
    }))

    return (
      <TableContainer>
        <TableTitle>Staff</TableTitle>
        <AddButton onClick={this.handleOpenModal}>Add new member</AddButton>
        <NewTable getData={getData} pagination={false} selection={false} columns={createColumns(COLUMNS)} data={data} />
      </TableContainer>
    )
  }
}

export { Staff }
