import React, { Fragment, useState, ReactNode } from 'react'
import { Icon } from 'components'
import { Header, Title, IconWrap } from './Style'

type Props = {
  title: string
  isOpen?: boolean
  children: ReactNode
  align?: 'center' | 'end'
}

const Toggle = ({ title, children, align, isOpen = true }: Props) => {
  const [showElement, setShowElement] = useState(isOpen)

  const handleClick = () => {
    setShowElement(!showElement)
  }

  return (
    <Fragment>
      <Header onClick={handleClick} isOpen={showElement} align={align}>
        <Title>{title}</Title>

        <IconWrap>
          <Icon name='arrowHead' size='fill' />
        </IconWrap>
      </Header>
      {showElement && children}
    </Fragment>
  )
}

export { Toggle }
