import React from 'react'

import { createModal } from 'containers/Modal'
import { NewCryptoRequestContainer } from '../containers'

const containerWithProps = () => <NewCryptoRequestContainer />

const newCryptoRequest = () =>
  createModal({
    title: `New crypto request`,
    name: 'OPEN_NEW_CRYPTO_REQUEST',
    container: containerWithProps(),
  })

export { newCryptoRequest }
