import { all, takeLatest, call, put } from 'redux-saga/effects'

import { query } from '../client'
import { getCompanyFailure, getCompanySuccess, setCompany } from '../actions'
import { GET_COMPANY_REQUEST } from '../constants'
import { GET_COMPANY, GetInfoResponse } from '../queries'

export function* getInfo() {
  try {
    const response: GetInfoResponse = yield call(query, {
      query: GET_COMPANY,
      fetchPolicy: 'no-cache',
    })
    const {
      data: { findCompanyInfo },
    } = response

    yield put(getCompanySuccess())
    yield put(setCompany(findCompanyInfo))
  } catch (err) {
    yield put(getCompanyFailure(err.message))
  }
}

export function* saga() {
  return yield all([yield takeLatest(GET_COMPANY_REQUEST, getInfo)])
}
