import { RequestState } from '@types'

import { Actions, ExchangeRate } from '../@types'
import {
  SET_EXCHANGE,
  GET_EXCHANGE_CLEAR,
  GET_EXCHANGE_FAILURE,
  GET_EXCHANGE_REQUEST,
  GET_EXCHANGE_SUCCESS,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getExchange = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_EXCHANGE_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_EXCHANGE_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_EXCHANGE_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    case GET_EXCHANGE_CLEAR:
      return initialDomainState
    default:
      return state
  }
}

const initialUiState: ExchangeRate = {
  min_amount: 0,
  rate: 1,
}

export const rate = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_EXCHANGE:
      const { params } = action.payload

      return { ...state, ...params }
    default:
      return state
  }
}
