import React from 'react'

const LogoIcon = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='162px'
    height='23px'
    viewBox='0 0 162 23'
    enableBackground='new 0 0 162 23'
  >
    <g>
      <path
        fill='#FFFFFF'
        d='M4.7,20.2c-1.5-0.8-2.6-1.8-3.5-3.2C0.5,15.7,0,14.1,0,12.4c0-1.7,0.4-3.3,1.2-4.6c0.8-1.4,2-2.4,3.5-3.2
        c1.5-0.8,3.1-1.1,5-1.1c1.8,0,3.4,0.4,4.8,1.1c1.4,0.8,2.4,1.9,3,3.3L13.6,10c-0.9-1.6-2.2-2.4-3.9-2.4C8.4,7.6,7.3,8,6.4,8.9
        c-0.9,0.9-1.3,2-1.3,3.5s0.4,2.7,1.3,3.5c0.9,0.9,1.9,1.3,3.3,1.3c1.7,0,3-0.8,3.9-2.4l3.9,2.1c-0.6,1.4-1.6,2.5-3,3.2
        c-1.4,0.8-3,1.2-4.8,1.2C7.9,21.3,6.2,21,4.7,20.2z'
      />
      <path
        fill='#FFFFFF'
        d='M22.9,20.2c-1.5-0.8-2.6-1.8-3.4-3.2c-0.8-1.4-1.2-2.9-1.2-4.6c0-1.7,0.4-3.3,1.2-4.6
        c0.8-1.4,2-2.4,3.4-3.2c1.5-0.8,3.1-1.1,4.9-1.1c1.8,0,3.5,0.4,4.9,1.1c1.4,0.8,2.6,1.8,3.4,3.2c0.8,1.4,1.2,2.9,1.2,4.6
        c0,1.7-0.4,3.3-1.2,4.6c-0.8,1.4-1.9,2.4-3.4,3.2c-1.4,0.8-3.1,1.1-4.9,1.1C25.9,21.3,24.3,21,22.9,20.2z M31,15.9
        c0.8-0.9,1.2-2,1.2-3.5c0-1.5-0.4-2.6-1.2-3.5c-0.8-0.9-1.9-1.3-3.2-1.3c-1.3,0-2.4,0.4-3.2,1.3c-0.8,0.9-1.3,2-1.3,3.5
        c0,1.5,0.4,2.6,1.3,3.5c0.8,0.9,1.9,1.3,3.2,1.3C29.1,17.2,30.1,16.8,31,15.9z'
      />
      <path
        fill='#FFFFFF'
        d='M90.1,13.8H77c0.2,1.1,0.8,1.9,1.7,2.6c0.9,0.6,2,0.9,3.3,0.9c0.9,0,1.7-0.1,2.4-0.4c0.7-0.3,1.4-0.7,2-1.3
        l2.7,2.9c-1.6,1.9-4,2.8-7.2,2.8c-2,0-3.7-0.4-5.2-1.1c-1.5-0.8-2.7-1.8-3.5-3.2c-0.8-1.4-1.2-2.9-1.2-4.6c0-1.7,0.4-3.2,1.2-4.6
        c0.8-1.4,1.9-2.4,3.3-3.2c1.4-0.8,3-1.1,4.7-1.1c1.7,0,3.2,0.4,4.6,1.1c1.4,0.7,2.5,1.8,3.2,3.1c0.8,1.4,1.2,3,1.2,4.8
        C90.2,12.5,90.2,13,90.1,13.8z M78.4,8.2c-0.8,0.6-1.2,1.5-1.4,2.6h8.6c-0.2-1.1-0.6-2-1.4-2.6c-0.8-0.7-1.7-1-2.8-1
        C80.1,7.3,79.1,7.6,78.4,8.2z'
      />
      <path
        fill='#FFFFFF'
        d='M96.5,20.2C95,19.4,93.9,18.4,93,17c-0.8-1.4-1.2-2.9-1.2-4.6c0-1.7,0.4-3.3,1.2-4.6c0.8-1.4,2-2.4,3.5-3.2
        c1.5-0.8,3.1-1.1,5-1.1c1.8,0,3.4,0.4,4.8,1.1c1.4,0.8,2.4,1.9,3,3.3l-3.9,2.1c-0.9-1.6-2.2-2.4-3.9-2.4c-1.3,0-2.4,0.4-3.3,1.3
        c-0.9,0.9-1.3,2-1.3,3.5s0.4,2.7,1.3,3.5c0.9,0.9,1.9,1.3,3.3,1.3c1.7,0,3-0.8,3.9-2.4l3.9,2.1c-0.6,1.4-1.6,2.5-3,3.2
        c-1.4,0.8-3,1.2-4.8,1.2C99.6,21.3,98,21,96.5,20.2z'
      />
      <path
        fill='#FFFFFF'
        d='M124,20.4c-0.5,0.4-1.1,0.6-1.8,0.8c-0.7,0.2-1.5,0.3-2.3,0.3c-2.1,0-3.7-0.5-4.8-1.6
        c-1.1-1.1-1.7-2.6-1.7-4.7V8.1h-2.7V4.3h2.7V0h5v4.2h4.3v3.9h-4.3v7.1c0,0.7,0.2,1.3,0.6,1.7c0.4,0.4,0.9,0.6,1.6,0.6
        c0.8,0,1.5-0.2,2-0.6L124,20.4z'
      />
      <path
        fill='#FFFFFF'
        d='M142.5,13.8h-13.1c0.2,1.1,0.8,1.9,1.7,2.6c0.9,0.6,2,0.9,3.3,0.9c0.9,0,1.7-0.1,2.4-0.4
        c0.7-0.3,1.4-0.7,2-1.3l2.7,2.9c-1.6,1.9-4,2.8-7.2,2.8c-2,0-3.7-0.4-5.2-1.1c-1.5-0.8-2.7-1.8-3.5-3.2c-0.8-1.4-1.2-2.9-1.2-4.6
        c0-1.7,0.4-3.2,1.2-4.6c0.8-1.4,1.9-2.4,3.3-3.2c1.4-0.8,3-1.1,4.7-1.1c1.7,0,3.2,0.4,4.6,1.1c1.4,0.7,2.5,1.8,3.2,3.1
        c0.8,1.4,1.2,3,1.2,4.8C142.6,12.5,142.6,13,142.5,13.8z M130.7,8.2c-0.8,0.6-1.2,1.5-1.4,2.6h8.6c-0.2-1.1-0.6-2-1.4-2.6
        c-0.8-0.7-1.7-1-2.8-1C132.5,7.3,131.5,7.6,130.7,8.2z'
      />
      <path
        fill='#FFFFFF'
        d='M161.9,13.8h-13.1c0.2,1.1,0.8,1.9,1.7,2.6c0.9,0.6,2,0.9,3.3,0.9c0.9,0,1.7-0.1,2.4-0.4
        c0.7-0.3,1.4-0.7,2-1.3l2.7,2.9c-1.6,1.9-4,2.8-7.2,2.8c-2,0-3.7-0.4-5.2-1.1c-1.5-0.8-2.7-1.8-3.5-3.2c-0.8-1.4-1.2-2.9-1.2-4.6
        c0-1.7,0.4-3.2,1.2-4.6c0.8-1.4,1.9-2.4,3.3-3.2c1.4-0.8,3-1.1,4.7-1.1c1.7,0,3.2,0.4,4.6,1.1c1.4,0.7,2.5,1.8,3.2,3.1
        c0.8,1.4,1.2,3,1.2,4.8C162,12.5,161.9,13,161.9,13.8z M150.1,8.2c-0.8,0.6-1.2,1.5-1.4,2.6h8.6c-0.2-1.1-0.6-2-1.4-2.6
        c-0.8-0.7-1.7-1-2.8-1C151.8,7.3,150.8,7.6,150.1,8.2z'
      />
      <path fill='#FFFFFF' d='M60.1,20.6V23h-4.3L44.5,9.2V23h-5.2V0.4h4.3l11.2,13.7L60.1,20.6z' />
      <path fill='#FFFFFF' d='M70.1,0.4V23h-4.3L54.5,9.2l-5.2-6.3V0.4h4.3l11.2,13.7V0.4H70.1z' />
    </g>
  </svg>
)

export { LogoIcon }
