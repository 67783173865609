import React, { ChangeEvent, Component, RefObject } from 'react'

import { Input, Error, WrapperInput } from './Style'
import { Props, State } from './@types'

const LENGTH = 6

// TODO: more options for api and default params from props
class VerificationCode extends Component<Props, State> {
  private iRefs: RefObject<HTMLInputElement>[] = []
  public constructor(props: Props) {
    super(props)

    this.state = {
      values: Array(LENGTH).fill(''),
    }

    for (let i = 0; i < LENGTH; i++) {
      this.iRefs.push(React.createRef())
    }
  }

  public componentDidUpdate({ value: prevValue }: Props) {
    const { value } = this.props
    if (prevValue !== value && !value) {
      this.resetState()
    }
  }

  public render() {
    const { values } = this.state
    const { isError, errorMessage } = this.props

    return (
      <WrapperInput>
        {values.map((value, index) => {
          return (
            <Input
              key={index}
              data-test={index + '-confirm-code'}
              value={value}
              ref={this.iRefs[index]}
              type='text'
              autoComplete='off'
              pattern='[0-9]*'
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              autoFocus={index === 0}
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              third={index === 2}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => this.handleSelect(index)}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={e => this.onChange(e, index)}
            />
          )
        })}
        {isError && <Error>{errorMessage ? errorMessage : 'Invalid code, please try again'}</Error>}
      </WrapperInput>
    )
  }

  private resetState = () => {
    this.setState(() => ({ values: Array(LENGTH).fill('') }))
  }

  private handleSelect = (index: number): void => {
    const node = this.iRefs[index]

    if (node && node.current) {
      node.current.select()
    }
  }

  private triggerChanges = (values: string[]) => {
    const { onActive } = this.props

    const code = values.join('')

    if (!code) {
      return
    }

    onActive(code)
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { values } = this.state
    const FIRST = 1

    const value = event.target.value.replace(/[^\d]/gi, '')

    let next

    if (value.length > FIRST) {
      const split = value.split('')
      split.forEach((item, i) => {
        const cursor = index + i
        if (cursor < LENGTH) {
          values[cursor] = item
        }
      })
      next = this.iRefs[split.length + index - FIRST]
    } else if (value === '') {
      values[index] = value
      next = this.iRefs[index - FIRST]
    } else {
      values[index] = value
      next = this.iRefs[index + FIRST]
    }

    this.setState({ values })
    this.triggerChanges(values)

    if (next) {
      const node = next.current

      if (node) {
        node.focus()
      }
    }
  }
}

export { VerificationCode }
