import React from 'react'

type Props = {
  color?: string
}

const Href = ({ color }: Props) => (
  <svg fill='none' viewBox='0 0 18 18'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M17 12.733v2.134A2.133 2.133 0 0114.867 17H3.133A2.133 2.133 0 011 14.867V3.133C1 1.955 1.955 1 3.133 1h2.134M10.6 1H17v6.4M8.467 9.533l7.88-7.88'
    />
  </svg>
)

export { Href }
