import React from 'react'

import { shortString } from 'utils'
import { Copy } from 'components'

import { ValueAddress } from './Style'

type Props = {
  value: string
}

function Address({ value }: Props) {
  return (
    <Copy value={value}>
      <ValueAddress>{shortString(value)}</ValueAddress>
    </Copy>
  )
}

export { Address }
