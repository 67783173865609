import { all, takeLatest, call, put } from 'redux-saga/effects'

import { getGraphQlError, getTrimming } from 'utils'

import { mutate, query } from '../client'
import { GET_CURRENCIES_LIST, GetCurrenciesListResponse } from '../queries'
import { SET_ONBOARDING } from '../mutation'
import { OnboardingInput } from '../@types'
import { SET_ONBOARDING_REQUEST, GET_CURRENCY_LIST_REQUEST } from '../constants'
import {
  setOnboardingFailure,
  setOnboardingRequest,
  setOnboardingSuccess,
  getCompanyRequest,
  getCurrencyListRequest,
  setCurrenciesList,
} from '../actions'

export function* onboarding({ payload }: ReturnType<typeof setOnboardingRequest>) {
  try {
    yield call(mutate, {
      variables: getTrimming<OnboardingInput>(payload),
      mutation: SET_ONBOARDING,
    })

    yield put(setOnboardingSuccess())
    yield put(getCompanyRequest())
  } catch (err) {
    yield put(setOnboardingFailure(getGraphQlError(err)))
  }
}

export function* getCurrencies({ payload }: ReturnType<typeof getCurrencyListRequest>) {
  try {
    const response: GetCurrenciesListResponse = yield call(query, {
      query: GET_CURRENCIES_LIST,
      variables: {
        filters: payload,
      },
    })

    const {
      generalFindExchangePairList: { list },
    } = response.data

    yield put(setCurrenciesList([...new Set(list.map(item => item.currency_to))]))
  } catch (err) {}
}

export function* saga() {
  return yield all([
    yield takeLatest(SET_ONBOARDING_REQUEST, onboarding),
    yield takeLatest(GET_CURRENCY_LIST_REQUEST, getCurrencies),
  ])
}
