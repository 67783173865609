import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { closeAllModal } from 'containers'

import { ClientState } from '../@types'
import { withInjectors } from '../injectors'
import { createStructuredSelector } from 'reselect'
import { selectRemoveUserRequest } from '../selectors'
import { RemoveUser as View } from '../components'
import { changeDetailClear } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeAllModal,
      clearState: changeDetailClear,
    },
    dispatch,
  )

type OwnProps = {
  removeRequest: () => void
}

type Props = {
  request?: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectRemoveUserRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const RemoveUserContainer = compose<ComponentType<OwnProps>>(withInjectors, withConnect)(View)
