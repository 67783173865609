export const DOMAIN = 'modules/auth'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_AUTH = 'modules/auth/ui/SET_AUTH'
export const SET_LOGOUT = 'modules/auth/ui/SET_LOGOUT'

export const SIGN_IN_REQUEST = 'modules/auth/domain/SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = 'modules/auth/domain/SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'modules/auth/domain/SIGN_IN_FAILURE'

export const SIGN_UP_REQUEST = 'modules/auth/domain/SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'modules/auth/domain/SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'modules/auth/domain/SIGN_UP_FAILURE'
