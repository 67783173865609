import { useState } from 'react'
import { copyToClipboard } from 'utils'
import { notify } from 'containers/Notification/notify'

const TIME_OUT = 800

type ReturnValue = [boolean, () => void]

// TODO move notify to local methods
function useCopy(value: string): ReturnValue {
  const [isCopied, setCopy] = useState(false)

  const handleClick = () => {
    setCopy(true)
    copyToClipboard(value)
    notify({ text: 'Copied' })

    setTimeout(() => {
      setCopy(false)
    }, TIME_OUT)
  }

  return [isCopied, handleClick]
}

export { useCopy }
