import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { StaffInjectors } from 'modules/Settings'

import { withInjectors } from '../injectors'
import { Main as View } from '../components'
import { ClientState, Transactions } from '../@types'
import { selectGetTransactions, selectTransactions } from '../selectors'
import { setTransactionsFilters, selectPage, selectPageSize, getTransactionsRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectPage,
      selectPageSize,
      getData: getTransactionsRequest,
      setFilters: setTransactionsFilters,
    },
    dispatch,
  )

type Props = {
  transactions: Transactions
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    transactions: selectTransactions(),
    request: selectGetTransactions(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const ExchangeContainer = compose<ComponentType>(withInjectors, StaffInjectors, withConnect)(View)
