import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { hot } from 'react-hot-loader/root'
import { parse } from 'query-string'
import { allRoute } from 'router'
import { defaultTheme, GlobalStyle } from 'theme'
import axios from 'axios'
import { ModalConductor, ToastConductor } from 'containers'
import { Global } from 'modules'

import { RootType } from './@types'
import { localStorage } from '../../utils'

const getToken = async (token: string) => {
  const response = await axios.get(`${window.location.origin}/api/service/login-as-company-staff/${token}`)
  localStorage.setItem(localStorage.getKey(), { jwt: response.data.token, time: Date.now() })
  window.location.reload()
}

const AppContainer: React.FC<RootType> = props => {
  const { store, persistor, history } = props
  const parsed = parse(window.location.search)
  const token = parsed.token
  useEffect(() => {
    if (token && typeof token === 'string') {
      getToken(token)
    }
  }, [token])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={defaultTheme}>
            <ModalConductor />
            <ToastConductor />
            <Global>{allRoute}</Global>
            <GlobalStyle />
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}

const Root = hot(AppContainer)

export { Root }
