import { SignUpInput } from '../@types'
import { SIGN_UP_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from '../constants'

export const signUpRequest = (params: SignUpInput) => {
  return {
    type: SIGN_UP_REQUEST,
    payload: params,
  } as const
}

export const signUpFailure = (errorMessage: string) => {
  return {
    type: SIGN_UP_FAILURE,
    payload: { errorMessage },
  } as const
}

export const signUpSuccess = () => {
  return {
    type: SIGN_UP_SUCCESS,
  } as const
}
