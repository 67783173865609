import { RequestState } from '@types'
import { Actions } from '../@types'
import { RESET_API_FAILURE, RESET_API_REQUEST, RESET_API_SUCCESS } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const resetApiRequest = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case RESET_API_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case RESET_API_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case RESET_API_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}
