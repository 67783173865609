import React, { useMemo } from 'react'
import ReactSelect, { ValueType } from 'react-select'

import { Icon, IconMap } from 'components'

import { CommonCurrencies } from '../../@types'

import { PaginationSelect, WrapperCoin, CoinValue } from './Style'

type Options = {
  value: string
}

type Props = {
  value: string
  name?: string
  cryptoCurrencies: CommonCurrencies
  onChange: (value: string, name?: string) => void
}

function SelectCoin({ value, onChange, name, cryptoCurrencies }: Props) {
  const handleOnChange = (value: ValueType<Options>) => {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange(value.value, name)
    }
  }

  const optionsCoins = useMemo(() => {
    return cryptoCurrencies.map(el => ({
      value: el.name,
      label: (
        <WrapperCoin>
          <Icon name={el.name.toLowerCase() as IconMap} size='small' />
          <CoinValue>{el.name.toLowerCase()}</CoinValue>
        </WrapperCoin>
      ),
    }))
  }, [cryptoCurrencies])

  const selected = optionsCoins.find(i => i.value === value)

  return (
    <PaginationSelect>
      <ReactSelect
        value={selected}
        minMenuHeight={96}
        isSearchable={false}
        className='SelectCoin'
        placeholder={selected}
        menuPlacement='bottom'
        options={optionsCoins}
        onChange={handleOnChange}
        classNamePrefix='SelectCoin'
      />
    </PaginationSelect>
  )
}

export { SelectCoin }
