import { gql, ApolloQueryResult } from 'apollo-boost'

export const SIGN_IN = gql`
  query logIn($email: String!, $password_hash: String!, $two_factor_code: String!) {
    logIn(data: { email: $email, password_hash: $password_hash, two_factor_code: $two_factor_code }) {
      token
    }
  }
`

export type SignInResponse = ApolloQueryResult<{
  logIn: {
    token: string
  }
}>
