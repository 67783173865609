import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { clients, getClients } from './Clients'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    clients,
  }),
  [DOMAIN_STATE]: combineReducers({
    getClients,
  }),
})
