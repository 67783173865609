import { gql, ApolloQueryResult } from 'apollo-boost'

export const RESEND_WEB_HOOK = gql`
  mutation postCallback($id: Int!) {
    postCallback(id: $id)
  }
`

export type ResendWebHook = ApolloQueryResult<{
  isResend: boolean
}>
