import React, { Fragment, useState, useEffect, useMemo } from 'react'

import { links } from 'constant'
import { RequestState } from '@types'
import { EXTERNAL_LINK_REGEXP, debounce } from 'utils'

import { notify } from 'containers/Notification/notify'
import { Copy, Icon, Select, Input, KeysListener, Checkbox } from 'components'

import { CreateInvoice, Currency, Invoice } from '../../@types'

import {
  Wrap,
  Wrapper,
  TextSend,
  InvoiceId,
  Container,
  CreateBtn,
  TitleSend,
  InputsWrap,
  StyledText,
  IconWrapSend,
  IconCopyWrap,
  InvoiceIdWrap,
  CheckboxWrapper,
} from './Style'

type Option = {
  value: Currency
  label: string
}

const options: Option[] = [
  {
    value: 'USD',
    label: '$ USD',
  },
  {
    value: 'EUR',
    label: '€ EUR',
  },
]

type Props = {
  invoice: Invoice
  request: RequestState
  closeModal: () => void
  clearState: () => void
  createInvoice: (data: CreateInvoice) => void
}

type UrlErrors = {
  success: string
  failure: string
}

type Debounce = <P>(fun: CallableFunction, args: P) => void
type SetError = {
  key: string
  value: string
}

function NewInvoice(props: Props) {
  const { createInvoice, clearState, request, invoice } = props
  useEffect(
    () => () => {
      clearState()
    },
    [clearState],
  )

  const debounceFn: Debounce = debounce((func, args) => func(args), Number('1500'))

  const [urlsErrors, setUrlsErrors] = useState<UrlErrors>({
    success: '',
    failure: '',
  })

  const [eur, setEur] = useState<string>('')
  const [successUrl, setSuccessUrl] = useState<string>('')
  const [failureUrl, setFailureUrl] = useState<string>('')
  const [currency, setCurrency] = useState<Currency>('EUR')
  const [allowExpired, setAllowExpired] = useState(false)

  const isDisabled = useMemo(() => {
    const allUrlParams = Object.values(urlsErrors)

    const isUrlsValid = Boolean(allUrlParams.filter(err => !err).length === allUrlParams.length)

    return !Boolean(isUrlsValid && Number(eur))
  }, [urlsErrors, eur])

  const onHandleFiatChange = (value: string) => {
    setEur(value)
  }

  const onSetError = ({ key, value }: SetError) => {
    const isValidSuccess = EXTERNAL_LINK_REGEXP.test(value)
    const error: string = !isValidSuccess && value ? 'Url address is not valid' : ''

    setUrlsErrors((prev: UrlErrors) => ({ ...prev, [key]: error }))
  }

  const onSetSuccess = (value: string) => {
    debounceFn(onSetError, { key: 'success', value })

    setSuccessUrl(value)
  }

  const onSetFailure = (value: string) => {
    debounceFn(onSetError, { key: 'failure', value })

    setFailureUrl(value)
  }

  const handleSelect = (todo: Option) => {
    setCurrency(todo.value)
  }

  const handleExpiredChange = () => {
    setAllowExpired(prev => !prev)
  }

  const handleReq = () => {
    if (isDisabled) {
      const errorText = 'This action is not available, please check the completed fields'

      notify({ type: 'error', text: errorText })

      return
    }

    createInvoice({
      amount: Number(eur),
      fiat_currency: currency,
      url_success: successUrl,
      url_failure: failureUrl,
      allow_paid_expired: allowExpired,
    })
  }

  return (
    <Fragment>
      {!request.isSuccess ? (
        <Wrap>
          <KeysListener handleSubmit={handleReq}>
            <Container>
              <Input<string>
                value={eur}
                type='number'
                label='Amount'
                placeholder='50'
                inputMode='decimal'
                error={request.isError}
                onChange={onHandleFiatChange}
                errorMessage={request.errorMessage}
              />

              <Wrapper>
                <Select isDisabled options={options} value={currency} select={handleSelect} />
              </Wrapper>
            </Container>
            <InputsWrap>
              <Input<string>
                type='text'
                value={successUrl}
                label='Success url'
                onChange={onSetSuccess}
                placeholder='https://url_success.com'
                error={Boolean(urlsErrors.success)}
                errorMessage={urlsErrors.success}
              />
              <Input<string>
                type='text'
                value={failureUrl}
                label='Failure url'
                onChange={onSetFailure}
                placeholder='https://url_failure.com'
                error={Boolean(urlsErrors.failure)}
                errorMessage={urlsErrors.success}
              />
            </InputsWrap>
            <CheckboxWrapper>
              <Checkbox id='allowExpired' value={undefined} onClick={handleExpiredChange} checked={allowExpired} />
              <label htmlFor='allowExpired'>
                <StyledText>Allow pay after expiration</StyledText>
              </label>
            </CheckboxWrapper>

            <CreateBtn isLoading={request.isFetching} disabled={isDisabled} onClick={handleReq}>
              Create invoice
            </CreateBtn>
          </KeysListener>
        </Wrap>
      ) : (
        <Fragment>
          <IconWrapSend>
            <Icon name='tick' size='auto' />
          </IconWrapSend>
          <TitleSend>Invoice has been created</TitleSend>
          <TextSend>Invoice Link:</TextSend>
          <InvoiceIdWrap>
            <Copy value={`${window.origin}${links.invoice}/${invoice.foreign_id}`}>
              <InvoiceId>
                {invoice.foreign_id}
                <IconCopyWrap>
                  <Icon name='copy' size='extraMedium' />
                </IconCopyWrap>
              </InvoiceId>
            </Copy>
          </InvoiceIdWrap>
        </Fragment>
      )}
    </Fragment>
  )
}

export { NewInvoice }
