import React from 'react'

import { Button } from 'components'
import { Wrapper, Title } from './Style'

type Props = {
  title: string
  open: () => void
  withButton?: boolean
}

function RequestHeader({ title, open, withButton = false }: Props) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {withButton && <Button onClick={open}>Generate Invoice</Button>}
    </Wrapper>
  )
}

export { RequestHeader }
