import styled from 'styled-components'
import { font } from 'theme'
import { Button } from 'components'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InputWrap = styled.div`
  margin: 0 0 24px;
`

export const CustomButton = styled(Button)`
  margin: 40px 0 0;
`

export const Text = styled.p`
  margin: 30px 0 16px;
  font-size: 16px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

export const CopyWrap = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const WrapSecret = styled.span`
  padding: 12px;
  display: block;
  width: 100%;
  font-family: ${font.display};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  background-color: rgba(255, 255, 255, 0.06);
  letter-spacing: 4px;
  text-align: center;
  border-radius: 10px;
`
