import React, { Component } from 'react'
import { Icon } from 'components'
import { Wrapper, Item, Bubble, BubbleContent, Title, Text, BubbleText, BubbleIcon } from './Style'

type StepProps = {
  step: number
}

type ItemProps = {
  count: number
  title: string
  text: string
}

const STEPS = [
  {
    count: 1,
    title: 'Complete the form',
    text: 'Choose the workflow and type all necessary information.',
  },
  {
    count: 2,
    title: 'Sign the form',
    text: '',
  },
]

class Steps extends Component<StepProps> {
  public getStatus = (count: number) => {
    const { step } = this.props
    if (count === step) {
      return 'active'
    }
    if (count < step) {
      return 'passed'
    }
    if (count > step) {
      return 'future'
    }
    return ''
  }

  public renderItem = ({ count, title, text }: ItemProps) => {
    return (
      <Item key={title} status={this.getStatus(count)}>
        <Bubble>
          <BubbleText>{count}</BubbleText>
          <BubbleIcon>
            <Icon name='tick' />
          </BubbleIcon>
        </Bubble>

        <BubbleContent>
          <Title status={this.getStatus(count)}>{title}</Title>
          {text && <Text>{text}</Text>}
        </BubbleContent>
      </Item>
    )
  }
  public render() {
    return <Wrapper>{STEPS.map(this.renderItem)}</Wrapper>
  }
}

export { Steps }
