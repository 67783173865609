import React, { Fragment, Component } from 'react'

import { RequestState } from '@types'
import { notify } from 'containers/Notification/notify'

import { Text, WrapBtn, CancelBtn, CustomBtn } from './Style'

type Props = {
  request: RequestState
  removeRequest: () => void
  clearState: () => void
  closeAllModal: () => void
}

class RemoveUser extends Component<Props> {
  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public componentDidUpdate() {
    const { request, closeAllModal } = this.props

    if (request.isSuccess) {
      notify({ text: 'User has been removed.' })
      closeAllModal()
    }
  }

  public handleValueChange = (value: string) => {
    this.setState(() => ({ value }))
  }

  public render() {
    const { closeAllModal, request, removeRequest } = this.props

    return (
      <Fragment>
        <Fragment>
          <Text>Are you sure that you want to&nbsp;delete this user?</Text>
          <WrapBtn>
            <CancelBtn type='ghost' size='medium' onClick={closeAllModal}>
              Cancel
            </CancelBtn>
            <CustomBtn type='warning' size='medium' isLoading={request.isFetching} onClick={removeRequest}>
              Delete
            </CustomBtn>
          </WrapBtn>
        </Fragment>
      </Fragment>
    )
  }
}

export { RemoveUser }
