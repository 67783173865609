import React, { Component, RefObject } from 'react'

import { KeysListener } from 'components'

import { ModalProps } from './@types'
import { SelectModalType } from './StyledModal'

class Modal extends Component<ModalProps> {
  private readonly node: RefObject<HTMLDivElement>
  public constructor(props: ModalProps) {
    super(props)

    this.node = React.createRef()
  }

  public componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  public componentWillUnmount() {
    document.body.style.overflow = 'unset'
  }

  public render() {
    const { size, type, children, isBack, title = '' } = this.props

    return (
      <KeysListener handleClose={this.onClose}>
        <SelectModalType
          size={size}
          type={type}
          title={title}
          isBack={isBack}
          ref={this.node}
          onBack={this.onBack}
          onClose={this.onClose}
        >
          {children}
        </SelectModalType>
      </KeysListener>
    )
  }

  private onBack = () => {
    const { closeModal, name } = this.props
    closeModal(name)
  }

  private onClose = () => {
    const { closeModal, closeAllModal, name, isBack } = this.props

    if (isBack) {
      closeAllModal()
    } else {
      closeModal(name)
    }
  }
}

export { Modal }
