import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { openModal } from 'containers/Modal'

import { withInjectors } from '../injectors'
import { Company as View } from '../components'
import { ClientState, Company, Request } from '../@types'
import { selectCompany, selectCurrentRequest } from '../selectors'
import { resetApiRequest, getCompanyRequest, getLatestUpdateRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      reset: resetApiRequest,
      getCompany: getCompanyRequest,
      getLatestRequest: getLatestUpdateRequest,
    },
    dispatch,
  )

type Props = {
  company: Company
  request?: Request
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    company: selectCompany(),
    request: selectCurrentRequest('callback_url'),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const CompanyContainer = compose<ComponentType>(withInjectors, withConnect)(View)
