import { links } from 'constant'
import { WalletContainer } from '../containers'

const routes = [
  {
    path: links.wallet,
    component: WalletContainer,
    isPrivate: true,
  },
]

export { routes }
