import { DateComponent, DecimalsFixed } from 'components'
import { EUR_PREFIX } from 'constant'

import { IdComponent, ValueComponent, TxIdComponent, CoinComponent, TypeComponent } from './Style'

export const COLUMNS = [
  {
    title: 'TX',
    key: 'tx_id',
    render: (props: string, render: { currency: string }) => TxIdComponent(props, render.currency),
  },
  {
    title: 'Date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'User ID',
    key: 'foreign_id',
    render: (props: string, render: { user_id: string }) => IdComponent(`${props} ${render.user_id}`),
  },
  {
    title: 'Asset',
    key: 'currency',
    render: (props: string) => CoinComponent(props),
  },
  {
    title: 'Volume',
    key: 'value',
    render: (props: string, render: { currency: string; value_eur: number }) =>
      ValueComponent(`${props} ${render.value_eur}`, render.currency),
  },
  {
    title: 'Type',
    key: 'direction',
    render: (props: string) => TypeComponent(props),
  },
  {
    title: 'Fee',
    key: 'fee',
    render: (props: string, render: { currency: string; fee_eur: number }) =>
      ValueComponent(`${props} ${render.fee_eur}`, render.currency),
  },
  {
    title: 'Fiat',
    key: 'fiat',
    render: (props: string, render: { value_eur: number }) => DecimalsFixed(render.value_eur, 'fiat', EUR_PREFIX),
  },
]
