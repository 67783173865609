import React, { ChangeEvent, Component, RefObject } from 'react'
import { Icon } from 'components'
import { ZERO } from 'constant'
import { Props, State, DefaultValue } from './@types'
import { ErrorMessage, Container, InputElement, Label, Wrapper, InputIcon, CrossIcon } from './Style'

class Input<Value extends DefaultValue> extends Component<Props<Value>, State> {
  private readonly input: RefObject<HTMLInputElement>
  public constructor(props: Props<Value>) {
    super(props)

    this.state = {
      value: '',
      isFocused: false,
      isVisible: false,
    }

    this.input = React.createRef()
  }

  public toggleFocus = (value: boolean) => {
    const node = this.input.current

    if (node && node.value !== '') {
      return
    }
    this.setState(() => ({ isFocused: value }))
  }

  public render() {
    const { isVisible } = this.state
    const {
      type,
      label,
      inputMode,
      autoComplete,
      errorMessage,
      error = false,
      crossError = false,
      disabled = false,
      ...rest
    } = this.props

    return (
      <Wrapper>
        {label && <Label>{label}</Label>}

        <Container>
          <InputElement
            {...rest}
            error={error}
            ref={this.input}
            inputMode={inputMode}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onKeyPress={this.handleKey}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            onChange={this.handleChange}
            type={isVisible ? 'text' : type}
            disabled={disabled}
            autoComplete={autoComplete}
          />

          {type === 'password' && (
            <InputIcon onClick={this.handleSetVisible}>
              <Icon name={isVisible ? 'eye' : 'eyeOff'} />
            </InputIcon>
          )}

          {error && crossError && <CrossIcon name='close' />}
        </Container>

        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Wrapper>
    )
  }

  private handleKey = (event: KeyboardEvent) => {
    const { isExchange } = this.props

    if (event.key === ',' && isExchange) {
      event.preventDefault()
      return
    }
  }

  private handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, name, type } = this.props
    let value = type === 'number' && event.target.value ? Number(event.target.value) : event.target.value

    if (!value && value !== ZERO) {
      value = ''
    }

    if (typeof onChange === 'function') {
      onChange(value as Value, name, event.target.value)
    }
  }

  private handleOnFocus = () => {
    this.toggleFocus(true)
  }

  private handleOnBlur = () => {
    const { onBlur } = this.props
    this.toggleFocus(false)

    if (onBlur) {
      onBlur()
    }
  }

  private handleSetVisible = () => {
    this.setState(({ isVisible }) => ({ isVisible: !isVisible }))
  }
}

export { Input }
