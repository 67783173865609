import { call, all, put, takeLatest, fork } from 'redux-saga/effects'

import { query } from '../client'
import { GET_CRYPTO_CURRENCIES_REQUEST } from '../constants'
import { GET_COMMON_CURRENCIES, GetCommonCurrenciesResponse } from '../queries'
import { getCryptoCurrenciesFailure, getCryptoCurrenciesSuccess, setCryptoCurrencies } from '../actions'

export function* getCryptoCurrencies() {
  try {
    const response: GetCommonCurrenciesResponse = yield call(query, {
      query: GET_COMMON_CURRENCIES,
      fetchPolicy: 'no-cache',
      variables: { page_size: 2 },
    })
    const {
      generalGetAppCurrencies: { list },
    } = response.data

    yield put(setCryptoCurrencies(list))

    yield put(getCryptoCurrenciesSuccess())
  } catch (err) {
    yield put(getCryptoCurrenciesFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield fork(getCryptoCurrencies),
    yield takeLatest(GET_CRYPTO_CURRENCIES_REQUEST, getCryptoCurrencies),
  ])
}
