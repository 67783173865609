import { call, all, put, takeLatest } from 'redux-saga/effects'

import { SHA3 } from 'crypto-js'

import { links } from 'constant'
import { getGraphQlError, localStorage, getTrimming } from 'utils'
import { push } from 'connected-react-router'

import { query } from '../client'
import { SignInInput } from '../@types'
import { SIGN_IN_REQUEST } from '../constants'
import { SIGN_IN, SignInResponse } from '../queries'
import { signInRequest, signInFailure, signInSuccess, setAuth } from '../actions'

export function* signIn({ payload }: ReturnType<typeof signInRequest>) {
  try {
    const variables = getTrimming<SignInInput>(payload)
    const response: SignInResponse = yield call(query, {
      query: SIGN_IN,
      variables: {
        ...variables,
        password_hash: SHA3(variables.password_hash).toString(),
      },
      fetchPolicy: 'no-cache',
    })
    const {
      logIn: { token },
    } = response.data

    localStorage.setItem(localStorage.getKey(), { jwt: token, time: Date.now() })

    yield put(setAuth(true))
    yield put(push(links.users))
    yield put(signInSuccess())
  } catch (err) {
    yield put(signInFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(SIGN_IN_REQUEST, signIn)])
}
