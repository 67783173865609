import React, { Fragment } from 'react'
import { DateComponent } from 'components'
import { DelButton } from './Style'

function PersonComponent(value: string) {
  return <Fragment>{value}</Fragment>
}

function DeleteButton(remove: () => void) {
  return (
    <DelButton onClick={remove} type='link' size='medium'>
      Delete
    </DelButton>
  )
}

export const COLUMNS = [
  {
    title: 'Email',
    key: 'email',
    render: (props: string) => PersonComponent(props),
  },
  {
    title: 'Role',
    key: 'role_name',
  },
  {
    title: 'Registration date',
    key: 'registrationDate',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    render: (props: () => void) => DeleteButton(props),
  },
]
