import { Address, DateComponent, DecimalsFixed } from 'components'
import {
  TxIdComponent,
  StatusComponent,
  AmountFiatComponent,
  AmountComponent,
  TextComponent,
  IdComponent,
} from './Style'

export const COLUMNS = [
  {
    title: 'TX',
    key: 'tx_id',
    render: (props: string, render: { paid_currency: string }) => TxIdComponent(props, render.paid_currency),
  },
  {
    title: 'Invoice ID',
    key: 'id',
    render: (props: string, render: { foreign_id: string }) => IdComponent(`${render.foreign_id} ${props}`),
  },
  {
    title: 'Date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Volume',
    key: 'client_amount',
    render: (props: string, render: { client_currency: string }) => AmountFiatComponent(props, render.client_currency),
  },
  {
    title: 'Fee',
    key: 'crypto_fee',
    render: (props: string, render: { paid_currency: string }) => AmountComponent(props, render.paid_currency),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => StatusComponent(props),
  },
  {
    title: 'ETH Address',
    key: 'address_eth',
    render: (props: string) => Address({ value: props }),
  },
  {
    title: 'ETH Amount',
    key: 'expected_amount_eth',
    render: (props: string) => DecimalsFixed(props, 'crypto'),
  },
  {
    title: 'BTC Address',
    key: 'address_btc',
    render: (props: string) => Address({ value: props }),
  },
  {
    title: 'BTC Amount',
    key: 'expected_amount_btc',
    render: (props: string) => DecimalsFixed(props, 'crypto'),
  },
  {
    title: 'Paid',
    key: 'client_real_amount',
    render: (props: string, render: { client_currency: string }) => AmountFiatComponent(props, render.client_currency),
  },
  {
    title: 'After expiration',
    key: 'allow_paid_after_expired',
    render: (props: boolean) => StatusComponent(props.toString()),
  },
  {
    title: 'Paid asset',
    key: 'paid_currency',
  },
]

export const STATS = [
  {
    title: 'Generated',
    key: 'created_invoices',
    align: 'center',
  },
  {
    title: 'Paid',
    key: 'paid_invoices',
    align: 'center',
  },
  {
    title: 'Waiting',
    key: 'pending_invoices',
    render: (props: string) => TextComponent(props),
    align: 'center',
  },
  {
    title: 'EUR Received',
    key: 'eur_amount',
    render: (props: string) => AmountFiatComponent(props, '€'),
    align: 'center',
  },
]
