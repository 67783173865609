import React, { Component } from 'react'

import { Input } from 'components'

import { MainWrapper, Style, Label, Row, InputWrapper } from './Style'

type RangeParams = {
  to?: string
  from?: string
}

type Props = {
  title: string
  endValue?: string
  disabled?: boolean
  startValue?: string
  setFilter: ({ from, to }: RangeParams) => void
}

class Range extends Component<Props> {
  public render() {
    const { title, endValue, startValue, disabled } = this.props

    return (
      <MainWrapper>
        <Label>{title}</Label>
        <Row>
          <InputWrapper>
            <Input
              name='from'
              type='number'
              placeholder='0'
              pattern='[0-9]*'
              inputMode='decimal'
              value={startValue}
              disabled={disabled}
              onChange={this.handleChange}
            />
          </InputWrapper>

          <Style />

          <InputWrapper>
            <Input
              name='to'
              type='number'
              pattern='[0-9]*'
              placeholder='10'
              value={endValue}
              inputMode='decimal'
              disabled={disabled}
              onChange={this.handleChange}
            />
          </InputWrapper>
        </Row>
      </MainWrapper>
    )
  }

  private handleChange = (value: string, name?: string) => {
    const { setFilter, startValue, endValue } = this.props
    if (name) {
      const actual = { from: startValue, to: endValue }
      setFilter({ ...actual, [name]: value })
    }
  }
}

export { Range }
