import React from 'react'

const Squares = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M1 1h7v7H1zM12 1h7v7h-7zM12 12h7v7h-7zM1 12h7v7H1z'
    />
  </svg>
)

export { Squares }
