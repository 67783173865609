import { css } from 'styled-components'
import { TextOverflowProps } from './@types'

/**
 * Text is cutted with ellipsis in the end. Example: Lorem ip...
 */
export const TextOverflowOneLine = css`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

/**
 * If webkit, text breaks by words. If the word is too long, it breaks in the middle.
 */
export const TextOverflowParagraph = css`
  overflow-wrap: break-word;
`

/**
 * Cut text on defined number of lines. Common styles.
 */
export const TextOverflowMultilineStyles = css`
  display: block; /* fallback for not webkit browsers */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
`

/**
 * Cut text on defined number of lines.
 * Pass lineHeight value and number of lines to cut and rewrite them in mediaqueries.
 */
export const TextOverflowMultiLineCalc = ({ lineHeight, lines }: TextOverflowProps) =>
  css`
    line-height: ${lineHeight};
    -webkit-line-clamp: ${lines};
    max-height: calc(${lineHeight} * ${lines});
  `
