import React from 'react'

import { Transaction } from '../../@types'
import { Icon, DateComponent, DecimalsFixed, IconMap, Status } from 'components'
import { CryptoElement, CryptoName } from './Style'

function CoinComponent(value: string) {
  const icon = value.toLowerCase() as IconMap

  return (
    <CryptoElement>
      <Icon name={icon} size='extraMedium' />
      <CryptoName>{value}</CryptoName>
    </CryptoElement>
  )
}

export const COLUMNS = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date',
    key: 'created_at',
    render: (props: string) => DateComponent(props),
  },
  {
    title: 'Asset From',
    key: 'currency_from',
    render: (props: string) => CoinComponent(props),
  },
  {
    title: 'Volume From',
    key: 'amount_from',
    // eslint-disable-next-line react/display-name
    render: (props: string, render: Transaction) => (
      <span>
        {DecimalsFixed(props, 'crypto')} {render.currency_from}
      </span>
    ),
  },
  {
    title: 'Asset To',
    key: 'currency_to',
    render: (props: string) => CoinComponent(props),
  },
  {
    title: 'Volume To',
    key: 'amount_to',
    render: (props: string) => DecimalsFixed(props, 'fiat'),
  },
  {
    title: 'Rate',
    key: 'rate',
    // eslint-disable-next-line react/display-name
    render: (props: string, render: Transaction) => (
      <span>
        1 {render.currency_from} = {props} {render.currency_to}
      </span>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    render: (props: string) => Status({ status: props }),
  },
]
