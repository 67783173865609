import React from 'react'

import { icons } from './icons'
import { Props } from './@types'
import { IconWrapper } from './Style'

const FIRST_LETTER = 0

const CustomIcon = ({ name, color }: Props) => {
  const IconComponent = icons[name]

  if (IconComponent) {
    return <IconComponent color={color} />
  }

  return <span>{name.charAt(FIRST_LETTER)}</span>
}

function Icon({ name, color, size = 'default', ...rest }: Props) {
  return (
    <IconWrapper className='icon-wrapper' size={size} {...rest}>
      <CustomIcon name={name} color={color} />
    </IconWrapper>
  )
}

export { Icon }
