import React, { Fragment } from 'react'
import styled from 'styled-components'
import { color } from 'theme'
import { shortString } from 'utils'

import { ColumnElements, DecimalsFixed, Status, TransactionLink, Copy } from 'components'

const WrapId = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
`

const UserId = styled.span`
  font-size: 12px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.5);
`

export function TxIdComponent(value = '', currency = '') {
  const url = currency === 'BTC' ? 'https://www.blockchain.com/btc/tx' : 'https://etherscan.io/tx'
  const link = `${url}/${value}`
  return (
    <Fragment>
      {value ? (
        <WrapId>
          <TransactionLink link={link} value={value} />
        </WrapId>
      ) : (
        <Fragment>-</Fragment>
      )}
    </Fragment>
  )
}

export function IdComponent(value: string) {
  const [first, second] = value.split(' ')
  return (
    <Copy value={first}>
      <ColumnElements>
        <WrapId>{shortString(first)}</WrapId>
        <UserId>{second}</UserId>
      </ColumnElements>
    </Copy>
  )
}

export function AmountFiatComponent(value: string, currency: string) {
  return (
    <WrapId>
      {DecimalsFixed(value, 'fiat')} {currency}
    </WrapId>
  )
}

export function AmountComponent(value: string, currency: string) {
  return (
    <WrapId>
      {DecimalsFixed(value, 'crypto')} {currency}
    </WrapId>
  )
}

export function StatusComponent(value: string) {
  return <Status status={value} />
}

const OrangeText = styled.span`
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.43;
  color: ${color.danger};
`

export const StyledDate = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
`

export function TextComponent(value: string) {
  return <OrangeText>{value}</OrangeText>
}

export const StyledAction = styled.div`
  color: red;
`
