import React from 'react'

const Upload = () => (
  <svg viewBox='0 0 12 15'>
    <path
      fillRule='nonzero'
      d='M6.532 6.967a.75.75 0 00-.247-.157.75.75 0 00-.57 0 .75.75 0 00-.247.157l-1.5 1.5a.753.753 0 101.064 1.066l.218-.226v1.943a.75.75 0 101.5 0V9.307l.218.226a.75.75 0 001.065 0 .75.75 0 000-1.066l-1.5-1.5zM12 5.205a.983.983 0 00-.045-.202v-.068a.803.803 0 00-.143-.21l-4.5-4.5a.803.803 0 00-.21-.142h-.075A.825.825 0 006.795 0H2.25A2.25 2.25 0 000 2.25v10.5A2.25 2.25 0 002.25 15h7.5A2.25 2.25 0 0012 12.75v-7.5-.045zM7.5 2.558L9.443 4.5H8.25a.75.75 0 01-.75-.75V2.558zm3 10.192a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V2.25a.75.75 0 01.75-.75H6v2.25A2.25 2.25 0 008.25 6h2.25v6.75z'
    />
  </svg>
)

export { Upload }
