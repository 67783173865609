import styled, { css, keyframes } from 'styled-components'
import { font, color } from 'theme'

import { Button as Default } from 'components'
import { TextOverflowOneLine } from 'theme/helpers/textHelpers'

export const FirstWrapper = styled.div`
  position: relative;
  margin: 0 0 30px;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ArrowWrap = styled.div`
  width: 16px;
  height: 8px;
`

export const Line = styled.div`
  display: flex;
  flex-basis: calc(50% - 20px);
  justify-content: space-between;
  align-items: flex-end;
`

const Value = css`
  font-size: 16px;
  line-height: 1.4;
`

export const Col = styled.div`
  width: 100%;
`

export const Title = styled.h3`
  ${Value};
  margin: 0;
  color: ${color.white};
`

export const LabelTop = styled.h5`
  margin: 0 0 8px;
  font-weight: 400;
  line-height: 16px;
  color: ${color.grey};
`

export const LabelBottom = styled.h5`
  margin-top: 4px;
  display: flex;
  align-items: center;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
`

export const IconWrapper = styled.div`
  margin: 0 10px 0 0;
`

export const IconWrapperExchange = styled.div`
  margin: 0 0 0 8px;
  transform: rotate(180deg);
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const WrapBtn = styled.div`
  margin: 0 0 30px;
  display: flex;
`

export const CustomBtn = styled(Default)`
  color: ${color.default};
  border: 1px solid ${color.default};
  transition: all 0.3s ease-out;
  &::before {
    content: none;
  }
  &:hover,
  &:focus {
    color: ${color.white};
    background-color: ${color.default};
    border: 1px solid ${color.default};
  }
  &:not(:last-child) {
    margin: 0 10px 0 0;
  }
`

export const WrapInfo = styled.div`
  margin: 30px 0 0;
  padding: 0 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

export const Course = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`

export const CourseTitle = styled.h5`
  margin: 0 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.5);
`

export const placeHolderShimmer = keyframes`
  0% {
    background-position: -660px 0
  }
  100% {
    background-position: 660px 0
  }
`

export const CourseValue = styled.span<{ isLoading?: boolean }>`
  position: relative;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: ${color.white};
  ${({ isLoading }) =>
    isLoading &&
    css`
      font-size: 0;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ${placeHolderShimmer};
        animation-timing-function: linear;
        will-change: background-position;
        background: linear-gradient(270deg, transparent 18%, rgba(232, 230, 255, 0.1) 44%, transparent 77%);
        background-size: 660px 100%;
        border-radius: 8px;
      }
    `}
`

export const BottomWrapper = styled.div`
  margin: 60px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`

export const BottomInfo = styled.div`
  margin: 0;
  width: 100%;
  max-width: calc(50% - 20px);
  display: flex;
  flex-basis: calc(50% - 20px);
  justify-content: space-between;
  align-items: center;
`

export const BottomText = styled.h5`
  ${TextOverflowOneLine};
  margin: 0;
  max-width: 80%;
  color: rgba(255, 255, 255, 0.5);
`

export const SubmitButton = styled(Default)`
  max-width: unset;
  flex-basis: calc(50% - 20px);
`

export const Currency = styled.span`
  position: absolute;
  right: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.44;
  text-align: right;
  color: ${color.white};
  opacity: 0.5;
`

export const InputWrapper = styled.div<{ isError: boolean }>`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-family: ${font.display};
  background-color: transparent;
  border: ${({ isError }) =>
    !isError
      ? `
    1px solid rgba(255, 255, 255, 0.2);
  `
      : `
    1px solid ${color.warning};
  `};
  border-top: 0;
  border-radius: 0 0 6px 6px;
  & input {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.44;
    border: none;

    &:focus {
      border: none;
    }
  }

  flex-basis: calc(50% - 20px);
  border-radius: 6px;
  border: ${({ isError }) =>
    !isError
      ? `
  1px solid rgba(255, 255, 255, 0.2);
`
      : `
  1px solid ${color.warning};
  `};

  margin: 0;
  height: 80px;
  flex-basis: calc(50% - 20px);
  & input {
    padding: 0 82px 0 24px;
    height: 80px;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.31;
    &:focus {
      border: none;
    }
  }
`

export const SwapBtn = styled.button`
  margin: 0;
  padding: 0;
  display: block;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  fill: #0562c7;
`
export const TopWrapper = styled.div`
  margin: 0 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TopInfo = styled.p`
  ${TextOverflowOneLine};
  margin: 0;
  max-width: 80%;
  font-weight: 400;
  font-size: 14px;
  color: ${color.grey};
`

export const TopCol = styled.div`
  display: flex;
  flex-basis: calc(50% - 20px);
  max-width: calc(50% - 20px);
  justify-content: space-between;
  align-items: center;
`

export const SmallButton = styled.div`
  padding-right: 0;
  width: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: ${color.default};
  text-transform: uppercase;
  background-image: none;
  background-color: transparent;
  transition: color 200ms;
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${color.white};
    background-color: transparent;
  }
`

export const CenterWrap = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Buttons = styled.div`
  margin: 34px 0 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  & > :nth-child(2n) {
    margin-left: 20px;
  }
`

export const WarningGray = styled.h5`
  margin: 24px 0 20px;
  padding: 10px;
  color: ${color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
`

export const PaginationSelect = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .SelectCoin {
    margin: 0 0 40px;
    width: 100%;
    &__control {
      margin: 0;
      padding: 16px 10px 16px 16px;
      width: 100%;
      height: 56px;
      color: ${color.white};
      background-color: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &--menu-is-open {
        .SelectCoin__indicator.SelectCoin__dropdown-indicator {
          color: ${color.default};
          transform: rotate(180deg);
        }
      }
    }
    &__value-container {
      padding: 0;
      &--has-value {
        overflow: visible;
      }
    }
    &__single-value {
      width: 100%;
      font-family: ${font.regular};
      font-size: 16px;
      line-height: normal;
      text-transform: uppercase;
      color: ${color.white};
    }
    &__placeholder {
      position: static;
      margin: 0 10px 0 0;
      font-size: 14px;
      color: ${color.white};
      transform: none;
    }
    &__indicators {
      margin: 0 0 0 10px;
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding: 0;
      transition: all 0.3s ease-out;
    }
    &__menu {
      top: 64px;
      right: 0;
      margin: 0;
      padding: 0;
      background-color: #212029;
      border: solid 1px rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      transition: all 0.3s ease-out;
      z-index: 3;
    }
    &__menu-list {
      padding: 0;
      max-height: 260px;
      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 3px;
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
      }
    }
    &__option {
      padding: 16px 45px 16px 18px;
      font-family: ${font.regular};
      font-size: 16px;
      line-height: 1.71;
      text-transform: uppercase;
      color: ${color.white};
      background-color: transparent;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &--is-focused {
        background-color: rgba(225, 225, 225, 0.1);
      }
      &:first-child {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const WrapperCoin = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ImgCoin = styled.img`
  width: 24px;
  margin-right: 5px;
`

export const CoinValue = styled.span`
  margin: 0 0 0 10px;
`

export const SumValue = styled.span`
  color: rgba(255, 255, 255, 0.5);
`

export const Modal = styled.div<{ isModalOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10;
  ${({ isModalOpen }) =>
    isModalOpen &&
    `
    display: flex;
  `}
`

export const ModalContainer = styled.div`
  position: relative;
  padding: 26px 40px 40px;
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #292833;
  border-radius: 12px;
  overflow: auto;
  z-index: 6;
`

export const CloseBtn = styled.button`
  position: absolute;
  top: 26px;
  right: 36px;
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: ${color.grey};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 10px;
    width: 16px;
    height: 2px;
    background-color: currentColor;
    transform: rotate(-45deg);
    z-index: 2;
  }
  &::after {
    content: '';
    position: absolute;
    top: 10px;
    width: 16px;
    height: 2px;
    background-color: currentColor;
    transform: rotate(45deg);
    z-index: 2;
  }
`

export const TitleModal = styled.h3`
  margin: 0 0 32px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.583333px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
`

export const InfoModal = styled.div`
  margin: 0 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ItemModal = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const ItemTextModal = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${color.white};
  opacity: 0.5;
`

export const ItemValueModal = styled.span<{ colorValue?: 'green' }>`
  font-size: 14px;
  line-height: 1.43;
  color: ${color.white};
  ${({ colorValue }) =>
    colorValue === 'green' &&
    `
    color: ${color.success};
  `}
`

export const InfoText = styled.p`
  margin: 0 0 16px;
  padding: 20px 16px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`

export const BtnWrap = styled.div`
  width: 100%;
  margin: 16px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconWrapSend = styled.div`
  margin: 20px auto;
  display: flex;
  width: 64px;
  height: 64px;
  color: #4caf50;
  border: 2px solid #4caf50;
  border-radius: 50%;
  svg {
    width: 100%;
  }
`

export const TitleModalSuccess = styled.h3`
  margin: 0 0 10px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 18px;
  line-height: 1.44;
  color: ${color.white};
`

export const ValueModalSuccess = styled.h4`
  margin: 0 0 30px;
  font-family: ${font.display};
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  color: ${color.success};
`

export const BtnTransaction = styled(Default)`
  width: 100%;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 5;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const Loader = styled.span`
  margin: 0;
  display: block;
  width: 22px;
  height: 22px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top: 3px solid ${color.default};
  border-width: 3px;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`
