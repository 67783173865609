import React from 'react'
import { PaginationBtn, PaginationPages, PaginationPagesText, Pretext } from './Style'

type Props = {
  total: number
  current: number
  loading: boolean
  selectPage?: (page: number) => void
}

const STEP = 1

function Pagination({ current, selectPage, total }: Props) {
  const handleNextPage = () => {
    if (selectPage) {
      const step = current + STEP

      selectPage(step > total ? total : step)
    }
  }

  const handlePrevPage = () => {
    if (selectPage) {
      const step = current - STEP

      selectPage(step < STEP ? STEP : step)
    }
  }

  return (
    <PaginationPages>
      <PaginationPagesText>
        Page:&nbsp;
        <strong>{current > total ? total : current}</strong>
        <Pretext>of</Pretext>
        <strong>{total}</strong>
      </PaginationPagesText>

      <PaginationBtn isLeft onClick={handlePrevPage} disabled={current <= STEP} />
      <PaginationBtn isRight onClick={handleNextPage} disabled={current >= total} />
    </PaginationPages>
  )
}

export { Pagination }
