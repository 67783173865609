import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { ToastConductor as View } from './ToastConductor'
import { Toasts, ToastState } from './@types'
import { selectToasts } from './selector'
import { withInjectors } from './withInjectors'

type Props = {
  toasts: Toasts
}

const mapStateToProps = createStructuredSelector<ToastState, Props>({
  toasts: selectToasts(),
})

const withConnect = connect(mapStateToProps)

export const ToastConductor = compose<ComponentType>(withInjectors, withConnect)(View)
