import React from 'react'

const Declined = () => (
  <svg viewBox='0 0 22 22'>
    <path
      fillRule='evenodd'
      d='M11 0C4.925 0 0 4.925 0 11s4.925 11 11 11 11-4.925 11-11S17.075 0 11 0zM2 11a9 9 0 1118 0 9 9 0 01-18 0zm12.707-3.707a1 1 0 010 1.414L12.414 11l2.293 2.293a1 1 0 01-1.414 1.414L11 12.414l-2.293 2.293a1 1 0 01-1.414-1.414L9.586 11 7.293 8.707a1 1 0 011.414-1.414L11 9.586l2.293-2.293a1 1 0 011.414 0z'
      clipRule='evenodd'
    />
  </svg>
)

export { Declined }
