import { Component, createElement } from 'react'
import { ConductorProps, Toasts } from './@types'

class ToastConductor extends Component<ConductorProps> {
  public render() {
    const { toasts } = this.props
    return this.createToast(toasts)
  }

  private createToast = (toasts: Toasts) => {
    const [toast] = toasts

    if (toast) {
      const { container, name } = toast

      return createElement(container, {
        key: name,
      })
    }

    return null
  }
}

export { ToastConductor }
