import styled from 'styled-components'
import { font, color } from 'theme'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.p`
  margin: 20px 0 30px;
  font-family: ${font.regular};
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`

export const IconWrapSend = styled.div`
  margin: 0 auto 20px;
  display: flex;
  width: 64px;
  height: 64px;
  color: ${color.success};
  border: 2px solid ${color.success};
  border-radius: 50%;
  svg {
    width: 100%;
  }
`

export const TitleSend = styled.h3`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  color: ${color.white};
`

export const TextSend = styled.p`
  margin: 0 0 10px;
  font-family: ${font.regular};
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
`
