import React from 'react'
import { Input } from 'components'

import { Currency, InputWrapper } from './Style'

type Props = {
  error: boolean
  value?: string
  currency: string
  errorMessage?: string
  disabled?: boolean
  onChange: (value: string) => void
}

function ExchangeInput({ currency, value, disabled, ...rest }: Props) {
  return (
    <InputWrapper isError={false}>
      <Input {...rest} isExchange={true} value={value} pattern='[0-9]*' inputMode='decimal' disabled={disabled} />
      <Currency>{currency}</Currency>
    </InputWrapper>
  )
}

export { ExchangeInput }
