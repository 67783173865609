import { IconMap } from '../components'

type Url =
  | '/users'
  | '/transactions'
  | '/balances'
  | '/exchange'
  | '/logs'
  | '/'
  | '/staff'
  | '/company'
  | '/limits'
  | '/signup'
  | '/requests'
  | '/invoice'
  | '/invoices'
  | '/wallet'
  | '/general'
  | '/web_hooks'

// TODO: rename to module name
type PageName =
  | 'users'
  | 'transactions'
  | 'balances'
  | 'exchange'
  | 'settings'
  | 'logs'
  | 'staff'
  | 'requests'
  | 'company'
  | 'limits'
  | 'signin'
  | 'signup'
  | 'invoice'
  | 'invoices'
  | 'wallet'
  | 'webHooks'

export type Link = {
  url: Url
  title: string
  icon: IconMap
}

export type LinksConstant = {
  [k in PageName]: Url
}

export const links: LinksConstant = {
  users: '/users',
  signin: '/',
  wallet: '/wallet',
  signup: '/signup',
  invoice: '/invoice',
  transactions: '/transactions',
  balances: '/balances',
  exchange: '/exchange',
  settings: '/general',
  logs: '/logs',
  invoices: '/invoices',
  limits: '/limits',
  company: '/company',
  requests: '/requests',
  staff: '/staff',
  webHooks: '/web_hooks',
}
