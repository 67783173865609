import { createElement, Component } from 'react'
import { ConductorProps, Modals } from './@types'

class ModalConductor extends Component<ConductorProps> {
  public render() {
    const { modals } = this.props
    return this.createModal(modals)
  }

  private createModal = (modals: Modals) => {
    const [firstModal] = modals

    if (firstModal) {
      const { container, name } = firstModal

      return createElement(container, {
        key: name,
      })
    }

    return null
  }
}

export { ModalConductor }
