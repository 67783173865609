import { Modal, ModalName } from './@types'
import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODAL } from './constants'

export function openModal(data: Modal) {
  return {
    type: OPEN_MODAL,
    payload: data,
  } as const
}

export function closeModal(name: ModalName) {
  return {
    type: CLOSE_MODAL,
    payload: { name },
  } as const
}

export function closeAllModal() {
  return {
    type: CLOSE_ALL_MODAL,
  } as const
}
