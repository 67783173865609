import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { closeAllModal } from 'containers'

import { ClientState } from '../@types'
import { changeDetailRequest } from '../actions'
import { createStructuredSelector } from 'reselect'
import { selectChangeDetailRequest } from '../selectors'
import { ChangeRateRequest as View } from '../components'

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      closeModal: closeAllModal,
      newRequest: changeDetailRequest,
    },
    dispatch,
  )
}

type Props = {
  request: RequestState
}

type OwnProps = {
  type: string
  title: string
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    request: selectChangeDetailRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const ChangeRateRequestContainer = compose<ComponentType<OwnProps>>(withConnect)(View)
