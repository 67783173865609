import React from 'react'

const ShoppingBagF = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeWidth='2'
    className='feather feather-shopping-bag'
    viewBox='0 0 24 24'
  >
    <path d='M6 2L3 6v14a2 2 0 002 2h14a2 2 0 002-2V6l-3-4z'></path>
    <path d='M3 6L21 6'></path>
    <path d='M16 10a4 4 0 01-8 0'></path>
  </svg>
)

export { ShoppingBagF }
