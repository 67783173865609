import { ZERO } from 'constant'

export const cutDecimals = (value: string | undefined, decimal: number) => {
  if (!value) {
    return value
  }
  const formatted = value.replace(',', '.').split('.')

  const result = formatted
    .map((el, i) =>
      i
        ? el
            .split('')
            .slice(ZERO, decimal)
            .join('')
        : el,
    )
    .join('.')

  return result
}
