import { RequestState } from '@types'

import { CommonCurrencies, Actions } from '../@types'
import {
  SET_CRYPTO_CURRENCIES,
  GET_CRYPTO_CURRENCIES_FAILURE,
  GET_CRYPTO_CURRENCIES_REQUEST,
  GET_CRYPTO_CURRENCIES_SUCCESS,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getCryptoCurrencies = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_CRYPTO_CURRENCIES_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_CRYPTO_CURRENCIES_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_CRYPTO_CURRENCIES_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: CommonCurrencies = []

export const cryptoCurrencies = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_CRYPTO_CURRENCIES:
      return action.payload.data
    default:
      return state
  }
}
