export const DOMAIN = 'modules/webHooks'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const SET_WEB_HOOKS = 'modules/webHooks/ui/SET_WEB_HOOKS'

export const SELECT_WEB_HOOKS_PAGE = 'modules/webHooks/ui/SELECT_WEB_HOOKS_PAGE'

export const GET_WEB_HOOKS_REQUEST = 'modules/webHooks/domain/GET_WEB_HOOKS_REQUEST'
export const GET_WEB_HOOKS_SUCCESS = 'modules/webHooks/domain/GET_WEB_HOOKS_SUCCESS'
export const GET_WEB_HOOKS_FAILURE = 'modules/webHooks/domain/GET_WEB_HOOKS_FAILURE'

export const SET_RESEND = 'modules/webHooks/ui/SET_RESEND'

export const ON_RESEND_REQUEST = 'modules/webHooks/ui/ON_RESEND_REQUEST'
export const ON_RESEND_SUCCESS = 'modules/webHooks/ui/ON_RESEND_SUCCESS'
export const ON_RESEND_FAILURE = 'modules/webHooks/ui/ON_RESEND_FAILURE'
