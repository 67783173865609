import React from 'react'
import { EXTERNAL_LINK_REGEXP } from 'utils'

import { Href, Icon, IconMap } from 'components'
import { Item, StyledAnchor, StyledLink } from './Style'

type LinkProps = {
  url: string
  title: string
  icon: IconMap
}

const CustomLink = ({ title, url, icon }: LinkProps) => {
  return (
    <Item>
      {EXTERNAL_LINK_REGEXP.test(url) ? (
        <Href href={url}>
          <StyledAnchor>{title}</StyledAnchor>
        </Href>
      ) : (
        <StyledLink exact to={url} activeClassName='activeNavLink'>
          <Icon name={icon} size='small' />
          <span className='link-title'>{title}</span>
        </StyledLink>
      )}
    </Item>
  )
}

export { CustomLink }
