import React, { Component } from 'react'

import { ChoiceInvoice } from '../Choice'
import { PaymentInvoice } from '../Payment'

import { Address, Invoice } from '../../@types'

type Props = {
  invoice: Invoice
}

type State = {
  selected: Address
}

const initialState = {
  isSelected: false,
  selected: {
    address: '',
    rate_usd: 0,
    rate_eur: 0,
    expected_amount: 0,
    crypto_currency: '',
  },
}

class Select extends Component<Props, State> {
  public state = initialState

  public handleGetBack = () => {
    this.setState(() => initialState)
  }

  public render() {
    const { isSelected, selected } = this.state
    const { invoice } = this.props

    return isSelected ? (
      <PaymentInvoice
        status={invoice.status}
        date={invoice.expired_at}
        address={selected.address}
        isBack={this.handleGetBack}
        rate={this.handleGetRate()}
        amount={invoice.client_amount}
        invoiceId={invoice.foreign_id}
        currency={invoice.client_currency}
        cryptoCurrency={selected.crypto_currency}
        expectedAmount={selected.expected_amount}
        paidAfterExpired={invoice.allow_paid_after_expired}
      />
    ) : (
      <ChoiceInvoice select={this.handleSelect} currency={invoice.client_currency} addresses={invoice.addresses} />
    )
  }

  private handleGetRate = () => {
    const { invoice } = this.props
    const { selected } = this.state
    return invoice.client_currency === 'EUR' ? selected.rate_eur : selected.rate_usd
  }

  private handleSelect = (selected: Address) => {
    this.setState(() => ({ selected, isSelected: true }))
  }
}

export { Select }
