import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { openModal } from 'containers'

import { selectStaffs, selectGetStaffRequest } from '../selectors'
import { withInjectors } from '../injectors'
import { ClientState, Staffs } from '../@types'
import { Staff as View } from '../components'
import { getStaffsRequest, removeUserRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      getData: getStaffsRequest,
      removeStaff: removeUserRequest,
    },
    dispatch,
  )

type Props = {
  staffs: Staffs
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    staffs: selectStaffs(),
    request: selectGetStaffRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const StaffContainer = compose<ComponentType>(withInjectors, withConnect)(View)
