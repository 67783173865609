import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { closeAllModal } from 'containers/Modal'

import { withInjectors } from '../injectors'
import { selectNewUser, selectAddUserRequest } from '../selectors'
import { AddUser as View } from '../components'
import { ClientState, NewUser } from '../@types'
import { addUserRequest, setUserClear } from '../actions'

type Props = {
  newUser: NewUser
  addUserRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    newUser: selectNewUser(),
    addUserRequest: selectAddUserRequest(),
  })

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ clearState: setUserClear, addUser: addUserRequest, onClose: closeAllModal }, dispatch)

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const AddUserContainer = compose<ComponentType>(withInjectors, withConnect)(View)
