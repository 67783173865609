import { Toast } from './@types'
import { SHOW_TOAST, CLOSE_ALL_TOAST, CLOSE_TOAST } from './constants'

export function showToast(data: Toast) {
  return {
    type: SHOW_TOAST,
    payload: data,
  } as const
}

export function closeToast(name: string) {
  return {
    type: CLOSE_TOAST,
    payload: { name },
  } as const
}

export function closeAllToast() {
  return {
    type: CLOSE_ALL_TOAST,
  } as const
}
