import styled from 'styled-components'
import { color } from 'theme'

export const InvoiceWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #f6f7fb;
  z-index: 3;
`

export const InvoiceContainer = styled.div`
  margin: 0 auto;
  padding: 24px 0 0;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  color: ${color.black};
`
