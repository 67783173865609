import React, { Fragment, Component } from 'react'

import { Company, Request } from '../../@types'
import { newChangeRateRequest } from '../../modals'

import {
  TableContainer,
  TableTitle,
  TableText,
  Wrapper,
  RateBlock,
  RateBlockTitle,
  RateBlockText,
  SelectButton,
} from './Style'

type Props = {
  company: Company
  request: Request
  getCompany: () => void
  getRequest: () => void
  openModal: (data: {}) => void
}

const data = [
  {
    title: 'Flexible workflow.',
    text: `Deposits and withdrawals are only in crypto. You can change the selected workflow at any time.`,
    type: 'float',
  },
  {
    title: 'Autoexchange workflow.',
    text: `All incoming crypto will be converted to EUR by current  market rate. You can change the selected workflow at any time.`,
    type: 'fix_eur',
  },
]

class General extends Component<Props> {
  public componentDidMount(): void {
    const { getRequest, getCompany } = this.props
    getCompany()
    getRequest()
  }

  public openNewRequest = (type: string) => {
    const { openModal } = this.props
    openModal(
      newChangeRateRequest({
        type,
        title: '',
      }),
    )
  }
  public render() {
    const { request, company } = this.props
    return (
      <Fragment>
        <TableContainer>
          <TableTitle>General</TableTitle>
          <TableText>Workflow how we operate</TableText>
          <Wrapper>
            {data.map(item => (
              <Block
                key={item.type}
                type={item.type}
                text={item.text}
                date={(request && request.processed_at) || ''}
                request={(request && request.value) || ''}
                title={item.title}
                current={company.approach_currency}
                onClick={this.openNewRequest}
              />
            ))}
          </Wrapper>
        </TableContainer>
      </Fragment>
    )
  }
}

type BlockProps = {
  text: string
  type: string
  title: string
  current: string
  request: string
  date: string
  onClick: (type: string) => void
}

const Block = ({ type, current, request, onClick, title, text, date }: BlockProps) => {
  const selected = current === type
  const requested = request === type

  const handleClick = () => {
    onClick(type)
  }

  return (
    <RateBlock statusActive={selected}>
      <RateBlockTitle>{title}</RateBlockTitle>
      <RateBlockText>{text}</RateBlockText>
      <SelectButton
        type={requested ? 'ghost' : 'default'}
        size='medium'
        isActive={selected}
        isRequested={requested}
        disabled={requested || selected}
        onClick={handleClick}
      >
        {selected ? 'Current workflow' : requested ? `Request sent on ${date}` : 'Change workflow'}
      </SelectButton>
    </RateBlock>
  )
}

export { General }
