import { call, all, put, takeLatest, select } from 'redux-saga/effects'

import { getTrimming } from 'utils'

import { query } from '../client'
import { FilterFiatRequest } from '../@types'
import { selectFiatRequests } from '../selectors'
import { GET_FIAT_REQ, GetFiatReqResponse } from '../queries'
import { getFiatFailure, getFiatSuccess, setFiat, getFiatRequest as request } from '../actions'
import { GET_FIAT_REQUEST, SELECT_FIAT_PAGE, SELECT_FIAT_PAGE_SIZE, SET_FIAT_FILTERS } from '../constants'

export function* getFiatRequest() {
  const { filters, currentPage, pageSize } = yield select(selectFiatRequests())

  try {
    const dataFilters = getTrimming<FilterFiatRequest>(filters)

    const response: GetFiatReqResponse = yield call(query, {
      query: GET_FIAT_REQ,
      fetchPolicy: 'no-cache',
      variables: { ...dataFilters, page: currentPage, page_size: pageSize },
    })
    const {
      findFiatReqs: { total, fiat_requests, stats },
    } = response.data

    yield put(setFiat(fiat_requests, total, stats))

    yield put(getFiatSuccess())
  } catch (err) {
    yield put(getFiatFailure(err.messages))
  }
}

export function* onRequest() {
  try {
    yield put(request())
  } catch (err) {
    yield put(getFiatFailure(err.messages))
  }
}

export function* saga() {
  return yield all([
    yield takeLatest(GET_FIAT_REQUEST, getFiatRequest),

    yield takeLatest(SELECT_FIAT_PAGE, onRequest),
    yield takeLatest(SET_FIAT_FILTERS, onRequest),
    yield takeLatest(SELECT_FIAT_PAGE_SIZE, onRequest),
  ])
}
