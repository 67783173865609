import React, { Fragment } from 'react'

import { ReactTip } from 'utils'
import { EUR_PREFIX } from 'constant'
import { Icon, DecimalsFixed, IconMap } from 'components'
import { Container, Currency, BottomWrap, InfoWrap, InfoTitle, InfoValue, InfoMoney, HrLine, IconInfo } from './Style'

type Props = {
  iconName: IconMap
  currency: string
  totalDeposits: number
  totalDepositsEur: number
  totalWithdrawals: number
  totalWithdrawalsEur: number
  totalNet: number
  totalNetEur: number
  loading: boolean
}

const CurrencyCard = ({
  iconName,
  currency,
  totalDeposits,
  totalDepositsEur,
  totalWithdrawals,
  totalWithdrawalsEur,
  totalNet,
  totalNetEur,
  loading,
}: Props) => (
  <Fragment>
    <Container loading={loading} data-tip data-for={currency}>
      <IconInfo>
        <Icon name='info' size='medium' />
      </IconInfo>
      <Currency>
        <Icon name={iconName} size='xlarge' />
      </Currency>

      <InfoWrap>
        <InfoValue loading={loading}>
          {DecimalsFixed(totalNet, 'crypto')} {currency}
        </InfoValue>
        <InfoMoney loading={loading}>{DecimalsFixed(totalNetEur, 'fiat', EUR_PREFIX)}</InfoMoney>
      </InfoWrap>
    </Container>

    <ReactTip id={currency} className='customTooltip'>
      <BottomWrap>
        <InfoWrap small>
          <InfoTitle>Total deposits</InfoTitle>
          <InfoValue small loading={loading}>
            {DecimalsFixed(totalDeposits, 'crypto')} {currency}
          </InfoValue>
          <InfoMoney small loading={loading}>
            {DecimalsFixed(totalDepositsEur, 'fiat', EUR_PREFIX)}
          </InfoMoney>
        </InfoWrap>

        <HrLine />

        <InfoWrap small>
          <InfoTitle>Total withdraws</InfoTitle>
          <InfoValue small loading={loading}>
            {DecimalsFixed(totalWithdrawals, 'crypto')} {currency}
          </InfoValue>
          <InfoMoney small loading={loading}>
            {DecimalsFixed(totalWithdrawalsEur, 'fiat', EUR_PREFIX)}
          </InfoMoney>
        </InfoWrap>
      </BottomWrap>
    </ReactTip>
  </Fragment>
)

export { CurrencyCard }
