import React from 'react'
import { links } from 'constant'

import { Icon } from 'components'
import { Container, MainInfo, Title, Text, BackBtn } from './Style'

const InvalidLinkInvoice = () => {
  return (
    <Container>
      <MainInfo>
        <Icon name='alert' size='xlarge' color='#d6ddef' />

        <Title>Invalid transaction link</Title>
        <Text>Please check the link and try once again.</Text>
      </MainInfo>

      <BackBtn to={links.signin}>Back to&nbsp;the site</BackBtn>
    </Container>
  )
}

export { InvalidLinkInvoice }
