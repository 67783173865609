import styled from 'styled-components'
import { Icon } from 'components'

import { Input } from './@types'
import { font, color } from 'theme'

export const Wrapper = styled.div`
  position: relative;
  margin: 0 0 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.8);
`

export const InputElement = styled.textarea<Input>`
  display: block;
  min-height: 68px;
  padding: 10px;
  font-family: ${font.regular};
  font-size: inherit;
  color: ${color.white};
  border-style: solid;
  border-color: ${({ error }) => (error ? color.warning : 'rgba(255, 255, 255, 0.3)')};
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: none;
  transition: border-color 0.2s;
  resize: none;

  &::placeholder {
    color: ${color.white};
    transition: color 0.2s;
  }

  &:hover {
    border-color: ${color.white};
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${color.default};
    caret-color: ${color.white};

    &::placeholder {
      color: grey;
    }
  }
`

export const CrossIcon = styled(Icon)`
  position: absolute;
  top: 25%;
  right: 20px;
  width: 12px;
  height: 12px;
  color: red;
  transform: translate(0, -50%);
`

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  color: ${color.warning};
  text-align: center;
`
