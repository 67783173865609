import styled from 'styled-components'
import { breakpoints } from 'theme'

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 30px 40px 40px;
  display: flex;
  flex-direction: column;
  max-width: 460px;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2;

  ${breakpoints.greaterThan('sm')`
    padding-top: 10px;
    height: auto;
    background-color: #292833;
    border-radius: 12px;
  ` as string};
`
