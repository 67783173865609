import React, { Component, Fragment } from 'react'

import { RequestState } from '@types'
import { createColumns, NewTable } from 'components'

import { COLUMNS } from './Columns'

import { WebHooks as Type, WebHook } from '../../@types'

type Props = {
  webHooks: Type
  getData: () => void
  request: RequestState
  resend: (id: number) => void
  selectPage: (number: number) => void
}

class WebHooks extends Component<Props> {
  public render() {
    const { getData, request, webHooks, selectPage, resend } = this.props

    const { data, page, pageCount } = webHooks

    return (
      <Fragment>
        <NewTable
          data={data || []}
          getData={getData}
          pagination={true}
          currentPage={page}
          pageCount={pageCount}
          selectPage={selectPage}
          loading={request.isFetching}
          columns={createColumns<WebHook>(COLUMNS(resend))}
        />
      </Fragment>
    )
  }
}

export { WebHooks }
