import styled from 'styled-components'

import { IconProps } from './@types'
import { getSize } from './helpers'

export const IconWrapper = styled.div<IconProps>`
  box-sizing: border-box;
  padding: ${({ size }) => getSize(size).padding};
  width: ${({ size }) => getSize(size).width};
  height: ${({ size }) => getSize(size).height};
  color: inherit;
  fill: ${({ color }) => color};
  svg {
    display: block;
  }
`
