import styled from 'styled-components'
import { font, color } from 'theme'

export const Wrap = styled.div`
  margin: 0 auto;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const IconWrap = styled.span`
  margin: 0 0 24px;
  width: 50px;
  color: ${color.white};
  opacity: 0.5;
`

export const Title = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-weight: bold;
  font-size: 18px;
  line-height: 1.44;
  color: ${color.white};
`

export const Text = styled.p`
  margin: 0 0 16px;
  max-width: 336px;
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: ${color.white};
  opacity: 0.5;
`
