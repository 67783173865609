import React from 'react'

const HouseIcon = () => (
  <svg fill='none' viewBox='0 0 20 21'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M1 8l7.772-6.045a2 2 0 012.456 0L19 8v10a2 2 0 01-2 2H3a2 2 0 01-2-2V8z'
      clipRule='evenodd'
    />
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M7 20v-6a2 2 0 012-2h2a2 2 0 012 2v6' />
  </svg>
)

export { HouseIcon }
