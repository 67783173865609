import { GET_EXCHANGE_FAILURE, GET_EXCHANGE_CLEAR, GET_EXCHANGE_REQUEST, GET_EXCHANGE_SUCCESS } from '../constants'

export const getExchangeRequest = (params: {}) => {
  return {
    type: GET_EXCHANGE_REQUEST,
    payload: params,
  } as const
}

export const getExchangeFailure = (errorMessage: string) => {
  return {
    type: GET_EXCHANGE_FAILURE,
    payload: { errorMessage },
  } as const
}

export const getExchangeSuccess = () => {
  return {
    type: GET_EXCHANGE_SUCCESS,
  } as const
}

export const getExchangeClear = () => {
  return {
    type: GET_EXCHANGE_CLEAR,
  } as const
}
