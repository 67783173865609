import styled from 'styled-components'
import { font, color } from 'theme'

export const Title = styled.h2`
  margin: 0 0 8px;
  font-family: ${font.display};
  font-size: 18px;
  line-height: 26px;
`

export const Text = styled.p<{ noMargin?: boolean }>`
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.43;
  opacity: 0.8;
  ${({ noMargin }) =>
    noMargin &&
    `
    margin: 0;
  `}
`

export const Link = styled.span`
  color: ${color.default};
  text-decoration: none;
`
