import React, { Fragment, useState, useEffect } from 'react'
import uuid from 'react-uuid'
import { ValueType } from 'react-select'

import { SelectProps, SelectOptions } from './@types'
import { Label, StyledSelect } from './Style'

function Select<T>({
  label,
  value,
  select,
  options,
  isDisabled,
  placeholder,
  size = 'default',
  isLoading = false,
  ...rest
}: SelectProps<T>) {
  const ID = uuid()
  const initialValue = options.find(i => i.value === value)
  const [selected, setSelect] = useState<ValueType<SelectOptions<T>>>(initialValue)

  useEffect(() => {
    setSelect(initialValue)
  }, [value, initialValue])

  const handleSelect = (selectedOption: ValueType<SelectOptions<T>>) => {
    const value = selectedOption as SelectOptions<T>
    select(value)
    setSelect(selectedOption)
  }

  return (
    <Fragment>
      {label && <Label htmlFor={ID}>{label}</Label>}

      <StyledSelect
        isDisabled={isDisabled}
        size={size}
        inputId={ID}
        className='Select'
        isLoading={isLoading}
        classNamePrefix='Select'
        {...rest}
        value={selected}
        placeholder={options.length ? selected : placeholder}
        isSearchable={false}
        minMenuHeight={96}
        menuPlacement='bottom'
        onChange={handleSelect}
        options={options}
      />
    </Fragment>
  )
}

export { Select }
