import React from 'react'

const ArrowLightIcon = () => (
  <svg fill='none' viewBox='0 0 18 10'>
    <g stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' opacity='.3'>
      <path d='M1 5h16M13 1l4 4-4 4' />
    </g>
  </svg>
)

export { ArrowLightIcon }
