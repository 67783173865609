import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { LogoLink } from './LogoLink'

export const StyledNavLink = styled(NavLink)`
  color: currentColor;
  display: block;
  width: 156px;
`

export const IconWrapper = styled.span`
  width: auto;
  max-width: 100%;
  cursor: pointer;
`

export const LogoImg = styled.img<LogoLink>`
  width: ${props => props.iconWidth};
  height: ${props => props.iconHeight};
`
