import React from 'react'

import styled from 'styled-components'

import { font, color } from 'theme'
import { currenciesMock } from 'constant'
import { CryptoFiat, ColumnElements, Icon, Fiat, Status, TransactionLink } from 'components'

const WrapId = styled.span`
  font-size: 14px;
  color: ${color.white};
`

const UserId = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
`

const CryptoIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

const CryptoName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`

const Type = styled.span`
  padding: 2px 8px;
  display: inline-block;
  text-transform: capitalize;
  color: #8d8d92;
  background-color: #292833;
  border-radius: 12px;
`

export function TxIdComponent(value: string, currency: string) {
  const [first, second] = value.split(' ')

  const url = currency.toUpperCase() === 'BTC' ? 'https://www.blockchain.com/btc/tx' : 'https://etherscan.io/tx'
  const link = `${url.toLowerCase()}/${first}`
  return (
    <ColumnElements>
      <WrapId>
        <TransactionLink link={link} value={first} />
      </WrapId>
      <UserId>{second}</UserId>
    </ColumnElements>
  )
}

export function IdComponent(value: string) {
  const [first, second] = value.split(' ')
  return (
    <ColumnElements>
      <WrapId>{first}</WrapId>
      <UserId>{second}</UserId>
    </ColumnElements>
  )
}

export function CoinComponent(value: string) {
  return (
    <CryptoIcon>
      <Icon
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        name={value.toLowerCase()}
        size='medium'
      />
      <CryptoName>{currenciesMock[value.toUpperCase()]}</CryptoName>
    </CryptoIcon>
  )
}

export function TypeComponent(value: string) {
  return <Type>{value}</Type>
}

export function ValueComponent(value: string, currency: string) {
  const [first, second] = value.split(' ')
  return <CryptoFiat cryptoAmount={first} cryptoCurrency={currency} fiatAmount={second} fiatCurrency='EUR' />
}

export function FiatComponent(value: string) {
  const [first, second] = value.split(' ')
  return <Fiat usdAmount={first} eurAmount={second} />
}

export function StatusComponent(value: string, direction: string) {
  if (!direction) {
    return '—'
  }
  const mockedStatuses: { [key in string]: string } = {
    withdrawal: 'sent',
  }

  return <Status status={mockedStatuses[direction.toLocaleLowerCase()] || value} />
}

export function TwiceTitle(first: string, second: string) {
  return (
    <ColumnElements>
      <span>{first}&nbsp;/</span>
      <span>{second}</span>
    </ColumnElements>
  )
}
