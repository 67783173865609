import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { CurrenciesInjectors } from 'modules/Global'
import { CompanyInjectors } from 'modules/Settings/injectors'

import { withInjectors } from '../injectors'
import { Form as View } from '../components'
import { ClientState, ExchangeRate } from '../@types'
import { selectGetExchange, selectGetRate, selectRate } from '../selectors'
import { getExchangeRequest, getRateRequest, getExchangeClear } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getRate: getRateRequest, clearState: getExchangeClear, exchange: getExchangeRequest }, dispatch)

type Props = {
  rate: ExchangeRate
  rateRequest: RequestState
  exchangeRequest: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    exchangeRequest: selectGetExchange(),
    rateRequest: selectGetRate(),
    rate: selectRate(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const FormContainer = compose<ComponentType>(
  withInjectors,
  CurrenciesInjectors,
  CompanyInjectors,
  withConnect,
)(View)
