import { ColumnsType, ColumnType } from 'rc-table/lib/interface'

import { OperationCell } from '../EditableCell'
import { OperationProps, EditableColumns } from '../@types'

const editCell = <RecordType>(params: OperationProps) => ({
  editing: false,
  key: 'operation',
  title: 'Operation',
  align: 'right',
  dataIndex: 'operation',
  render: (_: null, first: ColumnType<RecordType>, index: number) =>
    OperationCell({ row: { ...first, key: String(index) }, ...params }),
})

const createEditableColumns = <RecordType>(
  columns: EditableColumns<RecordType>,
  params: OperationProps,
): ColumnsType<RecordType> => {
  return [...columns, editCell(params)].map(col => {
    if (!col.editing) {
      return col
    }
    return {
      ...col,
      onCell: (row: RecordType, index: number) => {
        return {
          ...row,
          title: col.title,
          dataIndex: col.key,
          onChange: params.onChange,
          editing: params.isEditing(String(index)),
          initialValue: row[col.key as keyof RecordType],
        }
      },
    }
  }) as ColumnsType<RecordType>
}

export { createEditableColumns }
