import React from 'react'

import { DecimalsFixed, Icon, KeysListener } from 'components'

import { BtnTransaction, IconWrapSend, TitleModalSuccess, ValueModalSuccess } from '../Style'

type Props = {
  buyAmount: number
  buyCurrency: string
  toggleModal: () => void
  getDecimals: (currency: string) => number
}

const InfoModal = ({ buyAmount, buyCurrency, toggleModal, getDecimals }: Props) => {
  return (
    <KeysListener handleSubmit={toggleModal}>
      <IconWrapSend>
        <Icon name='tick' size='auto' />
      </IconWrapSend>

      <TitleModalSuccess>Transaction has been sent</TitleModalSuccess>
      <ValueModalSuccess>
        + {DecimalsFixed(buyAmount || '0', 'fiat', '', getDecimals(buyCurrency))} {buyCurrency}
      </ValueModalSuccess>

      <BtnTransaction submit type='ghost' onClick={toggleModal}>
        Close
      </BtnTransaction>
    </KeysListener>
  )
}

export { InfoModal }
