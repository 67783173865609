import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { push } from 'connected-react-router'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { openModal } from 'containers/Modal'

import { withInjectors } from '../injectors'
import { selectCompany, selectSetOnboardingRequest, selectCurrenciesList } from '../selectors'
import { ClientState, CommonCurrencyName, Company } from '../@types'
import { setOnboardingRequest, getCurrencyListRequest } from '../actions'
import { Onboarding as View } from '../components'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      openModal,
      setData: setOnboardingRequest,
      getCurrencies: getCurrencyListRequest,
    },
    dispatch,
  )

type Props = {
  company: Company
  currencies: CommonCurrencyName[]
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    company: selectCompany(),
    currencies: selectCurrenciesList(),
    request: selectSetOnboardingRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const OnboardingContainer = compose<ComponentType>(withInjectors, withConnect)(View)
