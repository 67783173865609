import { store } from 'store'

import { Type } from './@types'
import { showToast } from './actions'
import { createToast } from './createToast'

type Props = {
  type?: Type
  text: string
}

const notify = ({ text, type = 'default' }: Props) => {
  store.dispatch(showToast(createToast({ text, type })))
}

export { notify }
