import React, { Fragment, useMemo } from 'react'

import { RequestState } from '@types'
import { createColumns, NewTable } from 'components'

import { COLUMNS } from './Columns'
import { TransactionFilter } from '../Filter'
import { TransactionTotalInfo } from '../Stats'
import { Transaction, Transactions, CommonCurrencies } from '../../@types'

type Props = {
  getData: () => void
  setFilters: () => void
  request: RequestState
  transactions: Transactions
  selectPage: (number: number) => void
  selectPageSize: (number: number) => void
  cryptoCurrencies: CommonCurrencies
  getCryptoCurrenciesRequest: RequestState
}

function TransactionTable({
  getData,
  request,
  selectPage,
  selectPageSize,
  transactions,
  setFilters,
  cryptoCurrencies,
}: Props) {
  const stats = useMemo(
    () =>
      (transactions.stats || []).filter(({ currency }) =>
        cryptoCurrencies.find(({ name }) => name.toLowerCase() === currency.toLowerCase()),
      ),
    [cryptoCurrencies, transactions.stats],
  )

  return (
    <Fragment>
      <TransactionTotalInfo stats={stats.length ? stats : transactions.stats} loading={request.isFetching} />
      <TransactionFilter setFilters={setFilters} cryptoCurrencies={cryptoCurrencies} />
      <NewTable
        selection={true}
        getData={getData}
        pagination={true}
        selectPage={selectPage}
        data={transactions.data}
        setPageSize={selectPageSize}
        loading={request.isFetching}
        pageSize={transactions.pageSize}
        pageCount={transactions.pageCount}
        currentPage={transactions.currentPage}
        columns={createColumns<Transaction>(COLUMNS)}
      />
    </Fragment>
  )
}

export { TransactionTable }
