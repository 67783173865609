import React from 'react'

const Nok = () => (
  <svg fill='none' viewBox='0 0 60 60'>
    <path
      fill='#EF2B2D'
      d='M28.33 0h3.69C44.61.82 56.1 10.16 59.05 22.49c-11.56.02-23.13 0-34.69.01-.05-7.32.02-14.63-.04-21.95C25.65.32 26.99.13 28.33 0zM.97 22.49C2.28 17.04 5.36 12.14 9.3 8.21c.07 4.76.04 9.52.03 14.28-2.78.01-5.57.02-8.36 0zM.94 37.5c2.8-.01 5.6 0 8.4.01.01 4.79 0 9.58 0 14.36-4.01-3.94-7-8.92-8.4-14.37zM24.35 59.46c-.01-7.32-.03-14.64.01-21.96 11.56.01 23.11-.01 34.67.01C56.08 49.94 44.41 59.38 31.69 60h-3.71a56.75 56.75 0 01-3.63-.54z'
    />
    <path
      fill='#fff'
      d='M20.61 1.48c1.23-.37 2.47-.65 3.71-.93.06 7.32-.01 14.63.04 21.95 11.56-.01 23.13.01 34.69-.01.29 1.25.56 2.5.81 3.76-13.06 0-26.13.02-39.19-.02-.06-8.25.05-16.5-.06-24.75zM9.3 8.21c1.24-1.1 2.54-2.12 3.86-3.11.05 7.05.02 14.09.03 21.14-4.34-.01-8.69.09-13.02-.07.25-1.23.52-2.46.8-3.68 2.79.02 5.58.01 8.36 0 .01-4.76.04-9.52-.03-14.28zM.17 33.72c4.33.08 8.67 0 13 .05.02 7.01.03 14.03-.01 21.04-1.29-.94-2.6-1.88-3.82-2.94 0-4.78.01-9.57 0-14.36-2.8-.01-5.6-.02-8.4-.01-.19-.95-.58-2.83-.77-3.78zM20.66 33.75c13.06.04 26.13-.07 39.19.06-.24 1.24-.52 2.47-.82 3.7-11.56-.02-23.11 0-34.67-.01-.04 7.32-.02 14.64-.01 21.96-1.24-.29-2.47-.58-3.7-.94.01-8.26 0-16.51.01-24.77z'
    />
    <path
      fill='#002868'
      d='M13.16 5.1c2.32-1.51 4.86-2.66 7.45-3.62.11 8.25 0 16.5.06 24.75 13.06.04 26.13.02 39.19.02l.14.76v5.92l-.15.88c-13.06-.13-26.13-.02-39.19-.06-.01 8.26 0 16.51-.01 24.77-2.66-.87-5.2-2.09-7.49-3.71.04-7.01.03-14.03.01-21.04-4.33-.05-8.67.03-13-.05L0 32.86v-7.24l.17.55c4.33.16 8.68.06 13.02.07-.01-7.05.02-14.09-.03-21.14z'
    />
  </svg>
)

export { Nok }
