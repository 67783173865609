import { RequestState } from '@types'

import { ExchangePairs, Actions } from '../@types'
import { SET_PAIRS, GET_PAIRS_FAILURE, GET_PAIRS_REQUEST, GET_PAIRS_SUCCESS } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getPairs = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_PAIRS_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_PAIRS_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_PAIRS_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: ExchangePairs = []

export const pairs = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_PAIRS:
      return action.payload.data
    default:
      return state
  }
}
