import React from 'react'

const Eye = () => (
  <svg viewBox='0 0 24 24'>
    <path
      fill='#A7A6AA'
      fillRule='nonzero'
      d='M12 9a3 3 0 100 6 3 3 0 000-6zm0 8a5 5 0 110-10 5 5 0 010 10zm0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5z'
    />
  </svg>
)

export { Eye }
