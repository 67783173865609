import { RequestState } from '@types'
import { getPageCount } from 'utils'

import { Actions, WebHooks } from '../@types'
import {
  SET_WEB_HOOKS,
  GET_WEB_HOOKS_SUCCESS,
  GET_WEB_HOOKS_FAILURE,
  GET_WEB_HOOKS_REQUEST,
  SELECT_WEB_HOOKS_PAGE,
} from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const getWebHooks = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case GET_WEB_HOOKS_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case GET_WEB_HOOKS_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case GET_WEB_HOOKS_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}

const initialUiState: WebHooks = {
  page: 1,
  total: 1,
  page_size: 10,
  data: [],
  pageCount: 1,
}

export const webHooks = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_WEB_HOOKS:
      const { data, total, page_size } = action.payload
      const pageCount = getPageCount(total, page_size)
      return { ...state, total, data, pageCount }
    case SELECT_WEB_HOOKS_PAGE:
      const { page } = action.payload
      return { ...state, page }
    default:
      return state
  }
}
