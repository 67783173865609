import { createGlobalStyle } from 'styled-components'
import 'sanitize.css'
import './font/font.css'

import { font, color } from 'theme'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    display: grid;
    min-height: 100vh;
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: ${color.white};
    background-color: #162645;
  }

  b {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
    font-weight: ${font.weight};
  }

  small {
    font-size: 1.4rem;
  }

  a {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
    text-decoration: none;
    transition: all 0.3s;
    -webkit-text-decoration-skip: objects;
  }

  h1 {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
    font-weight: ${font.weight};
    font-size: 2.5rem;
  }

  button {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
    font-weight: ${font.weight};
  }

  input {
    font-family: 'Gilroy', 'Helvetica Neue', Helvetica, 'Arial', sans-serif;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    text-rendering: optimizeLegibility;
  }

  img {
    border-style: none;
  }

  video {
    width: 100%;
  }
`

export { GlobalStyle }
