import React from 'react'
import { Wrapper, Title, Text } from './Style'

type Props = {
  title: string
  text?: string
}

function Desc({ title, text }: Props) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Wrapper>
  )
}

export { Desc }
