import { gql, ApolloQueryResult } from 'apollo-boost'
import { Transaction, Stats } from './@types'

export const GET_TX = gql`
  query findCompanyDeposits(
    $tx_id: String
    $create_from: String
    $create_to: String
    $currency: [CommonCurrencyName]
    $deposit_from: Float
    $deposit_to: Float
    $company_id: Int
    $deposit_usd_from: Float
    $deposit_usd_to: Float
    $deposit_eur_from: Float
    $deposit_eur_to: Float
    $page: Int
    $page_size: Int
  ) {
    findCompanyDeposits(
      data: {
        company_id: $company_id
        currency: $currency
        deposit_usd_from: $deposit_usd_from
        deposit_usd_to: $deposit_usd_to
        deposit_eur_from: $deposit_eur_from
        deposit_eur_to: $deposit_eur_to
        tx_id: $tx_id
        create_from: $create_from
        create_to: $create_to
        deposit_from: $deposit_from
        deposit_to: $deposit_to
        page: $page
        page_size: $page_size
      }
    ) {
      stats {
        currency
        totalDeposits
        totalDepositsUsd
        totalDepositsEur
      }
      total
      transactions {
        id
        company_id
        tx_id
        created_at
        currency
        value
        value_usd
        value_eur
      }
    }
  }
`

export type GetTxResponse = ApolloQueryResult<{
  findCompanyDeposits: {
    total: number
    transactions: Transaction[]
    stats: Stats[]
  }
}>
