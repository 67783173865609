import React, { ComponentType } from 'react'
import { Route, Redirect, RouteProps } from 'react-router'

import { links } from 'constant'
import { ScrollToTop } from './ScrollWrapper'

type OwnProps = {
  exact?: boolean
  auth: boolean
  path: string | string[]
  component: ComponentType
}

const PrivateRouter = ({ component: Component, auth, ...rest }: OwnProps) => {
  const render = (props: RouteProps) => {
    const redirectProps = {
      pathname: links.signin,
      state: { from: props.location },
    }

    return (
      <ScrollToTop location={props.location}>
        {auth ? <Component {...props} /> : <Redirect to={redirectProps} />}
      </ScrollToTop>
    )
  }

  return <Route {...rest} render={render} />
}

export { PrivateRouter }
