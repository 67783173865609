import { combineReducers } from 'redux'

import { DOMAIN_STATE, UI_STATE } from '../constants'

import { webHooks, getWebHooks } from './WebHooks'

export const reducer = combineReducers({
  [UI_STATE]: combineReducers({
    webHooks,
  }),
  [DOMAIN_STATE]: combineReducers({
    getWebHooks,
  }),
})
