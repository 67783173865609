import React, { Component } from 'react'

import { RequestState } from '@types'
import { KeysListener } from 'components'
import { notify } from 'containers/Notification/notify'

import { Text, WrapBtn, CancelBtn, CustomBtn } from './Style'

type Props = {
  request: RequestState
  resetApiRequest: () => void
  clearState: () => void
  closeAllModal: () => void
}

class ResetApi extends Component<Props> {
  public componentWillUnmount() {
    const { clearState } = this.props
    clearState()
  }

  public componentDidUpdate() {
    const { request, closeAllModal } = this.props

    if (request.isSuccess) {
      notify({ text: 'API has been reset.' })
      closeAllModal()
    }
  }

  public handleValueChange = (value: string) => {
    this.setState(() => ({ value }))
  }

  public render() {
    const { closeAllModal, request, resetApiRequest } = this.props

    return (
      <KeysListener handleSubmit={resetApiRequest}>
        <Text>Please note that after you reset API your previous keys will no&nbsp;longer work.</Text>
        <WrapBtn>
          <CancelBtn type='ghost' size='medium' onClick={closeAllModal}>
            Cancel
          </CancelBtn>
          <CustomBtn type='warning' size='medium' isLoading={request.isFetching} onClick={resetApiRequest}>
            Confirm
          </CustomBtn>
        </WrapBtn>
      </KeysListener>
    )
  }
}

export { ResetApi }
