import React from 'react'

const UsdTIcon = () => (
  <svg fill='none' viewBox='0 0 56 56'>
    <path fill='#53ae94' d='M28 0c15.463 0 28 12.537 28 28S43.463 56 28 56 0 43.467 0 28 12.535 0 28 0z' />
    <path
      fill='#fff'
      d='M31.456 24.27v-4.166h9.525v-6.347H15.044v6.347h9.526v4.162c-7.742.355-13.563 1.889-13.563 3.725 0 1.837 5.824 3.37 13.563 3.728v13.342h6.888V31.718c7.728-.357 13.538-1.889 13.538-3.724 0-1.835-5.81-3.367-13.538-3.724h-.002zm.002 6.318v-.003c-.194.012-1.193.072-3.416.072-1.777 0-3.028-.05-3.469-.073v.005c-6.84-.303-11.945-1.494-11.945-2.919s5.106-2.615 11.945-2.918v4.65c.448.031 1.73.107 3.498.107 2.124 0 3.192-.088 3.388-.106v-4.651c6.827.304 11.92 1.496 11.92 2.916s-5.096 2.613-11.92 2.917'
    />
  </svg>
)

export { UsdTIcon }
