import { all, put, fork } from 'redux-saga/effects'

import { localStorage } from 'utils'
import { setAuth } from '../actions'

export function* checkAuth() {
  try {
    const { jwt, time } = localStorage.getItem(localStorage.getKey())

    if (!jwt) {
      return
    }

    const HOURS = 3600000
    const JWT_LIFE_TIME = 11

    const NOW = Date.now()
    const JWT_EXPIRED_TIME = JWT_LIFE_TIME * HOURS

    if (time + JWT_EXPIRED_TIME > NOW) {
      yield put(setAuth(true))
    } else {
      localStorage.clear()
    }
  } catch (err) {}
}

export function* saga() {
  return yield all([yield fork(checkAuth)])
}
