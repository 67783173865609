import { call, all, put, takeLatest } from 'redux-saga/effects'

import { getGraphQlError } from 'utils'

import { mutate } from '../client'
import { NEW_FIAT_REQUEST } from '../constants'
import { NEW_FIAT_REQ } from '../mutation'
import { newFiatFailure, newFiatRequest, newFiatSuccess, getFiatRequest } from '../actions'

export function* newFiat({ payload }: ReturnType<typeof newFiatRequest>) {
  try {
    yield call(mutate, {
      mutation: NEW_FIAT_REQ,
      variables: payload,
    })

    yield put(getFiatRequest())
    yield put(newFiatSuccess())
  } catch (err) {
    yield put(newFiatFailure(getGraphQlError(err)))
  }
}

export function* saga() {
  return yield all([yield takeLatest(NEW_FIAT_REQUEST, newFiat)])
}
