import { all, fork } from 'redux-saga/effects'

import { saga as NewFiat } from './NewFiatRequest'
import { saga as NewCrypto } from './NewCryptoRequest'

import { saga as FiatRequest } from './GetFiatRequest'
import { saga as CryptoRequest } from './GetCryptoRequest'

const RequestsSagas = [CryptoRequest, FiatRequest, NewCrypto, NewFiat]

export function* saga() {
  return yield all([...RequestsSagas.map(fork)])
}
