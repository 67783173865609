import { all, fork } from 'redux-saga/effects'

import { saga as Request } from './Requests'
import { saga as Company } from './Company'

import { saga as Staffs } from './Staff'
import { saga as User } from './User'
import { saga as Api } from './Api'
import { saga as Onboarding } from './Onboarding'

const SettingsSagas = [Company, User, Api, Onboarding, Staffs, Request]

export function* saga() {
  return yield all([...SettingsSagas.map(fork)])
}
