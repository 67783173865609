import React, { Component } from 'react'

import { links } from 'constant'
import { CommonCurrencyName, FiltersRequest, OnboardingInput } from '../../@types'

import { Steps } from '../Steps'
import { SignDoc } from '../SignDoc'
import { FillForm } from '../FillForm'

import { Container } from './Style'

type ISteps = 'form' | 'docs'

type State = {
  url: string
  rate: string
  currency: CommonCurrencyName
  show: ISteps
  addressEth: string
  addressBtc: string
  addressTrx: string
  bankDetail: string
}

type ConfirmParams = {
  btc: string
  eth: string
  trx: string
  url: string
  rate: string
  detail: string
  currency: CommonCurrencyName
}

type Props = {
  request: {
    isFetching: boolean
    isSuccess: boolean
    isError: boolean
    errorMessage: string
  }
  currencies: CommonCurrencyName[]
  push: (link: string) => void
  setData: (data: OnboardingInput) => void
  getCurrencies: (data: FiltersRequest) => void
}

const STEPS: { [k in ISteps]: number } = {
  form: 1,
  docs: 2,
} as const

class Onboarding extends Component<Props, State> {
  public constructor(props: Props) {
    super(props)

    this.state = {
      url: '',
      rate: '',
      show: 'form',
      currency: 'EUR',
      addressEth: '',
      addressBtc: '',
      addressTrx: '',
      bankDetail: '',
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { request, currencies, getCurrencies } = this.props

    if (prevProps.request.isSuccess !== request.isSuccess && request.isSuccess) {
      this.setState(() => ({ show: 'docs' }))
    }

    if (!currencies?.length) {
      getCurrencies({
        page: 1,
        page_size: 100,
      })
    }
  }

  public render() {
    const { request, currencies } = this.props
    const { show, rate, addressBtc, bankDetail, addressEth, addressTrx, url, currency } = this.state

    return (
      <Container>
        <Steps step={STEPS[show]} />
        {show === 'form' && (
          <FillForm
            url={url}
            rate={rate}
            selectedCurrency={currency}
            currencies={currencies}
            btc={addressBtc}
            eth={addressEth}
            trx={addressTrx}
            request={request}
            detail={bankDetail}
            onClick={this.handleConfirm}
          />
        )}
        {show === 'docs' && <SignDoc onConfirm={this.confirm} onBack={this.handleForm} onNext={this.handleApprove} />}
      </Container>
    )
  }

  private confirm = () => {
    const { addressEth, url, addressBtc, bankDetail, rate, currency, addressTrx } = this.state
    const { setData } = this.props

    const params = {
      approach_currency: currency,
      callback_url: url,
      bank_detail_usd: '',
      bank_detail_eur: '',
      btc_address: addressBtc,
      eth_address: addressEth,
      trx_address: addressTrx,
    }

    if (rate === 'fix_eur') {
      params.bank_detail_eur = bankDetail
    }

    setData(params)
  }

  private handleApprove = () => {
    const { push } = this.props
    push(links.settings)
  }

  private handleForm = () => {
    this.setState(() => ({ show: 'form' }))
  }

  private handleConfirm = (params: ConfirmParams) => {
    const { btc, eth, trx, detail, rate, url, currency } = params

    this.setState(
      () => ({
        url,
        rate,
        currency,
        addressBtc: btc,
        addressEth: eth,
        addressTrx: trx,
        bankDetail: detail,
      }),
      () => {
        this.confirm()
      },
    )
  }
}

export { Onboarding }
