import { Actions } from '../@types'
import { SET_AUTH, SET_LOGOUT } from '../constants'

const initialUiState = false

export const auth = (state = initialUiState, action: Actions) => {
  switch (action.type) {
    case SET_AUTH:
      const { auth } = action.payload
      return auth
    case SET_LOGOUT:
      return false
    default:
      return state
  }
}
