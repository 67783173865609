import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { closeAllModal } from 'containers'

import { RequestState } from '@types'
import { withInjectors } from '../injectors'
import { NewInvoice as View } from '../components'
import { InvoiceState, Invoice } from '../@types'
import { selectCrateInvoiceRequest, selectInvoice } from '../selectors'
import { createInvoiceRequest, createInvoiceClear } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModal: closeAllModal,
      clearState: createInvoiceClear,
      createInvoice: createInvoiceRequest,
    },
    dispatch,
  )

type Props = {
  invoice: Invoice
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<InvoiceState, Props>({
    invoice: selectInvoice(),
    request: selectCrateInvoiceRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const NewInvoiceContainer = compose<ComponentType>(withInjectors, withConnect)(View)
