import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { openModal } from 'containers/Modal'

import { RequestState } from '@types'
import { withInjectors } from '../injectors'
import { WebHooks as View } from '../components'
import { WebHooksState, WebHooks } from '../@types'
import { selectWebHooks, selectGetWebHooks } from '../selectors'
import { getWebHooksRequest, selectPage, onResendRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      openModal,
      selectPage,
      resend: onResendRequest,
      getData: getWebHooksRequest,
    },
    dispatch,
  )

type Props = {
  request: RequestState
  webHooks: WebHooks
}

const mapStateToProps = () =>
  createStructuredSelector<WebHooksState, Props>({
    webHooks: selectWebHooks(),
    request: selectGetWebHooks(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const WebHooksContainer = compose<ComponentType>(withInjectors, withConnect)(View)
