import React, { Component } from 'react'

import { DecimalsFixed, Select, SelectOptions } from 'components'
import { Col, Container, ImgCoin, LabelTop, Line, SumValue, WrapperCoin } from './Style'

type Currency = {
  name: string
  type: string
  extra_type?: string
  decimal: number
  image_url?: string
  full_name?: string
  balance: number
}

export type Props = {
  label: string
  value: string
  isLoading: boolean
  currencies: Currency[]
  setCurrency: (currency: string) => void
}

class TopLine extends Component<Props> {
  public render() {
    const { label, value, currencies, isLoading } = this.props

    const options = currencies.map(item => ({
      value: item.name,
      label: (
        <Container>
          <WrapperCoin>
            <ImgCoin src={item.image_url} alt={`${item.full_name} logo`} />
            {item.name}
          </WrapperCoin>

          <SumValue>
            {DecimalsFixed(item.balance, 'crypto', '', item.decimal)} {item.name}
          </SumValue>
        </Container>
      ),
    }))

    return (
      <Line>
        <Col>
          <LabelTop>{label}</LabelTop>
          <Select
            size='large'
            value={value}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            options={options}
            placeholder={
              isLoading ? 'Searching pairs' : currencies.length ? 'Select value' : `Don't have existing pairs`
            }
            isLoading={isLoading}
            isDisabled={isLoading}
            select={this.handleSelectCurrency}
          />
        </Col>
      </Line>
    )
  }

  public handleSelectCurrency = (params: SelectOptions<string>) => {
    const { setCurrency } = this.props
    setCurrency(params.value)
  }
}

export { TopLine }
