export const DOMAIN = 'modules/invoice'

export const UI_STATE = 'ui'
export const DOMAIN_STATE = 'domain'

export const STOP_RUNNER = 'modules/invoice/ui/STOP_RUNNER'
export const START_RUNNER = 'modules/invoice/ui/START_RUNNER'

export const SET_INVOICE = 'modules/invoice/ui/SET_INVOICE'
export const CHECK_INVOICE_REQUEST = 'modules/invoice/domain/CHECK_INVOICE_REQUEST'
export const CHECK_INVOICE_SUCCESS = 'modules/invoice/domain/CHECK_INVOICE_SUCCESS'
export const CHECK_INVOICE_FAILURE = 'modules/invoice/domain/CHECK_INVOICE_FAILURE'

export const CREATE_INVOICE_CLEAR = 'modules/invoice/domain/CREATE_INVOICE_CLEAR'
export const CREATE_INVOICE_REQUEST = 'modules/invoice/domain/CREATE_INVOICE_REQUEST'
export const CREATE_INVOICE_SUCCESS = 'modules/invoice/domain/CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAILURE = 'modules/invoice/domain/CREATE_INVOICE_FAILURE'

export const SET_INVOICES = 'modules/invoice/ui/SET_INVOICES'
export const SET_INVOICES_FILTERS = 'modules/invoice/ui/SET_INVOICES_FILTERS'
export const SELECT_INVOICES_PAGE = 'modules/invoice/ui/SELECT_INVOICES_PAGE'
export const SELECT_INVOICES_PAGE_SIZE = 'modules/invoice/ui/SELECT_INVOICES_PAGE_SIZE'

export const GET_INVOICES_REQUEST = 'modules/invoice/domain/GET_INVOICE_REQUEST'
export const GET_INVOICES_SUCCESS = 'modules/invoice/domain/GET_INVOICE_SUCCESS'
export const GET_INVOICES_FAILURE = 'modules/invoice/domain/GET_INVOICE_FAILURE'
