import React, { useState, HTMLAttributes } from 'react'
import { ColumnType } from 'rc-table/lib/interface'

import { Button } from 'components'
import { Icon } from '../Icon'
import { Input } from '../Input'
import { Td, WrapInput, EditButton, IconWrapper } from './Style'

type EditableCellProps = HTMLAttributes<HTMLElement> & {
  title: string
  index: number
  editing: boolean
  dataIndex: string
  initialValue: string
  children: React.ReactNode
  onChange: (key: string, value: string, rowId: number) => void
}

const EditableCell = ({
  index,
  editing,
  children,
  onChange,
  dataIndex,
  initialValue,
  ...restProps
}: EditableCellProps) => {
  const [value, setValue] = useState(initialValue)

  const handleOnChange = (newValue: string) => {
    setValue(newValue)
    onChange(dataIndex, newValue, index)
  }

  return (
    <Td {...restProps}>
      {editing ? (
        <WrapInput>
          <Input name={dataIndex} onChange={handleOnChange} type='text' defaultValue={value} />
        </WrapInput>
      ) : (
        children
      )}
    </Td>
  )
}

type OperationProps<RecordType> = {
  row: ColumnType<RecordType>
  selected: string
  edit: (key: string) => void
  save: (rowId: number) => void
}

const OperationCell = <RecordType extends {}>({ row, edit, save, selected }: OperationProps<RecordType>) => {
  const handleEdit = () => {
    edit(String(row.key))
  }

  const handleSave = () => {
    save(Number(row.key))
  }

  return selected === String(row.key) ? (
    <Button size='medium' onClick={handleSave}>
      Save
    </Button>
  ) : (
    <EditButton type='link' size='medium' onClick={handleEdit}>
      <IconWrapper>
        <Icon name='change' size='extraMedium' />
      </IconWrapper>
      Change
    </EditButton>
  )
}

export { EditableCell, OperationCell }
