import { links } from 'constant'

import { MainContainer } from '../Main'
import { GeneralContainer, StaffContainer, RequestContainer, CompanyContainer } from '../containers'

const privateRoutes = [
  {
    path: links.settings,
    title: 'General',
    icon: 'house',
    component: GeneralContainer,
    isPrivate: true,
  },
  {
    path: links.staff,
    title: 'Staff',
    icon: 'email',
    component: StaffContainer,
    isPrivate: true,
  },
  {
    path: links.requests,
    title: 'Requests',
    icon: 'squares',
    component: RequestContainer,
    isPrivate: true,
  },
  {
    path: links.company,
    title: 'Api keys',
    icon: 'lock',
    component: CompanyContainer,
    isPrivate: true,
  },
]

const routes = [
  {
    path: '/',
    exact: false,
    component: MainContainer,
    isPrivate: true,
  },
]

export { routes, privateRoutes }
