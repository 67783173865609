import styled from 'styled-components'
import { color } from 'theme'

export const HrefLink = styled.a`
  font-size: inherit;
  line-height: inherit;
  color: ${color.default};
  text-decoration: none;
  cursor: pointer;
`
