import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { RequestState } from '@types'
import { openModal } from 'containers/Modal'

import { withInjectors } from '../injectors'
import { Request as View } from '../components'
import { ClientState, Requests } from '../@types'
import { selectCurrencyRequests, selectGetLatestUpdateRequest } from '../selectors'
import { getLatestUpdateRequest, changeDetailRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ openModal, getData: getLatestUpdateRequest, changeDetails: changeDetailRequest }, dispatch)

type Props = {
  requests: Requests
  request: RequestState
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    requests: selectCurrencyRequests(),
    request: selectGetLatestUpdateRequest(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const RequestContainer = compose<ComponentType>(withInjectors, withConnect)(View)
