import { links } from 'constant'
import { TransactionContainer } from '../containers'

const routes = [
  {
    path: links.transactions,
    component: TransactionContainer,
    isPrivate: true,
  },
]

export { routes }
