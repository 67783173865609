import React from 'react'

import { CommonCurrencies, ExchangePairs } from 'modules/Global/@types'

import { TopLine } from './TopLine'
import { FirstWrapper } from './Style'

type Props = {
  isLoading: boolean
  buyCurrency: string
  pairs: ExchangePairs
  sellCurrency: string
  currenciesFrom: ExchangePairs
  currencies: CommonCurrencies
  getPairs: (currency: string) => void
  selectBuy: (currency: string) => void
  selectSell: (currency: string) => void
  getBalance: (currency: string) => void
}

function Headers(props: Props) {
  const { sellCurrency, pairs, isLoading, buyCurrency, currencies, currenciesFrom, ...methods } = props
  const { selectSell, selectBuy, getBalance } = methods

  const data = currencies.map(i => {
    const balance: number = (getBalance(i.name) as unknown) as number

    return {
      ...i,
      balance: parseFloat(balance.toFixed(i.decimal)),
    }
  })

  const pairsData = data.filter(i => pairs.find(p => i.name === p.name))
  const currenciesFromData = data.filter(i => currenciesFrom.find(p => i.name === p.name))

  return (
    <FirstWrapper>
      <TopLine
        label='From'
        value={sellCurrency}
        isLoading={isLoading}
        setCurrency={selectSell}
        currencies={currenciesFromData}
      />
      <TopLine isLoading={isLoading} label='To' value={buyCurrency} currencies={pairsData} setCurrency={selectBuy} />
    </FirstWrapper>
  )
}

export { Headers }
