import styled, { createGlobalStyle, css } from 'styled-components'
import { StyledNavLink } from '../../../../components/LogoLink/Style'

import { breakpoints, color, font } from 'theme'

export const IconWrapper = styled.div`
  padding: 12px 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${color.grey};
  font-family: ${font.display};
  white-space: nowrap;
  transition: color 200ms, background-color 200ms;

  .icon-wrapper {
    font-size: 14px;
    width: auto;
    height: auto;
    > svg {
      width: 1em;
      height: 1em;
    }
  }
  .link-title {
    margin-left: 6px;
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus,
  &.activeNavLink {
    color: ${color.white};
  }
`

export const MenuToggle = styled.button`
  border: none;
  outline: none;
  margin: 0;
  padding: 5px;
  width: auto;
  overflow: visible;
  background: transparent;
  color: #a7a6aa;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  right: -5px;
  transition: color 200ms, right 200ms;

  &:hover {
    color: ${color.white};
  }
  &:focus {
    border-color: transparent;
  }
`

export const Wrap = styled.header`
  position: relative;
  margin: 0;
  width: 100%;
  background-color: #292833;
  border-bottom: 1px solid #3e3d47;
  z-index: 2;
`

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Container = styled.div`
  margin: 0 auto;
  padding: 18px 16px;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoints.greaterThan('md')`
    padding: 18px 34px;
  ` as string};
`

export const Nav = styled.nav`
  display: flex;
  padding-top: 8px;
  border-top: 1px solid #32313e;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
`
export const SideNav = styled.header.attrs(() => ({
  className: 'side-nav',
}))`
  position: fixed;
  margin: 0;
  width: 240px;
  background-color: #292833;
  border-right: 1px solid #3e3d47;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 200ms;
`
export const SideOffset = styled.div<{ side: boolean }>`
  ${({ side }) =>
    side &&
    css`
      margin-left: 240px;
      transition: margin-left 200ms;
    `}
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 25px;
`

export const SideFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid #32313e;
  padding: 10px 0;
`
export const MainWrapper = styled.div``

export const GlobalStyle = createGlobalStyle`
  body[data-side="false"] {
    ${SideNav} {
      width: 70px;
    }
    ${StyledNavLink} {
      opacity: 0;
      visibility: hidden;
      width: 0;
    }
    ${MenuToggle} {
      right: 2px;
    }
    .link-title {
      visibility: hidden;
      opacity: 0;
    }
    ${SideOffset} {
      margin-left: 70px;
    }
  }
`
