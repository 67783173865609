import React from 'react'

import { EUR_PREFIX, USD_PREFIX } from 'constant'
import { DecimalsFixed, ColumnElements } from 'components'
import { CryptoValue, FiatValue } from './Style'

type Props = {
  fiatAmount: string
  cryptoAmount: string
  fiatCurrency: string
  cryptoCurrency: string
}

function CryptoFiat({ cryptoAmount, cryptoCurrency, fiatAmount, fiatCurrency }: Props) {
  const prefix = fiatCurrency === 'EUR' ? EUR_PREFIX : USD_PREFIX
  return (
    <ColumnElements>
      <CryptoValue>
        {DecimalsFixed(cryptoAmount, 'crypto')} {cryptoCurrency}
      </CryptoValue>
      <FiatValue>{DecimalsFixed(fiatAmount, 'fiat', prefix)}</FiatValue>
    </ColumnElements>
  )
}

export { CryptoFiat }
