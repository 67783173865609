import React, { Fragment, Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Portal } from 'containers'
import { RequestState } from '@types'
import { InvoiceWrap, InvoiceContainer } from 'components'

import { Select } from '../Select'
import { InvalidLinkInvoice } from '../InvalidLink'
import { FundsRecievedInvoice } from '../FundsRecieved'
import { Invoice } from '../../@types'

import { Main } from './Style'

type Props = {
  invoice: Invoice
  stopRunner: () => void
  startRunner: () => void
  checkRequest: RequestState
  check: (id?: string) => void
} & RouteComponentProps<{
  id?: string
}>

const VIEW_STATUSES = ['paid', 'declined', 'paid_after_expired']

class InvoiceComponent extends Component<Props> {
  public componentDidMount() {
    const {
      check,
      startRunner,
      match: { params },
    } = this.props

    startRunner()

    check(params.id)
  }

  public componentWillUnmount() {
    const { stopRunner } = this.props
    stopRunner()
  }

  public render() {
    const { invoice, checkRequest } = this.props

    return (
      <Portal>
        <InvoiceWrap>
          <InvoiceContainer>
            <Main>
              {invoice.status === 'created' || invoice.status === 'expired' ? <Select invoice={invoice} /> : null}
              {checkRequest.isError ? <InvalidLinkInvoice /> : null}
              {checkRequest.isSuccess ? (
                <Fragment>
                  {VIEW_STATUSES.includes(invoice.status) ? (
                    <FundsRecievedInvoice
                      date={invoice.paid_at}
                      status={invoice.status}
                      paid_tx={invoice.paid_tx}
                      amount={invoice.client_amount}
                      currency={invoice.client_currency}
                      paid_currency={invoice.paid_currency}
                    />
                  ) : null}
                </Fragment>
              ) : null}
            </Main>
          </InvoiceContainer>
        </InvoiceWrap>
      </Portal>
    )
  }
}

export { InvoiceComponent }
