import React from 'react'

const NotFound = () => (
  <svg viewBox='0 0 50 40'>
    <g>
      <path d='M49.05 0H.95c-.5 0-.9.4-.9.9v37.3c0 .5.4.9.9.9H42c.25 0 .45-.1.6-.25l7.05-7.05c.15-.15.25-.4.25-.6V.9c0-.5-.35-.9-.85-.9zm-.9 1.75v6.7H1.8v-6.7h46.35zM1.8 10.2h46.35v20.05H42c-.5 0-.85.4-.9.85v6.15H1.8V10.2zm45.15 21.85L42.9 36.1v-4.05h4.05z' />
      <path d='M6.45 6.55a1.451 1.451 0 000-2.9 1.451 1.451 0 000 2.9zM12.3 6.55a1.451 1.451 0 000-2.9 1.451 1.451 0 000 2.9zM18.15 6.55a1.451 1.451 0 000-2.9 1.451 1.451 0 000 2.9zM16.65 17.15L11.5 24.7v2h4.85v2.35h2.2v-2.4H20v-2h-1.45v-7.5h-1.9zm-.25 7.55h-2.75l2.75-4.05v4.05zM25 29.25c1.05 0 2.05-.4 2.7-1.2.8-1 1.15-2.6 1.15-4.85 0-2.25-.4-3.85-1.15-4.85-.7-.8-1.7-1.25-2.7-1.25-1.4 0-2.3.65-2.75 1.25-.7 1.05-1.1 2.55-1.1 4.75 0 2.3.35 3.85 1.05 4.75.65.95 1.7 1.4 2.8 1.4zm-1.25-9c.15-.55.5-1.2 1.25-1.2.9 0 1.2.9 1.25 1.3.2.95.2 1.9.2 2.85 0 1-.05 2-.25 2.95-.05.35-.25.7-.5.95-.45.35-1.05.35-1.45 0-.3-.3-.45-.65-.55-1.05-.2-.95-.2-1.9-.2-2.85 0-1 .05-2 .25-2.95zM35.1 17.15l-5.15 7.55v2h4.85v2.35H37v-2.4h1.5v-2H37v-7.5h-1.9zm-.3 7.55h-2.75l2.75-4.05v4.05z' />
    </g>
  </svg>
)

export { NotFound }
