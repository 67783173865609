import React from 'react'

const PdfIcon = () => (
  <svg viewBox='0 0 16 20'>
    <path
      fill='#4678ff'
      fillRule='nonzero'
      d='M10 0l6 6v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h8zm4 18V7H9V2H2v16h12zm-7.08-7.69c-.24-.77-.77-3.23.63-3.27 1.4-.04.48 3.12.48 3.12.39 1.49 2.02 2.56 2.02 2.56.5-.15 3.35-.48 2.95 1-.43 1.48-3.5.09-3.5.09-1.95.14-3.41.66-3.41.66-1.13 2.11-2.45 3.03-2.99 2.14-.67-1.11 2.13-2.54 2.13-2.54 1.45-2.35 1.67-3.72 1.69-3.76zm.65.84c-.4 1.3-1.2 2.69-1.2 2.69.85-.34 2.71-.73 2.71-.73-1.14-1-1.49-1.95-1.51-1.96zm3.14 2.17s1.75.65 1.79.39c.07-.27-1.33-.51-1.79-.39zm-5.66 1.49c-.77.3-1.51 1.58-1.33 1.58.18.01.91-.6 1.33-1.58zm2.52-5.55c0-.05.43-1.68 0-1.73-.3-.03-.01 1.69 0 1.73z'
    />
  </svg>
)

export { PdfIcon }
