import styled, { keyframes } from 'styled-components'
import { font, color } from 'theme'
import ReactSelect from 'react-select'

import { getSize } from './helpers'

export const placeHolderShimmer = keyframes`
  0% {
    background-position: -1220px 0
  }
  100% {
    background-position: 1220px 0
  }
`

export const Label = styled.label`
  margin: 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.5);
`

export const StyledSelect = styled(ReactSelect)`
  .Select {
    &__control {
      padding: ${({ size }) => getSize(size).padding};
      width: 100%;
      height: ${({ size }) => getSize(size).height};
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease-out;
      &--is-disabled {
        opacity: 0.5;
        pointer-events: auto;
        cursor: no-drop;
      }
    }
    &__value-container {
      padding: 0;
      height: 100%;
    }
    &__single-value {
      width: 100%;
      font-size: ${({ size }) => getSize(size).fontSize};
      line-height: 1.5;
      color: currentColor;
    }
    &__placeholder {
      position: static;
      margin: 0 10px 0 0;
      font-size: ${({ size }) => getSize(size).fontSize};
      color: ${color.white};
      transform: none;
    }
    &__loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation-duration: 2.8s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: ${placeHolderShimmer};
      animation-timing-function: linear;
      will-change: background-position;
      background: linear-gradient(270deg, transparent 18%, rgba(232, 230, 255, 0.1) 44%, transparent 77%);
      background-size: 1220px 100%;
      & > span {
        display: none;
      }
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding: 0;
    }
    &__menu {
      top: calc(100% + 8px);
      margin: 0;
      padding: 0;
      background-color: #292833;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      transition: all 0.3s ease-out;
      z-index: 2;
    }
    &__menu-list {
      padding: 0;
    }
    &__option {
      padding: ${({ size }) => getSize(size).paddingMenu};
      font-family: ${font.regular};
      font-size: ${({ size }) => getSize(size).fontSize};
      line-height: 1.71;
      color: ${color.grey};
      background-color: transparent;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &:hover {
        color: ${color.white};
      }
      &--is-focused {
        color: ${color.white};
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:first-child {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }
  }
`
