import React, { ComponentType } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { PrivateRouter } from 'router'
import { createStructuredSelector } from 'reselect'
import { Switch, Route, RouteProps } from 'react-router-dom'
import { NotFound } from 'components'

import { AuthState } from '../@types'
import { selectAuth } from '../selectors'

type RouteType = {
  path: string | string[]
  component: ComponentType
  isPrivate: boolean
}

type OwnRouteProps = {
  auth: boolean
} & RouteProps &
  RouteType

type ViewProps = {
  auth: boolean
  routes: RouteType[]
}

const Router = ({ isPrivate, ...rest }: OwnRouteProps) =>
  isPrivate ? <PrivateRouter {...rest} /> : <Route {...rest} />

const View = ({ auth, routes }: ViewProps) => {
  return (
    <Switch>
      {routes.map((route, index: number) => (
        <Router {...route} auth={auth} key={index} />
      ))}
      <Route component={NotFound} />
    </Switch>
  )
}

type Props = {
  auth: boolean
}

const mapStateToProps = () =>
  createStructuredSelector<AuthState, Props>({
    auth: selectAuth(),
  })

const withConnect = connect(mapStateToProps)

export const RouterContainer = compose(withConnect)(View)
