import styled from 'styled-components'
import { color } from 'theme'

export const Header = styled.header`
  position: relative;
  margin: 0 0 25px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BtnBack = styled.button`
  position: absolute;
  left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: ${color.default};
  background-color: ${color.white};
  border: 1px solid #e6e9f2;
  border-radius: 50%;
  transition: all 0.3s linear;
  cursor: pointer;
  outline: none;
  &:hover {
    color: ${color.primary};
  }
  &:focus,
  &:active {
    color: ${color.primary};
    background-color: #f6f7fb;
  }
`

export const IconWrapper = styled.span`
  height: 32px;
  width: auto;
  cursor: pointer;
`

export const Main = styled.main`
  width: 100%;
`
