import React from 'react'

const UsdIcon = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <mask id='a_UsdIcon' width='20' height='20' x='0' y='0' maskUnits='userSpaceOnUse'>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z'
        clipRule='evenodd'
      />
    </mask>
    <g mask='url(#a_UsdIcon)'>
      <path fill='#fff' d='M-7 0h35.366v22.965H-7' />
      <path
        fill='#b22234'
        fillRule='evenodd'
        d='M-6.983.821V0h35.33v.821h-35.33zm0 3.286V2.464h35.33v1.643h-35.33zm0 3.286V5.75h35.33v1.643h-35.33zm0 3.286V9.036h35.33v1.643h-35.33zm0 3.285v-1.643h35.33v1.643h-35.33zm0 3.286v-1.643h35.33v1.643h-35.33zm0 3.286v-1.643h35.33v1.643h-35.33zm0 2.464v-.821h35.33V23h-35.33z'
        clipRule='evenodd'
      />
      <path fill='#3c3b6e' d='M-7 0h17.453v12.366H-7' />
      <path
        fill='#fff'
        d='M1.33 3.074l.425-1.272.424 1.272-1.088-.777h1.335M1.33 5.511l.425-1.272.424 1.272-1.088-.777h1.335M1.33 7.985l.425-1.272.424 1.272-1.088-.778h1.335M1.33 10.458l.425-1.272.424 1.272L1.09 9.68h1.335M4.228 3.074l.424-1.272.424 1.272-1.088-.777h1.335M4.228 5.511l.424-1.272.424 1.272-1.088-.777h1.335M4.228 7.985l.424-1.272.424 1.272-1.088-.778h1.335M4.228 10.458l.424-1.272.424 1.272-1.088-.777h1.335M7.125 3.074l.424-1.272.424 1.272-1.088-.777H8.22M7.125 5.511l.424-1.272.424 1.272-1.088-.777H8.22M7.125 7.985l.424-1.272.424 1.272-1.088-.778H8.22M7.125 10.458l.424-1.272.424 1.272-1.088-.777H8.22M-.153 1.788L.271.516l.424 1.272-1.088-.778H.942M-.153 4.275l.424-1.272.424 1.272-1.088-.777H.942M-.153 6.762L.271 5.49l.424 1.272-1.088-.777H.942M-.153 9.25l.424-1.272.424 1.271-1.088-.777H.942M-.153 11.736l.424-1.271.424 1.271-1.088-.777H.942M2.733 1.788L3.157.516l.424 1.272-1.088-.778H3.83M2.733 4.275l.424-1.272.424 1.272-1.088-.777H3.83M2.733 6.762l.424-1.272.424 1.272-1.088-.777H3.83M2.733 9.25l.424-1.272.424 1.271-1.088-.777H3.83M2.733 11.736l.424-1.271.424 1.271-1.088-.777H3.83M5.62 1.788L6.044.516l.424 1.272L5.38 1.01h1.335M5.62 4.275l.424-1.272.424 1.272-1.088-.777h1.335M5.62 6.762l.424-1.272.424 1.272-1.088-.777h1.335M5.62 9.25l.424-1.272.424 1.271-1.088-.777h1.335M5.62 11.736l.424-1.271.424 1.271-1.088-.777h1.335M8.506 1.788L8.93.516l.424 1.272-1.088-.778h1.336M8.506 4.275l.424-1.272.424 1.272-1.088-.777h1.336M8.506 6.762L8.93 5.49l.424 1.272-1.088-.777h1.336M8.506 9.25l.424-1.272.424 1.271-1.088-.777h1.336M8.506 11.736l.424-1.271.424 1.271-1.088-.777h1.336'
      />
    </g>
  </svg>
)

export { UsdIcon }
