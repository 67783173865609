import React from 'react'
import { HrefLink } from './Style'

type Props = {
  href: string
  children: React.ReactNode
}

const Href = ({ href, children }: Props) => (
  <HrefLink href={href} target='_blank' rel='noopener noreferrer'>
    {children}
  </HrefLink>
)

export { Href }
