import { all, fork } from 'redux-saga/effects'

import { saga as LogOut } from './LogOut'
import { saga as CheckAuth } from './CheckAuth'

import { saga as SignIn } from './SignIn'
import { saga as SignUp } from './SignUp'

const AuthSagas = [SignIn, SignUp, CheckAuth, LogOut]

export function* saga() {
  return yield all([...AuthSagas.map(fork)])
}
