import { SignInContainer, SignUpContainer } from '../containers'

import { links } from 'constant'

const routes = [
  {
    path: links.signup,
    component: SignUpContainer,
    isPrivate: false,
  },
  {
    exact: true,
    path: links.signin,
    component: SignInContainer,
    isPrivate: false,
  },
]

export { routes }
