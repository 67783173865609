import { gql, ApolloQueryResult } from 'apollo-boost'
import { Invoice, InvoiceList, Stats } from './@types'

export const CHECK_INVOICE = gql`
  query getCompanyInvoiceLatestInfo($id: String!) {
    getCompanyInvoiceLatestInfo(foreign_id: $id) {
      foreign_id
      addresses {
        address
        rate_usd
        rate_eur
        expected_amount
        crypto_currency
      }
      client_amount
      client_currency
      expired_at
      paid_at
      paid_currency
      paid_tx
      status
      url_success
      url_failure
      client_real_amount
      allow_paid_after_expired
    }
  }
`

export type CheckInvoiceResponse = ApolloQueryResult<{
  getCompanyInvoiceLatestInfo: Invoice
}>

export const GET_INVOICES = gql`
  query findCompanyInvoices(
    $invoice_ids: String
    $crypto_currency: [CommonCurrencyName]
    $crypto_fee_from: Float
    $crypto_fee_to: Float
    $invoice_date_from: String
    $invoice_date_to: String
    $fiat_amount_from: Float
    $fiat_amount_to: Float
    $crypto_addresses: String
    $crypto_amount_from: Float
    $crypto_amount_to: Float
    $fiat_currency: [FiatCurrencyName]
    $tx_ids: String
    $status: InvoiceStatus
    $page: Int
    $page_size: Int
  ) {
    findCompanyInvoices(
      data: {
        invoice_ids: $invoice_ids
        crypto_currency: $crypto_currency
        crypto_fee_from: $crypto_fee_from
        crypto_fee_to: $crypto_fee_to
        invoice_date_from: $invoice_date_from
        invoice_date_to: $invoice_date_to
        fiat_amount_from: $fiat_amount_from
        fiat_amount_to: $fiat_amount_to
        crypto_addresses: $crypto_addresses
        crypto_amount_from: $crypto_amount_from
        crypto_amount_to: $crypto_amount_to
        fiat_currency: $fiat_currency
        tx_ids: $tx_ids
        status: $status
        page: $page
        page_size: $page_size
      }
    ) {
      total
      invoices {
        id
        tx_id
        status
        crypto_fee
        expired_at
        created_at
        address_btc
        foreign_id
        address_eth
        paid_currency
        client_amount
        client_currency
        client_real_amount
        expected_amount_eth
        expected_amount_btc
        allow_paid_after_expired
      }
      stats {
        eth_paid
        btc_paid
        eur_amount
        paid_invoices
        pending_invoices
        created_invoices
      }
    }
  }
`

export type GetInvoicesResponse = ApolloQueryResult<{
  findCompanyInvoices: {
    stats: Stats[]
    total: number
    invoices: InvoiceList[]
  }
}>
