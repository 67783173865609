import styled from 'styled-components'
import { color } from 'theme'

export const Header = styled.header`
  position: relative;
  margin: 0 0 25px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.p`
  margin: 0 auto 20px;
  padding: 0 32px;
  width: 100%;
  max-width: 400px;
  font-size: 14px;
  line-height: 1.4;
  color: ${color.black};
  text-align: center;
  opacity: 0.5;
`

export const ListChoice = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemChoiceInfo = styled.div`
  margin: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ItemChoiceTitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  color: ${color.black};
`

export const IconWrap = styled.span`
  position: absolute;
  right: 25px;
  width: 7px;
  height: 12px;
  svg {
    color: #e6e6e6;
    transition: 0.25s linear;
  }
`

export const ItemChoice = styled.button`
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 336px;
  background-color: ${color.white};
  border: none;
  border-radius: 8px;
  box-shadow: 0 12px 40px #e6ebf0;
  transition: 0.25s linear;
  cursor: pointer;
  outline: none;
  &:not(:last-child) {
    margin: 0 0 16px;
  }
  &:hover {
    box-shadow: 0 4px 12px #ced2d6;
    ${IconWrap} {
      svg {
        color: ${color.black};
      }
    }
  }
  &:focus,
  &:active {
    background-color: #f6f7fb;
    box-shadow: 0 4px 12px #ced2d6;
    ${IconWrap} {
      svg {
        color: ${color.black};
      }
    }
  }
`
