import React, { Fragment } from 'react'
import { StatusElement } from './Style'

type Props = {
  status: string
}

const Status = ({ status }: Props) => {
  if (status) {
    const typoStatus = status.replace(/_/g, ' ')
    return <StatusElement status={typoStatus}>{typoStatus}</StatusElement>
  } else {
    return <Fragment>—</Fragment>
  }
}

export { Status }
