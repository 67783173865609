import { createSelector } from 'reselect'

import { ClientState } from './@types'
import { DOMAIN, DOMAIN_STATE, UI_STATE } from './constants'

const selectUi = (state: ClientState) => state[DOMAIN][UI_STATE]
const selectDomain = (state: ClientState) => state[DOMAIN][DOMAIN_STATE]

// UI
export const selectFiatRequests = () => createSelector(selectUi, subState => subState.fiatRequests)
export const selectCryptoRequests = () => createSelector(selectUi, subState => subState.cryptoRequests)

// DOMAIN
export const selectCryptoRequest = () => createSelector(selectDomain, subState => subState.getCryptoRequests)
export const selectFiatRequest = () => createSelector(selectDomain, subState => subState.getFiatRequests)
export const selectNewFiatRequest = () => createSelector(selectDomain, subState => subState.newFiatRequest)
export const selectNewCryptoRequest = () => createSelector(selectDomain, subState => subState.newCryptoRequest)
