import { gql, ApolloQueryResult } from 'apollo-boost'
import { Request, Staff, Company, CommonCurrencyName } from './@types'

export const GET_STAFF = gql`
  query findWorker {
    findWorker {
      id
      name
      email
      role_name
    }
  }
`

export type GetStaffResponse = ApolloQueryResult<{
  findWorker: Staff[]
}>

export const GET_COMPANY = gql`
  query getCompany {
    findCompanyInfo {
      id
      api_key
      api_secret
      name
      balance {
        currency_name
        value
      }
      addresses {
        currency
        address
      }
      main_currency
      approach_currency
      callback_url
      withdrawal_eth_address
      withdrawal_usdt_address
      withdrawal_btc_address
      bank_detail_usd
      bank_detail_eur
      status
      created_at
    }
  }
`

export type GetInfoResponse = ApolloQueryResult<{
  findCompanyInfo: Company
}>

export const GET_LATEST_REQUEST = gql`
  query getLatestRequests {
    findCompanyInfoUpdateNewerReq {
      id
      name
      value
      created_at
      status
      old_value
      processed_at
      dao_staff_id
    }
  }
`

export const GET_CURRENCIES_LIST = gql`
  query generalFindExchangePairList($filters: PageFilter) {
    generalFindExchangePairList(filters: $filters) {
      list {
        currency_to
      }
    }
  }
`

export type GetLatestUpdateResponse = ApolloQueryResult<{
  findCompanyInfoUpdateNewerReq: Request[]
}>

export type GetCurrenciesListResponse = ApolloQueryResult<{
  generalFindExchangePairList: {
    list: {
      currency_to: CommonCurrencyName
    }[]
  }
}>
