import { CreateInvoice, Invoice } from '../@types'
import {
  SET_INVOICE,
  STOP_RUNNER,
  START_RUNNER,
  CREATE_INVOICE_CLEAR,
  CHECK_INVOICE_FAILURE,
  CHECK_INVOICE_REQUEST,
  CHECK_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
} from '../constants'

export const setInvoice = (invoice: Invoice) => {
  return {
    type: SET_INVOICE,
    payload: { invoice },
  } as const
}

export const checkInvoiceRequest = (id: string) => {
  return {
    type: CHECK_INVOICE_REQUEST,
    payload: { id },
  } as const
}

export const checkInvoiceSuccess = () => {
  return {
    type: CHECK_INVOICE_SUCCESS,
  } as const
}

export const checkInvoiceFailure = (errorMessage: string) => {
  return {
    type: CHECK_INVOICE_FAILURE,
    payload: { errorMessage },
  } as const
}

export const createInvoiceClear = () => {
  return {
    type: CREATE_INVOICE_CLEAR,
  } as const
}

export const createInvoiceRequest = (params: CreateInvoice) => {
  return {
    type: CREATE_INVOICE_REQUEST,
    payload: params,
  } as const
}

export const createInvoiceSuccess = () => {
  return {
    type: CREATE_INVOICE_SUCCESS,
  } as const
}

export const createInvoiceFailure = (errorMessage: string) => {
  return {
    type: CREATE_INVOICE_FAILURE,
    payload: { errorMessage },
  } as const
}

export const startRunner = () => {
  return {
    type: START_RUNNER,
  } as const
}

export const stopRunner = () => {
  return {
    type: STOP_RUNNER,
  } as const
}
