import React, { Fragment } from 'react'

import { useCopy } from 'hooks'
import styled from 'styled-components'

import { font, color } from 'theme'
import { currenciesMock } from 'constant'
import { CryptoFiat, DecimalsFixed, Icon, IconMap, Href } from 'components'

const WrapId = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
`

const Address = styled.span`
  margin: 0 12px 0 0;
  padding: 0;
  font-weight: 400;
`

const CryptoIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

const CryptoName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`

export const AddressValue = styled.span`
  font-weight: normal;
`

export const IconWrapper = styled.span`
  height: 20px;
  width: auto;
  cursor: pointer;
`

export const AddressBtn = styled.button`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.default};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Copied = styled.span`
  display: block;
  width: 100%;
  max-width: 176px;
  padding: 14px 36px;
  font-family: ${font.display};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${color.success};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  z-index: 2;
`

export const NotificationBtn = styled.button`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  color: ${color.default};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
`

export const NotificationIcon = styled.span`
  display: inline-flex;
  width: 16px;
  height: 20px;
`

export const NotificationText = styled.span`
  margin: 0 0 0 10px;
  display: inline-block;
`

export function AddressCopy(value: string) {
  const [status, onClick] = useCopy(value)

  return (
    <Fragment>
      <AddressBtn onClick={onClick}>
        <Address>{value}</Address>
        {status ? (
          <IconWrapper>
            <Icon name='apply' />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <Icon name='copy' size='extraMedium' />
          </IconWrapper>
        )}
      </AddressBtn>
    </Fragment>
  )
}

export function Notification() {
  return (
    <NotificationBtn disabled>
      <NotificationIcon>
        <Icon name='bell' color='#4678ff' />
      </NotificationIcon>
      <NotificationText>Add notification</NotificationText>
    </NotificationBtn>
  )
}

export function Balance(value: string, currency: string) {
  return (
    <Fragment>
      {DecimalsFixed(value, 'crypto')} {currency}
    </Fragment>
  )
}

export function TxIdComponent(value = '', currency = '') {
  const url = currency === 'BTC' ? 'https://www.blockchain.com/btc/tx' : 'https://etherscan.io/tx'
  const link = `${url}/${value}`
  return (
    <Fragment>
      {value ? (
        <WrapId>
          <Href href={link}>{value}</Href>
        </WrapId>
      ) : (
        <Fragment>-</Fragment>
      )}
    </Fragment>
  )
}

export const CurrencyElement = (value: string) => {
  return (
    value && (
      <CryptoIcon>
        <Icon name={value.toLowerCase() as IconMap} />
        <CryptoName>{currenciesMock[value.toUpperCase()]}</CryptoName>
      </CryptoIcon>
    )
  )
}

export function CoinComponent(value: string) {
  return (
    <CryptoIcon>
      <Icon name={value.toLowerCase() as IconMap} size='medium' />
      <CryptoName>{currenciesMock[value.toUpperCase()]}</CryptoName>
    </CryptoIcon>
  )
}

export function ValueComponent(value: string, currency: string) {
  const [first, second] = value.split(' ')
  return <CryptoFiat cryptoAmount={first} cryptoCurrency={currency} fiatAmount={second} fiatCurrency='EUR' />
}
