import { combineReducers } from 'redux'

import { Actions, Modals } from './@types'
import { UI_STATE } from './constants'

import { OPEN_MODAL, CLOSE_MODAL, CLOSE_ALL_MODAL } from './constants'

const initialState: Modals = []

export const modals = (state = initialState, action: Actions) => {
  switch (action.type) {
    case OPEN_MODAL:
      const { name, container } = action.payload
      return [{ name, container }, ...state]
    case CLOSE_MODAL:
      const { name: modalName } = action.payload
      return state.filter(m => m.name !== modalName)
    case CLOSE_ALL_MODAL:
      return []
    default:
      return state
  }
}

export const rootReducer = combineReducers({
  [UI_STATE]: combineReducers({
    modals,
  }),
})
