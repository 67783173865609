import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { withInjectors } from './withInjectors'
import { selectStaffs } from '../selectors'
import { getStaffsRequest } from '../actions'
import { Staffs, ClientState } from '../@types'

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getStaff: getStaffsRequest }, dispatch)

type Props = {
  staffs: Staffs
}

const mapStateToProps = () =>
  createStructuredSelector<ClientState, Props>({
    staffs: selectStaffs(),
  })

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export const StaffInjectors = compose(withInjectors, withConnect)
