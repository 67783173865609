import hoistNonReactStatics from 'hoist-non-react-statics'
import React, { Component } from 'react'
import { ReactReduxContext, ReactReduxContextValue } from 'react-redux'
import { Reducer } from 'redux'

import { ApplicationState } from 'store/@types'

import { ReduxInjectorStore } from './@types'
import getInjectors from './getInjectors'

interface Props {
  key: string
  reducer: Reducer
}

export const injectReducer = ({ key, reducer }: Props) => <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  class ReducerInjector extends Component<P> {
    public static WrappedComponent = WrappedComponent
    public static contextType = ReactReduxContext

    public static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

    public constructor(props: P, context: ReactReduxContextValue) {
      super(props, context)
      getInjectors(context.store as ReduxInjectorStore<ApplicationState>).injectReducer(key, reducer)
    }

    public render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistNonReactStatics(ReducerInjector, WrappedComponent)
}
