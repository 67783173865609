import React from 'react'

import QRCode from 'qrcode.react'

import { getSize } from './helpers'
import { QrCodeProps } from './@types'
import { StyledQrCode } from './Style'

const QrCode = ({ value, size = 'default' }: QrCodeProps) => {
  const { numberSize } = getSize(size)

  return (
    <StyledQrCode size={size}>
      <QRCode value={value} size={numberSize} />
    </StyledQrCode>
  )
}

export { QrCode }
