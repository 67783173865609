import React from 'react'
import moment from 'moment'

import { Title, Text } from './Style'

type Props = {
  company: {
    created_at: string
  }
}

function SubmitDoc({ company }: Props) {
  return (
    <div>
      <Title>Forms have been sent on&nbsp;{moment(company.created_at).format('DD.MM.YY')}</Title>
      <Text>Usually it takes approx 1 working day. We will contact you shortly.</Text>
    </div>
  )
}

export { SubmitDoc }
