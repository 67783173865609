import styled from 'styled-components'
import { color } from 'theme'

// TODO: принимать min max и рассчитывать
const MAX_RED = 10
const MIN_RED = 7.6

const MAX_ORANGE = 7.5
const MIN_ORANGE = 3.6

export const RatingNumber = styled.span<{ rating: number; inverse?: boolean }>`
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.43;
  ${({ rating, inverse }) =>
    rating <= MAX_RED && rating >= MIN_RED
      ? `
      color: ${inverse ? color.success : color.warning};
    `
      : rating <= MAX_ORANGE && rating >= MIN_ORANGE
      ? `
        color: ${color.danger};
      `
      : `color: ${inverse ? color.warning : color.success};
    `};
`
