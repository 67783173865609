import styled from 'styled-components'
import { font } from 'theme'

export const CryptoElement = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const CryptoName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`

export const CommentText = styled.span`
  display: inline-block;
  max-width: 118px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
`
