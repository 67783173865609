import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { font, color } from 'theme'
import { Icon } from 'components'

export const Aside = styled.aside`
  margin: 0 40px 0 0;
  width: 100%;
  max-width: 275px;
  height: 100%;
  display: block;
`

export const AsideLink = styled(NavLink)`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  font-family: ${font.display};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${color.grey};
  background-color: transparent;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  outline: none;
  &:not(:last-child) {
    margin: 0 0 8px;
  }
  svg {
    margin: 0 14px 0 0;
    width: 18px;
    stroke: ${color.grey};
  }
  &:hover,
  &:focus {
    color: ${color.white};
    svg {
      stroke: ${color.default};
      transition: all 0.3s ease-out;
    }
  }
  &.active {
    color: ${color.white};
    background-color: #292833;
    svg {
      stroke: ${color.default};
      transition: all 0.3s ease-out;
    }
  }
`

export const Content = styled.div`
  margin: 0;
  width: 100%;
  max-width: 905px;
`

export const WrapId = styled.span`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${color.white};
`

export const ArrowIcon = styled(Icon)`
  height: auto;
`

export const IconFiat = styled(Icon)`
  margin: 0 10px 0 0;
`

export const Date = styled.p`
  margin: 0;
  font-size: 14px;
`

export const Time = styled.span`
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
`

export const CryptoElement = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`

export const CryptoName = styled.span`
  margin: 0 0 0 8px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
`

export const Rate = styled.span`
  padding: 2px 8px;
  display: inline-block;
  text-transform: uppercase;
  color: ${color.default};
  background-color: #212538;
  border-radius: 12px;
`
