import styled from 'styled-components'
import { breakpoints } from 'theme'

import { Button } from 'components'
import { font, color } from 'theme'

export const BgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 55%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0 0;
  clip-path: polygon(0% 0%, 0% 94%, 50% 100%, 100% 94%, 100% 0%);
  z-index: 1;
`

export const InputWrapper = styled.div`
  margin-top: 36px;
  margin-bottom: 10px;
  &:last-of-type {
    margin-top: 58px;
  }

  ${breakpoints.greaterThan('sm')`
    margin-top: 32px;
    &:last-of-type {
      margin-top: 32px;
    }
  ` as string};
`

export const StyledButton = styled(Button)`
  margin: 32px 0 8px;
`

export const SmallText = styled.span`
  margin: 42px 0 10px;
  display: inline-block;
  font-family: ${font.regular};
  font-size: 12px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
`

export const Error = styled(SmallText)`
  color: ${color.warning};
`

export const Text = styled.span<{ marginText?: string }>`
  display: inline-block;
  font-family: ${font.regular};
  margin: auto 0 0;
  font-size: 14px;
  line-height: 1.33;
  color: rgba(255, 255, 255, 0.8);
  z-index: 2;

  ${({ marginText }) =>
    marginText &&
    `
    margin: ${marginText};
  `}

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const Link = styled(Text)`
  margin: 0;
  display: inline;
  color: ${color.default};
  cursor: pointer;
`

export const SmallLink = styled.a`
  margin: 0;
  font-family: ${font.regular};
  font-size: 12px;
  line-height: 1.5;
  color: ${color.default};
  text-decoration: none;
  cursor: pointer;

  ${breakpoints.greaterThan('sm')`
    margin-top: 24px;
  ` as string};
`

export const CopyWrapper = styled.span`
  position: relative;
  display: block;
  width: 100%;
  padding: 14px 36px;
  font-family: ${font.display};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${color.white};
  text-align: center;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 10px;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;

  ${breakpoints.greaterThan('sm')`
    max-width: 380px;
    margin: 0 auto;
  ` as string};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.01);
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    color: ${color.default};
  }
  &:hover,
  &:focus,
  &:active {
    svg {
      color: ${color.primary};
    }
  }
`

export const CheckboxWrapper = styled.div`
  margin: 30px 0 0;
  display: flex;
  align-items: flex-start;
  text-align: left;
`

export const Currency = styled.span`
  position: absolute;
  right: 20px;
  width: 65px;
  font-size: 20px;
  line-height: 2.5;
  color: ${color.white};
  text-align: right;
  opacity: 0.5;
`

export const CheckboxText = styled.h5`
  margin: 0 0 0 10px;
  font-weight: 400;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
`

export const LinkCheckbox = styled.span`
  color: ${color.default};
  text-decoration: none;
  transition: color 200ms;
  &:hover {
    color: #6191ff;
  }
`
