import { all, fork } from 'redux-saga/effects'

import { saga as CreateOrder } from './CreateOrder'
import { saga as ExchangeRate } from './ExchangeRate'
import { saga as Transactions } from './GetTransactions'

const AuthSagas = [Transactions, ExchangeRate, CreateOrder]

export function* saga() {
  return yield all([...AuthSagas.map(fork)])
}
