import { RequestState } from '@types'
import { Actions } from '../@types'
import { SIGN_IN_SUCCESS, SIGN_IN_REQUEST, SIGN_IN_FAILURE } from '../constants'

const initialDomainState: RequestState = {
  isError: false,
  isSuccess: false,
  isFetching: false,
  errorMessage: '',
}

export const signIn = (state = initialDomainState, action: Actions) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return { ...state, isFetching: true, isError: false, isSuccess: false }
    case SIGN_IN_FAILURE:
      const { errorMessage } = action.payload
      return { ...state, errorMessage, isError: true, isFetching: false }
    case SIGN_IN_SUCCESS:
      return { ...state, isFetching: false, isSuccess: true }
    default:
      return state
  }
}
