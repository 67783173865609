import { gql, ApolloQueryResult } from 'apollo-boost'
import { Invoice } from './@types'

export const CREATE_INVOICE = gql`
  mutation addCompanyInvoice(
    $amount: Float!
    $url_success: String
    $url_failure: String
    $fiat_currency: FiatCurrencyName!
    $allow_paid_expired: Boolean!
  ) {
    addCompanyInvoice(
      data: {
        amount: $amount
        fiat_currency: $fiat_currency
        url_success: $url_success
        url_failure: $url_failure
        allow_paid_expired: $allow_paid_expired
      }
    ) {
      foreign_id
      addresses {
        address
        expected_amount
        crypto_currency
      }
      status
      expired_at
      url_success
      url_failure
      client_amount
      client_currency
    }
  }
`

export type CreateInvoiceResponse = ApolloQueryResult<{
  addCompanyInvoice: Invoice
}>
