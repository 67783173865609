import React from 'react'

type Props = {
  color?: string
}

const Alert = ({ color }: Props) => (
  <svg fill='none' viewBox='0 0 49 42'>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M20.308 3.127L1.592 34.371A4.42 4.42 0 005.371 41H42.8a4.42 4.42 0 003.779-6.629L27.865 3.127a4.42 4.42 0 00-7.557 0z'
      clipRule='evenodd'
    />
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M24.086 14.484v8.839M24.086 29.484v1'
    />
  </svg>
)

export { Alert }
