import { ComponentType } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import { closeAllModal } from 'containers/Modal'

import { withInjectors } from '../injectors'
import { NewRequest as View } from '../components'
import { changeDetailRequest } from '../actions'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeModal: closeAllModal, changeDetails: changeDetailRequest }, dispatch)

type OwnProps = {
  currentValue: string
  type: string
}

const withConnect = connect(null, mapDispatchToProps)

export const NewRequestContainer = compose<ComponentType<OwnProps>>(withInjectors, withConnect)(View)
