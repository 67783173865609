const palette = {
  transparent: 'transparent',
  black: '#000',
  white: '#fff',

  blue: '#524FA1',
  cobalt: '#6765b3',
  darkBlue: '#171828',

  grey: '#a7a6aa',
  greyLight: '#b4b3b8',

  red: '#f44336',
  redLight: '#ff4f42',
  redDark: '#db3c31',

  orange: '#f5a623',
  green: '#4caf50',
  yellow: '#E19821',
  blood: '#322a22',
  dirtyYellow: '#322024',
  kiwi: '#222b27',
}

const COLOR_TRANSPARENT = palette.transparent
const COLOR_WHITE = palette.white
const COLOR_BLACK = palette.black
const COLOR_ALERT = palette.yellow
const COLOR_DISABLED = palette.darkBlue
const COLOR_SUCCESS = palette.green
const COLOR_DARK = palette.darkBlue

const COLOR_DEFAULT = palette.blue
const COLOR_PRIMARY = palette.cobalt

const COLOR_WARNING = palette.red
const COLOR_WARNING_HOVER = palette.redDark

const COLOR_GREY = palette.grey
const COLOR_GREY_LIGHT = palette.greyLight

const COLOR_GHOST_HOVER = `rgba(167, 166, 170, 0.1)`
const COLOR_GHOST_WARNING_HOVER = `rgba(244, 67, 54, 0.1)`
const COLOR_GHOST_WARNING_BORDER = palette.redLight

const COLOR_DANGER = palette.orange
const COLOR_SUN = 'rgba(245, 166, 35, 0.1)'
const COLOR_BLOOD = palette.blood
const COLOR_DIRTY_YELLOW = palette.dirtyYellow
const COLOR_KIWI = palette.kiwi

export const color = {
  transparent: COLOR_TRANSPARENT,
  white: COLOR_WHITE,
  black: COLOR_BLACK,

  disabled: COLOR_DISABLED,
  success: COLOR_SUCCESS,
  dark: COLOR_DARK,

  alert: COLOR_ALERT,
  default: COLOR_DEFAULT,
  primary: COLOR_PRIMARY,

  warning: COLOR_WARNING,
  warningHover: COLOR_WARNING_HOVER,

  grey: COLOR_GREY,
  greyLight: COLOR_GREY_LIGHT,
  ghostHover: COLOR_GHOST_HOVER,

  ghostWarningBorder: COLOR_GHOST_WARNING_BORDER,
  ghostWarningHover: COLOR_GHOST_WARNING_HOVER,

  link: COLOR_DEFAULT,
  linkHover: COLOR_PRIMARY,

  danger: COLOR_DANGER,
  sun: COLOR_SUN,
  blood: COLOR_BLOOD,
  dirtyYellow: COLOR_DIRTY_YELLOW,
  kiwi: COLOR_KIWI,
}
