import React from 'react'

const ZEur = () => (
  <svg fill='none' viewBox='0 0 20 20'>
    <circle cx='10' cy='10' r='10' fill='#000' />
    <path
      fill='#54b9c7'
      d='M5.352 6.23a.605.605 0 010-1.21h8.75a.605.605 0 01.428 1.033L12.262 8.32H10.55l2.09-2.09H5.352zm0 3.301a.605.605 0 100 1.211h2.776l-3.205 3.205a.605.605 0 00.429 1.034h9.316a.605.605 0 000-1.211H6.813l3.028-3.028h4.26a.605.605 0 000-1.21h-8.75z'
    />
  </svg>
)

export { ZEur }
