import React from 'react'

import { NewTable, createColumns } from 'components'
import { Stats } from '../../@types'

import { Wrap } from './Style'
import { COLUMNS } from './Columns'

type Props = {
  loading: boolean
  stats: Stats[]
}

function TotalStats({ stats, loading }: Props) {
  return (
    <Wrap>
      <NewTable loading={loading} columns={createColumns(COLUMNS)} data={stats} />
    </Wrap>
  )
}

export { TotalStats }
